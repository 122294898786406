import { useTitle } from '@/hooks/useTitle';
import { Alerts } from '@/routes/suba/common/components/Alerts.component';
import formatters from '@/utils/Formatters';
import { Typography } from '@mui/material';

import { FC, useEffect, useState } from 'react';

export const SearchAlertsPage: FC = () => {
  const title = useTitle('Alert Center');

  const [actualTabName, setActualTabName] = useState<string>('');

  useEffect(() => {
    title.setPageName(
      actualTabName
        ? `Alert Center ${formatters.displayCase(actualTabName)}`
        : 'Alert Center'
    );
  }, [actualTabName]);

  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Alert Center
      </Typography>
      <Alerts
        onAlertOpen={alert => {
          title.setPageName(`Alert ${alert.id} ${alert.alertName}`);
        }}
        onDrawerClose={() => {
          title.setPageName(
            actualTabName
              ? `Alert Center ${formatters.displayCase(actualTabName)}`
              : 'Alert Center'
          );
        }}
        onTabChange={newAlertType => {
          setActualTabName(newAlertType);
        }}
      />
    </>
  );
};
