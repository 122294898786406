import SimpleDropdown from '@/components/simple-dropdown';
import STATE_CODES from '@/consts/states.constants';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

type TaxDetailsCardProps = {
  keyPrefix: string;
  disableRothInfo: boolean;
  showOptional?: boolean;
};

const TaxDetailsCard: React.FunctionComponent<TaxDetailsCardProps> = (
  props: TaxDetailsCardProps
) => {
  const { keyPrefix, disableRothInfo } = props;
  const formik: FormikContextType<any> = useFormikContext();

  const fieldIds = useMemo(() => {
    return {
      _1099Code: `${keyPrefix}_1099Code`,
      err: `${keyPrefix}err`,
      federalWithholdingPercent: `${keyPrefix}federalWithholdingPercent`,
      needsTaxRecords: `${keyPrefix}needsTaxRecords`,
      rothInitialYear: `${keyPrefix}rothInitialYear`,
      rothOrAfterTaxBasisAmount: `${keyPrefix}rothOrAfterTaxBasisAmount`,
      rothQualifiedWithdrawal: `${keyPrefix}rothQualifiedWithdrawal`,
      stateWithholdingCode: `${keyPrefix}stateWithholdingCode`,
      stateWithholdingPercent: `${keyPrefix}stateWithholdingPercent`,
      taxableAmountNotDetermined: `${keyPrefix}taxableAmountNotDetermined`
    };
  }, [keyPrefix]);

  useUpdateEffect(() => {
    if (
      get(formik.values, fieldIds.taxableAmountNotDetermined) &&
      props.showOptional
    ) {
      formik.setFieldValue('disbursements[0].rothOrAfterTaxBasisAmount', '');
      formik.setFieldValue('disbursements[0].rothQualifiedWithdrawal', false);
      formik.setFieldValue('disbursements[0].rothInitialYear', '');
    }
  }, [formik.values, props.showOptional]);

  return (
    <>
      <Grid
        columnSpacing={{ md: 3, sm: 2, xs: 1 }}
        container
        rowSpacing={2}
        sx={{ py: 2 }}>
        {props.showOptional && (
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={get(formik.values, fieldIds.needsTaxRecords)}
                  />
                }
                label='Needs Tax Records'
                name={fieldIds.needsTaxRecords}
                onChange={(event, checked) => {
                  formik.setFieldValue(fieldIds.needsTaxRecords, checked);
                }}
              />
            </FormControl>
          </Grid>
        )}
        {(props.showOptional === undefined ||
          get(formik.values, fieldIds.needsTaxRecords)) && (
          <>
            <Grid item xs={4}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>%</InputAdornment>
                  )
                }}
                error={
                  get(formik.touched, fieldIds.federalWithholdingPercent) &&
                  Boolean(
                    get(formik.errors, fieldIds.federalWithholdingPercent)
                  )
                }
                fullWidth
                helperText={
                  (get(formik.touched, fieldIds.federalWithholdingPercent) &&
                    get(
                      formik.errors,
                      fieldIds.federalWithholdingPercent
                    )?.toString()) ||
                  ''
                }
                id={fieldIds.federalWithholdingPercent}
                label='Federal Withholding'
                name={fieldIds.federalWithholdingPercent}
                onChange={formik.handleChange}
                required
                size='small'
                value={get(formik.values, fieldIds.federalWithholdingPercent)}
              />
            </Grid>
            <Grid item xs={4}>
              <SimpleDropdown
                fieldId={fieldIds.stateWithholdingCode}
                fieldName='State Withholding Code'
                fieldValues={STATE_CODES}
                size='small'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>%</InputAdornment>
                  )
                }}
                error={
                  get(formik.touched, fieldIds.stateWithholdingPercent) &&
                  Boolean(get(formik.errors, fieldIds.stateWithholdingPercent))
                }
                fullWidth
                helperText={
                  (get(formik.touched, fieldIds.stateWithholdingPercent) &&
                    get(
                      formik.errors,
                      fieldIds.stateWithholdingPercent
                    )?.toString()) ||
                  ''
                }
                id={fieldIds.stateWithholdingPercent}
                label='State Withholding'
                name={fieldIds.stateWithholdingPercent}
                onChange={formik.handleChange}
                required
                size='small'
                value={get(formik.values, fieldIds.stateWithholdingPercent)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={
                  get(formik.touched, fieldIds._1099Code) &&
                  Boolean(get(formik.errors, fieldIds._1099Code))
                }
                fullWidth
                helperText={
                  (get(formik.touched, fieldIds._1099Code) &&
                    get(formik.errors, fieldIds._1099Code)?.toString()) ||
                  ''
                }
                id={fieldIds._1099Code}
                label='1099 Code'
                name={fieldIds._1099Code}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                size='small'
                value={get(formik.values, fieldIds._1099Code)}
              />
            </Grid>
            {get(formik.values, fieldIds.needsTaxRecords) &&
            props.showOptional &&
            !disableRothInfo ? (
              <>
                <Grid item xs={8}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={get(
                            formik.values,
                            fieldIds.taxableAmountNotDetermined
                          )}
                        />
                      }
                      label='Taxable Amount Not Determined'
                      name={fieldIds.taxableAmountNotDetermined}
                      onChange={(event, checked) => {
                        formik.setFieldValue(
                          fieldIds.taxableAmountNotDetermined,
                          checked
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    disabled={get(
                      formik.values,
                      fieldIds.taxableAmountNotDetermined
                    )}
                    error={
                      get(formik.touched, fieldIds.rothOrAfterTaxBasisAmount) &&
                      Boolean(
                        get(formik.errors, fieldIds.rothOrAfterTaxBasisAmount)
                      )
                    }
                    fullWidth
                    helperText={
                      (get(
                        formik.touched,
                        fieldIds.rothOrAfterTaxBasisAmount
                      ) &&
                        get(
                          formik.errors,
                          fieldIds.rothOrAfterTaxBasisAmount
                        )?.toString()) ||
                      ''
                    }
                    id='rothOrAfterTaxBasisAmount'
                    label='Roth or After Tax Basis Amount'
                    name={fieldIds.rothOrAfterTaxBasisAmount}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    size='small'
                    value={get(
                      formik.values,
                      fieldIds.rothOrAfterTaxBasisAmount
                    )}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={8}></Grid>
            )}
            {!disableRothInfo && (
              <>
                <Grid item xs={4}>
                  <TextField
                    disabled={get(
                      formik.values,
                      fieldIds.taxableAmountNotDetermined
                    )}
                    error={
                      get(formik.touched, fieldIds.rothInitialYear) &&
                      Boolean(get(formik.errors, fieldIds.rothInitialYear))
                    }
                    fullWidth
                    helperText={
                      (get(formik.touched, fieldIds.rothInitialYear) &&
                        get(
                          formik.errors,
                          fieldIds.rothInitialYear
                        )?.toString()) ||
                      ''
                    }
                    id={fieldIds.rothInitialYear}
                    label='Initial Roth Year'
                    name={fieldIds.rothInitialYear}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    required={!props.showOptional}
                    size='small'
                    value={get(formik.values, fieldIds.rothInitialYear)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={get(
                            formik.values,
                            fieldIds.rothQualifiedWithdrawal
                          )}
                          disabled={get(
                            formik.values,
                            fieldIds.taxableAmountNotDetermined
                          )}
                        />
                      }
                      label='Qualified Roth'
                      name={fieldIds.rothQualifiedWithdrawal}
                      onChange={(event, checked) => {
                        formik.setFieldValue(
                          fieldIds.rothQualifiedWithdrawal,
                          checked
                        );
                      }}
                    />
                    <FormHelperText
                      error={Boolean(
                        get(formik.errors, fieldIds.rothQualifiedWithdrawal)
                      )}
                      variant='outlined'>
                      {get(formik.touched, fieldIds.rothQualifiedWithdrawal)
                        ? get(
                            formik.errors,
                            fieldIds.rothQualifiedWithdrawal
                          )?.toString() ?? ''
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default TaxDetailsCard;
