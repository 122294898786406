import { PlanFeatures } from '@/models/PlanFeaturesDto.model';

import { isEqual, pick } from 'lodash';

import { Correction, GridRow } from '../types';

export const generateCorrections = (
  initialData: GridRow[],
  newData: GridRow[],
  planFeatures?: PlanFeatures
): Correction[] => {
  const hash = new Map(initialData?.map(value => [value.uuid, value]));

  return newData
    ?.filter(row => !isEqual(row, hash.get(row.uuid)))
    ?.map(row => {
      const initialRow = hash.get(row.uuid);

      return {
        ...pick(row, [
          'sd',
          'rc',
          'sh',
          'ln',
          'em',
          'ps',
          'qc',
          'qm',
          ...(planFeatures?.allowPrevailingWage ? ['pw'] : []),
          ...(planFeatures?.isAfterTaxContributionAllowed ? ['at'] : [])
        ]),
        em_original: initialRow?.em || 0,
        first_name: row.firstName,
        last_name: row.lastName,
        ln_original: initialRow?.ln || 0,
        participant_id: row.participantId,
        ps_original: initialRow?.ps || 0,
        qc_original: initialRow?.qc || 0,
        qm_original: initialRow?.qm || 0,
        rc_original: initialRow?.rc || 0,
        sd_original: initialRow?.sd || 0,
        sh_original: initialRow?.sh || 0,
        ssn: row.ssn,
        ...(planFeatures?.allowPrevailingWage
          ? { pw_original: initialRow?.pw || 0 }
          : {}),
        ...(planFeatures?.isAfterTaxContributionAllowed
          ? { at_original: initialRow?.at || 0 }
          : {})
      };
    });
};
