import Badge from '@/components/badge';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { DOCUMENT_TYPES } from '@/models/ConversionDTO.model';
import { Link, Theme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Column } from './ActionCenterPreviewDialog.component';
import { ActionTypes } from './ActionCenterStepperFilters.component';
import { useAnnualNoticesConfig } from './useAnnualNoticesConfig.hook';
import { useBulkEmailConfig } from './useBulkEmailConfig.hook';
import { useBulkUploadCashAllocationConfig } from './useBulkUploadCashAllocation.hook';
import { useBulkUploadEmployerListConfig } from './useBulkUploadEmployerList.hook';
import { useBulkUploadFilesConfig } from './useBulkUploadFilesConfig.hook';
import { useBulkWelcomeEmailsConfig } from './useBulkWelcomeEmailsConfig.hook';

export const previewColumns: Column[] = [
  {
    id: 'planId',
    label: 'Plan',
    rowComponent: (planId: string) => (
      <Link
        component={RouterLink}
        target='_blank'
        to={`/plans/${planId}/plan`}
        underline='none'>
        {planId}
      </Link>
    ),
    sx: (theme: Theme) => {
      return {
        width: theme.spacing(2)
      };
    }
  },
  { id: 'planName', label: 'Plan Name' },
  {
    id: 'status',
    label: 'Status',
    rowComponent: (status: string) => (
      <Badge color='success' size='small'>
        {status}
      </Badge>
    ),
    sx: (theme: Theme) => {
      return { pl: theme.spacing(3) + ' !important' };
    }
  },
  {
    id: 'effectiveDate',
    label: 'Effective Date',
    rowComponent: (effectiveDate: string) => <>{effectiveDate ?? 'N/A'}</>
  }
];

export const previewErrorColumns: Column[] = [
  {
    id: 'fileName',
    label: 'File Name'
  },
  { id: 'reason', label: 'Reason' }
];

export type BulkActionsPreviewConfig = {
  handleClose: () => void;
  open: boolean;
  data: any;
  onConfirm?: () => any;
};

export type BulkActionsConfig = {
  step2: React.ReactNode;
  visible: boolean;
  step3?: React.ReactNode;
} & (
  | {
      previewErrorData?: any[];
      isPreviewLoading: boolean;
      noPreviewDataMessage: string;
      onConfirm: () => void;
      onPreview: () => void;
      previewColumns: Column[];
      previewData: any[] | Record<string, unknown>;
      previewDialog?: (props: BulkActionsPreviewConfig) => React.ReactNode;
      isPreviewError?: boolean;
      previewErrorColumns?: Column[];
    }
  // some actions do not have a preview
  | { step3: null }
);

export const useBulkActionCenterConfig = (
  details: Record<string, any>,
  setDetails: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const bulkUploadFilesConfig = useBulkUploadFilesConfig(
    details,
    setDetails,
    setActiveStep,
    setDialogOpen,
    snackbar.showSnackbar,
    queryClient
  );

  const bulkEmailConfig = useBulkEmailConfig(
    details,
    setDetails,
    setActiveStep,
    setDialogOpen,
    snackbar.showSnackbar,
    queryClient
  );

  const bulkUploadEmployerListConfig = useBulkUploadEmployerListConfig(
    details,
    setDetails,
    setActiveStep,
    setDialogOpen,
    snackbar.showSnackbar,
    queryClient
  );

  const annualNoticeConfig = useAnnualNoticesConfig(
    details,
    setDetails,
    setActiveStep,
    setDialogOpen,
    snackbar.showSnackbar,
    queryClient
  );

  const bulkWelcomeEmailsConfig = useBulkWelcomeEmailsConfig(queryClient);

  const bulkCashAllocationConfig = useBulkUploadCashAllocationConfig(
    snackbar.showSnackbar,
    queryClient,
    DOCUMENT_TYPES.CASH_ALLOCATION
  );

  const bulkLoanConversionConfig = useBulkUploadCashAllocationConfig(
    snackbar.showSnackbar,
    queryClient,
    DOCUMENT_TYPES.LOANS
  );

  const bulkRothCostBasisConfig = useBulkUploadCashAllocationConfig(
    snackbar.showSnackbar,
    queryClient,
    DOCUMENT_TYPES.ROTH_COST_BASIS
  );

  const bulkDeferralRatesConfig = useBulkUploadCashAllocationConfig(
    snackbar.showSnackbar,
    queryClient,
    DOCUMENT_TYPES.DEFERRAL_RATES
  );

  const bulkHistoricalYearsOfServiceConfig = useBulkUploadCashAllocationConfig(
    snackbar.showSnackbar,
    queryClient,
    DOCUMENT_TYPES.HISTORICAL_YEARS_OF_SERVICE
  );

  return useMemo<Record<ActionTypes, BulkActionsConfig>>(
    () => ({
      [ActionTypes.BULK_UPLOAD_FILES]: bulkUploadFilesConfig,
      [ActionTypes.BULK_EMAIL]: bulkEmailConfig,
      [ActionTypes.BULK_EMPLOYER]: bulkUploadEmployerListConfig,
      [ActionTypes.BULK_WELCOME_EMAILS]: bulkWelcomeEmailsConfig,
      [ActionTypes.ANNUAL_NOTICE]: annualNoticeConfig,
      [ActionTypes.BULK_CASH_ALLOCATION]: bulkCashAllocationConfig,
      [ActionTypes.BULK_LOAN_CONVERSION]: bulkLoanConversionConfig,
      [ActionTypes.BULK_ROTH_COST_BASIS]: bulkRothCostBasisConfig,
      [ActionTypes.BULK_DEFERRAL_RATES]: bulkDeferralRatesConfig,
      [ActionTypes.BULK_HISTORICAL_YEARS_OF_SERVICE]:
        bulkHistoricalYearsOfServiceConfig
    }),
    [
      bulkUploadFilesConfig,
      bulkEmailConfig,
      bulkUploadEmployerListConfig,
      bulkWelcomeEmailsConfig,
      annualNoticeConfig,
      bulkCashAllocationConfig,
      bulkLoanConversionConfig,
      bulkRothCostBasisConfig,
      bulkDeferralRatesConfig,
      bulkHistoricalYearsOfServiceConfig
    ]
  );
};
