import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { DividendAccrualRateDto } from '@/models/suba/dividend-accrual-rates/DividendAccrualRateDTO.model';
import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import DividendAccrualRateService from '@/services/suba/dividend-accrual-rates/DividendAccrualRates.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';

type DeleteAccrualDetailsDialogProps = {
  onClose: () => void;
  security: SecurityDto;
  dividendAccrual: DividendAccrualRateDto;
  onDelete?: () => void;
};

export const DeleteAccrualDetailsDialog = (
  props: DeleteAccrualDetailsDialogProps
) => {
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const removeAccrualRateRecordMutation = useMutation(
    ['DividendAccrualRateService.remove'],
    () =>
      DividendAccrualRateService.remove({
        cusip: props.security.cusip,
        effectiveDate: props.dividendAccrual.effectiveDate
      }),
    {
      onError: (error: AxiosError) => {
        showSnackbar({
          message: error.message,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Dividend Accrual removed',
          severity: 'success'
        });

        queryClient.invalidateQueries(['DividendAccrualRateService.search']);
        props.onClose();
        if (typeof props.onDelete === 'function') {
          props.onDelete();
        }
      }
    }
  );

  return (
    <Dialog fullWidth maxWidth='md' onClose={() => props.onClose()} open>
      <DialogTitle
        sx={{
          pb: 1
        }}>
        Delete Accrual Rate Record
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Box
          data-testid='dividend-accrual-remove-summary'
          sx={{
            bgcolor: theme => theme.palette.primary.light,
            px: 3.25,
            py: 2
          }}>
          <Typography>{props.security.description || '\u2014'}</Typography>
          <Typography
            sx={{
              color: theme => theme.palette.grey[600],
              fontSize: 14
            }}>
            {formatters.formatSecurityName(
              props.security?.symbol,
              props.security?.cusip
            ) || '\u2014'}
          </Typography>
        </Box>
        <Box p={3}>
          <TextStack direction='row' divider rowColumnWidth='dynamic'>
            <TextStackItem>
              <TextLabel>Effective Date</TextLabel>
              <TextValue data-testid='dividend-accrual-effective-date'>
                {dayjs(props.dividendAccrual.effectiveDate).format(
                  'MM/DD/YYYY'
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Accrual Rate</TextLabel>
              <TextValue data-testid='dividend-accrual-rate'>
                {props.dividendAccrual.rate}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Effective Day Count</TextLabel>
              <TextValue data-testid='dividend-effective-day-count'>
                {props.dividendAccrual.dailyDayCount}
              </TextValue>
            </TextStackItem>
          </TextStack>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          data-testid='cancel-button'
          disabled={removeAccrualRateRecordMutation.isLoading}
          onClick={() => props.onClose()}>
          Cancel
        </Button>

        <LoadingButton
          color='warning'
          data-testid='remove-button'
          loading={removeAccrualRateRecordMutation.isLoading}
          onClick={() => removeAccrualRateRecordMutation.mutate()}
          variant='contained'>
          <Typography color='white'>Delete</Typography>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
