import { useTitle } from '@/hooks/useTitle';
import { ReconExceptions } from '@/routes/suba/common/components/ReconExceptions.component';
import { Typography } from '@mui/material';

export const SearchReconExceptionsPage = () => {
  useTitle('Reconciliation');
  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Reconciliation
      </Typography>
      <ReconExceptions />
    </>
  );
};
