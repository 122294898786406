import { CardPlaceholder } from '@/components/card';
import CardInfoField from '@/components/card-info-field';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2Dto } from '@/models';
import { Conversion, ConversionStatus } from '@/models/ConversionDTO.model';
import { ProgramFund } from '@/models/ops/investments/Program.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { PlanService } from '@/services/Plan.service';
import { SecurityMasterService } from '@/services/SecurityMaster.service';
import { userService } from '@/services/User.service';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import Decimal from 'decimal.js';
import { groupBy, omit } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

import ConversionCardButton from './ConversionMainComponents/ConversionCardButton.component';
import ConversionDialogHeader from './ConversionMainComponents/ConversionDialogHeader.component';
import {
  FundOption,
  InvestmentElectionMappingFundAutocomplete
} from './InvestmentElectionMappingFundAutocomplete.component';
import { useConversionAgGrid } from './useConversionAgGrid';
import { useConversionFile } from './useEditFile';
import useUploadOnboardingAgGridFile from './useUploadOnboardingAgGridFile.hook';

type InvestmentElectionMappingProps = {
  sponsorPlanId: number;
  conversion?: Conversion;
  fundMappings?: { oldCusip: string; newCusip: string }[];
  isHasActiveConversion?: boolean;
};

export type FundMappings = {
  newCusip: string;
  oldCusip: string;
}[];

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];

const validationSchema = (planId: number) =>
  yup
    .array()
    .of(
      yup.object().when({
        is: row => {
          return !row.pristine;
        },
        otherwise: schema => schema,
        then: schema =>
          schema.shape({
            allocation: yup
              .number()
              .min(0, 'Minimum allocation % must be greater than or equal to 0')
              .max(
                100,
                'Maximum allocation % must be less than or equal to 100%'
              )
              .required('Allocation is required'),
            cusip: yup.string().required('Ticker/CUSIP is required'),
            ssn: yup
              .string()
              .required('SSN is required.')
              .test(
                'Check for valid SSN format',
                'Please enter a valid SSN',
                (value?: string) => {
                  if (value) {
                    const ssnNoDashes = value.replace(/-/g, '');
                    const ssnDigits = new Set(ssnNoDashes.split(''));
                    return (
                      (formattedSSN.test(value) || plainSSN.test(value)) &&
                      ssnDigits.size > 1 &&
                      !blacklistedSSNs.includes(ssnNoDashes)
                    );
                  }
                  return true;
                }
              )
              .test(
                'Check if SSN exist within a plan',
                'This SSN does not exists in plan.',
                async value => {
                  let checkResult;
                  if (
                    planId &&
                    value &&
                    (formattedSSN.test(value) || plainSSN.test(value)) &&
                    !blacklistedSSNs.includes(value.replaceAll('-', '')) &&
                    new Set(value.replaceAll('-', '').split('')).size > 1
                  ) {
                    const ssnToValidate = formattedSSN.test(value)
                      ? value
                      : formatSsn(value);
                    checkResult = await PlanService.checkSsnWithinPlan(
                      planId,
                      ssnToValidate
                    );
                  } else {
                    await new Promise(resolve => {
                      setTimeout(resolve, 50);
                    });
                  }
                  return checkResult?.data ?? false;
                }
              )
          })
      })
    )
    .test(
      'Check for total allocation value',
      'Total allocation % for each participant must be 100%',
      function (value) {
        const groups = groupBy(
          value.filter(e => !e.pristine),
          'ssn'
        );

        const errors = [];

        for (const ssn in groups) {
          const totalSum = groups[ssn]
            .map(el => new Decimal(el.allocation || 0))
            .reduce((sum, allocation) => sum.plus(allocation), new Decimal(0))
            .toNumber();

          if (totalSum !== 100) {
            value.forEach((row, index) => {
              if (row.ssn === ssn) {
                errors.push(
                  this.createError({
                    path: `[${index}].allocation`
                  })
                );
              }
            });
          }
        }

        return errors.length ? new yup.ValidationError(errors) : true;
      }
    );

const StyledArrowForwardIcon = styled(ArrowForwardIcon)({
  alignSelf: 'center',
  display: 'flex'
});

export const InvestmentElectionMapping: React.FC<
  InvestmentElectionMappingProps
> = ({ sponsorPlanId, conversion, isHasActiveConversion }) => {
  const [fundMappings, setFundMappings] = useState<Record<string, FundOption>>(
    {}
  );
  const [planActionDialogOpened, togglePlanActionDialog] = useToggle(false);
  const conversionAgGrid = useConversionAgGrid(sponsorPlanId);
  const { showSnackbar } = useSnackbar();
  const userFileNameRef = useRef('');
  const userInfo = userService.getUser();
  const isUploadingUser = userInfo?.nickname === conversion?.submittedBy?.id;
  const isNewConversion = !conversion;
  const isDraftStage =
    conversion?.status === ConversionStatus.Draft || !conversion?.status;
  const isViewMode =
    conversion?.status && conversion.status !== ConversionStatus.Draft;

  const {
    mutateAsync: uploadMappning,
    isLoading,
    error
  } = useUploadOnboardingAgGridFile(
    sponsorPlanId as number,
    DOCUMENT_TYPES.INVESTMENT_ELECTION_MAPPINGS,
    userFileNameRef.current
  );

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', sponsorPlanId],
    () => PlanService.getPlanById(sponsorPlanId),
    {
      staleTime: Infinity
    }
  );

  const programId = sponsorPlanQuery.data?.data.relationships.program?.data.id;

  const programFundsQuery = useQuery<ProgramFund[]>(
    ['ProgramService.getProgramFunds', programId],
    () => ProgramService.getProgramFunds(programId),
    {
      enabled: !!programId,
      staleTime: Infinity
    }
  );

  const options = useMemo(
    () => programFundsQuery.data || [],
    [programFundsQuery]
  );

  const onConversionUpload = useCallback(
    async (data: Record<string, unknown>[]) => {
      conversionAgGrid.handleLoad(data);
      if (conversion.investmentElectionMappings.fundMappings) {
        const securitiesSearchLook =
          await SecurityMasterService.getSecuritiesByCusips(
            conversion.investmentElectionMappings.fundMappings.map(
              e => e.newCusip
            )
          );

        setFundMappings(
          conversion.investmentElectionMappings.fundMappings.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.oldCusip]: securitiesSearchLook.find(
                e => e.cusip === curr.newCusip
              )
            }),
            {}
          )
        );
      }
    },
    [conversionAgGrid.handleLoad]
  );

  const columnDefs = useMemo(
    () => [
      {
        alternates: [
          'social',
          'SS #',
          'ss#',
          'social security number',
          'social_security_number',
          'social security',
          'ssn#',
          'Taxpayer ID (SSN or Fed ID)',
          'socialsecuritynumber',
          'employee ssn',
          'national_identifier',
          'employee social',
          'national identifier',
          'ssn number',
          'ss number',
          'ssn/fein',
          'Social Security Number'
        ],
        cellEditorParams: {
          showExcludeRowButton: true
        },
        editable: conversion?.status !== ConversionStatus.Complete,
        field: 'ssn',
        headerName: 'SSN',
        required: true,
        type: 'ssn',
        valueParser: (value: string) => {
          if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
            return formatSsn(value);
          }
          return value;
        },
        width: 130
      },
      {
        alternates: ['Ticker', 'Ticker/CUSIP'],
        editable: conversion?.status !== ConversionStatus.Complete,
        field: 'cusip',
        headerName: 'Ticker/CUSIP',
        required: true,
        type: 'text',
        width: 130
      },
      {
        alternates: ['Allocation %'],
        editable: conversion?.status !== ConversionStatus.Complete,
        field: 'allocation',
        headerName: 'Allocation %',
        required: true,
        type: 'number',
        valueParser: (value: string) => {
          return value && value.endsWith('%')
            ? value.slice(0, -1)
            : isNaN(Number(value))
              ? value
              : Decimal.mul(value, 100).toString();
        },
        width: 130
      },
      {
        alternates: ['Total Alloc%'],
        field: 'totalAllocation',
        headerName: 'Total Alloc%',
        required: false,

        type: 'number',
        valueFormatter: row => {
          const rowsData = row.api.getRenderedNodes().map(node => node.data);
          const groups = groupBy(
            rowsData.filter(e => !e.pristine),
            'ssn'
          );

          return row.data.allocation
            ? groups[row.data.ssn]
                ?.map(
                  el =>
                    new Decimal(
                      isNaN(Number(el.allocation)) ? 0 : el.allocation
                    )
                )
                .reduce(
                  (sum, allocation) => sum.plus(allocation),
                  new Decimal(0)
                )
                .toString()
            : '';
        },
        width: 130
      }
    ],
    [conversion?.status]
  );

  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      onConversionUpload,
      sponsorPlanId,
      CONVERSION_TYPES.INVESTMENT_ELECTION_MAPPINGS,
      conversion?.documentId,
      [...columnDefs].map(i => ({ ...i, valueParser: undefined }))
    );

  const conversionFileExists = async (fileName: string): Promise<boolean> => {
    userFileNameRef.current = fileName;
    return PlanService.conversionFileExists(sponsorPlanId, fileName);
  };

  const uniqueParticipantsCount = useMemo(
    () =>
      new Set(
        conversionAgGrid.gridRows.reduce(
          (acc, curr) => (curr.ssn ? [...acc, curr.ssn] : acc),
          []
        )
      ).size,
    [conversionAgGrid.gridRows]
  );

  const uniqFunds = useMemo(
    () => [
      ...new Set(
        conversionAgGrid.gridRows.reduce(
          (acc, curr) => (curr.ssn && curr.cusip ? [...acc, curr.cusip] : acc),
          []
        )
      )
    ],
    [conversionAgGrid.gridRows]
  ) as unknown as string[];

  const rowsToDto = () => {
    return conversionAgGrid.getDataForUpload({ plan_id: sponsorPlanId });
  };

  const onEditorCommit = useCallback(
    ({ value, oldCusip }) => {
      setFundMappings(
        !value.cusip
          ? omit(fundMappings, [oldCusip])
          : {
              ...fundMappings,
              [oldCusip]: value
            }
      );
    },
    [fundMappings]
  );

  return (
    <>
      {conversion ? (
        <ConversionCardButton
          conversion={conversion}
          isLoading={isLoadingEditFile}
          onClick={() => {
            togglePlanActionDialog(true);
            editFileClicked();
          }}
          sponsorPlanId={sponsorPlanId}
        />
      ) : (
        !isHasActiveConversion && (
          <LoadingButton
            disabled={!sponsorPlanId || isLoading}
            loading={isLoading}
            onClick={() => {
              togglePlanActionDialog(true);
            }}
            startIcon={<FileUploadOutlinedIcon />}>
            Upload
          </LoadingButton>
        )
      )}
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          togglePlanActionDialog(false);
        }}
        open={planActionDialogOpened}>
        <DialogTitle>
          <ConversionDialogHeader
            checkFileNameExists={conversionFileExists}
            columnDefs={columnDefs}
            conversion={conversion}
            onUpload={conversionAgGrid.handleLoad}
            title='Investment Election Mapping'
            viewMode={isViewMode}
          />
        </DialogTitle>
        <DialogContent>
          <ActionTableForm
            emptyRowsCount={!conversion ? 10 : undefined}
            initialValues={conversionAgGrid.gridRows}
            onChange={conversionAgGrid.setGridRows}
            onErrors={conversionAgGrid.handleErrors}
            onSubmit={conversionAgGrid.handleSubmit}
            ref={conversionAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={validationSchema(sponsorPlanId)}>
            {isLoadingEditFile && (
              <Box sx={{ mt: 2 }}>
                <LinearLoading />
              </Box>
            )}
            <Stack
              direction='row'
              divider={<Divider orientation='vertical' />}
              spacing={1}>
              <Stack width='60%'>
                <FileUploadTable
                  columnDefs={columnDefs}
                  errors={conversionAgGrid.gridErrors}
                  isViewMode={isViewMode}
                  onCellChanged={conversionAgGrid.handleCellChange}
                  onRowsChanged={conversionAgGrid.handleRowsChange}
                  rowData={conversionAgGrid.gridRows}
                />
              </Stack>
              <Stack width='40%'>
                <Typography align='left' variant='h6'>
                  Investment Election Mapping
                </Typography>
                <DataTable
                  columnDefs={[
                    {
                      field: 'oldCusip',
                      flex: 1,
                      headerName: 'Old Fund'
                    },
                    {
                      cellRenderer: () => (
                        <StyledArrowForwardIcon fontSize='medium' />
                      ),
                      width: 61
                    },
                    {
                      cellEditor: InvestmentElectionMappingFundAutocomplete,
                      cellEditorParams: {
                        onCommit: onEditorCommit,
                        options: options
                      },
                      cellEditorPopup: true,
                      cellRenderer: params => {
                        if (!params.data.newCusip) {
                          return (
                            <Typography
                              color='rgba(0, 0, 0, 0.6)'
                              variant='body1'>
                              Select...
                            </Typography>
                          );
                        }
                        return (
                          <DataTableStackCell
                            primary={`${params.data.newCusip.symbol} | ${params.data.newCusip.cusip}`}
                            secondary={params.data.newCusip.fundName}
                          />
                        );
                      },
                      editable:
                        conversion?.status !== ConversionStatus.Complete,
                      field: 'newCusip.symbol',
                      flex: 1,
                      headerName: 'New Fund',
                      lockPinned: true,
                      singleClickEdit: true,
                      suppressPaste: true,
                      valueFormatter: params => params.data.newCusip?.symbol
                    }
                  ]}
                  columnSizing='fit'
                  defaultColDef={{
                    resizable: false,
                    suppressMenu: true,
                    suppressMovable: true
                  }}
                  emptyPlaceholderComponent={
                    <CardPlaceholder
                      data-testid='no-data-investment-election-table'
                      subtitle='No funds yet'
                    />
                  }
                  rowData={uniqFunds.map(fund => ({
                    newCusip: fundMappings[fund],
                    oldCusip: fund
                  }))}
                  rowHeight={56}
                />
              </Stack>
            </Stack>
          </ActionTableForm>
        </DialogContent>
        <DialogActions>
          {conversionAgGrid.gridRows.length > 0 && (
            <Grid container>
              <Stack
                direction='row'
                divider={<Divider flexItem orientation='vertical' />}
                spacing={1}>
                <CardInfoField
                  fieldName='Employees'
                  fieldValue={`${uniqueParticipantsCount}`}
                />
              </Stack>
            </Grid>
          )}

          {([ConversionStatus.PendingApproval, ConversionStatus.Draft].includes(
            conversion?.status
          ) ||
            !conversion?.status) && (
            <>
              {userService.hasPermission(
                FeatureLevelPermissions.WRITE_CONVERSION_ASSET_MAPPING_SUBMIT
              ) && (
                <LoadingButton
                  disabled={
                    conversionAgGrid.rowCount === 0 ||
                    isLoadingEditFile ||
                    (isHasActiveConversion && isNewConversion)
                  }
                  loading={isLoading}
                  onClick={async () => {
                    try {
                      await uploadMappning({
                        conversionDto: {
                          ...conversion,
                          documentId:
                            conversion?.status &&
                            conversion?.status !== ConversionStatus.Draft
                              ? conversion.documentId
                              : undefined,
                          status: ConversionStatus.Draft
                        },
                        fundMappings: Object.entries(fundMappings).map(
                          ([oldCusip, fund]) => ({
                            newCusip: fund.cusip,
                            oldCusip: oldCusip
                          })
                        ),
                        results: rowsToDto()
                      });
                    } catch (e) {
                      if (e) {
                        showSnackbar({
                          message: error?.message ?? 'Error',
                          severity: 'error'
                        });
                      }
                    } finally {
                      togglePlanActionDialog(false);
                      conversionAgGrid.form.current?.setValues([]);
                    }
                  }}
                  sx={{ width: 200 }}
                  variant='text'>
                  {isDraftStage ? 'Save as Draft' : 'Revert to Draft'}
                </LoadingButton>
              )}
              {(((isUploadingUser || isNewConversion) &&
                isDraftStage &&
                userService.hasPermission(
                  FeatureLevelPermissions.WRITE_CONVERSION_ASSET_MAPPING_SUBMIT
                )) ||
                (!isUploadingUser &&
                  userService.hasPermission(
                    FeatureLevelPermissions.WRITE_CONVERSION_ASSET_MAPPING_APPROVE
                  ))) && (
                <LoadingButton
                  disabled={
                    conversionAgGrid.errorsCount > 0 ||
                    conversionAgGrid.rowCount === 0 ||
                    isLoadingEditFile ||
                    (isHasActiveConversion && isNewConversion) ||
                    uniqFunds.some(fund => !fundMappings[fund])
                  }
                  loading={isLoading}
                  onClick={async () => {
                    try {
                      await uploadMappning({
                        conversionDto: {
                          ...conversion,
                          documentId:
                            conversion?.status &&
                            conversion?.status !== ConversionStatus.Draft
                              ? conversion.documentId
                              : undefined,
                          status:
                            conversion?.status === ConversionStatus.Draft ||
                            !conversion?.status
                              ? ConversionStatus.PendingApproval
                              : ConversionStatus.Complete
                        },
                        fundMappings: Object.entries(fundMappings).map(
                          ([oldCusip, fund]) => ({
                            newCusip: fund.cusip,
                            oldCusip: oldCusip
                          })
                        ),
                        results: rowsToDto()
                      });
                    } catch (e) {
                      showSnackbar({
                        message:
                          e.response?.data?.message ??
                          e.message ??
                          'Unknown Error',
                        severity: 'error'
                      });
                    } finally {
                      togglePlanActionDialog(false);
                      conversionAgGrid.form.current?.setValues([]);
                    }
                  }}
                  sx={{ width: 300 }}
                  variant='contained'>
                  {conversion?.status === ConversionStatus.Draft ||
                  !conversion?.status
                    ? 'Submit for approval'
                    : 'Approve & Submit'}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
