import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';

import { useContext } from 'react';

import { ACHReversalAlertDetailFields } from './detail-fields/ACHReversalAlertDetailFields.component';
import { CashTransferRejectedAlertDetailFields } from './detail-fields/CashTransferRejectedAlertDetailFields.component';
import { CustodianDestinationRejectAlertDetailFields } from './detail-fields/CustodianDestinationRejectAlertDetailFields.component';
import { DefaultAlertDetailFields } from './detail-fields/DefaultAlertDetailFields.component';
import { MoneyInAlertDetailFields } from './detail-fields/MoneyInAlertDetailFields.component';
import { OrderCustodianUnitSellOffsetAlertDetailFields } from './detail-fields/OrderCustodianUnitSellOffsetAlertDetailFields.component';
import { RolloversAlertDetailFields } from './detail-fields/RolloversAlertDetailFields.component';
import { UnexpectedStatusUpdateDetailFields } from './detail-fields/UnexpectedStatusUpdateDetailFields.component';

export const AlertDetails = (): JSX.Element | null => {
  const alert = useContext(AlertContext);

  if (!alert) return null;

  switch (alert.alertSubType) {
    case AlertSubType.NewConversionDeposit:
    case AlertSubType.UnclassifiedDeposit:
      return <MoneyInAlertDetailFields alert={alert} />;
    case AlertSubType.OrderCustodianUnitSellOffset:
      return <OrderCustodianUnitSellOffsetAlertDetailFields alert={alert} />;
    case AlertSubType.ACHReversal:
      return <ACHReversalAlertDetailFields alert={alert} />;
    case AlertSubType.UnexpectedStatusUpdate:
      return <UnexpectedStatusUpdateDetailFields alert={alert} />;
    case AlertSubType.NewRolloverDeposit:
      return <RolloversAlertDetailFields alert={alert} />;
    case AlertSubType.CashTransferRejected:
      return <CashTransferRejectedAlertDetailFields alert={alert} />;
    case AlertSubType.CustodianReject:
    case AlertSubType.DestinationReject:
      return <CustodianDestinationRejectAlertDetailFields alert={alert} />;
    default:
      return <DefaultAlertDetailFields alert={alert} />;
  }
};
