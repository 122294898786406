import Badge from '@/components/badge';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useTitle } from '@/hooks/useTitle';
import { orderStatusColorMap } from '@/models/suba/common/OrderStatusColorMap.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ParentAccountOrderDetailCellRenderer } from '@/routes/suba/accounts/common/components/ParentAccountOrderDetailCellRenderer.component';
import { SubAccountOrdersTab } from '@/routes/suba/accounts/common/components/tabs/SubAccountOrdersTab.route';
import { DeferParentAccountOrderDialog } from '@/routes/suba/common/components/dialogs/DeferParentAccountOrderDialog.component';
import { ExecuteParentAccountOrderDialog } from '@/routes/suba/common/components/dialogs/ExecuteParentAccountOrderDialog.component';
import { ResubmitParentAccountOrderDialog } from '@/routes/suba/common/components/dialogs/ResubmitParentAccountOrderDialog.component';
import { TransactionsTab } from '@/routes/suba/common/components/tabs/transactions';
import ParentAccountOrderService from '@/services/suba/parent-account-orders/ParentAccountOrder.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type ParentAccountOrdersDetailPageProps = {
  parentAccountOrderId: string;
};

export const ParentAccountOrdersDetailPage: FC = () => {
  const { parentAccountOrderId } =
    useParams<ParentAccountOrdersDetailPageProps>();
  const [errorStatus, setErrorStatus] = useState<number | null>(null);
  const [isShowingDetails, setIsShowingDetails] = useState(false);
  const [openDeferDialog, setOpenDeferDialog] = useState(false);
  const [openResubmitDialog, setOpenResubmitDialog] = useState(false);
  const [openExecuteDialog, setOpenExecuteDialog] = useState(false);

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
  );

  const { data: parentAccountOrderQueryData, isFetching } = useQuery(
    ['ParentAccountOrderService.getById', parentAccountOrderId],
    () => ParentAccountOrderService.getById(Number(parentAccountOrderId)),
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
        if (err?.response?.status) setErrorStatus(err.response.status);
      },
      onSuccess: () => {
        setErrorStatus(null);
      },
      staleTime: Infinity
    }
  );

  const getTabIdx = (availableTabs: TabData[]) => {
    const index = availableTabs.findIndex(
      tab => location.pathname === tab.path
    );

    return index < 0 ? 0 : index;
  };
  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => {
      const calculatedTabElements: TabData[] = [];
      // don't add tabs until data is loaded
      // also ourParentOrderId could be null and so we can't show the tabs if that is the case
      if (
        parentAccountOrderQueryData &&
        parentAccountOrderQueryData.ourParentOrderId
      ) {
        calculatedTabElements.push({
          component: (
            <SubAccountOrdersTab
              customDefaultFilters={{
                ourParentOrderId: parentAccountOrderQueryData.ourParentOrderId
              }}
              hideFilters
              hideHeader
              includeColumns={[
                'subAccount',
                'tradeType',
                'orderStatus',
                'securityOrderType',
                'orderUnits',
                'tradeDate'
              ]}
            />
          ),
          label: 'Sub Account Orders',
          path: `/ops/parent-account-orders/${parentAccountOrderQueryData?.id}/sub-account-orders`
        });

        if (parentAccountOrderQueryData.parentAccount?.parentAccountId) {
          calculatedTabElements.push({
            component: (
              <TransactionsTab
                accountId={
                  parentAccountOrderQueryData.parentAccount.parentAccountId
                }
                accountLevel={AccountLevel.SubAccount}
                customDefaultFilters={{
                  orderId: parentAccountOrderQueryData.ourParentOrderId,
                  statuses: [],
                  // grab all transaction base types except buy and sell
                  transactionBaseTypes: Object.values(
                    TransactionBaseType
                  ).filter(
                    value =>
                      ![
                        TransactionBaseType.Buy,
                        TransactionBaseType.Sell
                      ].includes(value)
                  )
                }}
                hideActions
                hideFilters
                hideHeader
                includeColumns={[
                  'status',
                  'transactionBaseType',
                  'units',
                  'securityUnitPrice',
                  'amount',
                  'tradeDate',
                  'sourceTransactionId'
                ]}
                preventSearchInUrl
              />
            ),
            label: 'Breakage Transactions',
            path: `/ops/parent-account-orders/${parentAccountOrderQueryData?.id}/transactions`
          });
        }
      }

      return calculatedTabElements;
    },
    [parentAccountOrderQueryData]
  );

  const tabs: SimpleTabsProps = {
    defaultTabIdx: getTabIdx(tabElements),
    onChange: index => {
      title.setPageName(
        `${PAGE_NAME} ${parentAccountOrderQueryData?.ourParentOrderId} ${tabElements[index].label}`
      );
    },
    tabs: tabElements,
    tabsAriaLabel: 'parent-account-order-tabs'
  };

  // fetch friendly display name for tradeType
  const displayTradeType = formatters.getValueKey(
    TradeType,
    parentAccountOrderQueryData?.tradeType
  );

  const PAGE_NAME = `${parentAccountOrderQueryData?.security.symbol} ${formatters.displayCase(displayTradeType)} Order`;

  const title = useTitle(
    `${PAGE_NAME} ${parentAccountOrderQueryData?.ourParentOrderId}`
  );

  if (errorStatus === 404) {
    return redirectToErrorPage(new Error('404'));
  }

  // fetch friendly display name for orderStatus
  const displayOrderStatus = formatters.getValueKey(
    OrderStatus,
    parentAccountOrderQueryData?.orderStatus
  );

  // fetch friendly display name for securityOrderType
  const displaySecurityOrderType = formatters.snakeToCamelCase(
    formatters.getValueKey(
      SecurityOrderType,
      parentAccountOrderQueryData?.securityOrderType
    )
  ); // snake case to camel case is necessary until the SecurityOrderType enum has its keys updated to camel case to match the convention

  // breadcrumb items
  const paths = [{ name: 'Accounts', to: '/ops/accounts' }];
  if (parentAccountOrderQueryData?.parentAccount) {
    // format friendly parent account name
    const parentAccountName =
      parentAccountOrderQueryData?.parentAccount?.accountType ===
        'SuperOmnibus' ||
      parentAccountOrderQueryData?.parentAccount?.accountType === 'House'
        ? parentAccountOrderQueryData?.parentAccount?.accountName
        : parentAccountOrderQueryData?.plan?.name;

    paths.push({
      name:
        parentAccountName ||
        `ID: ${parentAccountOrderQueryData.parentAccount.parentAccountId}`,
      to: `/ops/accounts/${parentAccountOrderQueryData.parentAccount.parentAccountId}`
    });

    paths.push({
      name: 'Trading',
      to: `/ops/accounts/${parentAccountOrderQueryData.parentAccount.parentAccountId}/trading`
    });
  }

  return (
    <>
      {isFetching && <LinearLoading />}
      {!isFetching && parentAccountOrderQueryData && (
        <>
          {openDeferDialog && (
            <DeferParentAccountOrderDialog
              onClose={() => {
                setOpenDeferDialog(false);
              }}
              open={openDeferDialog}
              parentAccountOrder={parentAccountOrderQueryData}
            />
          )}
          {openResubmitDialog && (
            <ResubmitParentAccountOrderDialog
              onClose={() => {
                setOpenResubmitDialog(false);
              }}
              open={openResubmitDialog}
              parentAccountOrder={parentAccountOrderQueryData}
            />
          )}
          {openExecuteDialog && (
            <ExecuteParentAccountOrderDialog
              onClose={() => {
                setOpenExecuteDialog(false);
              }}
              open={openExecuteDialog}
              parentAccountOrder={parentAccountOrderQueryData}
            />
          )}
          <Box
            data-testid='parent-account-orders-detail-header'
            display='flex'
            justifyContent='space-between'>
            <NavigationBreadcrumbs
              data-testid='parent-account-orders-detail-breadcrumbs'
              paths={paths}
            />
          </Box>
          <Box>
            <Typography role='heading' variant='h4'>
              {PAGE_NAME}
            </Typography>
            <Box alignItems='center' display='flex' justifyContent='left'>
              <Typography data-testid='detail-order-id' variant='subtitle1'>
                Order ID: {parentAccountOrderQueryData.ourParentOrderId}
              </Typography>

              <CopyToClipboard
                copyName='Order ID'
                copyValue={String(parentAccountOrderQueryData.ourParentOrderId)}
              />
            </Box>
          </Box>
          {hasWritePermissions &&
            [
              OrderStatus.Rejected,
              OrderStatus.Submitted,
              OrderStatus.Accepted
            ].includes(parentAccountOrderQueryData.orderStatus) && (
              <Stack
                alignItems='center'
                data-testid='parent-account-orders-detail-actions'
                direction='row'
                spacing={1}
                sx={{ mb: 3.75, mt: 3.25 }}>
                <Typography>Available Actions</Typography>
                {parentAccountOrderQueryData.orderStatus ===
                  OrderStatus.Rejected && (
                  <Button
                    color='primary'
                    data-testid='parent-account-orders-detail-actions-defer-button'
                    onClick={() => setOpenDeferDialog(true)}
                    sx={{
                      borderRadius: '999px',
                      fontSize: 13,
                      lineHeight: 1.4,
                      py: 0.75
                    }}
                    variant='outlined'>
                    Defer
                  </Button>
                )}
                {parentAccountOrderQueryData.orderStatus ===
                  OrderStatus.Rejected && (
                  <Button
                    color='primary'
                    data-testid='parent-account-orders-detail-actions-resubmit-button'
                    onClick={() => setOpenResubmitDialog(true)}
                    sx={{
                      borderRadius: '999px',
                      fontSize: 13,
                      lineHeight: 1.4,
                      py: 0.75
                    }}
                    variant='outlined'>
                    Resubmit
                  </Button>
                )}

                <Button
                  color='primary'
                  data-testid='parent-account-orders-detail-actions-execute-button'
                  onClick={() => setOpenExecuteDialog(true)}
                  sx={{
                    borderRadius: '999px',
                    fontSize: 13,
                    lineHeight: 1.4,
                    py: 0.75
                  }}
                  variant='outlined'>
                  Execute
                </Button>
              </Stack>
            )}
          <TextStack
            divider
            id='parent-account-orders-detail-header-fields'
            rowColumnWidth='dynamic'
            sx={{ mb: 2.5, p: 0 }}>
            <TextStackItem>
              <TextLabel>Status</TextLabel>
              <TextValue>
                <Badge
                  color={
                    orderStatusColorMap[parentAccountOrderQueryData.orderStatus]
                  }
                  size='small'>
                  {formatters.displayCase(displayOrderStatus)}
                </Badge>
              </TextValue>
            </TextStackItem>

            <TextStackItem>
              <TextLabel>Security</TextLabel>
              <TextValue
                links={[
                  {
                    label: `View Security Details`,
                    target: '_blank',
                    to: `/ops/securities/${parentAccountOrderQueryData.security.cusip}`
                  }
                ]}>
                {formatters.formatSecurityName(
                  parentAccountOrderQueryData.security.symbol,
                  parentAccountOrderQueryData.security.cusip
                )}
              </TextValue>
            </TextStackItem>

            <TextStackItem>
              <TextLabel>Trade Type</TextLabel>
              <TextValue>{formatters.displayCase(displayTradeType)}</TextValue>
            </TextStackItem>

            <TextStackItem>
              <TextLabel>Order Type</TextLabel>
              <TextValue>
                {formatters.displayCase(displaySecurityOrderType)}
              </TextValue>
            </TextStackItem>

            <TextStackItem>
              <TextLabel>
                {[
                  SecurityOrderType.DOLLAR_ALL,
                  SecurityOrderType.DOLLAR_ORDER
                ].includes(parentAccountOrderQueryData.securityOrderType)
                  ? 'Amount'
                  : 'Units'}
              </TextLabel>
              <TextValue>
                {[
                  SecurityOrderType.DOLLAR_ALL,
                  SecurityOrderType.DOLLAR_ORDER
                ].includes(parentAccountOrderQueryData.securityOrderType)
                  ? parentAccountOrderQueryData.orderAmount
                    ? formatters.formatDollars(
                        parentAccountOrderQueryData.orderAmount
                      )
                    : ''
                  : parentAccountOrderQueryData.orderUnits
                    ? formatters.formatDecimal(
                        parentAccountOrderQueryData.orderUnits,
                        3
                      )
                    : ''}
              </TextValue>
            </TextStackItem>
          </TextStack>
          {isShowingDetails && (
            <Box sx={{ mb: 2 }}>
              <ParentAccountOrderDetailCellRenderer
                data={parentAccountOrderQueryData}
                disablePadding
              />
            </Box>
          )}
          <Button
            endIcon={isShowingDetails ? <RemoveIcon /> : <AddIcon />}
            onClick={() => setIsShowingDetails(!isShowingDetails)}
            sx={{ fontSize: 13, lineHeight: 1.7 }}
            variant='text'>
            {isShowingDetails ? 'Less' : 'More'} Details
          </Button>
          {tabs.tabs.length > 0 && (
            <Box
              data-testid='parent-account-orders-related-record-tables'
              sx={{ mt: 3 }}>
              <SimpleTabs
                data-testid='parent-account-orders-detail-tabs'
                {...tabs}
                style={{
                  border: '1px solid #e0e0e0',
                  borderBottom: '0px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                  marginBottom: '0'
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
