import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { WorkflowContext } from '@/routes/suba/accounts/common/contexts/WorkflowContext';
import formatters from '@/utils/Formatters';

import { useContext } from 'react';

export const NewPlanInputFields = () => {
  const workflow = useContext(WorkflowContext);

  return (
    <TextStack direction='column' divider>
      {workflow?.planId && workflow.plan?.name && (
        <TextStackItem>
          <TextLabel>Plan</TextLabel>
          <TextValue
            links={[
              {
                label: `ID: ${workflow.planId}`,
                target: '_blank',
                to: `/plans/${workflow.planId}/plan`
              }
            ]}>
            {workflow.plan?.name}
          </TextValue>
        </TextStackItem>
      )}

      <TextStackItem>
        <TextLabel>Plan Setup Request</TextLabel>
        <TextValue
          links={
            workflow.coreRequest
              ? [
                  {
                    label: `View onboarding`,
                    target: '_blank',
                    to: `/plans/${workflow?.coreRequest?.context?.planId}/onboard`
                  }
                ]
              : undefined
          }
        />
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {formatters.formatFromIsoDate(workflow?.createdAt)}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
