import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import { LinearProgress, Stack } from '@mui/material';

import { useContext } from 'react';

import { CashTransferRejectedExpandedActions } from './cash-transfer-rejected-expanded-components/CashTransferRejectedExpandedActions.component';
import { CashTransferRejectedExpandedFromAccountBalances } from './cash-transfer-rejected-expanded-components/CashTransferRejectedExpandedFromAccountBalances.component';
import { CashTransferRejectedExpandedRecentReversals } from './cash-transfer-rejected-expanded-components/CashTransferRejectedExpandedRecentReversals.component';
import { CashTransferRejectedExpandedReconciliationExceptions } from './cash-transfer-rejected-expanded-components/CashTransferRejectedExpandedReconciliationExceptions.component';

export const CashTransferRejectedExpandedAlertDetails = () => {
  const alert = useContext(AlertContext);

  if (!alert) return <LinearProgress />;

  return (
    <Stack spacing={2}>
      <CashTransferRejectedExpandedActions />
      {alert?.details?.request?.fromSubAccount && (
        <CashTransferRejectedExpandedFromAccountBalances />
      )}
      <CashTransferRejectedExpandedRecentReversals />
      <CashTransferRejectedExpandedReconciliationExceptions />
    </Stack>
  );
};
