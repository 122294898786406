import Card, { CardHeader } from '@/components/card';
import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import NoInvestmentOptionLinked from '@/routes/ops/investments//NoInvestmentOptionLinked.component';
import {
  ExternalProviderInvestmentOptionSearchData,
  ExternalProviderInvestmentOptionService
} from '@/services/ops/investments/ExternalProviderInvestmentOptionService.service';
import { Box, Link, TableCell, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { FC } from 'react';

const useTableCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const NliTableCell: FC<CellComponentProps> = (
  cellProps: CellComponentProps
) => {
  const classes = useTableCellStyles();
  const field = cellProps.row[cellProps.column.field];

  return (
    <TableCell className={classes.size} component='th' scope='row'>
      <Box>
        {cellProps.column.field === 'name' ? (
          <Link
            href={`/ops/investments/target-series/${cellProps.row.baseInvestmentOptionId}`}
            rel='noopener noreferrer'
            target='_blank'>
            {field}
          </Link>
        ) : (
          field
        )}
      </Box>
    </TableCell>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    createdDateWidth: {
      width: '20%'
    },
    optionIdWidth: {
      width: '10%'
    },
    optionNameWidth: {
      width: '70%'
    }
  })
);

export const ProgramTiaaNli = () => {
  const classes = useStyles();

  const nliColumns: GridColDef[] = [
    {
      cellClassName: classes.optionIdWidth,
      field: 'baseInvestmentOptionId',
      headerName: 'ID'
    },
    {
      cellClassName: classes.optionNameWidth,
      field: 'name',
      headerName: 'Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'createdAt',
      headerName: 'Created Date'
    },
    { field: 'programCount', headerName: 'Programs' }
  ];

  const nliSearchResult = useQuery<
    ExternalProviderInvestmentOptionSearchData[]
  >(
    [
      'ExternalProviderInvestmentOptionService.getNliExternalProviderInvestmentOptions'
    ],
    () =>
      ExternalProviderInvestmentOptionService.getNliExternalProviderInvestmentOptions()
  );

  return (
    <Card>
      <CardHeader title='TIAA NLI' />
      <CollapsibleTable
        cellComponent={NliTableCell}
        columns={nliColumns}
        noDataPlaceholderComponent={
          <NoInvestmentOptionLinked typeName='TIAA NLI' />
        }
        tableData={nliSearchResult?.data ?? []}
      />
    </Card>
  );
};

ProgramTiaaNli.displayName = 'ProgramTiaaNli';
