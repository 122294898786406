import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { SubAccountOrderDto } from '@/models/suba/sub-account-orders/SubAccountOrderDTO.model';
import formatters from '@/utils/Formatters';
import { Divider, Stack } from '@mui/material';

export const subAccountOrderDetailCellRendererFields: Partial<
  Record<
    keyof SubAccountOrderDto,
    { label: string; valueFormatter: (value: any) => any }
  >
> = {
  confirmDate: {
    label: 'Confirm Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  createdAt: {
    label: 'Created At',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDate(value) : null
  },
  custodianAccountNumber: {
    label: 'Custodian Account #',
    valueFormatter: (value: string | null): string | null => value
  },
  custodianBatchDate: {
    label: 'Custodian Batch Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  custodianBatchId: {
    label: 'Custodian Batch ID',
    valueFormatter: (value: string | null): string | null => value
  },
  custodyConfirmId: {
    label: 'Custodian Confirm ID',
    valueFormatter: (value: string | null): string | null => value
  },
  id: {
    label: 'Order ID',
    valueFormatter: (value: string | null): string | null => value
  },
  omsBatchId: {
    label: 'OMS Batch ID',
    valueFormatter: (value: string | null): string | null => value
  },
  ourParentOrderId: {
    label: 'Parent Order ID',
    valueFormatter: (value: string | null): string | null => value
  },
  sourceTransactionId: {
    label: 'Source Transaction ID',
    valueFormatter: (value: string | null): string | null => value
  },
  tracerId: {
    label: 'Tracer ID',
    valueFormatter: (value: string | null): string | null => value
  },
  tradeAmount: {
    label: 'Executed Amount',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDollars(value) : null
  },
  tradeDate: {
    label: 'Trade Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  tradeFee: {
    label: 'Trade Fee',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDollars(value) : null
  },
  tradeUnitPrice: {
    label: 'Executed Price',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDollars(value) : null
  },
  tradeUnits: {
    label: 'Executed Units',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDecimal(value, 3) : null
  },
  updatedAt: {
    label: 'Updated At',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDate(value) : null
  }
};

const sections: Array<Array<keyof SubAccountOrderDto>> = [
  [
    'tradeDate',
    'confirmDate',
    'tradeAmount',
    'tradeUnits',
    'tradeUnitPrice',
    'tradeFee'
  ],
  [
    'custodianAccountNumber',
    'custodyConfirmId',
    'custodianBatchDate',
    'custodianBatchId',
    'omsBatchId'
  ],
  [
    'id',
    'ourParentOrderId',
    'sourceTransactionId',
    'tracerId',
    'createdAt',
    'updatedAt'
  ]
];

export const SubAccountOrderDetailCellRenderer = ({
  data: rowData
}: {
  data: SubAccountOrderDto;
}): JSX.Element => (
  <Stack
    direction='row'
    divider={<Divider flexItem orientation='vertical' />}
    spacing={2}
    sx={{ p: 2 }}>
    {sections.map(section => (
      // eslint-disable-next-line react/jsx-key
      <TextStack direction='column'>
        <>
          {section.map(fieldId => (
            <TextStackItem key={fieldId}>
              <TextLabel>
                {subAccountOrderDetailCellRendererFields[fieldId]?.label}
              </TextLabel>
              <TextValue>
                {subAccountOrderDetailCellRendererFields[
                  fieldId
                ]?.valueFormatter(rowData[fieldId])}
              </TextValue>
            </TextStackItem>
          ))}
        </>
      </TextStack>
    ))}
  </Stack>
);
