import SimpleDropdown from '@/components/simple-dropdown';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { SubaErrors } from '@/hooks/useSubaErrors.hook';
import { PlanSubAccount } from '@/models/WithdrawalsDTO.model';
import formatters from '@/utils/Formatters';
import { Card, CardContent, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { FormikContextType, useFormikContext } from 'formik';
import { FC, useMemo } from 'react';

type DisbursementSubaccountCardProps = {
  planAccounts?: PlanSubAccount[];
  errors: SubaErrors;
};

export const DisbursementSubaccountCard: FC<DisbursementSubaccountCardProps> = (
  props: DisbursementSubaccountCardProps
) => {
  const formik: FormikContextType<any> = useFormikContext();

  const fundingSourcesOpt = useMemo<
    Array<{ value: string; option: string }>
  >(() => {
    const accounts = props.planAccounts?.map(i => {
      return {
        option: `plan:${i.accountType}`,
        value: `plan:${i.accountType}`
      };
    });
    accounts?.push({
      option: 'house:billing',
      value: 'house:billing'
    });
    return accounts;
  }, [props.planAccounts]);

  const balance = useMemo(() => {
    const splitted = formik.values.accountType?.split(':');
    return splitted?.[0] === 'plan'
      ? props.planAccounts?.find(i => i.accountType === splitted[1])?.balance
      : undefined;
  }, [formik.values.accountType, props.planAccounts]);

  return (
    <Card elevation={0} variant='outlined'>
      <CardContent>
        <Typography
          id='tableTitle'
          sx={{ fontWeight: 500, mb: 3 }}
          variant='subtitle1'>
          Sub Account
        </Typography>
        <Grid2 container spacing={2}>
          <Grid2 xs={3}>
            <SimpleDropdown
              fieldId='accountType'
              fieldName='Funding Source*'
              fieldOptions={fundingSourcesOpt}
              onChange={event => {
                formik.setFieldValue('accountType', event.target.value);
              }}
              size='small'
            />
          </Grid2>
          {formik.values.accountType && (
            <Grid2 alignItems='center' display='flex' xs={3}>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Available Balance</TextLabel>
                  <TextValue>
                    {balance !== undefined
                      ? formatters.formatDollars(balance)
                      : EMPTY_FIELD_PLACEHOLDER}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Grid2>
          )}
        </Grid2>
      </CardContent>
    </Card>
  );
};

DisbursementSubaccountCard.displayName = 'DisbursementSubaccountCard';
