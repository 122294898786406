import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import { LinearProgress, Stack } from '@mui/material';

import { useContext } from 'react';

import { CustodianDestinationRejectActions } from './custodian-destination-reject-expanded-components/CustodianDestinationRejectActions.component';
import { CustodianDestinationRejectHistory } from './custodian-destination-reject-expanded-components/CustodianDestinationRejectHistory.component';
import { CustodianDestinationRejectParentAccountBalances } from './custodian-destination-reject-expanded-components/CustodianDestinationRejectParentAccountBalances.component';
import { CustodianDestinationRejectReconciliationExceptions } from './custodian-destination-reject-expanded-components/CustodianDestinationRejectReconciliationExceptions.component';

export const CustodianDestinationRejectExpandedAlertDetails = () => {
  const alert = useContext(AlertContext);

  if (!alert) return <LinearProgress />;

  return (
    <Stack spacing={2}>
      <CustodianDestinationRejectActions />
      <CustodianDestinationRejectParentAccountBalances />
      <CustodianDestinationRejectReconciliationExceptions />
      <CustodianDestinationRejectHistory />
    </Stack>
  );
};
