import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import { ParentToCustodianCashBalanceDTO } from '@/models/suba/parent-to-custodian-cash-balance/ParentToCustodianCashBalanceDTO.model';
import { ParentToCustodianCashBalanceRequest } from '@/models/suba/parent-to-custodian-cash-balance/ParentToCustodianCashBalanceRequest.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, FormControl, Stack, SxProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';
import { Field, Form, Formik } from 'formik';

type ParentToCustodianCashBalancesTabProps = {
  parentAccountId: string;
  exceptionDate: string;
  sx?: SxProps;
};

export const ParentToCustodianCashBalancesTab = ({
  parentAccountId,
  exceptionDate,
  sx
}: ParentToCustodianCashBalancesTabProps): JSX.Element => {
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );
  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );
  const [startDate, setStartDate] = useUrlStateParams(
    dayjs(exceptionDate).subtract(7, 'days').format('YYYY-MM-DD'),
    'startDate',
    value => String(value),
    value =>
      value
        ? dayjs(value).format('YYYY-MM-DD')
        : dayjs(exceptionDate).subtract(7, 'days').format('YYYY-MM-DD')
  );

  const [endDate, setEndDate] = useUrlStateParams(
    dayjs(exceptionDate).add(7, 'days').format('YYYY-MM-DD'),
    'endDate',
    value => String(value),
    value =>
      value
        ? dayjs(value).format('YYYY-MM-DD')
        : dayjs(exceptionDate).add(7, 'days').format('YYYY-MM-DD')
  );

  const { data, isFetching } = useQuery(
    [
      'ParentAccountService.getParentToCustodianCashBalances',
      page,
      pageSize,
      startDate,
      endDate
    ],
    () => {
      const queryWithPagination: ParentToCustodianCashBalanceRequest = {
        endDate,
        page,
        pageSize,
        parentAccountId,
        startDate
      };
      return ParentAccountService.getParentToCustodianCashBalances(
        queryWithPagination
      );
    }
  );

  const parentToCustodianBalancesDataColumnDefs: ColDef[] = [
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      minWidth: 200,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    },
    {
      field: 'custodianBalance',
      headerName: 'Custodian Balance',
      minWidth: 150,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatDollars(value)
    },
    {
      field: 'tradeDateBalance',
      headerName: 'Trade Date Balance',
      minWidth: 150,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatDollars(value)
    },
    {
      field: 'settlementDateBalance',
      headerName: 'Settlement Date Balance',
      minWidth: 150,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatDollars(value)
    },
    {
      headerName: 'Trade Date Difference',
      minWidth: 150,
      type: 'numericColumn',
      valueFormatter: ({
        data: row
      }: {
        data: ParentToCustodianCashBalanceDTO;
      }) =>
        formatters.formatDollars(
          Decimal.sub(
            new Decimal(row.custodianBalance),
            new Decimal(row.tradeDateBalance)
          ).toFixed(3),
          3
        )
    },
    {
      headerName: 'Settlement Date Difference',
      minWidth: 150,
      type: 'numericColumn',
      valueFormatter: ({
        data: row
      }: {
        data: ParentToCustodianCashBalanceDTO;
      }) =>
        formatters.formatDollars(
          Decimal.sub(
            new Decimal(row.custodianBalance),
            new Decimal(row.settlementDateBalance)
          ).toFixed(3),
          3
        )
    }
  ];

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleSubmit = (values: { startDate: string; endDate: string }) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setPage(1);
  };
  return (
    <Box sx={sx}>
      <Card>
        <Formik initialValues={{ endDate, startDate }} onSubmit={handleSubmit}>
          {() => (
            <Form data-testid='parent-to-custodian-cash-balances-update-date-range'>
              <Box
                sx={{
                  borderBottom: 1,
                  borderBottomColor: 'grey.300',
                  display: 'flex',
                  gap: 2,
                  pb: 1,
                  pt: 2,
                  px: 2
                }}>
                <FormControl
                  sx={{
                    width: '160px'
                  }}>
                  <Field
                    as={DatePicker}
                    data-testid='parent-to-custodian-cash-balances-start-date-picker'
                    label='Start Date'
                    name='startDate'
                    size='small'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl
                  sx={{
                    width: '160px'
                  }}>
                  <Field
                    as={DatePicker}
                    data-testid='parent-to-custodian-cash-balances-end-date-picker'
                    label='End Date'
                    name='endDate'
                    size='small'
                    variant='outlined'
                  />
                </FormControl>

                <FormControl
                  sx={{
                    pt: '2px'
                  }}>
                  <Button
                    data-testid='parent-to-custodian-cash-balance-update-date-range-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </FormControl>
              </Box>
            </Form>
          )}
        </Formik>

        <CardContent disablePadding loading={isFetching}>
          <DataTable
            columnDefs={parentToCustodianBalancesDataColumnDefs}
            data-testid='data-parent-to-custodian-cash-balances-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-parent-to-custodian-cash-balances-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No cash balances found.'
                />
              </Stack>
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationSx={{ py: 0.375 }}
            paginationTotal={data?.pagination?.total}
            rowData={data && data.results}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
