import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import ApiService from '@/services/Api.service';

class SecurityService {
  static async get(cusipOrTicker: string): Promise<SecurityDto> {
    return ApiService.getJson<SecurityDto>(`/securities/${cusipOrTicker}`);
  }

  static async search(params: {
    mstarFundId?: string;
  }): Promise<SecurityDto[]> {
    return ApiService.getJson<SecurityDto[]>(`/securities`, {
      mstarFundId: params.mstarFundId
    });
  }
}

export default SecurityService;
