import { CardContent, CardPlaceholder } from '@/components/card/Card.component';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { ParentAccountDto } from '@/models/suba/ParentAccountDTO.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

type SearchFormValues = {
  custodianId: CustodianId | '';
  query: string;
};

type ParentAccountsProps = {
  accountType?: ParentAccountType;
};

export const ParentAccounts = ({
  accountType = ParentAccountType.PlanLevel
}: ParentAccountsProps) => {
  const [custodianId, setCustodianId] = useState('');
  const [query, setQuery] = useState('');

  const { data: searchResults, isFetching } = useQuery(
    [
      'ParentAccountService.searchParentAccounts',
      accountType,
      custodianId,
      query
    ],
    () => {
      if (accountType === ParentAccountType.PlanLevel && query === '')
        return [];

      const result = ParentAccountService.searchParentAccounts({
        accountType: accountType,
        custodianId:
          custodianId === '' ? undefined : (custodianId as CustodianId),
        query
      });
      return result;
    },
    {
      enabled: accountType !== undefined
    }
  );

  const handleSubmit = (values: SearchFormValues) => {
    setCustodianId(values.custodianId);
    setQuery(values.query);
  };

  useEffect(() => {
    setCustodianId('');
    setQuery('');
  }, [accountType]);

  return (
    <>
      <CardContent>
        <Formik
          initialValues={{
            custodianId: '',
            query: ''
          }}
          onSubmit={handleSubmit}>
          <Form data-testid='query-form'>
            <Stack direction='row' spacing={2} useFlexGap>
              {accountType === ParentAccountType.PlanLevel && (
                <FormControl fullWidth variant='outlined'>
                  <Field
                    as={OutlinedInput}
                    autoComplete='off'
                    data-testid='query-input'
                    endAdornment={<SearchIcon color='primary' />}
                    inputProps={{
                      'aria-labelledby': 'search-accounts-heading'
                    }}
                    name='query'
                    placeholder='Account ID, plan ID, plan name, or Custodian Account number'
                    size='small'
                  />
                </FormControl>
              )}
              <FormControl fullWidth size='small' sx={{ flexShrink: 2 }}>
                <InputLabel id='menu-custodian-label'>Custodian</InputLabel>
                <Field
                  MenuProps={{
                    'data-testid': 'menu-custodian'
                  }}
                  as={Select}
                  data-testid='query-custodian'
                  label='Custodian'
                  labelId='menu-custodian-label'
                  name='custodianId'>
                  <MenuItem value=''>Any</MenuItem>
                  {Object.values(CustodianId).map(value => (
                    <MenuItem key={value} value={value}>
                      {value === 'bnyTreasury'
                        ? 'BNY Treasury'
                        : formatters.displayCase(value)}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Button
                data-testid='all-accounts-apply-button'
                type='submit'
                variant='outlined'>
                Apply
              </Button>
            </Stack>
          </Form>
        </Formik>
      </CardContent>
      <CardContent disablePadding loading={isFetching}>
        <DataTable
          columnDefs={[
            {
              autoHeight: true,
              cellRenderer: (props: { data: ParentAccountDto }) => {
                const name =
                  props.data?.accountType === 'SuperOmnibus' ||
                  props.data?.accountType === 'House'
                    ? props.data?.accountName
                    : props.data?.plan?.name;
                return (
                  <DataTableStackCell
                    primary={name || ''}
                    primaryLinkProps={{
                      to: `/ops/accounts/${props.data.parentAccountId}`
                    }}
                    secondary={`ID: ${props.data.parentAccountId}`}
                  />
                );
              },
              flex: 2,
              headerName: 'Parent Account'
            },
            {
              autoHeight: true,
              cellRenderer: (props: { data: ParentAccountDto }) => (
                <DataTableStackCell
                  primary={formatters.displayCase(props.data.custodianId)}
                  secondary={`ID: ${props.data.custodianAccountNumber}`}
                />
              ),
              flex: 2,
              headerName: 'Custodian'
            },
            {
              field: 'createdAt',
              flex: 5,
              valueFormatter: ({ value }: { value: string }) =>
                formatters.formatFromIsoDate(value)
            }
          ]}
          columnSizing='flex'
          data-testid='results-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid={
                query === '' ? 'results-placeholder' : 'no-results-message'
              }
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  query === ''
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          rowData={searchResults || []}
        />
      </CardContent>
    </>
  );
};
