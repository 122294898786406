import Badge from '@/components/badge';
import LinearLoading from '@/components/linear-loading';
import {
  GLOBAL_SEARCH_RESULT_PAGE_SIZE,
  GLOBAL_SEARCH_RESULT_PLANS_TYPE
} from '@/components/main-layout/GlobalSearch/GlobalSearchResults/constants';
import { colorByPlanStatus } from '@/consts/plan.constants';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchPlanDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { createPlanDynamicField } from '@/utils/GlobalSearchUtility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import { FC } from 'react';

interface PlanInputSearchProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchPlanContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchPlanField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    minHeight: theme.spacing(1.5)
  },
  globalSearchPlanIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '25%'
  },
  globalSearchPlanImg: {
    color: theme.palette.error.light,
    paddingBottom: theme.spacing(2.5)
  },
  globalSearchPlanItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  globalSearchPlansHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchPlansTextStack: {
    pointerEvents: 'none'
  },
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

export const PlanInputSearch: FC<PlanInputSearchProps> = props => {
  const classes = useStyles();

  const planSearchSigmaReport = useShowMore<
    GlobalSearchDto,
    GlobalSearchPlanDto
  >(
    ['sigmaReportPlanSearch', props.searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        props.searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_RESULT_PLANS_TYPE,
        props.abortSignal
      ),
    Boolean(props.searchTerm),
    data => data.data.plans.data,
    data => Boolean(data?.data.plans.links.next),
    'sponsorPlanId'
  );

  let loader;

  if (planSearchSigmaReport.isLoading || props.searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (planSearchSigmaReport.isError) {
    return <p>Error</p>;
  }
  return (
    <>
      <Typography
        className={classes.globalSearchPlansHeader}
        id='sigma-search-plans-header'>
        PLANS
      </Typography>
      {loader || (
        <div>
          {!planSearchSigmaReport.isLoading &&
          !!planSearchSigmaReport.data?.length ? (
            <List data-testid='sigma-search-plans' id='sigma-search-plans'>
              {planSearchSigmaReport.data.map((plan: GlobalSearchPlanDto) => {
                return (
                  <ListItem
                    className={classes.globalSearchPlanItem}
                    id='sigma-search-plans-item'
                    key={plan.sponsorPlanId}
                    onClick={() =>
                      props.setSearchTerm('' + plan.sponsorPlanId)
                    }>
                    <ListItemAvatar className={classes.globalSearchPlanImg}>
                      <ContentCopyIcon id='sigma-search-plans-image' />
                    </ListItemAvatar>
                    <div
                      className={classes.globalSearchPlanContainer}
                      id='sigma-search-plans-container'>
                      <div id='sigma-search-plans-wrapper'>
                        <ListItemText
                          id='sigma-search-plans-name'
                          primary={
                            <Stack
                              alignItems='center'
                              className={classes.globalSearchPlansTextStack}
                              direction='row'
                              spacing={1}>
                              <span>{plan.planName}</span>
                              <Badge
                                color={colorByPlanStatus[plan.adminStatus]}
                                size='small'>
                                {plan.adminStatus}
                              </Badge>
                            </Stack>
                          }
                        />
                        <Typography
                          className={classes.globalSearchPlanField}
                          id='sigma-search-plans-work-email'>
                          {plan.externalId ? 'Employer Name' : 'Sponsor Name'}:{' '}
                          {plan.companyName}
                        </Typography>
                        <Typography
                          className={classes.globalSearchPlanField}
                          id='sigma-search-plans-personal-email'>
                          {createPlanDynamicField(props.searchTerm, plan)
                            .key !== ''
                            ? `${
                                createPlanDynamicField(props.searchTerm, plan)
                                  .key
                              }: ${
                                createPlanDynamicField(props.searchTerm, plan)
                                  .value
                              }`
                            : ''}
                        </Typography>
                      </div>
                      <Typography
                        className={classes.globalSearchPlanIdField}
                        id='sigma-search-plans-id'>
                        PLAN ID: {plan.sponsorPlanId}
                      </Typography>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!planSearchSigmaReport.isLoading &&
            !!planSearchSigmaReport.data?.length &&
            planSearchSigmaReport.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={planSearchSigmaReport.isPaginationLoading}
                id='global-search-plans-show-more-btn'
                onClick={event => {
                  event.stopPropagation();
                  planSearchSigmaReport.showMore();
                }}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};
