import { DocumentDto } from '@/models/DocumentDTO.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { ConfirmedTransactionSearchRequest } from '@/models/suba/transactions/ConfirmedTransactionSearchRequest.model';
import { GetTransactionRequest } from '@/models/suba/transactions/GetTransactionRequest.model';
import { PendingTransactionSearchRequest } from '@/models/suba/transactions/PendingTransactionSearchRequest.model';
import { PostableTransaction } from '@/models/suba/transactions/PostableTransaction.model';
import { ReverseTransactionRequest } from '@/models/suba/transactions/ReverseTransactionRequest.model';
import { SearchTransactionsRequest } from '@/models/suba/transactions/SearchTransactionsRequest.model';
import { TransactionDto } from '@/models/suba/transactions/TransactionDTO.model';
import { UpdateTransactionRequest } from '@/models/suba/transactions/UpdateTransactionRequest.model';
import ApiService from '@/services/Api.service';

import Decimal from 'decimal.js';

export class TransactionService {
  static async create(body: PostableTransaction): Promise<TransactionDto> {
    return ApiService.postJson<PostableTransaction, TransactionDto>(
      '/transactions',
      body
    );
  }

  static async search(
    params: SearchTransactionsRequest
  ): Promise<PaginatedApiResponse<TransactionDto[]>> {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<TransactionDto[]>
      >(`transactions/`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async searchConfirmed(
    params: ConfirmedTransactionSearchRequest
  ): Promise<PaginatedApiResponse<TransactionDto[]>> {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<TransactionDto[]>
      >(`transactions/search-confirmed`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async searchPending(
    params: PendingTransactionSearchRequest
  ): Promise<PaginatedApiResponse<TransactionDto[]>> {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<TransactionDto[]>
      >(`transactions/search-pending`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async get(params: GetTransactionRequest): Promise<TransactionDto> {
    try {
      const result = await ApiService.getJson<TransactionDto>(
        `transactions/${params.sourceTransactionId}`
      );
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async getPlanBalance(accountId: string): Promise<Decimal> {
    try {
      const result = await ApiService.getJson<any>(
        `transactions/plan-available-balance`,
        { accountId }
      );

      return new Decimal(result.planAvailableBalance);
    } catch (err: any) {
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async reverse(
    sourceTransactionId: string | string[],
    data?: ReverseTransactionRequest
  ): Promise<void> {
    const result = await ApiService.patchJson<
      ReverseTransactionRequest & { sourceTransactionId: string | string[] },
      void
    >(`transactions/reverse`, { ...data, sourceTransactionId });
    return result;
  }

  static async update(data: UpdateTransactionRequest) {
    const result = await ApiService.patchJson<
      UpdateTransactionRequest,
      TransactionDto
    >(`transactions/update`, data);
    return result;
  }

  static async getCheckImage(transactionId: string): Promise<ArrayBuffer> {
    return ApiService.getArrayBuffer(
      `/transactions/${transactionId}/check-image.pdf`
    );
  }

  static async getCheckImageDocument(
    transactionId: string
  ): Promise<DocumentDto> {
    return ApiService.getJson(`/transactions/${transactionId}/check-image`);
  }

  static async postCheckImage(
    transactionId: string,
    file: File
  ): Promise<Partial<DocumentDto>> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    return ApiService.postFormData(
      `/transactions/${transactionId}/check-image`,
      formData
    );
  }

  static async deleteCheckImage(transactionId: string): Promise<unknown> {
    return ApiService.deleteJson(`/transactions/${transactionId}/check-image`);
  }
}

export default TransactionService;
