import Dot from '@/components/dot';
import { executionStatusColorMap } from '@/models/suba/workflows/ExecutionStatusColorMap.model';
import { workflowStatusColorMap } from '@/models/suba/workflows/WorkflowStatusColorMap.model';
import formatters from '@/utils/Formatters';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';
import { WorkflowStatus } from '@vestwell-sub-accounting/models/conductor/WorkflowStatus.model';

import Tree from '../tree';
import { TreeItem } from '../tree/Tree.component';

type SierraWorkflowStatusProps = {
  workflowStatus: WorkflowStatus;
  executionStatus: WorkflowExecutionStatusEnum;
};

const SierraWorkflowStatus = (props: SierraWorkflowStatusProps) => {
  return (
    <Tree>
      <TreeItem>
        <Dot color={executionStatusColorMap[props.executionStatus]}>
          {formatters.displayCase(props.executionStatus.toLowerCase())}
        </Dot>
      </TreeItem>
      <TreeItem>
        <Dot color={workflowStatusColorMap[props.workflowStatus]}>
          {formatters.displayCase(props.workflowStatus.toLowerCase())}
        </Dot>
      </TreeItem>
    </Tree>
  );
};

export default SierraWorkflowStatus;
