import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { DividendAnnouncementUpdateRequest } from '@/models/suba/dividend-declarations/DividendAnnouncementUpdateRequest.model';
import { DividendDeclarationDto } from '@/models/suba/dividend-declarations/DividendDeclarationDto.model';
import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import {
  doesNotExceedPrecision,
  hasExactPrecision
} from '@/routes/suba/securities/securities-detail/utils';
import DividendDeclarationsService from '@/services/suba/dividend-declarations/DividendDeclarations.service';
import formatters from '@/utils/Formatters';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DistributionType } from '@vestwell-sub-accounting/models/common/DistributionType';
import { DividendType } from '@vestwell-sub-accounting/models/common/DividendType';

import { AxiosError } from 'axios';
import { Dayjs } from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { isTradingDay } from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

type EditDividendDeclarationFormValues = {
  dividendRate?: string;
  recordDate?: string;
  paymentDate: string;
  reinvestDate?: string;
  reinvestPrice?: string;
};

const validationSchema = yup.object({
  dividendRate: yup.number().when('dividendType', {
    is: (value: DividendType) => DividendType.Periodic === value,
    then: schema =>
      schema
        .required()
        .moreThan(-1, 'Must be a positive number')
        .test(
          'is-decimal',
          'Must not exceed 8 decimal places',
          doesNotExceedPrecision(8)
        )
  }),
  paymentDate: yup.string().required(),
  recordDate: yup
    .string()
    .when('dividendType', {
      is: (value: DividendType) => DividendType.Periodic === value,
      then: schema => schema.required()
    })
    .when('distributionType', {
      is: (value: DividendType) => DividendType.Periodic !== value,
      then: schema => schema.optional()
    }),
  reinvestPrice: yup
    .number()
    .optional()
    .moreThan(-1, 'Must be a positive number')
    .test(
      'is-decimal',
      'Must be two decimal places',
      (value?: unknown) =>
        value === '' ||
        value === undefined ||
        value === null ||
        hasExactPrecision(2)(Number(value))
    )
});

type EditDividendDeclarationDialogProps = {
  onClose: () => void;
  security: SecurityDto;
  declaration: DividendDeclarationDto;
  onUpdate?: () => void;
};

export const EditDividendDeclarationDialog = ({
  onClose,
  security,
  onUpdate,
  declaration
}: EditDividendDeclarationDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const [
    hasReinvestDateAfterPaymentDateError,
    setReinvestDateAfterPaymentDateError
  ] = useState(false);

  const queryClient = useQueryClient();
  const EditDividendDeclarationMutation = useMutation(
    ['DividendDeclarationsService.update'],
    (params: DividendAnnouncementUpdateRequest) =>
      DividendDeclarationsService.update(params),
    {
      onError: (error: AxiosError) => {
        console.error(error);
        showSnackbar({
          message: 'Error updating Dividend Declaration',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Dividend Declaration updated',
          severity: 'success'
        });

        queryClient.invalidateQueries(['DividendDeclarationsService.search']);

        onClose();
        if (typeof onUpdate === 'function') {
          onUpdate();
        }
      }
    }
  );

  const handleSubmit = async (values: EditDividendDeclarationFormValues) => {
    setReinvestDateAfterPaymentDateError(false);

    // check if reinvest date is after the payment date
    if (
      values.reinvestDate &&
      values.paymentDate &&
      new Date(values.reinvestDate).getTime() >
        new Date(values.paymentDate).getTime()
    ) {
      setReinvestDateAfterPaymentDateError(true);
      return;
    }

    const submission = {
      cusip: security.cusip,
      dividendRate:
        declaration.dividendType === DividendType.Periodic
          ? values.dividendRate?.toString()
          : '0',
      paymentDate:
        values.paymentDate === '' || values.paymentDate === null
          ? undefined
          : values.paymentDate?.toString(),
      recordDate:
        declaration.dividendType === DividendType.Periodic
          ? values.recordDate
          : undefined,
      reinvestDate:
        values.reinvestDate === '' || values.reinvestDate === null
          ? undefined
          : values.reinvestDate,
      reinvestPrice:
        values.reinvestPrice === '' || values.reinvestPrice === null
          ? undefined
          : values.reinvestPrice?.toString()
    };

    Object.entries(submission).forEach(([key, value]) => {
      if (value === '') delete submission[key as keyof typeof submission];
    });

    EditDividendDeclarationMutation.mutate({
      ...submission,
      distributionType: declaration.distributionType as DistributionType,
      dividendType: declaration.dividendType as DividendType
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open>
      <Formik
        initialValues={declaration}
        onSubmit={(values: EditDividendDeclarationFormValues) =>
          handleSubmit(values)
        }
        validationSchema={validationSchema}>
        {({ handleSubmit: handleFormSubmit, errors, touched }) => (
          <>
            <DialogTitle pb={1}>Edit Dividend Declaration</DialogTitle>
            <DialogContent
              sx={{
                p: 0
              }}>
              <Box
                bgcolor='primary.light'
                data-testid='dividend-declaration-update-summary'
                px={3.25}
                py={2}>
                <Typography>{security.description || '\u2014'}</Typography>
                <Typography variant='body2'>
                  {formatters.formatSecurityName(
                    security?.symbol,
                    security?.cusip
                  ) || '\u2014'}
                </Typography>
              </Box>
              <Box p={3}>
                <Form data-testid='edit-dividend-declaration-form'>
                  <Stack alignItems='start' direction='row' spacing={2}>
                    <FormControl
                      disabled
                      fullWidth
                      size='small'
                      sx={{ maxWidth: 320 }}>
                      <InputLabel id='dividend-declaration-distributionType-button-label'>
                        Distribution Type
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-distributionType'
                        }}
                        as={Select}
                        label='Distribution Type'
                        labelId='dividend-declaration-distributionType-button-label'
                        name='distributionType'>
                        <MenuItem value={DistributionType.DIV}>
                          Dividend
                        </MenuItem>
                        <MenuItem value={DistributionType.LTCG}>
                          Long Term Capital Gain (LTCG)
                        </MenuItem>
                        <MenuItem value={DistributionType.STCG}>
                          Short Term Capital Gain (STCG)
                        </MenuItem>
                      </Field>
                    </FormControl>
                    {declaration.distributionType === DistributionType.DIV && (
                      <ToggleButtonGroup
                        color='primary'
                        data-testid='dividendType-toggle-group'
                        disabled
                        exclusive
                        size='small'
                        value={declaration.dividendType}>
                        {Object.values(DividendType).map(value => (
                          <ToggleButton
                            key={value}
                            sx={{
                              // to account for the 1px border
                              px: 1.5,
                              py: '5px'
                            }}
                            value={value}>
                            {declaration.dividendType === value && (
                              <Stack
                                sx={{
                                  fontSize: 20,
                                  lineHeight: '24px'
                                }}>
                                <CheckIcon fontSize='inherit' />
                              </Stack>
                            )}
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                lineHeight: '24px'
                              }}>
                              {value}
                            </Typography>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    )}
                  </Stack>

                  {declaration.distributionType?.length > 0 && (
                    <Stack
                      alignItems='start'
                      direction='row'
                      mt={2}
                      spacing={2}>
                      <FormControl
                        error={
                          (touched.paymentDate &&
                            Boolean(errors.paymentDate)) ||
                          hasReinvestDateAfterPaymentDateError
                        }
                        sx={{ alignSelf: 'flex-start' }}>
                        <Field
                          as={DatePicker}
                          autoComplete='off'
                          data-testid='paymentDate'
                          label='Payment Date'
                          name='paymentDate'
                          shouldDisableDate={(date: Dayjs) =>
                            !isTradingDay(date.format('YYYY-MM-DD'))
                          }
                          size='small' // FormControl doesn't pass to our DatePicker
                          sx={{ width: 213 }}
                          variant='outlined'
                        />
                        <FormHelperText>
                          {(touched.paymentDate && errors.paymentDate) || ' '}
                        </FormHelperText>
                      </FormControl>
                      {declaration.dividendType === DividendType.Periodic && (
                        <>
                          <FormControl
                            error={
                              touched.recordDate && Boolean(errors.recordDate)
                            }
                            sx={{ alignSelf: 'flex-start' }}>
                            <Field
                              as={DatePicker}
                              autoComplete='off'
                              data-testid='recordDate'
                              label='Record Date'
                              name='recordDate'
                              shouldDisableDate={(date: Dayjs) =>
                                !isTradingDay(date.format('YYYY-MM-DD'))
                              }
                              size='small' // FormControl doesn't pass to our DatePicker
                              sx={{ width: 213 }}
                              variant='outlined'
                            />
                            <FormHelperText>
                              {(touched.recordDate && errors.recordDate) || ' '}
                            </FormHelperText>
                          </FormControl>
                          <FormControl
                            error={
                              touched.dividendRate &&
                              Boolean(errors.dividendRate)
                            }
                            fullWidth
                            size='small'
                            sx={{ maxWidth: 213 }}>
                            <InputLabel htmlFor='dividendRate-input'>
                              Rate
                            </InputLabel>
                            <Field
                              as={OutlinedInput}
                              autoComplete='off'
                              id='dividendRate-input'
                              label='Rate'
                              name='dividendRate'
                              type='number'
                            />
                            <FormHelperText>
                              {(touched.dividendRate && errors.dividendRate) ||
                                ' '}
                            </FormHelperText>
                          </FormControl>
                        </>
                      )}
                    </Stack>
                  )}

                  <Divider textAlign='left'>Reinvestment</Divider>
                  <Stack alignItems='start' direction='row' mt={3} spacing={2}>
                    <FormControl
                      error={
                        (touched.reinvestDate &&
                          Boolean(errors.reinvestDate)) ||
                        hasReinvestDateAfterPaymentDateError
                      }
                      sx={{ alignSelf: 'flex-start' }}>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='reinvestDate'
                        disabled
                        label='Reinvestment Date'
                        name='reinvestDate'
                        shouldDisableDate={(date: Dayjs) =>
                          !isTradingDay(date.format('YYYY-MM-DD'))
                        }
                        size='small' // FormControl doesn't pass to our DatePicker
                        sx={{ width: 213 }}
                        variant='outlined'
                      />
                      <FormHelperText>
                        {(touched.reinvestDate && errors.reinvestDate) || ' '}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      error={
                        touched.reinvestPrice && Boolean(errors.reinvestPrice)
                      }
                      fullWidth
                      size='small'
                      sx={{ maxWidth: 213 }}>
                      <InputLabel htmlFor='reinvestPrice-input'>
                        Reinvestment Price
                      </InputLabel>
                      <Field
                        as={OutlinedInput}
                        autoComplete='off'
                        id='reinvestPrice-input'
                        label='Reinvestment Price'
                        name='reinvestPrice'
                        startAdornment={
                          <InputAdornment position='start'>$</InputAdornment>
                        }
                        type='number'
                      />
                      <FormHelperText>
                        {(touched.reinvestPrice && errors.reinvestPrice) ||
                          'Optional'}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Form>
                {hasReinvestDateAfterPaymentDateError && (
                  <Alert severity='error' sx={{ mt: 2 }}>
                    Reinvestment Date has to be before or equal to the Payment
                    Date
                  </Alert>
                )}
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions
              sx={{
                px: 3,
                py: 2.25
              }}>
              <Button
                disabled={EditDividendDeclarationMutation.isLoading}
                onClick={() => {
                  onClose();
                }}>
                Cancel
              </Button>
              <LoadingButton
                loading={EditDividendDeclarationMutation.isLoading}
                onClick={() => handleFormSubmit()}
                variant='contained'>
                Confirm
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
