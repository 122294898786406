import { useSnackbar } from '@/contexts/SnackBarContext';
import { FileDownloadDoneOutlined, RuleOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SecuritiesPage = (): JSX.Element => {
  const navigate = useNavigate();

  const [fundScreenerInput, setFundScreenerInput] = useState('');
  const [securityDetailsInput, setSecurityDetailsInput] = useState('');

  const { showSnackbar } = useSnackbar();

  const handleSecurityDetailsSubmit = useCallback(() => {
    navigate(`/ops/securities/${securityDetailsInput.trim()}/general`);
  }, [securityDetailsInput]);

  const handleFundScreenerSubmit = useCallback(() => {
    const funds = fundScreenerInput
      .split(/[\r?\n,]/)
      .filter(e => e)
      .map(e => e.trim());

    if (funds.length > 100) {
      showSnackbar({
        message: 'Maximum number of requested funds is 100',
        severity: 'error'
      });
      return;
    }

    navigate(`/ops/securities/fund-screener?funds=${funds}`);
  }, [fundScreenerInput]);

  return (
    <Stack direction='column' spacing={3}>
      <Typography role='heading' variant='h4'>
        Securities
      </Typography>
      <Stack divider={<Divider />} spacing={3}>
        <Stack alignItems='flex-start' spacing={2}>
          <Stack alignItems='center' direction='row' spacing={2}>
            <FileDownloadDoneOutlined />
            <Typography variant='h6'>Security Details</Typography>
          </Stack>
          <FormControl size='small'>
            <OutlinedInput
              inputProps={{
                'data-testid': 'security-details'
              }}
              onChange={event => setSecurityDetailsInput(event.target.value)}
              placeholder='Enter a ticker/CUSIP'
              sx={{ width: 400 }}
              type='text'
              value={securityDetailsInput}
            />
          </FormControl>
          <Button
            disabled={!securityDetailsInput.trim()}
            onClick={handleSecurityDetailsSubmit}
            variant='contained'>
            Search
          </Button>
        </Stack>
        <Stack alignItems='flex-start' spacing={2}>
          <Stack alignItems='center' direction='row' spacing={2}>
            <RuleOutlined />
            <Typography variant='h6'>Fund Screener</Typography>
          </Stack>
          <FormControl size='small'>
            <OutlinedInput
              inputProps={{
                'data-testid': 'fund-screener'
              }}
              minRows={4}
              multiline
              onChange={event => setFundScreenerInput(event.target.value)}
              placeholder='Enter a list of tickers/CUSIPs, separated by comma or new line'
              sx={{ width: 400 }}
              type='text'
              value={fundScreenerInput}
            />
          </FormControl>
          <Button
            disabled={!fundScreenerInput.trim()}
            onClick={handleFundScreenerSubmit}
            variant='contained'>
            Check
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
