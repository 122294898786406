import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { BreakageStatusColorMap } from '@/models/suba/recon/BreakageStatusColorMap.model';
import { ReconExceptionDto } from '@/models/suba/recon/ReconException.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import AuthZService from '@/services/AuthZ.service';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import { FC, useContext } from 'react';

import { CardHeader } from '../common/CardHeader.component';

const detailCellRenderer = ({ data: reconException }) => (
  <Stack direction='row' p={2} spacing={1}>
    <Stack flex={1}>
      <TextStack direction='column'>
        <TextStackItem>
          <TextLabel>Exception Date</TextLabel>
          <TextValue>
            {formatters.formatFromIsoDateCustom(
              reconException.exceptionDate,
              'MM/DD/YYYY'
            )}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Assignee</TextLabel>
          <TextValue>{reconException.assignee}</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Note</TextLabel>
          <TextValue>{reconException.notes}</TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
    <Divider flexItem orientation='vertical' />
    <Stack flex={1}>
      <TextStack direction='column'>
        <TextStackItem>
          <TextLabel>Parent value</TextLabel>
          <TextValue>
            {formatters.formatDollars(reconException.parentValue)}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Compare value</TextLabel>
          <TextValue>
            {formatters.formatDollars(reconException.comparisonValue)}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Difference</TextLabel>
          <TextValue>
            {formatters.formatDollars(reconException.valueDifference)}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  </Stack>
);

export const CustodianDestinationRejectReconciliationExceptions: FC = () => {
  const alert = useContext(AlertContext);
  const { showSnackbar } = useSnackbar();

  const getParentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      alert.details.trade?.custodianAccountNumber,
      alert.details.trade?.custodianId
    ],
    () =>
      ParentAccountService.get({
        custodianAccountNumber: alert.details.trade.custodianAccountNumber,
        custodianId: alert.details.trade.custodianId
      }),
    {
      enabled:
        !!alert.details.trade?.custodianAccountNumber &&
        !!alert.details.trade?.custodianId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting Parent account: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const searchReconException = useQuery(
    [
      'ReconExceptionService.search',
      {
        page: 1,
        pageSize: 0,
        parentAccountId: getParentAccountQuery.data?.parentAccountId,
        status: [BreakageStatus.Open]
      }
    ],
    async () => {
      const reconExceptions = await ReconExceptionService.search({
        page: 1,
        pageSize: 0,
        parentAccountId: getParentAccountQuery.data.parentAccountId,
        status: [BreakageStatus.Open]
      });
      return reconExceptions;
    },
    {
      enabled: !!getParentAccountQuery.data?.parentAccountId
    }
  );
  const vestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    }
  );

  const columnDefs: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => (
        <Link target='_blank' to={`/ops/recon-exceptions/${cellData.data.id}`}>
          {cellData.data.id}
        </Link>
      ),
      field: 'id',
      headerName: 'ID',
      minWidth: 150
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        return (
          <DataTableBadgeCell
            color={BreakageStatusColorMap[cellData.data.status]}>
            {formatters.displayCase(
              formatters.snakeToCamelCase(cellData.data.status)
            )}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Status',
      minWidth: 200
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        const displayDataType = formatters.getValueKey(
          BreakageDataType,
          cellData.data.dataType
        );

        const displayProcess = formatters.getValueKey(
          BreakageProcess,
          cellData.data.process
        );
        return (
          <DataTableStackCell
            primary={formatters.displayCase(displayDataType)}
            secondary={formatters.displayCase(displayProcess)}
          />
        );
      },
      field: 'dataType',
      headerName: 'Break Type',
      minWidth: 200
    }
  ];

  return (
    <Card data-testid='ach-reversal-expanded-reconciliation-exceptions-card'>
      <CardHeader title='Reconciliation Exceptions' />
      <Divider />
      <CardContent
        disablePadding
        loading={
          searchReconException.isLoading || vestwellStaffQuery.isLoading
        }>
        <DataTable
          columnDefs={columnDefs}
          detailCellRenderer={detailCellRenderer}
          detailRowAutoHeight
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='cash-transfer-rejected-expanded-reconciliation-exceptions-no-data'
              height='100%'
              justifyContent='center'>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found'
              />
            </Stack>
          }
          pageSizeOptions={[5, 10, 25]}
          pagination
          rowData={searchReconException.data?.results || []}
        />
      </CardContent>
    </Card>
  );
};

CustodianDestinationRejectReconciliationExceptions.displayName =
  'CustodianDestinationRejectReconciliationExceptions';
