import Card, {
  CardContent,
  CardHeader,
  CardHeaderProps
} from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanFunds } from '@/services/Investment.service';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography
} from '@mui/material';

import { useFormik } from 'formik';
import { sum } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBeforeUnload, useToggle } from 'react-use';

import { ChangeMapping } from './re-enrollment/InvestmentAutocomplete.component';
import { ReenrollMappingTable } from './re-enrollment/ReenrollMappingTable.component';

type ProgramReenrollPageProps = PlanFunds & {
  onSubmit: (data) => Promise<void>;
  planId: string;
};

export const ProgramReenrollPage: FC<ProgramReenrollPageProps> = props => {
  const snackbar = useSnackbar();
  const [isOpen, toggleOpen] = useToggle(false);
  const [isConfirming, toggleConfirming] = useToggle(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      changes: []
    },
    onSubmit: async () => {
      await props.onSubmit(formik.values.changes);

      formik.resetForm();
      toggleConfirming();

      snackbar.showSnackbar({
        message: `Successfully submitted changes to plan id ${props.planId}`,
        severity: 'success'
      });

      navigate(`/ops/investments/program/${props.newProgram.programId}`);
    }
  });

  const dirtyFn = useCallback(() => formik.dirty, [formik.dirty]);

  useBeforeUnload(
    dirtyFn,
    'Are you sure you want to leave and remove all changes?'
  );

  const currentProgramMappingsCount = useMemo(() => {
    const targetMappingsCount = props.currentProgram.targetModels?.length || 0;
    const riskMappingsCount = props.currentProgram.riskModels?.length || 0;
    const fundLineupMappingsCount =
      props.currentProgram.fundLineup?.fundLineupFunds?.length || 0;
    const goalMappingCount =
      props.currentProgram.goalSeries?.goePortfolioIds?.length || 0;
    const nliMappingCount = props.currentProgram.nliModels?.length || 0;
    const isMSMAMapped = props.currentProgram.externalProviders.some(
      i => 'ms_rp' === i.investmentType || 'ms_ma' === i.investmentType
    )
      ? 1
      : 0;
    return sum([
      targetMappingsCount,
      riskMappingsCount,
      fundLineupMappingsCount,
      goalMappingCount,
      nliMappingCount,
      isMSMAMapped
    ]);
  }, [props.currentProgram]);

  const isAllMappingsDone = useMemo(() => {
    const fundLineupMappingsCount =
      formik.values.changes.find(change => change.oldType === 'pmenu')?.mappings
        ?.length || 0;
    return (
      currentProgramMappingsCount ===
      sum([
        formik.values.changes.filter(change => change.oldType !== 'pmenu')
          .length,
        fundLineupMappingsCount
      ])
    );
  }, [currentProgramMappingsCount, formik.values.changes]);

  const actionButtons = useMemo(() => {
    return [
      formik.dirty && {
        label: 'Reset',
        onClick: () => {
          toggleOpen(true);
        },
        variant: 'text'
      },
      {
        disabled: !formik.dirty || !isAllMappingsDone,
        label: 'Review',
        onClick: () => {
          toggleConfirming();
        },
        variant: 'contained'
      }
    ].filter(Boolean) as CardHeaderProps['actionButtonsProps'];
  }, [formik.dirty, isAllMappingsDone]);

  const onKeepEditing = useCallback(() => {
    toggleOpen(false);
  }, []);

  const onReset = useCallback(() => {
    formik.resetForm();
    toggleOpen(false);
  }, []);

  return (
    <>
      <Dialog open={isOpen}>
        <DialogContent>
          <DialogTitle>Reset and remove all changes?</DialogTitle>
          <DialogActions>
            <Button onClick={onKeepEditing}>Keep Editing</Button>
            <Button color='error' onClick={onReset} variant='contained'>
              Reset
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Stack spacing={3}>
        {isConfirming && (
          <Card>
            <CardHeader
              actionButtonsProps={[
                {
                  label: 'Back To Edit',
                  onClick: () => {
                    toggleConfirming();
                  },
                  variant: 'text'
                },
                {
                  label: 'Confirm & Submit',
                  onClick: () => {
                    formik.submitForm();
                  },
                  variant: 'contained'
                }
              ]}
              title='Review'
            />
            <CardContent>
              <Stack spacing={2}>
                <Alert severity='warning'>
                  <Typography color='rgba(0,0,0,0.6)' mb={2} variant='body2'>
                    You&apos;re about to change this plans&apos; investment
                    program. This could mean re-defaulting a saver&apos;s
                    investment election to a new QDIA.
                  </Typography>
                  <Typography color='rgba(0,0,0,0.6)' variant='body2'>
                    Submitting the below may impact savers&apos; investment
                    elections in the plan.
                  </Typography>
                </Alert>
                <Typography color='rgba(0,0,0,0.87)' variant='body2'>
                  Are you sure you want to update {props.name}'s investment
                  program?
                </Typography>
                <Stack>
                  <Typography color='rgba(0,0,0,0.87)' variant='body2'>
                    <strong>Plan Name:</strong> {props.name}
                  </Typography>
                  <Typography color='rgba(0,0,0,0.87)' variant='body2'>
                    <strong>Plan ID:</strong> {props.planId}
                  </Typography>
                </Stack>
                <Divider />
                <Typography>Please confirm the below changes.</Typography>
              </Stack>
            </CardContent>
          </Card>
        )}
        <Card>
          {!isConfirming && (
            <CardHeader
              actionButtonsProps={actionButtons}
              title='Map investment strategies'
            />
          )}
          <CardContent disablePadding>
            <ReenrollMappingTable
              context={{
                changes: formik.values.changes,
                setChanges: (changes: ChangeMapping) =>
                  formik.setFieldValue('changes', changes)
              }}
              currentProgram={props.currentProgram}
              isConfirming={isConfirming}
              newProgram={props.newProgram}
            />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

ProgramReenrollPage.displayName = 'ProgramReenrollPage';
