import LinearLoading from '@/components/linear-loading';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantInfo } from '@/models';
import { RolloverDto } from '@/models/RolloversDTO.model';
import { SubaDepositTransactionDto } from '@/models/suba/deposits/SubaDepositTransactionResponse.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import Warning from '@mui/icons-material/Warning';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React, { useMemo, useState } from 'react';

interface InvestModalrops {
  rollover?: RolloverDto;
  isOpen: boolean;
  setIsOpen: any;
  participant: Pick<
    ParticipantInfo,
    'firstName' | 'lastName' | 'sponsorPlanId'
  >;
}

const InvestModal: React.FunctionComponent<InvestModalrops> = (
  props: InvestModalrops
) => {
  const { showSnackbar } = useSnackbar();

  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedDeposit, setSelectedDeposit] =
    useState<SubaDepositTransactionDto>();

  const { rollover, isOpen, setIsOpen, participant } = props;
  const queryClient = useQueryClient();
  const clearSelectedVal = () => {
    setSelectedValue(null);
    setSelectedDeposit(undefined);
  };

  const unmatchedDeposits = useQuery<SubaDepositTransactionDto[]>(
    ['PlanService.getUnmatchedDeposits', rollover?.id, isOpen],
    async () => {
      clearSelectedVal();
      const data = await PlanService.getUnmatchedDeposits(
        participant?.sponsorPlanId
      );
      return data.data;
    },
    {
      enabled: Boolean(rollover?.id && rollover?.id > 0) && isOpen
    }
  );

  const rolloverInvestMutation = useMutation(
    () =>
      ParticipantService.rolloverInvestment(
        rollover?.id,
        selectedDeposit?.sourceTransactionId,
        selectedDeposit?.amount
      ),
    {
      onError: () => {
        queryClient.invalidateQueries(['ParticipantService.getRollover']);
        setOpenConfirm(false);
        setIsOpen(false);

        showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['ParticipantService.getRollover']);
        setOpenConfirm(false);
        setIsOpen(false);

        showSnackbar({
          message: 'Successfully completed!',
          severity: 'success'
        });
      }
    }
  );

  const rolloverType = useMemo(() => {
    return (
      (rollover?.attributes?.rothAmount && 'Roth') ||
      (rollover?.attributes?.preTaxAmount && 'Pre-tax') ||
      EMPTY_FIELD_PLACEHOLDER
    );
  }, [rollover?.attributes]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(+event.target.value);
    setSelectedDeposit(
      unmatchedDeposits.data?.[+event.target.value] ?? undefined
    );
  };

  const isWarningText = useMemo(() => {
    return ((rollover?.attributes?.rothAmount &&
      Math.abs(
        (selectedDeposit?.amount as number) - rollover?.attributes?.rothAmount
      ) >
        (selectedDeposit?.amount as number) * 0.1) ||
      (rollover?.attributes?.preTaxAmount &&
        Math.abs(
          (selectedDeposit?.amount as number) -
            rollover?.attributes?.preTaxAmount
        ) >
          (selectedDeposit?.amount as number) * 0.1)) as boolean;
  }, [rollover?.attributes, selectedDeposit?.amount]);

  return (
    <>
      <Dialog maxWidth='md' onClose={() => setIsOpen(false)} open={isOpen}>
        <DialogTitle
          sx={{
            paddingBottom: theme => theme.spacing(1),
            paddingTop: theme => theme.spacing(2),
            px: theme => theme.spacing(2)
          }}>
          Match with a deposit
        </DialogTitle>
        {unmatchedDeposits.isFetching ? (
          <LinearLoading />
        ) : (
          <DialogContent sx={{ px: 0 }}>
            <TableContainer component={Box}>
              <Table aria-label='simple table'>
                <TableHead
                  sx={{
                    borderTop: 1,
                    borderTopColor: theme => theme.palette.grey[300]
                  }}>
                  <TableRow>
                    <TableCell sx={{ py: theme => theme.spacing(1) }}>
                      {' '}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ py: theme => theme.spacing(1) }}>
                      Tx ID
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ py: theme => theme.spacing(1) }}>
                      Amount
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ py: theme => theme.spacing(1) }}>
                      Tx Code
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ py: theme => theme.spacing(1) }}>
                      Created At
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unmatchedDeposits.data?.map((deposit, index) => (
                    <TableRow
                      key={deposit.sourceTransactionId}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}>
                      <TableCell sx={{ py: 0 }}>
                        <Radio
                          checked={selectedValue === index}
                          inputProps={{ 'aria-label': '' }}
                          name='alertId-radios'
                          onChange={handleChange}
                          value={index}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        {deposit.sourceTransactionId}
                      </TableCell>
                      <TableCell align='right'>
                        {(deposit?.amount as number)
                          ? formatters.formatDollars(deposit.amount as number)
                          : '--'}
                      </TableCell>
                      <TableCell align='left'>
                        {deposit.transactionTypeCode}
                      </TableCell>
                      <TableCell align='left'>{deposit.createdAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Don&apos;t invest</Button>
          <Button
            disabled={selectedValue === null}
            onClick={() => setOpenConfirm(true)}
            variant='contained'>
            Invest
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={() => setOpenConfirm(false)} open={openConfirm}>
        <DialogContent>
          <DialogContentText>
            Submitting this request will invest{' '}
            {formatters.formatDollars(selectedDeposit?.amount as number)} into{' '}
            {`${participant.firstName} ${participant.lastName}`}&apos;s{' '}
            {rolloverType} rollover account from Transaction{' '}
            {selectedDeposit?.sourceTransactionId}. This should only be done
            after the rollover funds are confirmed to have arrived at the plan
            account. Do you want to proceed?
            {isWarningText && (
              <Typography sx={{ alignItems: 'center', display: 'flex', mt: 2 }}>
                <Warning sx={{ color: '#ED6C02' }} /> The rollover request and
                deposit amounts differ by more than 10%!
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}>Go back</Button>
          <LoadingButton
            loading={rolloverInvestMutation.isLoading}
            onClick={async () => {
              await rolloverInvestMutation.mutateAsync();
              clearSelectedVal();
            }}
            type='submit'
            variant='contained'>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvestModal;
