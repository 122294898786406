import { useSnackbar } from '@/contexts/SnackBarContext';
import { SubAccountOrderDto } from '@/models/suba/sub-account-orders/SubAccountOrderDTO.model';
import SubAccountOrderService from '@/services/suba/sub-account-orders/SubAccountOrder.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';

type DeferSubAccountOrderDialogProps = {
  open: boolean;
  onClose: () => void;
  subAccountOrder: SubAccountOrderDto;
  onDeferredSubAccountOrders?: () => void;
};

export const DeferSubAccountOrderDialog = ({
  open,
  onClose,
  subAccountOrder,
  onDeferredSubAccountOrders
}: DeferSubAccountOrderDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const deferSubAccountOrderMutation = useMutation(
    ['SubAccountOrderService.defer'],
    (id: number) => {
      return SubAccountOrderService.defer(id);
    },
    {
      onError: (error: any) => {
        console.error(error);
        showSnackbar({
          message: 'Error deferring Sub Account Order',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Sub Account Order deferred',
          severity: 'success'
        });
        // Invalidate all queries to ensure any related components reflect these changes
        queryClient.invalidateQueries();
        onClose();
        if (typeof onDeferredSubAccountOrders === 'function') {
          onDeferredSubAccountOrders();
        }
      }
    }
  );

  let displayUnitsAmount = '';
  if (
    [SecurityOrderType.DOLLAR_ALL, SecurityOrderType.DOLLAR_ORDER].includes(
      subAccountOrder.securityOrderType
    )
  ) {
    displayUnitsAmount = subAccountOrder.orderAmount
      ? formatters.formatDollars(subAccountOrder.orderAmount)
      : '';
  } else {
    displayUnitsAmount = subAccountOrder.orderUnits
      ? formatters.formatDecimal(subAccountOrder.orderUnits, 3)
      : '';
  }

  // fetch friendly display name for tradeType
  const displayTradeType = formatters.getValueKey(
    TradeType,
    subAccountOrder?.tradeType
  );

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        Defer Sub Account Order
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Box
          data-testid='sub-account-order-defer-summary'
          sx={{
            bgcolor: theme => theme.palette.primary.light,
            px: 3.25,
            py: 2
          }}>
          <Typography>
            {displayUnitsAmount} {formatters.displayCase(displayTradeType)}{' '}
            order for {subAccountOrder.security.symbol} (
            {subAccountOrder.security.cusip})
            {subAccountOrder.tradeDate
              ? `, traded on ${formatters.formatFromIsoDateCustom(
                  subAccountOrder.tradeDate,
                  'MM/DD/YYYY'
                )}`
              : ''}
          </Typography>
          <Typography
            sx={{ color: theme => theme.palette.grey[600], fontSize: 14 }}>
            Order ID: {subAccountOrder.ourParentOrderId}
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 1 }}>
            Deferring this order will remove the order from the pending queue so
            it will be not be aggregated and sent to the custodian. In addition,
            the related pending transaction will be cancelled.
          </Typography>
          <Typography>
            This action only affects the selected order. If you are trying to
            defer an entire request, you should defer via the Conductor tab.
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          onClick={() => {
            onClose();
          }}>
          Nevermind
        </Button>

        <LoadingButton
          loading={deferSubAccountOrderMutation.isLoading}
          onClick={() => {
            deferSubAccountOrderMutation.mutate(subAccountOrder.id);
          }}
          variant='contained'>
          Defer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
