import AccessControl from '@/components/access-control/AccessControl.component';
import { Order } from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import SimpleTabs from '@/components/simple-tabs';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { SponsorPlanInfo, SponsorPlanInfoDto } from '@/models/PlanV2DTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import RecordKeeperService, {
  AllRecordkeepers
} from '@/services/RecordKeeper.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { Box, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  initialPlansFilters,
  PlanFilters,
  planMainTypesOptions
} from '../consts';
import PlansIndexTable from './PlansIndexTable.component';

export interface SponsorPlanV1 {
  sponsorPlanId: number;
  sponsorId: number;
  isActive: boolean;
  effectivePlanDate?: string;
  planName?: string;
  status: string;
  type: string;
  recordkeeper?: string;
  programName?: string;
  firmName?: string;
  tpaName?: string;
  tierName?: string;
}

interface PlansIndexProps {
  isModal?: boolean;
  pooledPlanId?: number;
  receivePlan?: (selectedPlan: SponsorPlanV1) => void;
  firmIds?: number[];
  recordKeepers?: number[];
  planTypes?: string[];
  statuses?: string[];
  createPlanButton?: React.ReactNode;
  hideProgram?: boolean;
  hideTier?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3)
  },
  planIdCellWidth: {
    width: theme.spacing(30)
  },
  planNameCellWidth: {
    width: theme.spacing(45)
  },
  textField: {
    width: '50ch'
  },
  tpaMaxWidth: {
    maxWidth: theme.spacing(20)
  }
}));

export const toSponsorPlanV1 = (
  plan: SponsorPlanInfo
): SponsorPlanV1 & { disableLink: boolean } => {
  return {
    ...plan,
    disableLink: !(plan.advisorId && plan.sponsorId),
    isActive: plan.status === 'Active',
    recordkeeper: RecordKeeperService.formatRKName(plan.recordkeeper),
    tpaName: plan.tpas?.length ? plan.tpas.join(',') : EMPTY_FIELD_PLACEHOLDER
  };
};

const PlansIndex = (props: PlansIndexProps): JSX.Element => {
  const sharedColumns = [
    { field: 'firmName', headerName: 'Firm', width: 130 },
    ...(props.hideTier
      ? []
      : [{ field: 'tierName', headerName: 'Tier', width: 130 }])
  ];

  const classes = useStyles();
  const { isModal, pooledPlanId, receivePlan } = props;

  const [isUserAuthenticated, updateIsUserAuthenticated] = useState(false);

  const [sorter, setSorter] = useState<{ direction: Order; by: string }>({
    by: 'status',
    direction: 'asc'
  });

  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [planFilters, setPlanFilters] =
    useState<PlanFilters>(initialPlansFilters);
  const [isVestwellUser, setIsVestwellUser] = useState(false);

  const isTPA = userService.isTpaUser();
  const areFiltersApplied = !isEqual(planFilters, initialPlansFilters);

  const defaultColumns: GridColDef[] = [
    {
      cellClassName: !isVestwellUser
        ? classes.planIdCellWidth
        : isTPA
          ? classes.tpaMaxWidth
          : '',
      field: 'sponsorPlanId',
      headerName: 'Plan ID'
    },
    {
      cellClassName: !isVestwellUser
        ? classes.planNameCellWidth
        : isTPA
          ? classes.tpaMaxWidth
          : '',
      field: 'planName',
      headerName: 'Plan Name'
    },
    {
      cellClassName: isTPA ? classes.tpaMaxWidth : '',
      field: 'status',
      headerName: 'Status'
    }
  ];

  const vestwellUsersOnlyColumns: GridColDef[] = [
    ...defaultColumns,
    // { field: 'tpaName', headerName: 'TPA', width: 130 }, // david: hide until we backfill data for tpa
    ...sharedColumns,
    ...(props.hideProgram
      ? []
      : [{ field: 'programName', headerName: 'Program Name', width: 130 }]),
    ...(props.planTypes?.length === 1
      ? []
      : [{ field: 'type', headerName: 'Type', width: 130 }]),
    ...(props.recordKeepers?.length === 1
      ? []
      : [{ field: 'recordkeeper', headerName: 'Recordkeeper', width: 130 }])
  ];

  const tpaOnlyColumns: GridColDef[] = [...defaultColumns, ...sharedColumns];

  const adoptersColumns: GridColDef[] = [
    ...defaultColumns,
    { field: 'programName', headerName: 'Program Name', width: 130 },
    { field: 'firmName', headerName: 'Firm Name', width: 130 },
    {
      field: 'effectivePlanDate',
      headerName: 'Plan Effective Date',
      width: 130
    }
  ];

  useEffect(() => {
    const subscriptionId = 'PlansIndex';

    userService.subscribe(subscriptionId, ({ isAuthenticated, token }) => {
      updateIsUserAuthenticated(isAuthenticated && !!token);
      setIsVestwellUser(!!token && !token.tpaId);
    });

    return () => userService.unsubscribe(subscriptionId);
  }, []);

  const planListQuery = useQuery<SponsorPlanInfoDto>(
    [
      'PlanService.getPlansPage',
      pageNumber,
      rowsPerPage,
      planFilters.search,
      planFilters.filterType,
      props.firmIds,
      props.recordKeepers || [...AllRecordkeepers].map(r => r.id),
      props.statuses || planFilters.statuses,
      props.planTypes || planFilters.types,
      isModal,
      pooledPlanId,
      sorter
    ],
    async () => {
      const dto = await PlanService.getPlansPage({
        adminStatuses: props.statuses || planFilters.statuses,
        filterType: planFilters.filterType,
        firmIds: props.firmIds,
        pageNumber,
        pageSize: rowsPerPage,
        planTypes:
          props.planTypes ||
          (planFilters.types.length ? planFilters.types : planMainTypesOptions),
        pooledPlanId,
        recordkeepersIds: isModal
          ? RecordKeeperService.getVestwellRKIds()
          : props.recordKeepers,
        search: planFilters.search,
        sortBy: sorter.by,
        sortDirection: sorter.direction
      });

      return dto;
    },
    {
      enabled: isUserAuthenticated,
      staleTime: Infinity
    }
  );

  const plansStats = useQuery(
    [
      'PlanService.getOverviewStats',
      pageNumber,
      rowsPerPage,
      planFilters.search,
      planFilters.filterType,
      props.recordKeepers || [...AllRecordkeepers].map(r => r.id),
      planFilters.statuses,
      props.planTypes || planFilters.types,
      isModal,
      pooledPlanId,
      sorter
    ],
    async () =>
      await PlanService.getOverviewStats(
        [...planListQuery.data.data].map(plan => plan.sponsorPlanId)
      ),
    {
      enabled: !!planListQuery?.data?.data.length,
      staleTime: Infinity
    }
  );

  const plans = useMemo(
    () =>
      (planListQuery.data?.data.map(toSponsorPlanV1) || []).map(plan => {
        const stats = plansStats.data?.find(
          stat => stat.sponsorPlanId === plan.sponsorPlanId
        );

        return { ...plan, stats: stats };
      }),
    [planListQuery]
  );

  const updateFilters = useCallback((newFilters: PlanFilters) => {
    setPlanFilters(newFilters);
    setPageNumber(0);
  }, []);

  let columns = defaultColumns;

  if (isVestwellUser && !isModal) {
    columns = vestwellUsersOnlyColumns;
  }

  if (isTPA) {
    columns = tpaOnlyColumns;
  }

  if (pooledPlanId) {
    columns = adoptersColumns;
  }

  const statsColums: GridColDef[] = [
    {
      cellClassName: !isVestwellUser ? classes.planIdCellWidth : '',
      field: 'sponsorPlanId',
      headerName: 'Plan ID',
      width: 130
    },
    {
      cellClassName: !isVestwellUser ? classes.planNameCellWidth : '',
      field: 'planName',
      headerName: 'Plan Name',
      width: 130
    },
    {
      cellClassName: !isVestwellUser ? classes.planNameCellWidth : '',
      field: 'stats.eligibleParticipants',
      headerName: 'Eligible Participants',
      sortable: false,
      valueGetter: params => params.row?.stats?.eligibleParticipants,
      width: 130
    },
    {
      cellClassName: !isVestwellUser ? classes.planNameCellWidth : '',
      field: 'stats.participantsWithBalance',
      headerName: 'Participants with Balance',
      sortable: false,
      valueGetter: params => params.row?.stats?.participantsWithBalance,
      width: 130
    },
    {
      cellClassName: !isVestwellUser ? classes.planNameCellWidth : '',
      field: 'stats.terminatedParticipantsWithBalance',
      headerName: 'Terminated with Balance',
      sortable: false,
      valueGetter: params =>
        params.row?.stats?.terminatedParticipantsWithBalance,
      width: 130
    },
    {
      cellClassName: !isVestwellUser ? classes.planNameCellWidth : '',
      field: 'stats.totalPlanMarketValue',
      headerName: 'Plan Market Value',
      sortable: false,
      valueGetter: params =>
        formatters.formatDollars(params.row?.stats?.totalPlanMarketValue),
      width: 130
    }
  ];

  const tabs = useMemo(
    () => [
      {
        component: (
          <PlansIndexTable
            areFiltersApplied={areFiltersApplied}
            columns={columns}
            hideProgram={props.hideProgram}
            isModal={isModal}
            isTPA={isTPA}
            isVestwellUser={isVestwellUser}
            pageNumber={pageNumber}
            planListQuery={planListQuery}
            planTypes={props.planTypes}
            plans={plans}
            plansStatsFetching={plansStats.isFetching}
            receivePlan={receivePlan}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            setSorter={setSorter}
            sorter={sorter}
            updateFilters={updateFilters}
          />
        ),
        label: 'Overview',
        value: 'Overview'
      },
      {
        component: (
          <PlansIndexTable
            areFiltersApplied={areFiltersApplied}
            columns={statsColums}
            hideProgram={props.hideProgram}
            isModal={isModal}
            isTPA={isTPA}
            isVestwellUser={isVestwellUser}
            pageNumber={pageNumber}
            planListQuery={planListQuery}
            planTypes={props.planTypes}
            plans={plans}
            plansStatsFetching={plansStats.isFetching}
            receivePlan={receivePlan}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            setSorter={setSorter}
            sorter={sorter}
            updateFilters={updateFilters}
          />
        ),
        label: 'Plan Stats',
        value: 'Plan Stats'
      }
    ],
    [
      planFilters,
      areFiltersApplied,
      isModal,
      isTPA,
      isVestwellUser,
      props.planTypes,
      planListQuery
    ]
  );
  const handleChange = useCallback(() => {
    setPlanFilters(initialPlansFilters);
  }, []);

  // we ignore errors for the exact match query
  // since we expect that to fail regularly
  const { error } = planListQuery;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  return (
    <div>
      {!isModal && props.createPlanButton && (
        <AccessControl
          hideFromTPA
          requiresOneOf={[FeatureLevelPermissions.WRITE_CREATE_PLAN]}>
          <Box display='flex' justifyContent='flex-end' marginBottom={3}>
            {props.createPlanButton}
          </Box>
        </AccessControl>
      )}
      <Box sx={{ border: `1px solid ${grey[300]}`, borderRadius: '4px' }}>
        {props.isModal ? (
          <PlansIndexTable
            areFiltersApplied={areFiltersApplied}
            columns={columns}
            hideProgram={props.hideProgram}
            isModal={isModal}
            isTPA={isTPA}
            isVestwellUser={isVestwellUser}
            pageNumber={pageNumber}
            planListQuery={planListQuery}
            planTypes={props.planTypes}
            plans={plans}
            receivePlan={receivePlan}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            setSorter={setSorter}
            sorter={sorter}
            updateFilters={updateFilters}
          />
        ) : (
          <SimpleTabs
            data-testid='plans-index-table-tabs'
            onChange={handleChange}
            style={{ marginBottom: 0 }}
            tabs={tabs}
            tabsAriaLabel='plans-tabs'
          />
        )}
      </Box>
    </div>
  );
};

export default PlansIndex;
