import { PlanV2Dto } from '@/models';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  Card,
  Divider,
  LinearProgress,
  Link,
  Switch,
  Theme,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { camelCase } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useToggle } from 'react-use';
import * as uuid from 'uuid';

import { NoRowsOverlay } from './NoRowsOverlay.component';

type ContributionsProps = {
  ucid: string;
  planId: number;
  search?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  cellBorder: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  }
}));

export const Contributions: FC<ContributionsProps> = props => {
  const classes = useStyles();
  const [isFilterZeros, toggleFilterZeros] = useToggle(true);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10
  });

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, props.planId],
    () => PlanService.getPlanById(props.planId),
    { enabled: !!props.planId }
  );

  const planFeatures = useQuery<PlanFeaturesDto>(
    [PlanService.getPlanFeatures.name, plan.data?.data?.id],
    () => PlanService.getPlanFeatures(plan.data?.data?.id),
    {
      enabled: !!plan.data
    }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      isFilterZeros,
      pagination.page + 1,
      pagination.pageSize,
      plan.data?.data?.id,
      props.search,
      'lastName',
      plan.data?.data?.relationships?.sponsor?.data?.id,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        filterZeros: isFilterZeros,
        pageNumber: pagination.page + 1,
        pageSize: pagination.pageSize,
        planId: plan.data?.data?.id,
        search: props.search,
        sort: 'lastName',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: props.ucid
      }),
    {
      enabled: !!props.ucid && !!plan.data?.data,
      keepPreviousData: true,
      refetchOnMount: 'always'
    }
  );

  const rows = useMemo(
    () =>
      contribution.data?.participantData?.map(participant => ({
        ...participant.accountMeta,
        esaEmployeeGroup: participant.esaEmployeeGroup,
        firstName: participant.firstName,
        id: uuid.v4(),
        lastName: participant.lastName,
        participantId: participant.participantId,
        ...(participant.esaEmployerContributions?.length
          ? participant.esaEmployerContributions?.reduce(
              (acc, current) => ({
                ...acc,
                [camelCase(current.contributionType)]:
                  current.contributionValue ?? 0
              }),
              {
                employeeDeferral: 0,
                employerMatch: 0,
                initialDepositBonus: 0,
                milestoneBonus: 0
              }
            )
          : {})
      })) ?? [],
    [contribution.data]
  );

  const columns = useMemo<GridColDef[]>(() => {
    const columns = [
      {
        cellClassName: classes.cellBorder,
        field: 'name',
        headerName: 'Full Name',
        renderCell: params =>
          params.row?.participantId ? (
            <Link
              component={RouterLink}
              target='_blank'
              to={`/participants/${params.row?.participantId}`}>
              {params.value}
            </Link>
          ) : (
            params.value
          ),
        rules: {
          recordKeepers: [2, 4, 5, 6]
        },
        valueGetter: params =>
          `${params.row?.firstName} ${params.row?.lastName}`,
        width: 150
      },
      {
        field: 'esaEmployeeGroup',
        headerName: 'Employee Group',
        rules: {
          recordKeepers: [6]
        },
        width: 150
      },
      {
        align: 'right',
        field: 'sd',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Pre-Tax',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isPreTaxContributionAllowed,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'rc',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Roth',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isRothIraContributionAllowed ||
            planFeatures.data?.data?.attributes?.isSalaryRothDeferral,
          recordKeepers: [2, 4, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'at',
        flex: 1,
        headerAlign: 'right',
        headerName: 'After-Tax',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isAfterTaxContributionAllowed,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'pw',
        flex: 1,
        headerAlign: 'right',
        headerName: 'P. Wage',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.allowPrevailingWage,
          recordKeepers: [5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'sh',
        flex: 1,
        headerAlign: 'right',
        headerName: 'S. Harbor',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isSafeHarborMatchAllowed ||
            planFeatures.data?.data?.attributes?.isSafeHarborNonElectiveAllowed,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'em',
        flex: 1,
        headerAlign: 'right',
        headerName: 'D. Match',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isEmployerMatching,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'ps',
        flex: 1,
        headerAlign: 'right',
        headerName: 'P. Sharing',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isProfitSharing,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'ln',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Loan',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isLoansAllowed ||
            planFeatures.data?.data?.attributes?.hasPriorLoans,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'qc',
        flex: 1,
        headerAlign: 'right',
        headerName: 'QNEC',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isQnecQmacAllowed,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'qm',
        flex: 1,
        headerAlign: 'right',
        headerName: 'QMAC',
        maxWidth: 200,
        rules: {
          planFeatureEnabled:
            planFeatures.data?.data?.attributes?.isQnecQmacAllowed,
          recordKeepers: [2, 5]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'employeeDeferral',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Employee Contribution',
        maxWidth: 200,
        rules: {
          recordKeepers: [6]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'employerMatch',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Employer Match',
        maxWidth: 200,
        rules: {
          recordKeepers: [6]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'initialDepositBonus',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Initial Deposit',
        maxWidth: 200,
        rules: {
          recordKeepers: [6]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        align: 'right',
        field: 'milestoneBonus',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Milestone Deposit',
        maxWidth: 200,
        rules: {
          recordKeepers: [6]
        },
        valueFormatter: data => formatters.formatDollars(data.value)
      }
    ];

    return columns.filter(
      column =>
        column.rules?.recordKeepers?.includes(
          plan.data?.data?.relationships?.recordKeeper?.data?.id
        ) &&
        (typeof column.rules?.planFeatureEnabled === 'boolean'
          ? column.rules?.planFeatureEnabled
          : true)
    ) as GridColDef[];
  }, [contribution.data]);

  const onPaginationModelChange = useCallback(
    model => setPagination(model),
    []
  );

  return (
    <Card variant='outlined'>
      <Grid p={2}>
        <Typography data-testid='contributions-header' variant='h6'>
          Contributions
        </Typography>
      </Grid>
      <Divider />
      <Grid alignItems='center' display='flex' p={1}>
        <Switch
          data-testid='contributions-switch'
          defaultChecked
          onChange={toggleFilterZeros}
        />
        <Typography color='GrayText'>
          Hide Employees without contributions
        </Typography>
      </Grid>
      <DataGridPro
        autoHeight
        columns={columns}
        data-testid='contributions-table'
        disableColumnMenu
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10
            }
          }
        }}
        loading={contribution.isLoading || contribution.isFetching}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        paginationMode='server'
        rowCount={contribution.data?.participantsCount}
        rows={rows}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay
        }}
        sx={{ '--DataGrid-overlayHeight': '200px' }}
      />
    </Card>
  );
};
