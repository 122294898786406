import ContributionService from '@/services/Contribution.service';
import formatters from '@/utils/Formatters';
import { Card, Divider, LinearProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPro } from '@mui/x-data-grid-pro';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import type { FC } from 'react';
import * as uuid from 'uuid';

import { NoRowsOverlay } from './components/NoRowsOverlay.component';

type ContributionAdjustmentsProps = {
  sponsorId: number;
  sponsorPlanId: number;
  ucid: string;
  search?: string;
};

const errorCodes = {
  ERR_BAD_STATE_FORMAT: 'Invalid state/province',
  ERR_BAD_ZIP_FORMAT: 'Invalid zip/postal',
  ERR_CONTRIBUTIONS_AT_BAD_FORMAT: 'Invalid After-Tax format',
  ERR_CONTRIBUTIONS_EM_BAD_FORMAT: 'Invalid Employer Match format',
  ERR_CONTRIBUTIONS_FOR_INELIGIBLE_LTPT:
    'Employer contributions not expected for long-term part-time employees.',
  ERR_CONTRIBUTIONS_GROSS_PAY_BAD_FORMAT: 'Invalid Gross Pay format',
  ERR_CONTRIBUTIONS_LN_BAD_FORMAT: 'Invalid Loan format',
  ERR_CONTRIBUTIONS_MISSING_GROSS_PAY: 'Missing Gross Pay',
  ERR_CONTRIBUTIONS_PS_BAD_FORMAT: 'Invalid Profit Sharing format',
  ERR_CONTRIBUTIONS_QC_BAD_FORMAT: 'Invalid QNEC format',
  ERR_CONTRIBUTIONS_QM_BAD_FORMAT: 'Invalid QMAC format',
  ERR_CONTRIBUTIONS_RC_BAD_FORMAT: 'Invalid Roth format',
  ERR_CONTRIBUTIONS_RC_CATCHUP_BAD_FORMAT: 'Invalid Catch-up Roth format',
  ERR_CONTRIBUTIONS_SD_BAD_FORMAT: 'Invalid Pre-Tax format',
  ERR_CONTRIBUTIONS_SD_CATCHUP_BAD_FORMAT: 'Invalid Catch-up Pre-Tax format',
  ERR_CONTRIBUTIONS_SH_BAD_FORMAT: 'Invalid Safe Harbor format',
  ERR_DATE_MISMATCH_BIRTH_HIRE: 'Date of Hire must be after Date of Birth',
  ERR_DATE_MISSING_HIRE: 'Missing Date of Hire',
  ERR_ELIGIBILITY_BAD_DOB_FORMAT: 'Invalid Date Of Birth',
  ERR_ELIGIBILITY_BAD_DOH_FORMAT: 'Invalid Date of Hire',
  ERR_ELIGIBILITY_BAD_DOR_FORMAT: 'Invalid Date Of Rehire',
  ERR_ELIGIBILITY_BAD_DOT_FORMAT: 'Invalid Date Of Termination',
  ERR_ELIGIBILITY_BAD_HOURS_FORMAT: 'Invalid Hours format',
  ERR_ELIGIBILITY_CONTRIBUTIONS_BY_INELIG: 'Eligibility requirements not met',
  ERR_ELIGIBILITY_MISSING_BIRTHDAY: 'Missing Date of Birth',
  ERR_ELIGIBILITY_MISSING_HIRE: 'Missing Date of Hire',
  ERR_ELIGIBILITY_REHIRE_WITHOUT_TERM:
    'Date Of Rehire provided without Termination Date',
  ERR_ELIGIBILITY_YTD_HOURS_INVALID_VALUE: 'Invalid YTD Hours value',
  ERR_ESA_FOREIGN_ADDRESS_NOT_ALLOWED: 'Foreign address is not allowed',
  ERR_ESA_UNKNOWN_EMPLOYEE_GRP: 'Unknown employee group',
  ERR_IDENTITY_BAD_SSN_FORMAT: 'Improperly formatted social security number',
  ERR_IDENTITY_DUPLICATE_SSN: 'Duplicated social security number',
  ERR_IDENTITY_INVALID_SSN: 'Invalid social security number',
  ERR_IDENTITY_MISMATCHED_SSN: 'Mismatched SSN',
  ERR_IDENTITY_MISSING_ADDRESS: 'Missing Address',
  ERR_IDENTITY_MISSING_NAMES: 'Missing a name',
  ERR_IDENTITY_MISSING_SSN: 'Missing a valid social security number',
  ERR_MISMATCHED_LOAN_PAYMENT: 'Mismatched loan repayment',
  ERR_STATE_IRA_OVER_IRS_LIMIT: 'Employee Contribution Limit',
  STOP_ELIGIBILITY_UNREALISTIC_DATE_OF_BIRTH: 'Unrealistic Date Of Birth',
  UNREALISTIC_DATE_OF_BIRTH: 'Unrealistic Date Of Birth',
  UNRECOGNIZED_EMPLOYEE: 'Unrecognized employee detected',
  WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT: 'Participant has no active loans',
  WARN_CONTRIBUTIONS_IRS_LIMITS: 'Employee and Employer Contribution Limits',
  WARN_CONTRIBUTIONS_UNESTABLISHED_NON_ROTH: 'Unestablished non-Roth Account',
  WARN_CONTRIBUTIONS_UNESTABLISHED_ROTH: 'Unestablished Roth Account',
  WARN_EXCESSIVE_NEW_EMPLOYEES_AMOUNT: 'Excessive new employees amount',
  WARN_IDENTITY_MISSING_EMAIL: 'Missing Email',
  WARN_MATCH_NOT_CALCULATED: 'Unable to calculate match'
};

const columns: GridColDef[] = [
  {
    field: 'ssn',
    headerName: 'SSN',
    minWidth: 150,
    resizable: true,
    sortable: true,
    valueFormatter: params => {
      try {
        return formatters.maskSSN(params.value);
      } catch {
        return params.value;
      }
    }
  },
  {
    field: 'name',
    headerName: 'Full Name',
    minWidth: 150,
    resizable: true,
    sortable: true,
    valueGetter: params =>
      params.row?.firstName && params.row?.lastName
        ? `${params.row?.lastName || ''}, ${params.row?.firstName || ''}`
        : 'Unknown'
  },
  {
    field: 'correctionType',
    headerName: 'Type',
    resizable: true,
    sortable: true,
    valueFormatter: params =>
      ({
        auto_update: 'Auto update',
        delete: 'Delete',
        ignore: 'Ignore',
        update: 'Update'
      })[params.value] ?? '--'
  },
  {
    field: 'columnNames',
    headerName: 'Field(s)',
    resizable: true,
    sortable: false,
    valueFormatter: params => params.value?.join(', ')
  },
  {
    field: 'createdAt',
    headerName: 'Time',
    minWidth: 150,
    resizable: true,
    sortable: true,
    valueFormatter: params => dayjs(params.value).format('M/DD/YYYY h:mm A')
  },
  {
    field: 'reason',
    flex: 1,
    headerName: 'Reason',
    resizable: true,
    sortable: true,
    valueGetter: params =>
      params.value || errorCodes[params.row?.errorCode] || params.row?.errorCode
  },
  {
    align: 'right',
    field: 'contributionAmount',
    headerName: 'Reported Amount',
    minWidth: 150,
    resizable: true,
    sortable: true,
    valueFormatter: params => formatters.formatDollars(params.value)
  },
  {
    align: 'right',
    field: 'processedAmount',
    headerName: 'Processed Amount',
    minWidth: 150,
    resizable: true,
    sortable: true,
    valueFormatter: params => formatters.formatDollars(params.value),
    valueGetter: params =>
      Decimal.sub(
        params.row?.contributionAmount ?? 0,
        params.row?.unallowedAmount ?? 0
      ).toNumber()
  }
];

export const ContributionAdjustments: FC<
  ContributionAdjustmentsProps
> = props => {
  const adjustments = useQuery(
    [
      ContributionService.getContributionAdjustments.name,
      props.ucid,
      props.sponsorId,
      props.sponsorPlanId
    ],
    () =>
      ContributionService.getContributionAdjustments(
        { ucid: props.ucid },
        {
          sponsorId: props.sponsorId,
          sponsorPlanId: props.sponsorPlanId
        }
      ),
    {
      refetchOnMount: 'always'
    }
  );

  const rows = useMemo(
    () =>
      adjustments.data?.adjustmentData?.map(row => ({
        ...row,
        id: uuid.v4()
      })) ?? [],
    [adjustments.data]
  );

  return (
    <Card data-testid='adjustments' variant='outlined'>
      <Grid p={2}>
        <Typography data-testid='adjustments-header' variant='h6'>
          Contribution Adjustments ({rows?.length ?? 0})
        </Typography>
      </Grid>
      <Divider />
      <DataGridPro
        autoHeight
        columnBuffer={8}
        columns={columns}
        data-testid='adjustments-table'
        disableColumnMenu
        disableRowSelectionOnClick
        filterModel={{
          items: [{ field: 'name', operator: 'contains', value: props.search }]
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10
            }
          },
          sorting: {
            sortModel: [
              {
                field: 'name',
                sort: 'asc'
              },
              {
                field: 'ssn',
                sort: 'asc'
              }
            ]
          }
        }}
        loading={adjustments.isFetching}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        paginationMode='client'
        rows={rows}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay
        }}
        sx={{ '--DataGrid-overlayHeight': '200px' }}
      />
    </Card>
  );
};
