import { useSnackbar } from '@/contexts/SnackBarContext';
import { TransactionCodeDto } from '@/models/suba/transaction-codes/TransactionCodeDto';
import { TransactionCodeSearchRequest } from '@/models/suba/transaction-codes/TransactionCodeSearchRequest';
import TransactionCodeService from '@/services/suba/transaction-codes/TransactionCode.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { sortBy } from 'lodash';

export const useTransactionCodes = (
  params?: TransactionCodeSearchRequest
): UseQueryResult<undefined | TransactionCodeDto[]> => {
  const { showSnackbar } = useSnackbar();

  return useQuery(
    ['transactionCodes', params],
    async () =>
      sortBy(
        await TransactionCodeService.search(params),
        transactionCode => transactionCode.transactionTypeCode
      ),
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch transaction codes: ${message}`,
          severity: 'error'
        });
      },
      placeholderData: []
    }
  );
};
