import AppConfig from '@/App.config';
import { useUserToken } from '@/contexts/UserTokenContext';
import BasePath from '@/models/BasePath.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ApiService from '@/services/Api.service';
import TpaService from '@/services/Tpa.service';
import { IDPUser, TokenData, userService } from '@/services/User.service';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Book,
  ChevronLeft,
  ChevronRight,
  PeopleAlt,
  Summarize
} from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FolderIcon from '@mui/icons-material/Folder';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import AccessControl from '../access-control/AccessControl.component';
import LinearLoading from '../linear-loading';
import GlobalSearch from './GlobalSearch/GlobalSearch.component';
import Logout from './Logout.component';
import OpsMenuItem from './Ops/OpsMenuItem.component';
import SessionTimerDialog from './SessionTimerDialog';

const drawerWidth = 240;
const hedgeClauseText = `All data shown on the Vestwell portal is compiled from various sources including sources outside of Vestwell's control such as payroll providers and other third parties. While we believe the data to be generally reliable, we do not make any representations about the accuracy or completeness of the data. Participant deferral changes, employment status, and other demographic or plan-related information displayed on the portal may be outdated and may have changed in material respects. For these reasons, the data displayed on the portal should not be provided to or relied upon for compliance testing, preparation of regulatory filings, audits, or any other purpose.`;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    left: 0,
    transition: theme.transitions.create(['width', 'margin', 'left'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    zIndex: theme.zIndex.vestwellAppBar
  },
  appBarShift: {
    left: `${drawerWidth}px`,
    transition: theme.transitions.create(['width', 'margin', 'left'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    })
  },
  bumper: {
    height: '2rem',
    marginRight: '36px',
    padding: '0',
    width: '2rem'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(9)})`
    }
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: theme.spacing(9)
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: drawerWidth
  },
  footer: {
    border: 'none',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textAlign: 'justify',
    transition: theme.transitions.create(['width', 'padding'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    })
  },
  hide: {
    display: 'none'
  },
  menuButton: {
    marginRight: 36
  },
  pdfLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  root: {
    display: 'flex',
    minHeight: '90vh',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  termsOfUse: {
    minHeight: theme.spacing(4.5)
  },
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  vestwellLogo: {
    height: '2rem',
    marginTop: '.5rem',
    width: 'auto'
  }
}));

interface MainLayoutProps {
  isGlobalSearchResultOpen: boolean;
  children: React.ReactNode;
  appWidth: number;
}

enum PageStyle {
  BASE_NAV = 'base-nav',
  FRAMELESS = 'frameless'
}

const MainLayout = (props: MainLayoutProps): JSX.Element => {
  const { isGlobalSearchResultOpen, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { isAuthenticated, user, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pageStyle, setPageStyle] = useState(PageStyle.BASE_NAV);
  const [isTpaPortalUser, setIsTpaPortalUser] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const { pathname } = useLocation();
  const { setUserToken, token } = useUserToken();

  useEffect(() => {
    const framelessRoutes = ['/', '/terms-of-use'];
    if (framelessRoutes.includes(pathname)) {
      setPageStyle(PageStyle.FRAMELESS);
    } else {
      setPageStyle(PageStyle.BASE_NAV);
    }
  }, [pathname]);

  const [activePage, setActivePage] = useState('/');
  const plansPath: BasePath = '/plans';
  const tpaPath: BasePath = '/tpa';
  const userManagementPath: BasePath = '/user-management';
  const reportsPath: BasePath = '/reports';
  const firmsPath: BasePath = '/firms';
  const platformReportingPath: BasePath = '/platform-reporting';

  const checkUserTermsOfUseQuery = useQuery<unknown>(
    ['TpaService.getTermsOfUseStatus'],
    async () => {
      return TpaService.getTermsOfUseStatus();
    },
    {
      enabled: isTpaPortalUser,
      onError: (err: any) => {
        if (err.message?.includes('404')) {
          setShowTermsOfUse(true);
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity
    }
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const updateActivePage = () => {
    setActivePage(window.location.pathname);
  };

  const getAuth0AccessToken = useCallback(
    async (ignoreCache = false) => {
      try {
        const token = await getAccessTokenSilently({
          audience: AppConfig.auth0Config.audience,
          ignoreCache
        });

        userService.setAccessToken(token);
        if (AppConfig.environment !== 'production') {
          (window as any).getTestToken = () => token;
        }
        const data: TokenData = await ApiService.getJson('me');
        const hasTpaId = data.tpaId !== null;
        const hasAccessToTpaFeature = data.permissions.some(
          permission =>
            permission === 'user:tpa' ||
            permission === FeatureLevelPermissions.READ_TPA
        );
        setIsTpaPortalUser(hasTpaId && hasAccessToTpaFeature);

        userService.setTokenData(data);
        // this is where we get news that the token is here and we can make API calls
        setUserToken(data);
        if (!data.permissions.length) {
          sessionStorage.setItem(
            'logoutMessage',
            'You do not have permissions to access this portal, please contact support.'
          );
          return logout({ returnTo: window.location.origin });
        }
        const lastPage = sessionStorage.getItem('lastPage');
        sessionStorage.removeItem('lastPage');
        sessionStorage.removeItem('logoutMessage');
        if (lastPage) {
          navigate(lastPage);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [getAccessTokenSilently, setUserToken, navigate]
  );

  useEffect(() => {
    const ensureUserHasToken = async () => {
      updateActivePage();

      const sendUserToLoginPage = () => {
        const loginPath = AppConfig.loginPage;
        if (window.location.pathname !== loginPath) {
          sessionStorage.setItem('lastPage', window.location.pathname);
          navigate(loginPath);
        }
      };

      userService.setUserFromIDP(user as IDPUser);
      userService.setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        getAuth0AccessToken();
        if (!sessionStorage.getItem('tabId')) {
          sessionStorage.setItem('tabId', uuidv4());
        }
      } else if (!isLoading) {
        sendUserToLoginPage();
      }
    };

    ensureUserHasToken();
  }, [isAuthenticated, user, navigate, isLoading, getAuth0AccessToken]);

  useEffect(() => {
    if (checkUserTermsOfUseQuery.isError && showTermsOfUse) {
      navigate('/terms-of-use', { replace: true });
    }
  }, [showTermsOfUse, checkUserTermsOfUseQuery.isError, navigate]);

  return (
    <>
      {pageStyle === PageStyle.BASE_NAV && (
        <>
          <div className={classes.root}>
            <AppBar
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open
              })}
              color='default'
              position='fixed'
              sx={theme => {
                const spacingPx = +theme.spacing(9).replaceAll('px', '');
                return {
                  width:
                    props.appWidth - (open ? drawerWidth : 0) + spacingPx + 'px'
                };
              }}>
              <Toolbar>
                {isAuthenticated && (
                  <IconButton
                    aria-label='open drawer'
                    className={clsx(classes.menuButton, open && classes.hide)}
                    color='inherit'
                    edge='start'
                    onClick={handleDrawerOpen}
                    size='large'>
                    <MenuIcon />
                  </IconButton>
                )}
                {!isAuthenticated && <div className={classes.bumper} />}
                <Link to='/'>
                  <Typography variant='h6'>
                    <img
                      alt={AppConfig.branding.logoAlt}
                      className={classes.vestwellLogo}
                      src={AppConfig.branding.logoPath}
                    />
                  </Typography>
                </Link>

                {isAuthenticated && (
                  <SessionTimerDialog
                    getAuth0AccessToken={getAuth0AccessToken}
                    promptBeforeIdle={AppConfig.promptBeforeIdle}
                    timeout={AppConfig.timeout}
                  />
                )}
                {isAuthenticated && (
                  <GlobalSearch
                    isOpen={isGlobalSearchResultOpen}
                    placeholder='Global search'
                  />
                )}
              </Toolbar>
            </AppBar>
            {isAuthenticated && (
              <Drawer
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                  })
                }}
                variant='permanent'>
                <div className={classes.toolbar}>
                  <IconButton onClick={handleDrawerClose} size='large'>
                    {theme.direction === 'rtl' ? (
                      <ChevronRight />
                    ) : (
                      <ChevronLeft />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <ListItemButton
                    component={Link}
                    data-testid='plans-navmenu'
                    key='Plans'
                    selected={window.location.pathname.indexOf('/plans') >= 0}
                    to={plansPath}>
                    <ListItemIcon>
                      <Book />
                    </ListItemIcon>
                    <ListItemText primary='Plans' />
                  </ListItemButton>

                  <AccessControl
                    requiresOneOf={[
                      FeatureLevelPermissions.READ_INVESTMENTS,
                      FeatureLevelPermissions.READ_SUBA_ACCOUNTS,
                      FeatureLevelPermissions.READ_SUBA_ALERTS,
                      FeatureLevelPermissions.READ_SUBA_RECON,
                      FeatureLevelPermissions.WRITE_BULK_EMAIL_ACTIONS,
                      FeatureLevelPermissions.WRITE_BULK_UPLOAD_ACTIONS,
                      FeatureLevelPermissions.WRITE_STATE_IRA_EMPLOYER_BULK_UPLOAD
                    ]}>
                    <OpsMenuItem
                      activePage={activePage}
                      data-testid='ops-navmenu'
                      open={open}
                      setOpen={setOpen}
                    />
                  </AccessControl>

                  <AccessControl
                    requiresOneOf={[
                      'user:tpa',
                      FeatureLevelPermissions.READ_TPA
                    ]}>
                    <ListItemButton
                      component={Link}
                      data-testid='tpa-navmenu'
                      key='TPA'
                      selected={window.location.pathname.indexOf('/tpa') >= 0}
                      to={tpaPath}>
                      <ListItemIcon>
                        <img
                          alt={isTpaPortalUser ? 'Manage Team' : 'TPA'}
                          src='/images/tpa-icon.png'
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={isTpaPortalUser ? 'Manage Team' : 'TPA'}
                      />
                    </ListItemButton>
                  </AccessControl>
                  <AccessControl
                    requires={[FeatureLevelPermissions.READ_USER_MANAGEMENT]}>
                    <ListItemButton
                      component={Link}
                      data-testid='user-management-navmenu'
                      key='userManagement'
                      selected={
                        window.location.pathname.indexOf('/user-management') >=
                        0
                      }
                      to={userManagementPath}>
                      <ListItemIcon>
                        <PeopleAlt />
                      </ListItemIcon>
                      <ListItemText primary='Users' />
                    </ListItemButton>
                  </AccessControl>
                  <AccessControl requires={[FeatureLevelPermissions.READ_TPA]}>
                    <ListItemButton
                      component={Link}
                      data-testid='reports-navmenu'
                      key='Reports'
                      selected={
                        window.location.pathname.indexOf('/reports') >= 0
                      }
                      to={reportsPath}>
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText primary='Reports' />
                    </ListItemButton>
                  </AccessControl>
                  <AccessControl
                    hideFromTPA
                    requires={[FeatureLevelPermissions.READ_FIRM]}>
                    <ListItemButton
                      component={Link}
                      data-testid='firms-navmenu'
                      key='Firms'
                      selected={window.location.pathname.indexOf('/firms') >= 0}
                      to={firmsPath}>
                      <ListItemIcon>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      <ListItemText primary='Firms' />
                    </ListItemButton>
                  </AccessControl>
                  <AccessControl
                    requires={[FeatureLevelPermissions.READ_SIGMA_REPORTING]}>
                    <ListItemButton
                      component={Link}
                      data-testid='platform-reporting-navmenu'
                      key='Platform Reporting'
                      selected={
                        window.location.pathname.indexOf(
                          '/platform-reporting'
                        ) >= 0
                      }
                      to={platformReportingPath}>
                      <ListItemIcon>
                        <Summarize />
                      </ListItemIcon>
                      <ListItemText primary='Platform Reporting' />
                    </ListItemButton>
                  </AccessControl>
                </List>
                <Divider />
                <List>
                  <Logout />
                </List>
              </Drawer>
            )}
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open
              })}>
              <div className={classes.toolbar} />
              {isAuthenticated && token?.permissions.length ? (
                children
              ) : (
                <LinearLoading />
              )}
            </main>
          </div>
          <AppBar
            className={classes.footer}
            color='transparent'
            elevation={0}
            position='static'
            style={{ paddingLeft: open ? '190px' : undefined }}>
            {isAuthenticated && isTpaPortalUser && (
              <Container maxWidth='xl'>
                <Toolbar className={classes.termsOfUse}>
                  <Typography
                    color='textSecondary'
                    data-testid='terms-of-use-link'
                    variant='caption'>
                    Please see{' '}
                    <a
                      className={classes.pdfLink}
                      href='/terms-of-use?disableAgreement=true'
                      rel='noreferrer'
                      target='_blank'>
                      here
                    </a>{' '}
                    for our Terms of Use.
                  </Typography>
                </Toolbar>
              </Container>
            )}
            {isAuthenticated && (
              <Container maxWidth='xl'>
                <Toolbar>
                  <Typography
                    color='textSecondary'
                    data-testid='hedge-clause-text'
                    variant='caption'>
                    {hedgeClauseText}
                  </Typography>
                </Toolbar>
              </Container>
            )}
          </AppBar>
        </>
      )}
      {pageStyle === PageStyle.FRAMELESS && <>{children}</>}
    </>
  );
};

export default MainLayout;
