import AccessControl from '@/components/access-control/AccessControl.component';
import DetailsPage from '@/components/details-page';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import ErrorMessage from '@/components/error-message';
import LinearLoading from '@/components/linear-loading';
import PlanScheduledChangesInfo from '@/components/scheduled-changes/PlanScheduledChangesInfo.component';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import TooltipButton from '@/components/tool-tip-button/TooltipButton.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useUserToken } from '@/contexts/UserTokenContext';
import { useFixPlanRoute } from '@/hooks/useFixPlanRoute.hook';
import { PlanSalesforceInfo, SponsorDto } from '@/models';
import { LoanResponseDto } from '@/models/LoanDTO.model';
import {
  initialPlanRolloverFilters,
  PlanRolloverFilters
} from '@/models/PlanDistributionFilters.model';
import { PlanV2Dto } from '@/models/PlanV2DTO.model';
import { PooledPlan } from '@/models/PooledPlanDTO.model';
import { FormattedRolloverDto } from '@/models/RolloversDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { FormattedWithdrawalDto } from '@/models/WithdrawalsDTO.model';
import PlanContributionsTab from '@/routes/plans/plan-detail/PlanContributionsTab/PlanContributionsTab.component';
import PlanDeconversion from '@/routes/plans/plan-detail/PlanDeconversionTab/PlanDeconversion.component';
import PlanDocumentsTab from '@/routes/plans/plan-detail/PlanDocumentsTab/PlanDocumentsTab.component';
import { PlanEmployees } from '@/routes/plans/plan-detail/PlanEmployees/PlanEmployees.component';
import PlanInvestmentsTab from '@/routes/plans/plan-detail/PlanInvestmentsTab/PlanInvestmentsTab.component';
import PlanOnboard from '@/routes/plans/plan-detail/PlanOnboard';
import PlanRolloversTab from '@/routes/plans/plan-detail/PlanRolloversTab/PlanRolloversTab.component';
import { PlanTab } from '@/routes/plans/plan-detail/PlanTab/PlanTab.component';
import PlanUploads from '@/routes/plans/plan-detail/PlanUploads';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import { userService } from '@/services/User.service';
import UserManagementService from '@/services/UserManagement.service';
import { Card, Grid, Theme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { HardcodedBanner } from './HardcodedBanner.component';
import PlanActionTableV2 from './PlanActionTableV2';
import { PlanAuditLogsTab } from './PlanActivitiesTab/PlanAuditLogsTab/PlanAuditLogsTab.component';
import PlanCommunicationsTab from './PlanActivitiesTab/PlanCommunicationsTab/PlanCommunicationsTab.component';
import { PlanAnnualTestingTab } from './PlanAnnualTestingTab/PlanAnnualTestingTab.component';
import { PlanCompanyTab } from './PlanCompanyTab/PlanCompanyTab.component';
import { PlanForfeituresTab } from './PlanForfeituresTab/PlanForfeituresTab.component';
import PlanLoansTab from './PlanLoansTab/PlanLoansTab.component';
import { PlanRolloverDistributionsFilters } from './PlanRolloverDistributionsFilters.component';
import PlanWithdrawals from './PlanWithdrawalsTab/PlanWithdrawals.component';
import { usePlanType } from './usePlanType.hook';

type PlanDetailRouteParams = {
  sponsorPlanId: string;
  planType: string;
};

type QueryError = {
  message: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: blue[700]
    },
    backgroundColor: blue[500],
    color: theme.palette.getContrastText(blue[500]),
    fontSize: theme.spacing(1.5),
    height: theme.spacing(5),
    width: theme.spacing(20)
  },
  buttonPosition: {
    marginTop: theme.spacing(4)
  },
  employerButton: {
    '&:hover': {
      backgroundColor: blue[700]
    },
    backgroundColor: blue[500],
    color: theme.palette.getContrastText(blue[500]),
    fontSize: theme.spacing(1.5),
    height: theme.spacing(5),
    width: theme.spacing(22)
  },
  mutedText: {
    color: theme.palette.text.secondary
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'max'
  }
}));

const LT_RECORDKEEPER = {
  id: 1,
  name: 'LT'
};

const SUB_ACCOUNTING_RECORDKEEPER = {
  id: 5,
  name: 'Vestwell Sub-Accounting Platform'
};

const PlanDetailRoute: React.FC = () => {
  const params = useParams<PlanDetailRouteParams>();

  const { userHasValidToken, token } = useUserToken();

  const isBNYUser = useMemo(() => token?.tpaId === 42, [token]);

  const classes = useStyles();

  const [selectedRolloverComponent, setSelectedRolloverComponent] =
    useState('Rollovers');

  const [selectedActivitiesComponent, setSelectedActivitiesComponent] =
    useState('Communications');

  const [rolloversQueryData, setRolloversQueryData] =
    useState<FormattedRolloverDto[]>();

  const [withdrawalsQueryData, setWithdrawalsQueryData] =
    useState<FormattedWithdrawalDto[]>();

  const [loansQueryData, setLoansQueryData] = useState<LoanResponseDto>();

  const [filters, setFilters] = useState<PlanRolloverFilters>(
    initialPlanRolloverFilters
  );

  const sponsorPlanQuery = useQuery<PlanV2Dto, QueryError>(
    ['PlanService.getPlanById', params.sponsorPlanId],
    () => PlanService.getPlanById(params.sponsorPlanId),
    {
      enabled: userHasValidToken && Boolean(params.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const planType = usePlanType({
    recordkeeper: sponsorPlanQuery.data?.data.attributes.recordkeeper,
    type: sponsorPlanQuery.data?.data.attributes.type
  });

  useFixPlanRoute();

  const pooledPlanQuery = useQuery<PooledPlan>(
    [
      'PlanService.getPooledPlanById',
      sponsorPlanQuery.data?.data?.relationships?.pooledPlan?.data?.id
    ],
    () =>
      PlanService.getPooledPlanById(
        Number(sponsorPlanQuery.data?.data?.relationships?.pooledPlan?.data?.id)
      ),
    {
      enabled:
        userHasValidToken &&
        Boolean(
          sponsorPlanQuery.data?.data?.relationships?.pooledPlan?.data?.id
        ),
      staleTime: Infinity
    }
  );

  const sponsorQuery = useQuery<SponsorDto, QueryError>(
    [
      'SponsorService.getSponsorById',
      sponsorPlanQuery.data?.data.relationships.sponsor.data.id.toString()
    ],
    () => {
      if (!sponsorPlanQuery.data?.data.relationships.sponsor.data.id)
        throw new Error(`keep the compiler happy`);

      return SponsorService.getSponsorById(
        sponsorPlanQuery.data?.data.relationships.sponsor.data.id
      );
    },
    {
      enabled:
        userHasValidToken &&
        Boolean(sponsorPlanQuery?.data?.data.relationships.sponsor.data.id),
      staleTime: Infinity
    }
  );

  const isVestwellSubaccounting = useMemo(
    () =>
      sponsorPlanQuery.data?.data.attributes.recordkeeper ===
      SUB_ACCOUNTING_RECORDKEEPER.name,
    [sponsorPlanQuery.data?.data.attributes.recordkeeper]
  );

  const planSalesforceInfoQuery = useQuery<PlanSalesforceInfo>(
    ['PlanService.getPlanSalesforceInfo', params.sponsorPlanId],
    () => PlanService.getPlanSalesforceInfo(params.sponsorPlanId),
    {
      enabled: isVestwellSubaccounting,
      staleTime: Infinity
    }
  );

  const hasOnboardingPermission = userService.hasPermission(
    FeatureLevelPermissions.READ_ONBOARD
  );

  const isInvestments =
    userService.hasPermission(FeatureLevelPermissions.USER_READ) &&
    Boolean(sponsorPlanQuery.data?.data.relationships.program);

  const cantAccessEsa506472 =
    !userService.hasPermission(FeatureLevelPermissions.READ_ESA_506472) &&
    +params.sponsorPlanId === 506472;

  const hasPlan1ReadPermission = userService.hasPermission(
    FeatureLevelPermissions.READ_PLAN_1
  );

  const hasEsaPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_ESA
  );

  const isConversion = useMemo(
    () =>
      sponsorQuery.data?.data.attributes.isConvert?.toLowerCase() === 'true' &&
      hasOnboardingPermission &&
      !planType.isStateIRA,
    [hasOnboardingPermission, planType.isStateIRA, sponsorQuery.data]
  );

  const requestError = sponsorPlanQuery.error || sponsorQuery.error;
  if (requestError) {
    return redirectToErrorPage(requestError);
  }

  if (!hasEsaPermissions && planType.urlPlansType === 'esa-plans') {
    return redirectToErrorPage({ code: 401, message: 'Unauthorized' });
  }

  const makeTabs = () => {
    const sponsorId = sponsorPlanQuery.data?.data.relationships.sponsor.data.id;
    const firmId = sponsorPlanQuery.data?.data?.relationships?.firm?.data?.id;
    const programId =
      sponsorPlanQuery.data?.data?.relationships?.program?.data?.id || 0;
    const custodianAccountNumber =
      sponsorPlanQuery.data?.data?.attributes?.custodianAccountNumber;

    const planTab: TabData = {
      component: (
        <PlanTab
          isEsa={planType.isEsa}
          isStateIRA={planType.isStateIRA}
          isVestwellSubaccounting={isVestwellSubaccounting}
          pooledPlanData={pooledPlanQuery.data}
          sponsorPlanId={params.sponsorPlanId}
        />
      ),
      label: 'Plan',
      path: `/${planType.urlPlansType}/${params.sponsorPlanId}/plan`
    };

    const planEmployeesTab: TabData = {
      component: (
        <PlanEmployees
          isEsa={planType.isEsa}
          isStateIRA={planType.isStateIRA}
          plan={sponsorPlanQuery?.data as PlanV2Dto}
          sponsorPlanId={params.sponsorPlanId}
        />
      ),
      label: 'Employees',
      path: `/${planType.urlPlansType}/${params.sponsorPlanId}/employees`
    };

    const baseTabs = [planTab];
    if (token?.permissions.includes(FeatureLevelPermissions.READ_PARTICIPANT)) {
      baseTabs.push(planEmployeesTab);
    }

    if (sponsorId === undefined) {
      return {
        tabs: baseTabs,
        tabsAriaLabel: 'plan-detail-tabs'
      };
    }

    const registeredTabs: TabData[] = [
      ...baseTabs,
      {
        component: (
          <PlanCompanyTab
            firmId={firmId}
            isEsa={planType.isEsa}
            isStateIRA={planType.isStateIRA}
            isVoyaPlan={planType.isVoyaPlan}
            sponsorId={sponsorId}
            sponsorPlanId={+params.sponsorPlanId}
            tpaId={
              sponsorPlanQuery.data?.data?.relationships?.tpas?.data?.[0]?.id
            }
          />
        ),
        label: 'Company',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/company`
      },
      {
        component: (
          <PlanInvestmentsTab
            custodianAccountNumber={custodianAccountNumber}
            planId={+params.sponsorPlanId}
            programId={programId}
          />
        ),
        label: 'Investments',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/investments`
      },
      {
        component: (
          <PlanContributionsTab
            isEsa={planType.isEsa}
            isStateIRA={planType.isStateIRA}
            sponsorId={sponsorId}
            sponsorPlanId={+params.sponsorPlanId}
          />
        ),
        label: 'Contributions',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/contributions`
      },
      ...(token?.permissions.includes(FeatureLevelPermissions.READ_CONVERSION)
        ? [
            {
              component: (
                <PlanActionTableV2 sponsorPlanId={+params.sponsorPlanId} />
              ),
              label: 'Conversion',
              path: `/${planType.urlPlansType}/${params.sponsorPlanId}/conversion`
            },
            {
              component: (
                <PlanDeconversion
                  sponsorId={sponsorId}
                  sponsorPlanId={+params.sponsorPlanId}
                />
              ),
              label: 'Deconversion',
              path: `/${planType.urlPlansType}/${params.sponsorPlanId}/deconversion`
            }
          ]
        : []),
      {
        component: <PlanUploads sponsorPlanId={+params.sponsorPlanId} />,
        label: 'Uploads',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/uploads`
      },
      {
        component: (
          <PlanOnboard
            isConversion={isConversion}
            isStatePlan={planType.isStateIRA}
            recordkeeper={
              sponsorPlanQuery.data?.data?.attributes?.recordkeeper ?? ''
            }
            recordkeeperId={
              sponsorPlanQuery.data?.data?.relationships?.recordKeeper?.data?.id
            }
            sponsorId={sponsorId}
            sponsorPlanId={params.sponsorPlanId}
            taskTracker={
              sponsorPlanQuery.data?.data?.attributes
                ?.statefulSchemaTrackingState
            }
            tpaId={
              sponsorPlanQuery.data?.data?.relationships?.tpas?.data?.[0]?.id
            }
          />
        ),
        label: 'Onboard',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/onboard`
      },
      {
        component: (
          <PlanDocumentsTab
            planName={sponsorPlanQuery.data?.data.attributes.name || ''}
            sponsorPlanId={+params.sponsorPlanId}
          />
        ),
        label: 'Documents',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/documents`
      },
      {
        component: (
          <PlanAnnualTestingTab sponsorPlanId={+params.sponsorPlanId} />
        ),
        label: 'Annual Testing',
        path: `/${planType.urlPlansType}/${params.sponsorPlanId}/annual-testing`
      },
      ...(token?.permissions.includes(FeatureLevelPermissions.READ_ROLLOVERS)
        ? [
            {
              component: (
                <Card variant='outlined'>
                  <Grid container wrap='nowrap'>
                    <AccessControl
                      requires={[FeatureLevelPermissions.WORK_IN_PROGRESS]}>
                      <Grid borderRight={`1px ${grey[300]}`}>
                        <PlanRolloverDistributionsFilters
                          filters={filters}
                          queryData={
                            selectedRolloverComponent === 'Rollovers'
                              ? rolloversQueryData
                              : selectedRolloverComponent === 'Withdrawls'
                                ? withdrawalsQueryData
                                : loansQueryData
                          }
                          type={selectedRolloverComponent}
                          updateFilters={setFilters}
                        />
                      </Grid>
                    </AccessControl>
                    <Grid width='100%'>
                      {selectedRolloverComponent === 'Rollovers' ? (
                        <PlanRolloversTab
                          setQueryData={setRolloversQueryData}
                          sponsorPlanId={+params.sponsorPlanId}
                        />
                      ) : selectedRolloverComponent === 'Withdrawals' ? (
                        <PlanWithdrawals
                          setQueryData={setWithdrawalsQueryData}
                          sponsorPlanId={+params.sponsorPlanId}
                        />
                      ) : (
                        <PlanLoansTab
                          recordkeeper={
                            sponsorPlanQuery.data?.data.attributes.recordkeeper
                          }
                          setQueryData={setLoansQueryData}
                          sponsorPlanId={+params.sponsorPlanId}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Card>
              ),
              dropdownOptions: [
                {
                  label: 'Rollovers',
                  onClick: () => setSelectedRolloverComponent('Rollovers')
                },
                {
                  label: 'Loans',
                  onClick: () => setSelectedRolloverComponent('Loans')
                },
                {
                  label: 'Withdrawals',
                  onClick: () => setSelectedRolloverComponent('Withdrawals')
                }
              ],
              isDropDown: true,
              label: 'Rollovers & Distributions',
              path: `/${planType.urlPlansType}/${params.sponsorPlanId}/rollovers`
            }
          ]
        : []),
      ...(token?.permissions.includes(FeatureLevelPermissions.READ_FORFEITURE)
        ? [
            {
              component: (
                <PlanForfeituresTab sponsorPlanId={+params.sponsorPlanId} />
              ),
              label: 'Forfeitures',
              path: `/${planType.urlPlansType}/${params.sponsorPlanId}/forfeitures`
            }
          ]
        : []),
      ...(token?.permissions.includes(FeatureLevelPermissions.READ_ACTIVITY)
        ? [
            {
              component:
                selectedActivitiesComponent === 'Communications' ? (
                  <PlanCommunicationsTab
                    parentTabPath={`/plans/${params.sponsorPlanId}/activities`}
                    planId={+params.sponsorPlanId}
                    sponsorId={sponsorId}
                  />
                ) : (
                  <PlanAuditLogsTab planId={+params.sponsorPlanId} />
                ),
              dropdownOptions: [
                {
                  label: 'Communications',
                  onClick: () =>
                    setSelectedActivitiesComponent('Communications')
                },
                ...(userService.hasPermission(
                  FeatureLevelPermissions.READ_AUDIT_LOG
                )
                  ? [
                      {
                        label: 'Audit Logs',
                        onClick: () =>
                          setSelectedActivitiesComponent('Audit Logs')
                      }
                    ]
                  : [])
              ],
              isDropDown: true,
              label: 'Activities',
              path: `/plans/${params.sponsorPlanId}/activities`
            }
          ]
        : [])
    ];

    const filterOnboardingTab = (tab: TabData) =>
      (tab.label === 'Onboard' &&
        ([LT_RECORDKEEPER.name, SUB_ACCOUNTING_RECORDKEEPER.name].includes(
          sponsorPlanQuery.data?.data?.attributes?.recordkeeper ?? ''
        ) ||
          planType.isStateIRA ||
          planType.isEsa) &&
        (hasOnboardingPermission || (userService.isTpaUser() && !isBNYUser))) ||
      tab.label !== 'Onboard';

    const hideInvestments = (tab: TabData) =>
      planType.isStateIRA ||
      planType.isVoyaPlan ||
      !isInvestments ||
      planType.isEsa
        ? tab.label !== 'Investments'
        : true;

    const isUploadTabAllowed =
      userService.hasPermission(FeatureLevelPermissions.WRITE_UPLOADS) &&
      (userService.isTpaUser() || hasOnboardingPermission);

    const hideUpload = (tab: TabData) =>
      planType.isStateIRA || !isUploadTabAllowed || planType.isEsa
        ? tab.label !== 'Uploads'
        : true;

    const hidePlanForIRA = (tab: TabData) =>
      (!planType.isStateIRA && tab.label === 'Plan') || tab.label !== 'Plan';

    const hideDocumentsTab = (tab: TabData) =>
      planType.isStateIRA || planType.isVoyaPlan
        ? tab.label !== 'Documents'
        : true;

    const hideAnnualTestingTab = (tab: TabData) =>
      !userService.hasPermission(FeatureLevelPermissions.READ_ANNUAL_TESTING) ||
      planType.isStateIRA ||
      planType.isVoyaPlan ||
      planType.isEsa ||
      userService.isTpaUser()
        ? tab.label !== 'Annual Testing'
        : true;

    const hideRolloversAndDistributionsTab = (tab: TabData) =>
      ([
        'LT',
        'Vestwell (RK) - Folio',
        'Vestwell Sub-Accounting Platform',
        'Vestwell ESA'
      ].includes(sponsorPlanQuery.data?.data?.attributes.recordkeeper ?? '') &&
        tab.label === 'Rollovers & Distributions') ||
      tab.label !== 'Rollovers & Distributions';

    const hideForfeituresTab = (tab: TabData) =>
      (isVestwellSubaccounting && tab.label === 'Forfeitures') ||
      tab.label !== 'Forfeitures';

    const hideConversionTabs = (tab: TabData) =>
      ['Conversion', 'Conversion V2'].includes(tab.label)
        ? !planType.isEsa
        : true;

    const showDeConversionTab = (tab: TabData) =>
      tab.label === 'Deconversion'
        ? ['Deconverted', 'Deconversion Requested'].includes(
            sponsorPlanQuery.data?.data.attributes.status || ''
          )
        : true;

    return {
      tabs: registeredTabs
        .filter(filterOnboardingTab)
        .filter(hideInvestments)
        .filter(hideUpload)
        .filter(hidePlanForIRA)
        .filter(hideDocumentsTab)
        .filter(hideAnnualTestingTab)
        .filter(hideRolloversAndDistributionsTab)
        .filter(hideForfeituresTab)
        .filter(hideConversionTabs)
        .filter(showDeConversionTab),
      tabsAriaLabel: 'plan-detail-tabs'
    };
  };

  const tabs = makeTabs();

  if (
    (params.sponsorPlanId === '1' && !hasPlan1ReadPermission) ||
    cantAccessEsa506472
  ) {
    return redirectToErrorPage(new Error('403'));
  }

  const isLoading =
    !sponsorPlanQuery.isSuccess ||
    sponsorPlanQuery.isError ||
    !sponsorQuery.isSuccess ||
    sponsorQuery.isError;

  const paths =
    pooledPlanQuery.data?.pooledPlanName && pooledPlanQuery.data?.pooledPlanId
      ? [
          { name: 'Pooled Plans', to: '/pooled-plans' },
          {
            name: pooledPlanQuery.data?.pooledPlanName,
            to: `/pooled-plans/${pooledPlanQuery.data?.pooledPlanId}`
          }
        ]
      : [{ name: planType.urlPlansTypeName, to: `/${planType.urlPlansType}` }];

  const getMasqueradeButtonText = () => {
    if (
      sponsorPlanQuery.data.data.attributes.status === 'Onboarding' &&
      userService.hasPermission(FeatureLevelPermissions.READ_ONBOARDING_PARTNER)
    ) {
      return 'Onboard Plan';
    } else {
      return !planType.isStateIRA
        ? 'View Sponsor Portal'
        : 'View Employer Portal';
    }
  };

  return (
    <>
      {isLoading && <LinearLoading />}
      {!isLoading && !requestError && (
        <Grid
          className={classes.root}
          container
          data-testid='plan-detail-component'>
          <Grid item xs={8}>
            <DetailsPage
              copyableNumber={Number(params.sponsorPlanId)}
              headerItems={
                planType.isStateIRA
                  ? []
                  : [
                      <PlanScheduledChangesInfo
                        isEsa={planType.isEsa}
                        key='scheduledChanges'
                        planId={params.sponsorPlanId}
                        pooledPlanData={pooledPlanQuery.data}
                      />
                    ]
              }
              isStateIRA={planType.isStateIRA}
              paths={paths}
              planStage={planSalesforceInfoQuery.data?.planStage}
              status={sponsorPlanQuery.data.data.attributes.status}
              title={
                sponsorPlanQuery.data?.data.attributes.name ||
                EMPTY_FIELD_PLACEHOLDER
              }
              variant='sponsorPlan'
            />
            <AccessControl hideFromTPA>
              <HardcodedBanner sponsorPlanId={+params.sponsorPlanId} />
            </AccessControl>
          </Grid>
          <Grid className={classes.buttonPosition} item>
            <AccessControl
              requiresOneOf={[
                FeatureLevelPermissions.WRITE_MASQUERADE_SPONSOR,
                FeatureLevelPermissions.READ_ONBOARDING_PARTNER
              ]}>
              <TooltipButton
                handleOnClick={() => {
                  const sponsorId =
                    sponsorPlanQuery.data?.data.relationships.sponsor.data.id;
                  const url = UserManagementService.getSentinelRedirectUrl(
                    'sponsor',
                    sponsorId,
                    token?.tpaId
                      ? {
                          sponsorPlanId: +params.sponsorPlanId,
                          tpaId: token?.tpaId
                        }
                      : undefined
                  );
                  window.open(url, '_blank');
                }}
                testId='sponsor-detail-route-view-portal-btn'
                variant='contained'>
                {getMasqueradeButtonText()}
              </TooltipButton>
            </AccessControl>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <SimpleTabs tabs={tabs.tabs} tabsAriaLabel={tabs.tabsAriaLabel} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {requestError && (
        <ErrorMessage
          error={requestError.message || 'An Unknown Error Occurred'}
        />
      )}
    </>
  );
};

export default PlanDetailRoute;
