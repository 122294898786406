import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import NumericMoneyOrPercentageForm from '@/components/numeric-money-or-percentage-form/NumericMoneyOrPercentageForm.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { FormStep, useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantOptOutInfoDTO } from '@/models';
import DeferralElections from '@/models/ParticipantInfo.model';
import { UpdateDeferralRateAttributes } from '@/models/UpdateDeferralRate.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import React from 'react';
import * as yup from 'yup';

export type ParticipantElectionDeferralRateProps = {
  isVoyaPlan: boolean;
  isStateIRA: boolean;
  participant: DeferralElections;
  isEsa?: boolean;
};

const StyledCard = styled(Card)(() => ({
  display: 'flex',
  height: '100%'
}));

const StyledAlert = styled(Alert)(() => ({
  '& .MuiAlert-icon': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  alignItems: 'center',
  display: 'flex'
}));

const percentageToAmount = (percentage: number, contributionLimit: number) =>
  contributionLimit ? (contributionLimit * percentage) / 100 : 0;

const createValidationSchema = (limit: number): yup.AnyObjectSchema =>
  yup
    .object({
      afterTax: yup.object().shape({
        type: yup.string(),
        value: yup.number()
      }),
      preTax: yup.object().shape({
        type: yup.string(),
        value: yup.number()
      }),
      roth: yup.object().shape({
        type: yup.string(),
        value: yup.number()
      })
    })
    .test('Check if contribution amounts exceed limit', (value, options) => {
      if (value.roth.value && value.preTax.value) {
        let calculatedRothAmount = value.roth.value;
        const contributionLimit = limit || -1;
        if (value.roth.type === '%') {
          calculatedRothAmount = percentageToAmount(
            value.roth.value,
            contributionLimit
          );
        }
        let calculatedPreTaxAmount = value.preTax.value;
        if (value.preTax.type === '%') {
          calculatedPreTaxAmount = percentageToAmount(
            value.preTax.value,
            contributionLimit
          );
        }
        if (calculatedRothAmount + calculatedPreTaxAmount > contributionLimit) {
          const contributionLimitFormatted = formatters.formatDeferral(
            '$',
            contributionLimit
          );
          return new yup.ValidationError([
            options.createError({
              message: `Combined contribution can't exceed ${contributionLimitFormatted}`,
              path: 'roth'
            }),
            options.createError({
              message: `Combined contribution can't exceed ${contributionLimitFormatted}`,
              path: 'preTax'
            })
          ]);
        }
      }

      return true;
    });

const ParticipantElectionDeferralRate: React.FC<
  ParticipantElectionDeferralRateProps
> = (props: ParticipantElectionDeferralRateProps) => {
  const participantOptOutInfoQuery = useQuery<ParticipantOptOutInfoDTO>(
    [
      'ParticipantService.getParticipantOptOutInfo',
      props.participant.participantId
    ],
    () => {
      return ParticipantService.getParticipantOptOutInfo(
        props.participant.participantId
      );
    },
    {
      enabled: Boolean(props.participant.participantId)
    }
  ).data?.findUniqueParticipant;

  const queryClient = useQueryClient();
  const dialog = useDialog();
  const snackbar = useSnackbar();
  const validationSchema = createValidationSchema(
    props.participant.contributionsIrsLimit
  );

  const preTax = formatters.formatDeferral(
    props.participant.deferralElections?.preTaxType,
    props.participant.deferralElections?.preTaxAmt ?? 0
  );

  const roth = formatters.formatDeferral(
    props.participant.deferralElections?.rothType,
    props.participant.deferralElections?.rothAmt ?? 0
  );

  const afterTax = formatters.formatDeferral(
    props.participant.deferralElections?.afterTaxType,
    props.participant.deferralElections?.afterTaxAmt ?? 0
  );

  const updateDeferralRateMutation = useMutation(
    ['ParticipantService.updateDeferralRate'],
    (attributes: UpdateDeferralRateAttributes) => {
      const oldRoth =
        props.participant.deferralElections?.rothType === '%'
          ? `${props.participant.deferralElections?.rothAmt}%`
          : `$${props.participant.deferralElections?.rothAmt}`;

      const oldPretax =
        props.participant.deferralElections?.preTaxType === '%'
          ? `${props.participant.deferralElections?.preTaxAmt}%`
          : `$${props.participant.deferralElections?.preTaxAmt}`;

      const oldAftertax =
        props.participant.deferralElections?.afterTaxType === '%'
          ? `${props.participant.deferralElections?.afterTaxAmt}%`
          : `$${props.participant.deferralElections?.afterTaxAmt}`;

      return ParticipantService.updateDeferralRate(
        props.participant?.participantId as number,
        props.participant?.sponsorPlanId as number,
        attributes,
        {
          loginEmail: props.participant?.userEmail,
          personalEmail: props.participant?.personalEmail,
          sendEmailUpdate: !props.isStateIRA && !props.isVoyaPlan
        },
        {
          afterTax: oldAftertax,
          preTax: oldPretax,
          roth: oldRoth
        }
      );
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Error updating deferral rates',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        snackbar.showSnackbar({ message: 'Success!', severity: 'success' });

        await queryClient.refetchQueries([
          'ParticipantService.getDeferralChanges',
          props.participant.participantId
        ]);

        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById',
          props.participant.participantId?.toString()
        ]);
      }
    }
  );

  const optOutParticipantMutation = useMutation(
    ['ParticipantService.postParticipantsAutoEnrollOptOut'],
    (planId: number) => {
      return ParticipantService.postParticipantsAutoEnrollOptOut(planId, {
        participant: {
          contribution: participantOptOutInfoQuery?.contribution || 0,
          firstName: props.participant?.firstName,
          lastName: props.participant?.lastName,
          loginEmail: props.participant?.userEmail,
          participantId: props.participant?.participantId,
          personalEmail: props.participant?.personalEmail,
          workEmail: props.participant?.workEmail
        },
        participantIds: [props.participant.participantId]
      });
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Error opting out participant',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        snackbar.showSnackbar({
          message: 'Opt Out Successful!',
          severity: 'success'
        });

        await queryClient.refetchQueries([
          'ParticipantService.getDeferralChanges',
          props.participant.participantId
        ]);

        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById'
        ]);
      }
    }
  );

  const step: FormStep<string> = {
    fields: {},
    title: 'Edit Deferrals'
  };

  const optOutStep: FormStep<string> = {
    fields: {
      optOut: {
        component: (
          <Typography component='div'>
            Once an employee has been opted out:
            <Box component='ul'>
              <li>
                The employee's salary deferral will be set to 0%, and auto
                escalation will no longer apply.
              </li>
              <li>
                A confirmation email will be sent to the employee's email
                address on file.
              </li>
              <li>
                Eligible employees can resume their enrollment in the plan at
                any time by logging into their account or contacting our service
                center.
              </li>
            </Box>
            {!props.participant?.personalEmail &&
              !props.participant?.workEmail &&
              !props.participant?.userEmail && (
                <StyledAlert severity='warning'>
                  No email on file for this participant. Add one before opting
                  them out to ensure they receive the opt-out notice.
                </StyledAlert>
              )}
          </Typography>
        ),
        label: 'Opt Out'
      }
    },
    title: `Opt out ${props.participant.firstName} ${props.participant.lastName}?`
  };

  if (!props.isStateIRA && !props.isEsa) {
    step.fields.preTax = {
      component: (
        <NumericMoneyOrPercentageForm
          defaultIsPercentage={
            props.participant.deferralElections?.preTaxType === '%'
          }
          fieldId='preTax'
          fieldName='Pre-Tax'
          oneHundredPercentValue={props.participant.contributionsIrsLimit}
        />
      ),
      initialValue: {
        type: props.participant.deferralElections?.preTaxType,
        value: props.participant.deferralElections?.preTaxAmt ?? 0
      },
      label: 'Pre Tax'
    };
  }

  if (!props.isEsa) {
    step.fields.roth = {
      component: (
        <NumericMoneyOrPercentageForm
          defaultIsPercentage={
            props.participant.deferralElections?.rothType === '%'
          }
          fieldId='roth'
          fieldName='Roth'
          oneHundredPercentValue={props.participant.contributionsIrsLimit}
        />
      ),
      initialValue: {
        type: props.participant.deferralElections?.rothType === '%' ? '%' : '$',
        value: props.participant.deferralElections?.rothAmt ?? 0
      },
      label: 'Roth'
    };
  }

  if (props.isEsa) {
    step.fields.afterTax = {
      component: (
        <NumericMoneyOrPercentageForm
          defaultIsPercentage={
            props.participant.deferralElections?.afterTaxType === '%'
          }
          fieldId='afterTax'
          fieldName='After Tax'
          oneHundredPercentValue={props.participant.contributionsIrsLimit}
        />
      ),
      initialValue: {
        type:
          props.participant.deferralElections?.afterTaxType === '%' ? '%' : '$',
        value: props.participant.deferralElections?.afterTaxAmt ?? 0
      },
      label: 'After Tax'
    };
  }

  return (
    <StyledCard
      data-testid='deferral-rate-card'
      elevation={0}
      variant='outlined'>
      <Grid container flexDirection='column' justifyContent='space-between'>
        <Grid>
          <CardContent>
            <Typography variant='h5'>Deferral Rate</Typography>
            {!props.isStateIRA && !props.isEsa && (
              <CardInfoField
                fieldName='Pre-Tax'
                fieldSubscript=''
                fieldValue={preTax || EMPTY_FIELD_PLACEHOLDER}
              />
            )}
            {!props.isEsa && (
              <CardInfoField
                fieldName='Roth'
                fieldSubscript=''
                fieldValue={roth || EMPTY_FIELD_PLACEHOLDER}
              />
            )}
            {props.isEsa && (
              <CardInfoField
                fieldName='After-Tax'
                fieldSubscript=''
                fieldValue={afterTax || EMPTY_FIELD_PLACEHOLDER}
              />
            )}
            <CardInfoField
              fieldName='After-Tax'
              fieldSubscript=''
              fieldValue={afterTax || EMPTY_FIELD_PLACEHOLDER}
            />
            {participantOptOutInfoQuery?.autoEnrollOptedOutParticipants[0] && (
              <CardInfoField
                fieldName='Auto-Enroll Opt Out'
                fieldSubscript=''
                fieldValue={`${dayjs
                  .utc(
                    participantOptOutInfoQuery
                      ?.autoEnrollOptedOutParticipants[0].createdAt
                  )
                  .local()
                  .format('YYYY-MM-DD HH:mm A')} by 
                  ${participantOptOutInfoQuery?.autoEnrollOptedOutParticipants[0].createdBy.id}`}
              />
            )}
          </CardContent>
        </Grid>
        {!props.isStateIRA && (
          <Grid>
            <AccessControl
              hideFromTPA
              requires={[
                FeatureLevelPermissions.WRITE_ELECTIONS_DEFERRAL_RATE
              ]}>
              <CardActions>
                <Button
                  data-testid='edit-deferral-rate-button'
                  onClick={() => {
                    dialog.openDialog({
                      actionButtons: {
                        cancelButton: {
                          children: 'Cancel'
                        },
                        submitButton: {
                          children: 'Save'
                        }
                      },
                      onSubmit: async (rawInputValues: any) => {
                        const preTaxChanged =
                          !isEqual(
                            props.participant.deferralElections?.preTaxAmt,
                            rawInputValues.preTax?.value?.toString()
                          ) ||
                          !isEqual(
                            props.participant.deferralElections?.preTaxType,
                            rawInputValues.preTax?.type
                          );

                        const rothChanged =
                          !isEqual(
                            props.participant.deferralElections?.rothAmt,
                            rawInputValues.roth?.value?.toString()
                          ) ||
                          !isEqual(
                            props.participant.deferralElections?.rothType,
                            rawInputValues.roth?.type
                          );

                        const afterTaxChanged =
                          !isEqual(
                            props.participant.deferralElections?.afterTaxAmt,
                            rawInputValues.afterTax?.value?.toString()
                          ) ||
                          !isEqual(
                            props.participant.deferralElections?.afterTaxType,
                            rawInputValues.afterTax?.type
                          );

                        const updatedDeferralRate: UpdateDeferralRateAttributes =
                          {
                            afterTaxAmt: rawInputValues.afterTax?.value || 0,
                            afterTaxType: rawInputValues.afterTax?.type || '%',
                            preTaxAmt: rawInputValues.preTax?.value || 0,
                            preTaxType: rawInputValues.preTax?.type || '%',
                            rothAmt: rawInputValues.roth?.value || 0,
                            rothType: rawInputValues.roth?.type || '%'
                          };

                        if (
                          [rothChanged, preTaxChanged, afterTaxChanged].every(
                            value => value === false
                          )
                        ) {
                          return;
                        }

                        await updateDeferralRateMutation.mutateAsync(
                          updatedDeferralRate
                        );
                      },
                      steps: [step],
                      subcomponentProps: {
                        dialogContentProps: {
                          dividers: true
                        }
                      },
                      validationSchema
                    });
                  }}
                  type='button'
                  variant='text'>
                  EDIT DEFERRALS
                </Button>
                <Button
                  data-testid='opt-out-button'
                  disabled={
                    participantOptOutInfoQuery?.deferralChanged ||
                    !!participantOptOutInfoQuery
                      ?.autoEnrollOptedOutParticipants[0]
                  }
                  onClick={() => {
                    dialog.openDialog({
                      actionButtons: {
                        cancelButton: {
                          children: 'Cancel'
                        },
                        submitButton: {
                          children: 'Opt Out'
                        }
                      },
                      disableValueChangeCheck: true,
                      onSubmit: async () => {
                        await optOutParticipantMutation.mutateAsync(
                          props.participant.sponsorPlanId
                        );
                        await queryClient.invalidateQueries([
                          'ParticipantService.getParticipantOptOutInfo',
                          props.participant.participantId
                        ]);
                      },
                      steps: [optOutStep],
                      subcomponentProps: {
                        dialogContentProps: {
                          dividers: true
                        }
                      }
                    });
                  }}
                  type='button'
                  variant='text'>
                  OPT OUT
                </Button>
              </CardActions>
            </AccessControl>
          </Grid>
        )}
      </Grid>
    </StyledCard>
  );
};

export default ParticipantElectionDeferralRate;
