import { DocumentDto } from '@/models';
import type { FundChangeApprovalDto } from '@/models/FundChangeDTO.model';
import {
  FUND,
  MANAGED,
  ProgramExternalProvider,
  RISK,
  TARGET
} from '@/models/ops/investments/Program.model';
import { FundLineupData } from '@/routes/ops/investments/FundLineup.component';
import { TickerLookupSearchResult } from '@/routes/ops/investments/investment-table/TickerSelect.component';
import {
  AssetClass,
  GoalSeriesFund,
  ManagedAccountSeries
} from '@/routes/ops/investments/ManagedAccount.component';
import {
  RiskSeriesData,
  RiskSeriesGridData,
  RiskSeriesModelProps
} from '@/routes/ops/investments/RiskSeries.component';
import {
  TargetSeriesData,
  TargetSeriesGridData,
  TargetSeriesModelProps
} from '@/routes/ops/investments/TargetSeries.component';
import ApiService from '@/services/Api.service';

export interface InvestmentOptionParticipants {
  participantIds: number[];
}

export interface Model {
  name: string;
  description?: string;
  fundLineupId?: string;
  targetSeriesId?: string;
  riskSeriesId?: string;
  goalSeriesId?: string;
  goeAccountId?: number;
}

export type ModelType = string;

type GoalSeries = {
  description?: string;
  goeAccountId: number;
  goePortfolioIds?: number[];
  goalSeriesId: number;
  name: string;
};

type TargetSeries = {
  name: string;
  targetSeriesId: number;
};

export type Program = {
  fundLineup?: FundLineupData;
  goalSeries?: GoalSeries;
  name: string;
  nliModels?: Omit<TargetSeriesModelProps, 'modelFunds'>[];
  nliSeries?: TargetSeries;
  programId: number;
  riskModels?: Omit<RiskSeriesModelProps, 'modelFunds'>[];
  riskSeries?: RiskSeriesData;
  targetModels?: Omit<TargetSeriesModelProps, 'modelFunds'>[];
  targetSeries?: TargetSeries;
  externalProviders: ProgramExternalProvider[];
};

export type PlanFunds = {
  currentProgram: Program;
  name: string;
  newProgram: Program;
};

class InvestmentService {
  static async getTargetModelSeries(
    targetSeriesId: number
  ): Promise<TargetSeriesData> {
    return ApiService.getJson(`investments/target-series/${targetSeriesId}`);
  }

  static async getPlanFunds(
    planId: number,
    newProgramId: number
  ): Promise<PlanFunds> {
    return ApiService.getJson(
      `/investments/re-enrollment/plan/${planId}/new-program/${newProgramId}`
    );
  }

  static async getTargetModelSeriesGrid(
    targetSeriesId: number
  ): Promise<TargetSeriesGridData> {
    return ApiService.getJson(
      `investments/target-series/${targetSeriesId}/grid-models`
    );
  }

  static async getTargetModelSeriesGridByIds(
    targetSeriesIds: number[]
  ): Promise<Map<number, TargetSeriesGridData>> {
    return Promise.all(
      targetSeriesIds.map(targetSeriesId => {
        return ApiService.getJson<TargetSeriesGridData>(
          `investments/target-series/${targetSeriesId}/grid-models`
        ).then(gridData => {
          return { gridData, targetSeriesId };
        });
      })
    ).then(results => {
      return new Map<number, TargetSeriesGridData>(
        results.map(item => [item.targetSeriesId, item.gridData])
      );
    });
  }

  static async postTargetModelSeriesGrid(
    targetSeriesId: number,
    data: TargetSeriesGridData
  ): Promise<TargetSeriesGridData> {
    return ApiService.postJson(
      `investments/target-series/${targetSeriesId}/grid-models`,
      data
    );
  }

  static async getRiskSeriesGrid(
    riskSeriesId: number
  ): Promise<RiskSeriesGridData> {
    return ApiService.getJson(
      `investments/risk-series/${riskSeriesId}/grid-models`
    );
  }

  static async getRiskSeriesGridByIds(
    riskSeriesIds: number[]
  ): Promise<Map<number, RiskSeriesGridData>> {
    return Promise.all(
      riskSeriesIds.map(riskSeriesId => {
        return ApiService.getJson<RiskSeriesGridData>(
          `investments/risk-series/${riskSeriesId}/grid-models`
        ).then(gridData => {
          return { gridData, riskSeriesId };
        });
      })
    ).then(results => {
      return new Map<number, RiskSeriesGridData>(
        results.map(item => [item.riskSeriesId, item.gridData])
      );
    });
  }

  static async postRiskSeriesGrid(
    riskSeriesId: number,
    data: RiskSeriesGridData
  ): Promise<RiskSeriesGridData> {
    return ApiService.postJson(
      `investments/risk-series/${riskSeriesId}/grid-models`,
      data
    );
  }

  static async postModelSeries(
    modelType: ModelType,
    data: Model
  ): Promise<Model> {
    const getModelType = (model: ModelType) => {
      switch (model) {
        case TARGET:
          return 'target-series';
        case FUND:
          return 'fund-lineup';
        case RISK:
          return 'risk-series';
        case MANAGED:
          return 'goal-series';
        default:
          return `unknown modelType`;
      }
    };

    return ApiService.postJson(`investments/${getModelType(modelType)}`, data);
  }

  static async getTargetModels(
    targetSeriesId: number
  ): Promise<TargetSeriesModelProps[]> {
    return ApiService.getJson(
      `investments/target-series/${targetSeriesId}/target-model`
    );
  }

  static async putTargetModelSeries(
    data: TargetSeriesData
  ): Promise<TargetSeriesData> {
    return ApiService.putJson(
      `investments/target-series/${data.targetSeriesId}`,
      data
    );
  }

  static async postTargetModel(
    targetSeriesId: number,
    data: TargetSeriesModelProps
  ): Promise<TargetSeriesModelProps> {
    return ApiService.postJson(
      `investments/target-series/${targetSeriesId}/target-model`,
      data
    );
  }

  static async putTargetModel(
    targetSeriesId: number,
    targetModelId: number,
    data: TargetSeriesModelProps
  ): Promise<TargetSeriesModelProps> {
    return ApiService.putJson(
      `investments/target-series/${targetSeriesId}/target-model/${targetModelId}`,
      data
    );
  }

  static async upsertTargetModel(
    targetSeriesId: number,
    data: TargetSeriesModelProps
  ): Promise<TargetSeriesModelProps> {
    const { targetModelId, ...payload } = data;
    return typeof targetModelId === 'number'
      ? this.putTargetModel(targetSeriesId, targetModelId, payload)
      : this.postTargetModel(targetSeriesId, payload);
  }

  static getRiskModelSeries(riskSeriesId: number): Promise<RiskSeriesData> {
    return ApiService.getJson(`investments/risk-series/${riskSeriesId}`);
  }

  static async getRiskModels(
    riskSeriesId: number
  ): Promise<RiskSeriesModelProps[]> {
    return ApiService.getJson(
      `investments/risk-series/${riskSeriesId}/risk-model`
    );
  }

  static async putRiskSeries(data: RiskSeriesData): Promise<RiskSeriesData> {
    return ApiService.putJson(
      `investments/risk-series/${data.riskSeriesId}`,
      data
    );
  }

  static async postRiskModel(
    riskSeriesId: number,
    data: RiskSeriesModelProps
  ): Promise<RiskSeriesModelProps> {
    return ApiService.postJson(
      `investments/risk-series/${riskSeriesId}/risk-model`,
      data
    );
  }

  static async putRiskModel(
    riskSeriesId: number,
    riskModelId: number,
    data: RiskSeriesModelProps
  ): Promise<RiskSeriesModelProps> {
    return ApiService.putJson(
      `investments/risk-series/${riskSeriesId}/risk-model/${riskModelId}`,
      data
    );
  }

  static async upsertRiskModel(
    riskSeriesId: number,
    data: RiskSeriesModelProps
  ): Promise<RiskSeriesModelProps> {
    const { riskModelId, ...payload } = data;
    return typeof riskModelId === 'number'
      ? this.putRiskModel(riskSeriesId, riskModelId, payload)
      : this.postRiskModel(riskSeriesId, payload);
  }

  static async searchSymbol(
    symbol: string
  ): Promise<TickerLookupSearchResult[]> {
    return ApiService.getJson<TickerLookupSearchResult[]>(
      `investments/securities?query=${symbol}`
    );
  }

  static async getFundLineup(fundLineupId: number): Promise<FundLineupData> {
    return ApiService.getJson(`investments/fund-lineup/${fundLineupId}`);
  }

  static async putFundLineup(
    fundLineupId: number,
    data: FundLineupData
  ): Promise<FundLineupData> {
    return ApiService.putJson(`investments/fund-lineup/${fundLineupId}`, data);
  }

  static getGoalSeriesAssetClasses(
    goeAccountId: number
  ): Promise<AssetClass[]> {
    return ApiService.getJson(
      `investments/goe-account/${goeAccountId}/goe-account-asset-class`
    );
  }

  static getGoalSeries(goalSeriesId: number): Promise<ManagedAccountSeries> {
    return ApiService.getJson(`investments/goal-series/${goalSeriesId}`);
  }

  static getGoalSeriesFunds(
    managedAccountSeriesId: number
  ): Promise<GoalSeriesFund[]> {
    return ApiService.getJson(
      `/investments/goal-series/${managedAccountSeriesId}/goal-fund`
    );
  }

  static putGoalSeries(
    goalSeriesId: number,
    series: ManagedAccountSeries
  ): Promise<ManagedAccountSeries> {
    return ApiService.putJson(
      `investments/goal-series/${goalSeriesId}`,
      series
    );
  }

  static putGoalSeriesFunds(
    goalSeriesId: number,
    funds: GoalSeriesFund[]
  ): Promise<GoalSeriesFund[]> {
    return ApiService.putJson(
      `investments/goal-series/${goalSeriesId}/goal-fund`,
      funds
    );
  }

  static getParticipantsByModel(
    investmentOptionId: number,
    modelType: string
  ): Promise<InvestmentOptionParticipants> {
    return ApiService.getJson(
      `investments/models/${investmentOptionId}/participants/${modelType}`
    );
  }

  static async getInvestmentOptionsDownload(
    programId: number
  ): Promise<DocumentDto> {
    return ApiService.getJson(
      `/investments/program/${programId}/investment-options/download`
    );
  }

  static async getFundChangeApproval(
    fundChangeId: number
  ): Promise<FundChangeApprovalDto> {
    return ApiService.getJson(
      `/investments/fund-change/${fundChangeId}/approval`
    );
  }

  static async putFundChangeApproval(
    fundChangeId: number,
    approved: boolean
  ): Promise<FundChangeApprovalDto> {
    return ApiService.putJson(
      `/investments/fund-change/${fundChangeId}/approval`,
      { approved }
    );
  }
}

export default InvestmentService;
