import AccessControl from '@/components/access-control/AccessControl.component';
import TooltipButton from '@/components/tool-tip-button';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  HceEmployee,
  PlanTestResultDto,
  TestExecutionResultsDto,
  WorkflowProcessEventResponse
} from '@/models/YearEndTestingDTO.model';
import {
  TestExecutionDropdown,
  TestExecutionDropdownProps
} from '@/routes/plans/plan-detail/PlanAnnualTestingTab/TestExecutionDropdown.component';
import { PlanService } from '@/services/Plan.service';
import { annualTestExecutionValidationSchema } from '@/utils/validations/AnnualTestExecutionValidationSchema.schema';
import {
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight
} from '@mui/icons-material';
import {
  Alert,
  Collapse,
  Unstable_Grid2 as Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { BypassYetStepButton } from './BypassYetStepButton';

interface TestExecutionRowProps {
  completedEvents: (string | undefined)[];
  event: WorkflowProcessEventResponse | undefined;
  onCompleteStep: (action: string) => void;
  planId: number;
  year?: number;
  data: PlanTestResultDto['testExecution'];
  sponsorPlanId: number;
  open: boolean;
  toggleOpen: (boolean) => void;
}

export const initialValues = {
  previousYear: new Date().getFullYear() - 1,
  result402g: {
    additionalData: {
      hce: []
    },
    result: 'N/A',
    testId: 4
  },
  result415: {
    additionalData: {
      hce: []
    },
    result: 'N/A',
    testId: 3
  },
  resultACP: {
    additionalData: {
      hce: [],
      qnec: 0
    },
    result: 'N/A',
    testId: 2
  },
  resultADP: {
    additionalData: {
      hce: [],
      qnec: 0
    },
    result: 'N/A',
    testId: 1
  },
  resultTopHeavyCurrentYear: {
    additionalData: {
      minC: 0
    },
    result: 'N/A',
    testId: 5
  }
};

export const mapHce = (
  values: TestExecutionResultsDto,
  year: number
): TestExecutionResultsDto => {
  const newValues = {} as TestExecutionResultsDto;
  Object.entries(values).forEach(([key, value]) => {
    value.additionalData?.hce
      ? (newValues[key] = {
          ...value,
          additionalData: {
            ...value.additionalData,
            hce: JSON.stringify(
              value.additionalData.hce.map((e: HceEmployee) => ({
                '1099_codes': e['1099_codes'],
                earnings: e.earnings,
                fundingSource: e.fundingSource,
                id: e.id,
                lostGain: e.lostGain,
                name: e.name,
                value: e.value
              }))
            )
          },
          previousYear: year
        })
      : (newValues[key] = value);
  });
  return newValues;
};

export const TestExecutionRow: FC<TestExecutionRowProps> = props => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [hasADPHceJson, setHasADPHceJson] = useState(false);
  const [hasACPHceJson, setHasACPHceJson] = useState(false);
  const [hasTopHeavyQnec, setHasTopHeavyQnec] = useState(false);
  const [has402gHceJson, setHas402gHceJson] = useState(false);
  const [has415HceJson, setHas415HceJson] = useState(false);

  // first render results sometimes comes back undefined
  useEffect(() => {
    setHasADPHceJson(props.data?.resultADP?.result === 'fail');
    setHasACPHceJson(props.data?.resultACP?.result === 'fail');
    setHasTopHeavyQnec(
      props.data?.resultTopHeavyCurrentYear?.result ===
        'top-heavy-action-required'
    );
    setHas402gHceJson(props.data?.result402g?.result === 'fail');
    setHas415HceJson(props.data?.result415?.result === 'fail');
  }, [
    props.data?.resultADP?.result,
    props.data?.resultACP?.result,
    props.data?.resultTopHeavyCurrentYear?.result,
    props.data?.result402g?.result === 'fail',
    props.data?.result415?.result === 'fail'
  ]);

  const postYETTestResultsMutation = useMutation(
    (data: TestExecutionResultsDto) =>
      PlanService.postYETTestResults(props.planId, data, props.year),
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Test Results have been saved!',
          severity: 'success'
        });
        props.onCompleteStep('completeTestExecution');
      }
    }
  );

  const postYETTestingResultsToSponsorsMutation = useMutation(
    () => PlanService.sendYETTestingResultsToSponsors(props.planId),
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Tests results have been emailed to the plan sponsor!',
          severity: 'success'
        });
      }
    }
  );

  const initValues = useMemo(() => {
    return {
      ...initialValues,
      ...props.data
    };
  }, [props.data]);

  const onSubmit = useCallback(
    (values: TestExecutionResultsDto) => {
      postYETTestResultsMutation.mutate(mapHce(values, props.year));
      queryClient.invalidateQueries([
        'PlanService.getYETTestResults',
        props.sponsorPlanId,
        props.year
      ]);
    },
    [postYETTestResultsMutation]
  );

  const sendEmail = useCallback(() => {
    postYETTestingResultsToSponsorsMutation.mutate();
  }, [postYETTestingResultsToSponsorsMutation]);

  const rows = useMemo<TestExecutionDropdownProps['row'][]>(
    () => [
      {
        hasHceJson: hasADPHceJson,
        hasQnecTotal: hasADPHceJson,
        label: 'resultADP',
        name: 'ADP'
      },
      {
        hasHceJson: hasACPHceJson,
        hasQnecTotal: hasACPHceJson,
        label: 'resultACP',
        name: 'ACP'
      },
      {
        hasHceJson: false,
        hasQnecTotal: hasTopHeavyQnec,
        label: 'resultTopHeavyCurrentYear',
        name: `Top Heavy (${props.year})`
      },
      {
        hasHceJson: has402gHceJson,
        hasQnecTotal: false,
        label: 'result402g',
        name: '402g Limits'
      },
      {
        hasHceJson: has415HceJson,
        hasQnecTotal: false,
        label: 'result415',
        name: '415 Limits'
      }
    ],
    [
      props.year,
      hasADPHceJson,
      hasACPHceJson,
      hasTopHeavyQnec,
      has402gHceJson,
      has415HceJson
    ]
  );

  return (
    <>
      <TableRow data-testid='test-execution-row'>
        <TableCell align='center' padding='none'>
          <IconButton
            aria-label='expand row'
            data-testid='test-execution-expand-row-button'
            onClick={props.toggleOpen}
            size='small'>
            {props.open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell align='center' padding='none'>
          {!!props.event && (
            <Check color='primary' data-testid='test-execution-checkmark' />
          )}
        </TableCell>
        <TableCell>Test Execution</TableCell>
        <TableCell data-testid='test-execution-initiatedBy'>
          {props.event?.initiatedBy?.id}
          {props.event?.eventData?.bypass && ' - Bypassed'}
        </TableCell>
        <TableCell>{props.event?.createdAt}</TableCell>
        <TableCell padding='none'>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
            <TooltipButton
              disabled={
                postYETTestingResultsToSponsorsMutation.isLoading ||
                !props.completedEvents?.includes('yearEndContributionCompleted')
              }
              handleOnClick={sendEmail}
              testId='send-email-btn'
              tooltipMessage={
                props.completedEvents?.includes('yearEndContributionCompleted')
                  ? ''
                  : 'Please complete the previous step'
              }>
              SEND TEST RESULTS
            </TooltipButton>
          </AccessControl>
        </TableCell>
        <TableCell sx={{ p: 0 }}>
          <BypassYetStepButton
            completed={!!props.event}
            eventName='testExecutionCompleted'
            previousStepDone={props.completedEvents?.includes(
              'yearEndContributionCompleted'
            )}
            sponsorPlanId={props.sponsorPlanId}
            year={props.year}
          />
        </TableCell>
      </TableRow>
      <TableRow data-testid='test-execution-expandable-row'>
        <TableCell
          colSpan={12}
          padding='none'
          sx={{
            backgroundColor: theme => theme.palette.grey[50]
          }}>
          <Collapse in={props.open} timeout='auto' unmountOnExit>
            <Formik
              initialValues={initValues}
              onSubmit={onSubmit}
              validationSchema={annualTestExecutionValidationSchema}>
              {({ handleSubmit, errors }) => (
                <Form data-testid='test-execution-form'>
                  <Grid
                    container
                    direction='column'
                    disableEqualOverflow={true}
                    padding={2}
                    spacing={2}>
                    <Grid lg={12}>
                      <Paper
                        data-testid='test-execution-results-card'
                        variant='outlined'>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              {rows.map(row => (
                                <TestExecutionDropdown
                                  data={props.data}
                                  has402gHceJson={has402gHceJson}
                                  has415HceJson={has415HceJson}
                                  hasACPHceJson={hasACPHceJson}
                                  hasADPHceJson={hasADPHceJson}
                                  hasTopHeavyQnec={hasTopHeavyQnec}
                                  key={row.name}
                                  row={row}
                                  setHas402gHceJson={setHas402gHceJson}
                                  setHas415HceJson={setHas415HceJson}
                                  setHasACPHceJson={setHasACPHceJson}
                                  setHasADPHceJson={setHasADPHceJson}
                                  setHasTopHeavyQnec={setHasTopHeavyQnec}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid container flexDirection='column'>
                      <Grid>
                        <Alert severity='warning'>
                          If updating test results to 'Pass', please reset QNEC
                          Totals and remove any HCE data first.
                        </Alert>
                      </Grid>
                      <Grid container>
                        <Grid>
                          <AccessControl
                            requires={[
                              FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS
                            ]}>
                            <TooltipButton
                              disabled={
                                postYETTestResultsMutation.isLoading ||
                                !isEmpty(errors) ||
                                !props.completedEvents?.includes(
                                  'yearEndContributionCompleted'
                                )
                              }
                              handleOnClick={handleSubmit}
                              testId='save-test-execution-btn'
                              tooltipMessage={
                                props.completedEvents?.includes(
                                  'yearEndContributionCompleted'
                                )
                                  ? ''
                                  : 'Please complete the previous step'
                              }
                              variant='contained'>
                              SAVE
                            </TooltipButton>
                          </AccessControl>
                        </Grid>
                        {!isEmpty(errors) && (
                          <Grid>
                            <Typography color='error' variant='body2'>
                              Please fix the highlighted errors
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
