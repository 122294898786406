export type QueueErrorDTO = {
  errorId: number;
  initialMessage: any;
  failureMessage: string;
  context: {
    message: string;
  };
  isArchived: boolean;
  createdAt: string;
  updatedAt: string;
  note?: string;
  isReplayed: boolean;
};

export interface SubaQueueErrorDTO {
  data: QueueErrorDTO[];
}

export type QueueErrorPaginatedResponse = {
  data: {
    rows: QueueErrorDTO[];
    totalRows: number;
  };
};

export type BulkErrorReplayResponse = {
  data: {
    result: string;
    errorIds?: number[];
  };
};

export type ErrorQueueTypes =
  | 'all'
  | 'subA'
  | 'stateIra'
  | 'recordkeeping'
  | 'payroll'
  | 'investment'
  | 'plan'
  | 'sponsor';

export const ErrorQueueTypesMap: Record<ErrorQueueTypes, string> = {
  all: 'All',
  investment: 'Investment',
  payroll: 'Payroll',
  plan: 'Plan',
  recordkeeping: 'General',
  sponsor: 'Sponsor',
  stateIra: 'State IRA',
  subA: 'SIERRA'
};
