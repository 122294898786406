/* eslint-disable sort-keys-plus/sort-keys */
import AppConfig from '@/App.config';
import DatePicker from '@/components/date-picker/DatePicker';
import PriorProviderComponent from '@/components/prior-provider';
import SimpleAutocomplete from '@/components/simple-autocomplete';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleMaskedInput from '@/components/simple-masked-input';
import SimpleRadio from '@/components/simple-radio';
import SimpleStaticValue from '@/components/simple-static-value';
import { UNVERIFIED_PRIOR_PROVIDER_ID } from '@/consts/plan.constants';
import {
  POOLED_PLAN_SUBTYPES,
  POOLED_PLAN_TYPES
} from '@/consts/pooled-plan.constants';
import STATE_CODES from '@/consts/states.constants';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import {
  AdvisorDto,
  CreatePrimaryContactDto,
  FirmDto,
  PlanV2Dto,
  PriorProvidersDto,
  TpaListDto
} from '@/models';
import {
  AdvisorSearchResultDto,
  CreateAdvisorDto,
  EditAdvisorDto
} from '@/models/AdvisorDTO.model';
import { AdvisorEntitySearchResultDto } from '@/models/AdvisorEntitySearchResultDTO.model';
import { UserRegistrationLinkResponseDto } from '@/models/CreateSponsorUserInviteDTO.model';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';
import { usePlanType } from '@/routes/plans/plan-detail/usePlanType.hook';
import { SponsorPlanV1 } from '@/routes/plans/plans/PlansIndex/PlansIndex.component';
import AdvisorService from '@/services/Advisor.service';
import ApiService from '@/services/Api.service';
import {
  CreateEsaPlanDto,
  CreateEsaPlanSchema,
  EsaService,
  UpdateEsaPlanDto,
  UpdateEsaPlanSchema
} from '@/services/Esa.service';
import FirmService, {
  CreateFirmDto,
  CreateFirmSchema,
  makeUpdateFirmSchema,
  UpdateFirmDto
} from '@/services/Firm.service';
import {
  CreateAdopterPlanDto,
  CreateAdopterPlanSchema,
  CreatePlanDto,
  CreatePlanSchema,
  CreatePooledPlanDto,
  CreatePooledPlanSchema,
  EditPlanDto,
  EditPlanSchema,
  PlanService
} from '@/services/Plan.service';
import { PriorProviderService } from '@/services/PriorProviders.service';
import SponsorService from '@/services/Sponsor.service';
import TpaService from '@/services/Tpa.service';
import { WhitelabelService } from '@/services/whitelabel.service';
import formatters from '@/utils/Formatters';
import { CheckCircleOutline, Search } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  Box,
  Button,
  ButtonProps,
  DialogContent,
  FormControl,
  Grid,
  Icon,
  ListItem,
  Theme,
  Typography
} from '@mui/material';
import { blue, green } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { defer, isEmpty, isNumber, last, omit, orderBy, sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { NullSignifierOption } from '../simple-dropdown/SimpleDropdown.component';

const transformRawPhoneInputValue = (raw: string) => {
  const numerals = formatters.extractNumerals(raw);

  return numerals.length <= 10 ? formatters.formatPhone(raw) : null;
};

export const VESTWELL_TPA = {
  ID: 1,
  NAME: 'None (Vestwell Bundled)'
};

const ENTERPRISE_TPA = [
  {
    name: 'J.P. Morgan - Enterprise Partner',
    tpaSubType: 2
  },
  {
    name: 'Asure - Enterprise Partner',
    tpaSubType: 3
  }
];

const PLAN_TIERS: Record<string, number> = {
  Workplace: 1,
  Plus: 2,
  Pro: 3,
  'Vestwell Flex': 4,
  'Recordkeeping Only': 5
};

type MetaChooserProps = {
  options: string[];
  defaultValue: string;
  fieldName: string;
  fieldId: string;
  children: (renderParams: {
    fieldName: string;
    fieldId: string;
    meta: string;
  }) => JSX.Element;
  required?: boolean;
};

const MetaChooser = (props: MetaChooserProps): JSX.Element => {
  const { options, fieldId, fieldName, defaultValue, children } = props;

  const [meta, setMeta] = useState<string>(defaultValue || options[0]);

  return (
    <>
      <SimpleRadio
        defaultValue={defaultValue}
        fieldId={`${fieldName}-meta-chooser`}
        fieldName={fieldName}
        fieldValues={[...options]}
        onChange={e => {
          setMeta(e.target.value);
        }}
      />
      {children({
        fieldName,
        fieldId,
        meta
      })}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(3),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      '& > *': {
        marginBottom: theme.spacing(2)
      }
    },
    invitePrimaryContactButton: {
      color: theme.palette.getContrastText(blue[500]),
      backgroundColor: blue[500],
      '&:hover': {
        backgroundColor: blue[700]
      },
      marginBottom: theme.spacing(0.5)
    }
  })
);

type CreatePlanSuccessSplashScreenProps = {
  plan: PlanV2Dto;
  pooledPlanId?: number;
};

const CreatePlanSuccessSplashScreen = (
  props: CreatePlanSuccessSplashScreenProps
): JSX.Element => {
  const { plan, pooledPlanId } = props;

  const isCreateAdopterPlan = isNumber(pooledPlanId);

  const classes = useStyles();
  const { closeDialog, openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { mutateAsync: createSponsorPrimaryContact } = useMutation(
    [
      'SponsorService.createSponsorPrimaryContact',
      plan.data.relationships.sponsor.data.id
    ],
    (primaryContact: CreatePrimaryContactDto) => {
      return SponsorService.createSponsorPrimaryContact(
        plan.data.relationships.sponsor.data.id,
        primaryContact
      );
    }
  );

  const { mutateAsync: sendSponsorPrimaryContactInviteEmail } = useMutation(
    [
      'SponsorService.sendSponsorPrimaryContactInviteEmail',
      plan.data.relationships.sponsor.data.id
    ],
    (email: string) => {
      return SponsorService.sendSponsorPrimaryContactInviteEmail(
        plan.data.relationships.sponsor.data.id,
        email
      );
    },
    {
      onSuccess(data: UserRegistrationLinkResponseDto) {
        if (AppConfig.environment !== 'production') {
          console.log(`Registration url is: ${data.registrationUrl}`);
        }
      }
    }
  );

  const planType = usePlanType({
    recordkeeper: plan.data.attributes.recordkeeper,
    type: plan.data.attributes.type
  });

  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <Box alignItems='center' alignSelf='center' display='block'>
          <Icon style={{ overflow: 'unset' }}>
            <CheckCircleOutline
              style={{
                color: green[500],
                fontSize: '80px',
                marginLeft: '-28px'
              }}
            />
          </Icon>
        </Box>
        <Typography align='center' variant='h3'>
          {isCreateAdopterPlan
            ? 'New Adopter Plan Created'
            : 'New Plan Created'}
        </Typography>
        <Box>
          <Typography align='center' color='textSecondary' variant='body1'>
            {plan.data.attributes.name}
          </Typography>
          <Typography align='center' color='textSecondary' variant='body1'>
            PLAN ID: {plan.data.id}
          </Typography>
        </Box>
        <Box
          alignItems='center'
          alignSelf='center'
          display='flex'
          flexDirection='column'>
          <Button
            className={classes.invitePrimaryContactButton}
            onClick={() => {
              const validationSchema = yup.object({
                firstName: yup.string().required(),
                lastName: yup.string().required(),
                email: yup.string().email().required()
              });

              closeDialog();

              return defer(() => {
                openDialog({
                  actionButtons: {
                    cancelButton: {
                      type: 'cancel',
                      children: 'Cancel'
                    },
                    createButton: {
                      type: 'submit',
                      children: 'Create',
                      props: {
                        id: 'createBtn'
                      }
                    },
                    submitButton: {
                      type: 'submit',
                      children: 'Create and Invite',
                      props: {
                        id: 'createAndInviteBtn'
                      }
                    }
                  },
                  steps: [
                    {
                      title: 'Create Primary Contact',
                      fields: {
                        firstName: {
                          initialValue: '',
                          isFieldRequired: true,
                          label: 'First Name'
                        },
                        lastName: {
                          initialValue: '',
                          isFieldRequired: true,
                          label: 'Last Name'
                        },
                        email: {
                          initialValue: '',
                          isFieldRequired: true,
                          label: 'Email'
                        }
                      }
                    }
                  ],
                  validationSchema,
                  disableSubmitWhenInvalid: true,
                  includeSubmitButtonInFormValues: true,
                  onSubmit: async ({ submitBtn, ...values }) => {
                    const primaryContact =
                      validationSchema.validateSync(values);

                    await createSponsorPrimaryContact({
                      primaryContactFirstName: primaryContact.firstName,
                      primaryContactLastName: primaryContact.lastName,
                      primaryContactEmail: primaryContact.email
                    });

                    if (submitBtn === 'createAndInviteBtn') {
                      await sendSponsorPrimaryContactInviteEmail(
                        primaryContact.email
                      );
                      showSnackbar({
                        message: `${primaryContact.firstName} ${primaryContact.lastName} was invited!`,
                        severity: 'success'
                      });
                    } else {
                      showSnackbar({
                        message: `${primaryContact.firstName} ${primaryContact.lastName} was created!`,
                        severity: 'success'
                      });
                    }

                    navigate(`/${planType.urlPlansType}/${plan.data.id}`);
                  }
                });
              });
            }}
            type='button'>
            Add Employer
          </Button>
          <Button
            onClick={() => {
              closeDialog();
              navigate(`/${planType.urlPlansType}/${plan.data.id}`);
            }}>
            Go to Plan
          </Button>
        </Box>
      </DialogContent>
    </>
  );
};

type EntityModalParams =
  | {
      action: 'create';
      planId?: never;
      pooledPlanId?: number;
    }
  | {
      action: 'edit';
      planId: SponsorPlanV1['sponsorPlanId'];
      pooledPlanId?: never;
    };

const useFirmModal = (params: {
  action: 'edit' | 'create';
  firmId: number;
}): {
  showModal: () => void;
  isEnabled: boolean;
  title: string;
} => {
  const { action, firmId } = params;
  const { userHasValidToken } = useUserToken();

  const homeOfficeNamesQuery = useQuery<string[]>(
    ['FirmService.getHomeOfficeNames'],
    () => FirmService.getHomeOfficeNames(),
    {
      enabled: userHasValidToken,
      staleTime: Infinity
    }
  );

  const whitelabelEntities = useQuery(
    ['whitelabel.getEntities'],
    () => WhitelabelService.getEntities(),
    {
      enabled: userHasValidToken,
      staleTime: Infinity,
      select: data =>
        data.map(e => ({
          option: e.subdomain,
          value: e.whitelabelId
        }))
    }
  );

  const title = action === 'edit' ? 'Edit Firm' : 'New Firm';

  const firmQuery = useQuery<FirmDto>(
    ['FirmService.getFirmById', firmId],
    () => FirmService.getFirmById(firmId),
    {
      enabled: action === 'edit' && userHasValidToken,
      staleTime: Infinity
    }
  );

  const sharedFirmFields = {
    companyName: {
      initialValue:
        action === 'edit' ? firmQuery.data?.data.attributes.companyName : '',
      isFieldRequired:
        action === 'create' ||
        (action === 'edit' && !!firmQuery.data?.data.attributes.companyName),
      label: 'Company Name',
      title
    },
    [action === 'edit' ? 'mainFirmContactName' : 'contactName']: {
      initialValue:
        action === 'edit'
          ? firmQuery.data?.data.attributes.contactName || ''
          : '',
      isFieldRequired:
        action === 'create' ||
        (action === 'edit' && !!firmQuery.data?.data.attributes.contactName),
      label: 'Contact Name'
    },
    homeOfficeName: {
      initialValue:
        action === 'edit' ? firmQuery.data?.data.attributes.homeOfficeName : '',
      label: 'Home Office',
      component:
        action === 'edit' ? (
          <SimpleDropdown
            fieldId='homeOfficeName'
            fieldName='Home Office'
            fieldValues={[NullSignifierOption]
              .concat(homeOfficeNamesQuery.data || [])
              .filter(n => !isEmpty(n))}
          />
        ) : (
          <MetaChooser
            defaultValue='New'
            fieldId='homeOfficeName'
            fieldName='Home Office'
            options={['New', 'None', 'Choose']}>
            {({ fieldName, fieldId, meta }) => (
              <>
                {meta === 'Choose' && (
                  <SimpleDropdown
                    fieldId={fieldId}
                    fieldName={fieldName}
                    fieldValues={(homeOfficeNamesQuery.data || []).filter(
                      n => !isEmpty(n)
                    )}
                  />
                )}
                {meta === 'None' && (
                  <SimpleStaticValue
                    fieldId={fieldId}
                    fieldName={fieldName}
                    value={NullSignifierOption}
                  />
                )}
                {meta === 'New' && (
                  <Field
                    component={TextField}
                    fullWidth
                    label={fieldName}
                    name={fieldId}
                  />
                )}
              </>
            )}
          </MetaChooser>
        )
    },
    mainPhoneNumber: {
      initialValue:
        action === 'edit' ? firmQuery.data?.data.attributes.phoneNumber : '',
      label: 'Phone Number',
      component: (
        <SimpleMaskedInput
          fieldId='mainPhoneNumber'
          fieldName='Phone Number'
          inputTransform={transformRawPhoneInputValue}
          placeholder='(999) 999-9999'
          required={
            action === 'create' ||
            (action === 'edit' && !!firmQuery.data?.data.attributes.phoneNumber)
          }
        />
      )
    },
    whitelabelId: {
      initialValue:
        action === 'edit' ? firmQuery.data?.data.attributes.whitelabelId : 161,
      label: 'Sub Domain',
      component: (
        <SimpleDropdown
          fieldId='whitelabelId'
          fieldName='Sub Domain'
          fieldOptions={whitelabelEntities.data || []}
          required
        />
      )
    }
  };

  const updateExclusiveFirmFields = {
    firmId: {
      initialValue: firmId,
      isFieldRequired: true,
      label: '',
      hidden: true
    },
    address1: {
      initialValue: firmQuery.data?.data.attributes.address.address1,
      isFieldRequired: !!firmQuery.data?.data.attributes.address.address1,
      label: 'Address 1'
    },
    address2: {
      initialValue: firmQuery.data?.data.attributes.address.address2,
      label: 'Address 2'
    },
    city: {
      initialValue: firmQuery.data?.data.attributes.address.city,
      isFieldRequired: !!firmQuery.data?.data.attributes.address.city,
      label: 'City'
    },
    state: {
      initialValue: firmQuery.data?.data.attributes.address.state,
      label: 'State',
      component: (
        <SimpleDropdown
          fieldId='state'
          fieldName='State'
          fieldValues={STATE_CODES}
          required={!!firmQuery.data?.data.attributes.address.state}
        />
      )
    },
    zip: {
      initialValue: firmQuery.data?.data.attributes.address.zip,
      label: 'Zipcode',
      component: (
        <SimpleMaskedInput
          fieldId='zip'
          fieldName='Zipcode'
          inputTransform={(raw: string) => {
            const numerals = formatters.extractNumerals(raw, 9);

            // 90210
            if (numerals.length <= 5) {
              return numerals;
            }
            // 90210-1234
            else if (numerals.length <= 9) {
              return `${numerals.slice(0, 5)}-${numerals.slice(5)}`;
            }

            return null;
          }}
          placeholder='90210'
          required={!!firmQuery.data?.data.attributes.address.zip}
        />
      )
    }
  };

  const fields = {
    ...sharedFirmFields,
    ...(action === 'edit' ? updateExclusiveFirmFields : {})
  };

  const steps = [
    {
      fields,
      title,
      stepValidationSchema:
        action === 'edit' ? makeUpdateFirmSchema(fields) : CreateFirmSchema
    }
  ];

  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const createFirmMutation = useMutation<FirmDto, unknown, CreateFirmDto>(
    ['FirmService.createFirm'],
    async (createFirmDto: CreateFirmDto) => {
      if (createFirmDto.homeOfficeName) {
        const existingHomeOfficeName = homeOfficeNamesQuery.data?.find(
          (homeOfficeName: string) =>
            homeOfficeName.toLowerCase()?.trim() ===
            createFirmDto.homeOfficeName?.toLowerCase()?.trim()
        );

        if (existingHomeOfficeName) {
          createFirmDto.homeOfficeName = existingHomeOfficeName;
        }
      }

      const firmCreatedDto = await FirmService.createFirm(createFirmDto);

      queryClient.invalidateQueries({
        predicate: ({ queryHash }) => /FirmService/.test(queryHash)
      });

      return FirmService.getFirmById(firmCreatedDto.firmId);
    }
  );

  const updateFirmMutation = useMutation<FirmDto, unknown, UpdateFirmDto>(
    ['FirmService.updateFirm'],
    async (updateFirmDto: UpdateFirmDto) => {
      const updatedFirm = await FirmService.updateFirm(
        updateFirmDto.firmId,
        updateFirmDto
      );

      queryClient.invalidateQueries({
        predicate: ({ queryHash }) => /FirmService/.test(queryHash)
      });

      return FirmService.getFirmById(updatedFirm.firmId);
    }
  );

  const showModal = () => {
    openDialog({
      subcomponentProps: {
        dialogContentProps: {
          dividers: true
        }
      },
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: title
        }
      },
      disableSubmitWhenInvalid: true,
      steps,
      onSubmit: async rawInputValues => {
        try {
          const firms = await FirmService.getFirmsPage({
            pageNumber: 1,
            pageSize: 1,
            search: rawInputValues.companyName.trim()
          });
          if (
            firmQuery.data?.data.attributes.companyName !==
              rawInputValues.companyName &&
            firms.data.length
          ) {
            showSnackbar({
              message: `Firm with that Company Name already exists`,
              severity: 'error'
            });
            return;
          }

          if (action === 'edit') {
            const dto = {
              firmId,
              ...rawInputValues
            };
            const lastStep = last(steps);

            if (!lastStep)
              throw new Error(`EntityModal form is missing a Dialog step!`);

            const updateFirmSchema = lastStep.stepValidationSchema;

            const updateFirmDto = await updateFirmSchema.validate(dto);

            const finalDto = {
              ...updateFirmDto,
              homeOfficeName:
                rawInputValues.homeOfficeName === NullSignifierOption
                  ? null
                  : rawInputValues.homeOfficeName
            } as UpdateFirmDto;

            const updatedFirm = await updateFirmMutation.mutateAsync(finalDto);

            showSnackbar({
              message: `${updatedFirm.data.attributes.companyName} updated`,
              severity: 'success'
            });
          } else {
            const createFirmDto =
              await CreateFirmSchema.validate(rawInputValues);
            const newFirm = await createFirmMutation.mutateAsync({
              ...createFirmDto,
              homeOfficeName:
                rawInputValues.homeOfficeName === NullSignifierOption
                  ? null
                  : rawInputValues.homeOfficeName
            });

            showSnackbar({
              message: `${newFirm.data.attributes.companyName} created`,
              severity: 'success'
            });
          }
        } catch (error) {
          showSnackbar({
            message: `Error ${
              action === 'create' ? 'creating' : 'editing'
            } firm!`,
            severity: 'error'
          });
          return;
        }
      }
    });
  };

  return {
    showModal,
    isEnabled: homeOfficeNamesQuery.isSuccess,
    title
  };
};

const useCreateAdvisorModal = (params: {
  firmId: number;
}): {
  showModal: () => void;
  isEnabled: boolean;
  title: string;
} => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { userHasValidToken } = useUserToken();

  const { firmId } = params;

  if (!isNumber(firmId)) {
    throw new Error(
      `invalid firmId <${firmId}> provided, firmId must be a number`
    );
  }

  const firmQuery = useQuery<FirmDto>(
    ['FirmService.getFirmById'],
    () => FirmService.getFirmById(firmId),
    {
      enabled: userHasValidToken,
      staleTime: Infinity
    }
  );

  const createAdvisorMutation = useMutation<
    AdvisorDto,
    unknown,
    CreateAdvisorDto
  >(
    ['AdvisorService.createAdvisor'],
    async (createAdvisorDto: CreateAdvisorDto) => {
      const newAdvisor = await AdvisorService.createAdvisor(createAdvisorDto);

      queryClient.invalidateQueries({
        predicate: ({ queryHash }) => /(Advisor|Firm)Service/.test(queryHash)
      });

      return AdvisorService.getById(newAdvisor.data.id);
    }
  );

  const createAdvisorSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email().required('Email Address is required'),
    companyName: yup.string().optional(),
    crdNumber: yup.string().optional()
  });

  const showModal = () =>
    openDialog({
      subcomponentProps: {
        dialogContentProps: {
          dividers: true
        }
      },
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: 'Add Advisor'
        }
      },
      disableSubmitWhenInvalid: true,
      steps: [
        {
          title: 'New Advisor',
          fields: {
            firstName: {
              initialValue: '',
              isFieldRequired: true,
              label: 'First Name'
            },
            lastName: {
              initialValue: '',
              isFieldRequired: true,
              label: 'Last Name'
            },
            email: {
              initialValue: '',
              isFieldRequired: true,
              label: 'Email'
            },
            crdNumber: {
              initialValue: '',
              label: 'CRD #'
            },
            companyName: {
              initialValue: firmQuery.data?.data.attributes.companyName || '',
              hidden: true,
              label: ''
            }
          },
          stepValidationSchema: createAdvisorSchema
        }
      ],
      onSubmit: async rawInputValues => {
        try {
          const advisors = await FirmService.getFirmAdvisors({
            filter: rawInputValues.email,
            firmId,
            page: 1,
            size: 1
          });
          if (advisors.advisors.length) {
            showSnackbar({
              message: `Advisor with that email already exists`,
              severity: 'error'
            });
            return;
          }
          await createAdvisorMutation.mutateAsync({
            data: {
              type: 'advisor',
              attributes: await createAdvisorSchema.validate(rawInputValues),
              relationships: {
                firm: {
                  data: {
                    type: 'firm',
                    id: firmId
                  }
                }
              }
            }
          });
        } catch (e) {
          showSnackbar({
            message: `Failed to create Advisor`,
            severity: 'error'
          });
        }

        closeDialog();
      }
    });

  return {
    showModal,
    isEnabled: firmQuery.isSuccess,
    title: 'Add Advisor'
  };
};

const useEditAdvisorModal = (params: {
  advisorId: number;
  firmId: number;
}): {
  showModal: () => void;
  isEnabled: boolean;
  title: string;
} => {
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { userHasValidToken } = useUserToken();

  const { advisorId, firmId } = params;

  if (!isNumber(+advisorId)) {
    throw new Error(
      `invalid advisorId <${advisorId}> provided, advisorId must be a number`
    );
  }

  const advisorQuery = useQuery<AdvisorDto>(
    ['AdvisorService.getById'],
    () => AdvisorService.getById(advisorId),
    {
      enabled: userHasValidToken
    }
  );

  const editAdvisorMutation = useMutation<AdvisorDto, unknown, EditAdvisorDto>(
    ['AdvisorService.editAdvisor'],
    async (editAdvisorDto: EditAdvisorDto) => {
      const updatedAdvisor = await AdvisorService.editAdvisor(editAdvisorDto);

      queryClient.invalidateQueries({
        predicate: ({ queryHash }) => /(Advisor|Firm)Service/.test(queryHash)
      });

      return AdvisorService.getById(updatedAdvisor.data.id);
    }
  );

  const editAdvisorSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email().required('Email Address is required'),
    phone: yup // moshe: not all newly created advisors have a valid phone number
      .string()
      .transform(s => s || '')
      .optional(),
    crdNumber: yup.string().optional()
  });

  const showModal = () =>
    openDialog({
      subcomponentProps: {
        dialogContentProps: {
          dividers: true
        }
      },
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: 'Edit Advisor'
        }
      },
      disableSubmitWhenInvalid: true,
      steps: [
        {
          title: 'Edit Advisor',
          fields: {
            firstName: {
              initialValue: advisorQuery.data?.data.attributes.firstName,
              isFieldRequired: true,
              label: 'First Name'
            },
            lastName: {
              initialValue: advisorQuery.data?.data.attributes.lastName,
              isFieldRequired: true,
              label: 'Last Name'
            },
            email: {
              initialValue: advisorQuery.data?.data.attributes.email,
              isFieldRequired: true,
              label: 'Email'
            },
            phone: {
              initialValue: advisorQuery.data?.data.attributes.phone,
              label: 'Phone',
              component: (
                <SimpleMaskedInput
                  fieldId='phone'
                  fieldName='Phone Number'
                  inputTransform={transformRawPhoneInputValue}
                  placeholder='(999) 999-9999'
                />
              )
            },
            crdNumber: {
              initialValue: advisorQuery.data?.data.attributes.crdNumber,
              label: 'CRD #'
            }
          },
          stepValidationSchema: editAdvisorSchema
        }
      ],
      onSubmit: async rawInputValues => {
        try {
          const dto = {
            data: {
              id: advisorId,
              type: 'advisor',
              attributes: await editAdvisorSchema.validate(rawInputValues)
            }
          };

          const advisors = await FirmService.getFirmAdvisors({
            filter: rawInputValues.email,
            firmId,
            page: 1,
            size: 1
          });
          if (advisors.advisors.length) {
            showSnackbar({
              message: `Advisor with that email already exists`,
              severity: 'error'
            });
            return;
          }

          await editAdvisorMutation.mutateAsync(dto as EditAdvisorDto);
        } catch (e) {
          showSnackbar({
            message: `Failed to update Advisor`,
            severity: 'error'
          });
        }

        closeDialog();
      }
    });

  return {
    showModal,
    isEnabled: advisorQuery.isSuccess,
    title: 'Edit Advisor'
  };
};

const usePlanModal = (
  params: EntityModalParams
): {
  showModal: () => void;
  isEnabled: boolean;
  title: string;
} => {
  const { action, planId, pooledPlanId } = params;
  const { closeDialog, openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken, token } = useUserToken();
  const navigate = useNavigate();

  const isCreateAdopterPlan = isNumber(pooledPlanId);
  const isEditSponsorPlan = isNumber(planId);
  const isCreateSponsorPlan = !isCreateAdopterPlan && !isEditSponsorPlan;

  const queryClient = useQueryClient();

  const tpaListDtoQuery = useQuery<TpaListDto>(
    ['TpaService.getAll'],
    () => TpaService.getAll(),
    {
      // todo: david - migrate this to the use the permission framework
      // temp fix to suppress 403 errors in middleware when a tpa user is logged in
      enabled: userHasValidToken && !token?.tpaId,
      staleTime: Infinity
    }
  );

  const pooledPlanQuery = useQuery(
    ['PlanService.getPooledPlanById', pooledPlanId],
    () => PlanService.getPooledPlanById(pooledPlanId),
    {
      enabled: userHasValidToken && isCreateAdopterPlan,
      staleTime: Infinity
    }
  );

  const planQuery = useQuery(
    ['PlanService.getPlanById', planId?.toString()],
    () => {
      if (planId === undefined)
        throw new Error(
          `planQuery missing planId -- this should be an impossible state`
        );

      return PlanService.getPlanById(planId);
    },
    {
      enabled: userHasValidToken && !!planId,
      staleTime: Infinity
    }
  );

  const advisorId =
    planQuery.data?.data.relationships.advisor.data.id ||
    pooledPlanQuery.data?.pooledPlanDefault.advisorId;

  const planDesignQuery = useQuery(
    ['PlanService.getPlanDesignById', planId],
    () => {
      if (!planId)
        throw new Error(`planId missing -- this should be an impossible state`);
      return PlanService.getPlanDesignById(planId);
    },
    {
      enabled: userHasValidToken && !!planId,
      staleTime: Infinity
    }
  );

  const advisorQuery = useQuery<AdvisorDto>(
    ['AdvisorService.getById', advisorId],
    () => {
      if (!advisorId)
        throw new Error(
          `advisorQuery missing advisorId -- this should be an impossible state`
        );

      return AdvisorService.getById(advisorId);
    },
    {
      enabled: Boolean(
        userHasValidToken &&
          advisorId &&
          ((planQuery.data?.data.relationships.advisor.data.id &&
            action === 'edit') ||
            (pooledPlanQuery.data?.pooledPlanDefault.advisorId &&
              ['gop', 'pep'].includes(pooledPlanQuery.data?.pooledPlanType)))
      ),
      staleTime: Infinity
    }
  );

  const priorProvidersQuery = useQuery<PriorProvidersDto>(
    ['PriorProviders.service'],
    () => PriorProviderService.getPriorProviders()
  );

  const tpas = tpaListDtoQuery.data?.data || [];

  const planTpaOptions = sortBy(tpas, tpa =>
    tpa.tpaId === VESTWELL_TPA.ID ? Infinity : tpa.name
  ).map(tpa => (tpa.tpaId === VESTWELL_TPA.ID ? VESTWELL_TPA.NAME : tpa.name));

  const options = useMemo(
    () => ({
      planType: ['401k', 'Solo 401k', '403b', 'Starter 401k'],
      planTier:
        action === 'edit'
          ? Object.keys(PLAN_TIERS)
          : Object.keys(omit(PLAN_TIERS, ['Pro'])),
      planConversionType: ['New', 'Conversion'],
      planTpa: planTpaOptions,
      planAdvisors: []
    }),
    [action, planTpaOptions]
  );

  const getConversionTypeName = (
    value: string | undefined
  ): string | undefined => {
    if (value === 'true') {
      return 'Conversion';
    }

    if (value === 'false') {
      return 'New';
    }

    return undefined;
  };

  const createPlanMutation = useMutation(
    ['PlanService.createOnboardingPlan'],
    async (createPlanDto: CreatePlanDto) => {
      const planCreatedDto =
        await PlanService.createOnboardingPlan(createPlanDto);
      return PlanService.getPlanById(planCreatedDto.sponsorPlanId);
    }
  );

  const createAdopterPlanMutation = useMutation(
    ['PlanService.createAdopterOnboardingPlan'],
    async (createAdopterPlanDto: CreateAdopterPlanDto) => {
      const planCreatedDto =
        await PlanService.createAdopterOnboardingPlan(createAdopterPlanDto);

      return PlanService.getPlanById(planCreatedDto.sponsorPlanId);
    }
  );

  const editPlanMutation = useMutation(
    ['PlanService.updatePlan'],
    async (editPlanDto: EditPlanDto) => {
      await PlanService.updatePlan(editPlanDto.planId, {
        data: {
          type: 'plan',
          id: editPlanDto.planId,
          attributes: {
            name: editPlanDto.companyName,
            type: editPlanDto.planType
          },
          relationships: {
            advisor: {
              data: {
                type: 'advisor',
                id: editPlanDto.advisorId
              }
            }
          }
        }
      });

      const updatePlanDesignPromise = await PlanService.updatePlanDesign(
        editPlanDto.planId,
        {
          overview: {
            conversionType: Helper.getConversionTypeForName(
              editPlanDto.planConversionType
            ),
            planTierId: editPlanDto.planTier,
            planType: editPlanDto.planType
          },
          recordkeeperAndCustodian: {
            priorProviderId:
              editPlanDto.planConversionType === 'Conversion'
                ? editPlanDto.priorProviderId
                : null
          }
        }
      );

      if (
        !(
          editPlanDto.newTpaId === undefined ||
          editPlanDto.newTpaId === editPlanDto.oldTpaId
        )
      ) {
        await Promise.all([
          updatePlanDesignPromise,
          TpaService.replacePlanToTpaLink({
            planId: editPlanDto.planId,
            newTpaId: editPlanDto.newTpaId
          })
        ]);
      }

      queryClient.invalidateQueries();
    }
  );

  const getOrCreatePriorProviderMutation = useMutation(
    ['PriorProviderService.postPriorProvider'],
    (priorProviderName: string) =>
      PriorProviderService.postPriorProvider(priorProviderName)
  );

  const unverifiedPriorProvider = useMemo(() => {
    const verifiedPriorProvider = priorProvidersQuery.data?.data?.find(
      pp =>
        pp.priorProviderId ===
        planDesignQuery.data?.data?.recordkeeperAndCustodian?.priorProviderId
    );

    return !verifiedPriorProvider
      ? planDesignQuery.data?.data?.recordkeeperAndCustodian?.priorProviderName
      : '';
  }, [planDesignQuery.data, priorProvidersQuery.data]);

  const getTpa = (p: typeof planQuery): { ID: number; NAME: string } | null => {
    const tpa = p.data?.data.relationships.tpas?.data[0];

    if (!tpa) {
      return null;
    }

    const { name, id } = tpa;

    if (id === VESTWELL_TPA.ID) {
      return { ...VESTWELL_TPA };
    }

    return {
      ID: id,
      NAME: name
    };
  };

  const toAdvisorSearchResultDto = (
    q: typeof advisorQuery
  ): AdvisorEntitySearchResultDto | null => {
    if (!q.data) return null;

    return {
      advisorId: +q.data.data.id,
      firstName: q.data?.data.attributes.name,
      lastName: '',
      firmName: ''
    };
  };

  const isAdvisorDto = (dto: any): dto is AdvisorDto => {
    return 'attributes' in dto && ('id' in dto && dto.type) === 'advisor';
  };

  const getAdvisorDropdownLabel = (
    advisor: AdvisorDto | AdvisorEntitySearchResultDto | string | null
  ) => {
    if (advisor == null) {
      return 'Advisor';
    }

    if (isAdvisorDto(advisor)) {
      return advisor.data.attributes.name;
    }

    return `${(advisor as AdvisorEntitySearchResultDto).firstName} ${
      (advisor as AdvisorEntitySearchResultDto).lastName
    }`;
  };

  let actionTitle: string;
  if (isCreateAdopterPlan) {
    actionTitle = 'Create Adopter Plan';
  } else if (isEditSponsorPlan) {
    actionTitle = 'Edit Plan';
  } else {
    actionTitle = 'Create Plan';
  }

  const sharedEditAndCreateSteps = [
    {
      title: actionTitle,
      fields: {
        planName: {
          initialValue:
            action === 'edit' ? planQuery.data?.data.attributes.name : '',
          isFieldRequired: true,
          label: 'Company Name'
        },
        ...(isCreateAdopterPlan
          ? null
          : {
              planTpa: {
                initialValue: action === 'edit' ? getTpa(planQuery)?.NAME : '',
                label: 'Third-Party Administrator',
                component: (
                  <SimpleDropdown
                    fieldId='planTpa'
                    fieldName='Third-Party Administrator'
                    fieldValues={options.planTpa}
                  />
                )
              }
            }),
        ...(isCreateAdopterPlan || action === 'edit'
          ? null
          : {
              tpaSubtypeId: {
                component: (
                  <SimpleDropdown
                    fieldId='tpaSubtypeId'
                    fieldName='Partner Access'
                    fieldOptions={ENTERPRISE_TPA.map(partner => ({
                      option: partner.name,
                      value: partner.tpaSubType
                    }))}
                  />
                ),
                initialValue: undefined,
                label: 'TPA Subtype'
              }
            }),
        ...(isCreateAdopterPlan &&
        !['gop', 'pep'].includes(pooledPlanQuery.data?.pooledPlanType)
          ? null
          : {
              planAdvisor: {
                initialValue: { advisorId },
                label: 'Advisor',
                component: (
                  <SimpleAutocomplete<AdvisorEntitySearchResultDto>
                    defaultValue={toAdvisorSearchResultDto(advisorQuery)}
                    fieldId='planAdvisor'
                    fieldName='Advisor'
                    getFieldValues={(userInput: string) =>
                      // TODO moshe: this should be wrapped in a service call for mocking
                      ApiService.getJson<AdvisorSearchResultDto>('/advisors', {
                        name: userInput
                      }).then(dto => {
                        return orderBy(
                          dto.data.data,
                          advisor => advisor.firstName + advisor.lastName
                        );
                      })
                    }
                    getOptionLabel={getAdvisorDropdownLabel}
                    icon={<Search />}
                    renderOption={(optionProps, option) => {
                      return (
                        <ListItem {...optionProps} key={option.advisorId}>
                          <Grid container>
                            <Grid item md={9}>
                              <Typography>
                                {getAdvisorDropdownLabel(option)}
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography
                                align='right'
                                color={theme => theme.palette.grey[700]}
                                data-testid={`advisor-id-${option.advisorId}-list-item`}
                                variant='caption'>
                                ADVISOR ID: {option.advisorId}
                              </Typography>
                            </Grid>
                            <Grid container>
                              <Grid item>
                                <Typography variant='caption'>
                                  Firm: {option.firmName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    }}
                    required
                  />
                )
              }
            }),
        ...(action === 'edit'
          ? {
              planConversionType: {
                initialValue:
                  getConversionTypeName(
                    planDesignQuery.data?.data?.overview?.conversionType
                  ) || '',
                label: 'Conversion Type',
                component: (
                  <SimpleDropdown
                    fieldId='planConversionType'
                    fieldName='Conversion Type'
                    fieldValues={options.planConversionType}
                    required
                  />
                )
              }
            }
          : null),
        ...(action === 'edit'
          ? {
              priorProviderId: {
                component: <PriorProviderComponent />,
                initialValue: !planDesignQuery.data?.data
                  .recordkeeperAndCustodian?.priorProviderId
                  ? ''
                  : !unverifiedPriorProvider
                    ? priorProvidersQuery.data?.data?.find(
                        pp =>
                          pp.priorProviderId ===
                          planDesignQuery.data?.data?.recordkeeperAndCustodian
                            ?.priorProviderId
                      )?.priorProviderId
                    : UNVERIFIED_PRIOR_PROVIDER_ID,
                label: 'Prior Provider',
                showWhen: (formValues: any) =>
                  formValues.planConversionType === 'Conversion'
              },
              priorProviderUnverified: {
                initialValue: unverifiedPriorProvider,
                label: 'Other Prior Provider Name',
                isFieldRequired: true,
                showWhen: (formValues: any) =>
                  formValues.planConversionType === 'Conversion' &&
                  formValues.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID
              }
            }
          : null),
        ...(action === 'edit'
          ? {
              planTier: {
                initialValue: planDesignQuery.data?.data.overview?.planTierName,
                label: 'Plan Tier',
                component: (
                  <SimpleDropdown
                    fieldId='planTier'
                    fieldName='Plan Tier'
                    fieldValues={options.planTier}
                    required
                  />
                )
              }
            }
          : null),
        ...(action === 'edit'
          ? {
              planType: {
                initialValue: planDesignQuery.data?.data.overview?.planType,
                label: 'Plan Type',
                component: (
                  <SimpleDropdown
                    fieldId='planType'
                    fieldName='Plan Type'
                    fieldValues={options.planType}
                    required
                  />
                )
              }
            }
          : null)
      },
      stepValidationSchema: yup.object({
        planName: yup.string().required('Company Name is required'),
        // We need this logic to support plans created before implementing this field
        planTpa: yup.string().oneOf(options.planTpa).optional(),
        ...(isCreateAdopterPlan &&
        !['gop', 'pep'].includes(pooledPlanQuery.data?.pooledPlanType)
          ? null
          : {
              planAdvisor: yup
                .object({
                  advisorId: yup.number().required('You must select an Advisor')
                })
                .required()
            }),
        ...(action === 'edit'
          ? {
              planConversionType: yup
                .string()
                .oneOf(options.planConversionType)
                .required('You must select a conversion type'),
              planTier: yup
                .string()
                .oneOf(options.planTier)
                .required('You must select a plan tier'),
              planType: yup
                .string()
                .oneOf(options.planType)
                .required('You must select a plan type'),
              // We need this logic to support plans created before implementing this field
              priorProviderId: EditPlanSchema.fields.priorProviderId,
              priorProviderUnverified: yup.string().test({
                test(value) {
                  if (
                    this.parent.planConversionType === 'Conversion' &&
                    this.parent.priorProviderId ===
                      UNVERIFIED_PRIOR_PROVIDER_ID &&
                    !value
                  ) {
                    return this.createError({
                      path: this.path,
                      message: 'Required'
                    });
                  }

                  return true;
                }
              })
            }
          : {})
      })
    }
  ];

  const createPlanExclusiveSteps = [
    {
      title: actionTitle,
      fields: {
        planType: {
          initialValue: '',
          label: 'Plan Type',
          component: (
            <SimpleRadio
              fieldId='planType'
              fieldName='Plan Type'
              fieldValues={options.planType}
              required
              row
            />
          )
        },
        planTier: {
          initialValue: '',
          label: 'Plan Tier',
          component(formValues: Record<string, any>) {
            return (
              <SimpleRadio
                fieldId='planTier'
                fieldName='Plan Tier'
                fieldValues={
                  !isCreateAdopterPlan &&
                  formValues.planTpa !== VESTWELL_TPA.NAME
                    ? ['Vestwell Flex', 'Recordkeeping Only']
                    : options.planTier
                }
                required
                row
              />
            );
          }
        },
        opportunityId: {
          initialValue: '',
          label: 'Opportunity ID',
          component: (
            <Field
              component={TextField}
              fullWidth
              key='opportunityId'
              label='Opportunity ID'
              name='opportunityId'
              required
            />
          )
        },
        planConversionType: {
          initialValue: '',
          label: 'Conversion Type',
          component: (
            <SimpleRadio
              fieldId='planConversionType'
              fieldName='Conversion Type'
              fieldValues={options.planConversionType}
              required
              row
            />
          )
        },
        priorProviderId: {
          initialValue: '',
          label: 'Prior Provider',
          showWhen: (formValues: any) =>
            formValues.planConversionType === 'Conversion',
          component: <PriorProviderComponent required />
        },
        priorProviderUnverified: {
          initialValue: '',
          label: 'Other Name',
          isFieldRequired: true,
          showWhen: (formValues: any) =>
            formValues.planConversionType === 'Conversion' &&
            formValues.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID
        }
      },
      stepValidationSchema: yup.object({
        planType: yup
          .string()
          .oneOf(options.planType)
          .required('Plan Type is required'),
        planTier: yup
          .string()
          .oneOf(options.planTier)
          .required('You must select a plan tier'),
        opportunityId: yup.string().required('Opportunity ID is required'),
        planConversionType: yup
          .string()
          .oneOf(options.planConversionType)
          .required('You must select a conversion type'),
        priorProviderId: yup.number().when('planConversionType', {
          is: 'Conversion',
          then: schema =>
            schema.required('Prior Provider is required for conversion plans'),
          otherwise: schema => schema.optional()
        }),
        priorProviderUnverified: yup.string().test({
          test(value) {
            if (
              this.parent.planConversionType === 'Conversion' &&
              this.parent.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID &&
              !value
            ) {
              return this.createError({
                path: this.path,
                message: 'Required'
              });
            }

            return true;
          }
        })
      })
    }
  ];

  const steps =
    action === 'edit'
      ? sharedEditAndCreateSteps
      : [...sharedEditAndCreateSteps, ...createPlanExclusiveSteps];

  const showModal = () => {
    openDialog({
      subcomponentProps: {
        dialogContentProps: {
          dividers: true
        }
      },
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: actionTitle
        }
      },
      disableContinueWhenInvalid: true,
      disableSubmitWhenInvalid: true,
      steps,
      onSubmit: async rawInputValues => {
        const tpaId = isCreateAdopterPlan
          ? undefined
          : rawInputValues.planTpa === VESTWELL_TPA.NAME
            ? VESTWELL_TPA.ID
            : tpas.find(t => t.name === rawInputValues.planTpa)?.tpaId;

        const planAdvisorId =
          isCreateAdopterPlan &&
          !['gop', 'pep'].includes(pooledPlanQuery.data?.pooledPlanType)
            ? undefined
            : (
                rawInputValues.planAdvisor as any as AdvisorEntitySearchResultDto
              ).advisorId;

        try {
          const planTier = PLAN_TIERS[rawInputValues.planTier];

          // todo fix types
          const isConvertedBoolean =
            rawInputValues.planConversionType === 'Conversion';

          let unsafeCreateDto: any = {
            advisorId: planAdvisorId,
            companyName: rawInputValues.planName,
            isConvertedPlan: String(isConvertedBoolean),
            opportunityId: rawInputValues.opportunityId,
            planTier,
            planType: rawInputValues.planType,
            tpaId,
            tpaSubtypeId: rawInputValues.tpaSubtypeId
          };

          if (isConvertedBoolean) {
            const priorProviderId =
              +rawInputValues.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID
                ? (
                    await getOrCreatePriorProviderMutation.mutateAsync(
                      rawInputValues.priorProviderUnverified
                    )
                  )?.data?.priorProviderId
                : rawInputValues.priorProviderId;

            unsafeCreateDto = {
              ...unsafeCreateDto,
              priorProviderId
            };
          }

          if (isCreateAdopterPlan) {
            const dto = await CreateAdopterPlanSchema.validate({
              ...unsafeCreateDto,
              pooledPlanId
            });

            const newPlan: PlanV2Dto =
              await createAdopterPlanMutation.mutateAsync(dto);

            showSnackbar({
              message: `${newPlan.data.attributes.name} created`,
              severity: 'success'
            });

            return defer(() => {
              openDialog({
                dialogProps: {
                  onClose: () => {
                    closeDialog();
                    navigate(`/plans/${newPlan.data.id}`);
                  }
                },
                customContent: (
                  <CreatePlanSuccessSplashScreen
                    plan={newPlan}
                    pooledPlanId={pooledPlanId}
                  />
                )
              });
            });
          }
          if (isCreateSponsorPlan) {
            const dto = await CreatePlanSchema.validate(unsafeCreateDto);

            const newPlan: PlanV2Dto =
              await createPlanMutation.mutateAsync(dto);

            showSnackbar({
              message: `${newPlan.data.attributes.name} created`,
              severity: 'success'
            });

            return defer(() => {
              openDialog({
                dialogProps: {
                  onClose: () => {
                    closeDialog();
                    navigate(`/plans/${newPlan.data.id}`);
                  }
                },
                customContent: <CreatePlanSuccessSplashScreen plan={newPlan} />
              });
            });
          }

          const priorProviderIdVal = (
            rawPriorProviderId: string,
            isConversion: boolean,
            priorProviderId: number
          ) => {
            if (isConversion) {
              return +rawPriorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID
                ? priorProviderId
                : rawPriorProviderId;
            }
            return undefined;
          };

          await editPlanMutation.mutateAsync(
            await EditPlanSchema.validate({
              planId,
              planConversionType: rawInputValues.planConversionType,
              companyName: rawInputValues.planName,
              newTpaId: tpaId,
              oldTpaId: getTpa(planQuery)?.ID,
              advisorId: planAdvisorId,
              planTier: PLAN_TIERS[rawInputValues.planTier],
              planType: rawInputValues.planType,
              priorProviderId: priorProviderIdVal(
                rawInputValues.priorProviderId,
                isConvertedBoolean,
                unsafeCreateDto.priorProviderId
              )
            })
          );

          showSnackbar({
            message: `Success! plan is updated.`,
            severity: 'success'
          });

          return true;
        } catch (e: any) {
          if (action === 'create' && e.statusCode === 409) {
            showSnackbar({
              message: `Opportunity ID already exists`,
              severity: 'error'
            });
          } else {
            showSnackbar({
              message: `Error ${
                action === 'create' ? 'creating' : 'editing'
              } plan!`,
              severity: 'error'
            });
          }

          return Promise.reject(e);
        }
      }
    });
  };

  return {
    showModal,
    isEnabled:
      ((!isCreateAdopterPlan || pooledPlanQuery.data) &&
        !!tpaListDtoQuery.data) ||
      (action === 'edit' &&
        advisorQuery.isFetching &&
        planDesignQuery.isFetching),
    title: actionTitle
  };
};

const usePooledPlanModal = ({
  onRefetch
}: {
  onRefetch?: () => void;
}): { isEnabled: boolean; showModal: () => void } => {
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken, token } = useUserToken();

  const tpaListDTOQuery = useQuery<TpaListDto>(
    ['TpaService.getAll'],
    () => TpaService.getAll(),
    {
      // todo: david - migrate this to the use the permission framework
      // temp fix to suppress 403 errors in middleware when a tpa user is logged in
      enabled: userHasValidToken && !token?.tpaId,
      staleTime: Infinity
    }
  );

  const createPooledPlanMutation = useMutation(
    ['PlanService.createPooledPlan'],
    async (pooledPlanDTO: CreatePooledPlanDto) =>
      PlanService.createPooledPlan(pooledPlanDTO)
  );

  const planTpaOptions = sortBy(tpaListDTOQuery.data?.data || [], tpa =>
    tpa.tpaId === VESTWELL_TPA.ID ? Infinity : tpa.name
  ).map(tpa => (tpa.tpaId === VESTWELL_TPA.ID ? VESTWELL_TPA.NAME : tpa.name));

  const isAdvisorDTO = (dto: any): dto is AdvisorDto => {
    return 'attributes' in dto && ('id' in dto && dto.type) === 'advisor';
  };

  const getAdvisorDropdownLabel = (
    advisor: AdvisorDto | AdvisorEntitySearchResultDto | string | null
  ) => {
    if (advisor == null) {
      return 'Advisor';
    }

    if (isAdvisorDTO(advisor)) {
      return advisor.data.attributes.name;
    }

    return `${(advisor as AdvisorEntitySearchResultDto).firstName} ${
      (advisor as AdvisorEntitySearchResultDto).lastName
    }`;
  };

  const options = useMemo(() => {
    return {
      subType: POOLED_PLAN_SUBTYPES,
      tpa: planTpaOptions,
      type: POOLED_PLAN_TYPES.map(t => t.toUpperCase())
    };
  }, [planTpaOptions]);

  const firstStep = {
    fields: {
      pooledPlanName: {
        initialValue: '',
        isFieldRequired: true,
        label: 'Plan Name'
      },
      planTpa: {
        component: (
          <SimpleDropdown
            fieldId='planTpa'
            fieldName='Third-Party Administrator'
            fieldValues={options.tpa}
            required
          />
        ),
        label: 'Third-Party Administrator'
      },
      planAdvisor: {
        label: 'Advisor',
        component: (
          <SimpleAutocomplete<AdvisorEntitySearchResultDto>
            fieldId='planAdvisor'
            fieldName='Advisor'
            getFieldValues={(userInput: string) =>
              // TODO moshe: this should be wrapped in a service call for mocking
              ApiService.getJson<AdvisorSearchResultDto>('/advisors', {
                name: userInput
              }).then(dto => {
                return orderBy(
                  dto.data.data,
                  advisor => advisor.firstName + advisor.lastName
                );
              })
            }
            getOptionLabel={getAdvisorDropdownLabel}
            icon={<Search />}
            renderOption={(optionProps, option) => {
              return (
                <ListItem {...optionProps} key={option.advisorId}>
                  <Grid container>
                    <Grid item md={9}>
                      <Typography>{getAdvisorDropdownLabel(option)}</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography
                        align='right'
                        color={theme => theme.palette.grey[700]}
                        data-testid={`advisor-id-${option.advisorId}-list-item`}
                        variant='caption'>
                        ADVISOR ID: {option.advisorId}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <Typography variant='caption'>
                          Firm: {option.firmName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }}
            required
          />
        )
      }
    },
    stepValidationSchema: yup.object({
      pooledPlanName: yup.string().required('Plan Name is required'),
      planTpa: yup
        .string()
        .oneOf(options.tpa)
        .required('You must select a Third-Party Administrator'),
      planAdvisor: yup
        .object({
          advisorId: yup.number().required('You must select an Advisor')
        })
        .required()
    }),
    title: 'Create Pooled Plan'
  };
  const secondStep = {
    fields: {
      pooledPlanType: {
        component: (
          <SimpleRadio
            fieldId='pooledPlanType'
            fieldName='Pooled Plan Type'
            fieldValues={options.type}
            required
            row
          />
        ),
        initialValue: '',
        label: 'Pooled Plan Type'
      },
      pooledPlanSubType: {
        component: (
          <SimpleDropdown
            fieldId='pooledPlanSubType'
            fieldName='Pooled Plan Sub Type'
            fieldValues={options.subType}
            required
          />
        ),
        initialValue: '',
        label: 'Pooled Plan Sub Type',
        showWhen: (formValues: Record<string, unknown>) =>
          formValues.pooledPlanType === 'MEP'
      },
      opportunityId: {
        initialValue: '',
        isFieldRequired: true,
        label: 'Opportunity ID'
      }
    },
    stepValidationSchema: yup.object({
      pooledPlanSubType: yup.string().when('pooledPlanType', {
        is: 'MEP',
        then: schema =>
          schema
            .oneOf(options.subType)
            .required('You must select Pooled Plan Sub Type'),
        otherwise: schema => schema.optional()
      }),
      pooledPlanType: yup
        .string()
        .required('You must select a pooled plan type'),
      opportunityId: yup.string().required('Opportunity ID is required')
    }),
    title: 'Create Pooled Plan'
  };
  const steps = [firstStep, secondStep];

  const showModal = () => {
    openDialog({
      subcomponentProps: {
        dialogContentProps: {
          dividers: true
        }
      },
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: 'Create Plan'
        }
      },
      disableContinueWhenInvalid: true,
      disableSubmitWhenInvalid: true,
      steps,
      onSubmit: async values => {
        try {
          const thirdPartyAdministratorId =
            values.planTpa === VESTWELL_TPA.NAME
              ? VESTWELL_TPA.ID
              : tpaListDTOQuery?.data?.data?.find(
                  t => t.name === values.planTpa
                )?.tpaId;

          const dto = await CreatePooledPlanSchema.validate({
            opportunityId: values.opportunityId,
            pooledPlanDefault: {
              advisorId: (
                values.planAdvisor as unknown as AdvisorEntitySearchResultDto
              ).advisorId,
              planDesign: {
                overview: {
                  advisorFirstName: (
                    values.planAdvisor as unknown as AdvisorEntitySearchResultDto
                  ).firstName,
                  advisorLastName: (
                    values.planAdvisor as unknown as AdvisorEntitySearchResultDto
                  ).lastName,
                  tpaFiduciary: values.planTpa
                }
              },
              thirdPartyAdministratorId
            },
            pooledPlanName: values.pooledPlanName,
            pooledPlanType: values.pooledPlanType.toLowerCase(),
            ...(values.pooledPlanType === 'MEP'
              ? { pooledPlanSubType: values.pooledPlanSubType }
              : {})
          });

          const newPlan = await createPooledPlanMutation.mutateAsync(dto);

          showSnackbar({
            message: `${newPlan?.pooledPlanName} created`,
            severity: 'success'
          });

          onRefetch?.();
        } catch (err: any) {
          showSnackbar({
            message: 'Error creating plan!',
            severity: 'error'
          });

          return Promise.reject(err);
        }
      }
    });
  };

  return {
    isEnabled: !!tpaListDTOQuery.data,
    showModal
  };
};

const useEsaPlanModal = (
  params:
    | {
        action: 'create';
        planId?: never;
      }
    | {
        action: 'edit';
        planId: SponsorPlanV1['sponsorPlanId'];
      }
): {
  isEnabled: boolean;
  showModal: () => void;
  title: string;
} => {
  const { action, planId } = params;
  const { closeDialog, openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const isEditSponsorPlan = isNumber(planId);

  const planQuery = useQuery(
    ['PlanService.getPlanById', planId?.toString()],
    () => {
      if (planId === undefined)
        throw new Error(
          `planQuery missing planId -- this should be an impossible state`
        );

      return PlanService.getPlanById(planId);
    },
    {
      enabled: userHasValidToken && !!planId,
      staleTime: Infinity
    }
  );

  const esaPrograms = useQuery(['EsaService.getEsaPrograms'], () =>
    EsaService.getEsaPrograms()
  );

  const advisorId = planQuery.data?.data.relationships.advisor.data.id;

  const advisorQuery = useQuery<AdvisorDto>(
    ['AdvisorService.getById', advisorId],
    () => {
      if (!advisorId)
        throw new Error(
          `advisorQuery missing advisorId -- this should be an impossible state`
        );

      return AdvisorService.getById(advisorId);
    },
    {
      enabled:
        userHasValidToken &&
        planQuery.data?.data.relationships.advisor.data.id &&
        action === 'edit',
      staleTime: Infinity
    }
  );

  const toAdvisorSearchResultDto = (
    q: typeof advisorQuery
  ): AdvisorEntitySearchResultDto | null => {
    if (!q.data) return null;

    return {
      advisorId: +q.data.data.id,
      firstName: q.data?.data.attributes.name,
      lastName: '',
      firmName: ''
    };
  };

  const isAdvisorDTO = (dto: any): dto is AdvisorDto => {
    return 'attributes' in dto && ('id' in dto && dto.type) === 'advisor';
  };

  const getAdvisorDropdownLabel = (
    advisor: AdvisorDto | AdvisorEntitySearchResultDto | string | null
  ) => {
    if (advisor == null) {
      return 'Advisor';
    }

    if (isAdvisorDTO(advisor)) {
      return advisor.data.attributes.name;
    }

    return `${(advisor as AdvisorEntitySearchResultDto).firstName} ${
      (advisor as AdvisorEntitySearchResultDto).lastName
    }`;
  };

  const createEsaPlanMutation = useMutation(
    ['EsaService.createEsaPlan'],
    async (createEsaPlanDto: CreateEsaPlanDto) => {
      const planCreatedDto = await EsaService.createEsaPlan(createEsaPlanDto);

      return PlanService.getPlanById(planCreatedDto.sponsorPlanId);
    }
  );

  const updateEsaPlanMutation = useMutation(
    ['EsaService.updatePlan'],
    async (updateEsaPlanDto: UpdateEsaPlanDto) => {
      await EsaService.updateEsaPlan(updateEsaPlanDto.planId, updateEsaPlanDto);

      queryClient.invalidateQueries();
    }
  );

  const actionTitle = isEditSponsorPlan ? 'Edit ESA Plan' : 'Create ESA Plan';

  const esaEditAndCreateFields = [
    {
      title: actionTitle,
      fields: {
        planName: {
          initialValue:
            action === 'edit' ? planQuery.data?.data.attributes.name : '',
          isFieldRequired: true,
          label: 'Plan Name'
        },
        esaProgram: {
          initialValue:
            action === 'edit'
              ? planQuery.data?.data.relationships.esaProgram?.data.id
              : '',
          label: 'ESA Program',
          component: (
            <SimpleDropdown
              fieldId='esaProgram'
              fieldName='ESA Program'
              fieldOptions={
                esaPrograms.data?.map(program => ({
                  value: program.id,
                  option: program.name
                })) || []
              }
              required
            />
          )
        },
        planAdvisor: {
          initialValue: action === 'edit' ? { advisorId } : {},
          label: 'Advisor',
          component: (
            <SimpleAutocomplete<AdvisorEntitySearchResultDto>
              defaultValue={toAdvisorSearchResultDto(advisorQuery)}
              fieldId='planAdvisor'
              fieldName='Advisor'
              getFieldValues={(userInput: string) =>
                // TODO moshe: this should be wrapped in a service call for mocking
                ApiService.getJson<AdvisorSearchResultDto>('/advisors', {
                  name: userInput
                }).then(dto => {
                  return orderBy(
                    dto.data.data,
                    advisor => advisor.firstName + advisor.lastName
                  );
                })
              }
              getOptionLabel={getAdvisorDropdownLabel}
              icon={<Search sx={{ mr: 1.5 }} />}
              renderOption={(optionProps, option) => {
                return (
                  <ListItem {...optionProps} key={option.advisorId}>
                    <Grid container>
                      <Grid item md={9}>
                        <Typography>
                          {getAdvisorDropdownLabel(option)}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          align='right'
                          color={theme => theme.palette.grey[700]}
                          data-testid='advisor-id-heading'
                          variant='caption'>
                          ADVISOR ID: {option.advisorId}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <Typography variant='caption'>
                            Firm: {option.firmName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              }}
              required
            />
          )
        },
        ...(action === 'edit'
          ? {
              effectiveDate: {
                initialValue: planQuery.data?.data?.attributes?.effectiveDate,
                label: 'Plan Effective Date',
                component(
                  formValues: Record<string, any>,
                  formErrors: Record<string, any>
                ) {
                  return (
                    <FormControl error={!!formErrors.effectiveDate}>
                      <Field
                        as={DatePicker}
                        label='Plan Effective Date'
                        name='effectiveDate'
                        variant='outlined'
                      />
                    </FormControl>
                  );
                }
              }
            }
          : null),
        ...(action === 'create'
          ? {
              opportunityId: {
                initialValue: '',
                label: 'Opportunity ID'
              }
            }
          : null)
      },
      stepValidationSchema: yup.object({
        planName: yup.string().required('Plan Name is required'),
        esaProgram: yup.number().required('You must select an ESA Program'),
        planAdvisor: yup
          .object({
            advisorId: yup.number().required('You must select an Advisor')
          })
          .required(),
        opportunityId: yup
          .string()
          .nullable()
          .transform(value => (!value ? null : value))
      })
    }
  ];

  const showModal = () => {
    openDialog({
      subcomponentProps: {
        dialogContentProps: {
          dividers: true
        }
      },
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: actionTitle
        }
      },
      disableSubmitWhenInvalid: true,
      steps: esaEditAndCreateFields,
      onSubmit: async values => {
        try {
          if (!isEditSponsorPlan) {
            const dto = await CreateEsaPlanSchema.validate({
              advisorId: (
                values.planAdvisor as unknown as AdvisorEntitySearchResultDto
              ).advisorId,
              companyName: values.planName,
              esaProgramId: values.esaProgram,
              opportunityId: values.opportunityId
            });

            const newPlan: PlanV2Dto =
              await createEsaPlanMutation.mutateAsync(dto);

            showSnackbar({
              message: `${newPlan.data.attributes.name} created`,
              severity: 'success'
            });

            queryClient.invalidateQueries(['PlanService.getPlansPage', 'ESA']);

            return defer(() => {
              openDialog({
                dialogProps: {
                  onClose: () => {
                    closeDialog();
                    navigate(`/esa-plans/${newPlan.data.id}`);
                  }
                },
                customContent: <CreatePlanSuccessSplashScreen plan={newPlan} />
              });
            });
          }

          await updateEsaPlanMutation.mutateAsync(
            await UpdateEsaPlanSchema.validate({
              advisorId: (
                values.planAdvisor as unknown as AdvisorEntitySearchResultDto
              ).advisorId,
              companyName: values.planName,
              effectiveDate: values.effectiveDate || null,
              esaProgramId: values.esaProgram,
              planId
            })
          );

          showSnackbar({
            message: `Success! Plan is updated.`,
            severity: 'success'
          });

          return true;
        } catch (err: any) {
          if (action === 'create' && err.statusCode === 409) {
            showSnackbar({
              message: `Opportunity ID already exists`,
              severity: 'error'
            });
          } else {
            showSnackbar({
              message: `Error ${
                action === 'create' ? 'creating' : 'editing'
              } plan!`,
              severity: 'error'
            });
          }

          return Promise.reject(err);
        }
      }
    });
  };

  return {
    isEnabled:
      !!esaPrograms.data ||
      (action === 'edit' &&
        advisorQuery.isFetching &&
        esaPrograms.isFetching &&
        planQuery.isFetching),
    showModal,
    title: actionTitle
  };
};

const CreatePlanModal = (props: {
  pooledPlanId?: number;
  title?: string;
  variant?: 'text' | 'outlined' | 'contained';
}): JSX.Element => {
  const { pooledPlanId, title: titleOverride, variant = 'contained' } = props;

  const {
    showModal,
    title: modalTitle,
    isEnabled
  } = usePlanModal({
    action: 'create',
    pooledPlanId
  });

  return (
    <Button
      data-testid='create-plan-modal-button'
      disabled={!isEnabled}
      onClick={() => showModal()}
      variant={variant}>
      {titleOverride || modalTitle}
    </Button>
  );
};

const FirmModal = (props: {
  variant?: ButtonProps['variant'];
  action: 'edit' | 'create';
  firmId?: number;
}): JSX.Element => {
  const { variant, action, firmId } = props;
  const { showModal, title, isEnabled } = useFirmModal({
    action,
    firmId: firmId || -1
  });

  return (
    <Button
      data-testid='create-firm-modal-button'
      disabled={!isEnabled}
      onClick={() => showModal()}
      variant={variant}>
      {title}
    </Button>
  );
};

const EditAdvisorModal = (params: {
  advisorId: number;
  firmId: number;
}): JSX.Element => {
  const { advisorId, firmId } = params;
  const { showModal, title, isEnabled } = useEditAdvisorModal({
    advisorId,
    firmId
  });

  return (
    <Button
      data-testid='edit-advisor-modal-button'
      disabled={!isEnabled}
      onClick={() => showModal()}
      variant='contained'>
      {title}
    </Button>
  );
};
const CreateAdvisorModal = (params: { firmId: number }): JSX.Element => {
  const { firmId } = params;
  const { showModal, title, isEnabled } = useCreateAdvisorModal({
    firmId
  });

  return (
    <Button
      data-testid='create-advisor-modal-button'
      disabled={!isEnabled}
      onClick={() => showModal()}
      variant='contained'>
      {title}
    </Button>
  );
};

const CreatePooledPlanModal = (props: {
  onRefetch?: () => void;
  variant?: 'text' | 'outlined' | 'contained';
}): JSX.Element => {
  const { variant = 'contained' } = props;

  const { isEnabled, showModal } = usePooledPlanModal({
    onRefetch: props.onRefetch
  });

  return (
    <Button
      data-testid='create-pooled-plan-modal-button'
      disabled={!isEnabled}
      endIcon={<AddOutlinedIcon />}
      onClick={() => showModal()}
      variant={variant}>
      Create Pooled Plan
    </Button>
  );
};

const CreateEsaPlanModal = (props: {
  title?: string;
  variant?: 'text' | 'outlined' | 'contained';
}): JSX.Element => {
  const {
    isEnabled,
    showModal,
    title: modalTitle
  } = useEsaPlanModal({
    action: 'create'
  });

  return (
    <Button
      data-testid='create-esa-plan-modal-button'
      disabled={!isEnabled}
      onClick={() => showModal()}
      variant={props.variant || 'contained'}>
      {props.title || modalTitle}
    </Button>
  );
};

export {
  CreateAdvisorModal,
  CreatePlanModal,
  CreatePooledPlanModal,
  CreateEsaPlanModal,
  EditAdvisorModal,
  FirmModal,
  useFirmModal,
  usePlanModal,
  useEsaPlanModal
};
