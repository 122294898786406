import { Order } from '@/components/collapsible-table';
import ApiService from '@/services/Api.service';

export type ExternalProviderInvestmentOptionSearchData = {
  externalInvestmentOptionId: number;
  baseInvestmentOptionId: number;
  name: string;
  description?: string;
  createdAt?: string;
  programCount?: number;
};

export type ExternalProviderInvestmentOptionSearchQuery = {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  order: Order;
  modelType?: string;
  name?: string;
  id?: number;
};

export type ExternalProviderInvestmentOptionListDto = {
  data: ExternalProviderInvestmentOptionSearchData[];
  meta: { count: number };
};

class ExternalProviderInvestmentOptionService {
  static async getExternalProviderInvestmentOptionsPage(
    query: ExternalProviderInvestmentOptionSearchQuery
  ): Promise<ExternalProviderInvestmentOptionListDto> {
    return ApiService.getJson('/investments/epio', {
      id: query.id,
      modelType: query.modelType,
      name: query.name,
      pageNumber: query.pageNumber,
      pageSize: query.pageSize,
      sort: (query.order === 'asc' ? '' : '-').concat(query.orderBy)
    }) as unknown as ExternalProviderInvestmentOptionListDto;
  }

  static async getNliExternalProviderInvestmentOptions(): Promise<
    ExternalProviderInvestmentOptionSearchData[]
  > {
    return (
      await ExternalProviderInvestmentOptionService.getExternalProviderInvestmentOptionsPage(
        {
          modelType: 'nli',
          order: 'asc',
          orderBy: 'externalInvestmentOptionId',
          pageNumber: 1,
          pageSize: 10
        }
      )
    ).data;
  }
}

export { ExternalProviderInvestmentOptionService };
