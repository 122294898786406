import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { BreakageStatusColorMap } from '@/models/suba/recon/BreakageStatusColorMap.model';
import {
  ReconExceptionApiQueryServiceRequest,
  ReconExceptionDto
} from '@/models/suba/recon/ReconException.model';
import AuthZService from '@/services/AuthZ.service';
import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import { numericComparator } from '@/utils/Comparators';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, FormControl, Stack, SxProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';

type RelatedReconExceptionsProps = {
  currentExceptionId: number;
  sx?: SxProps;
  dataType: BreakageDataType;
  exceptionDate: string;
  parentAccountId: string;
  cusip: string;
};

export const RelatedReconExceptions = ({
  currentExceptionId,
  sx,
  dataType,
  exceptionDate,
  parentAccountId,
  cusip
}: RelatedReconExceptionsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [dateRange, setDateRange] = useState({
    endDate: dayjs(exceptionDate).add(7, 'days').format('YYYY-MM-DD'),
    startDate: dayjs(exceptionDate).subtract(7, 'days').format('YYYY-MM-DD')
  });

  const searchRelatedExceptionsQuery = useQuery(
    [
      'ReconExceptionService.searchRelated',
      dataType,
      exceptionDate,
      parentAccountId,
      cusip,
      dateRange.startDate,
      dateRange.endDate,
      page,
      pageSize
    ],
    () => {
      const query: ReconExceptionApiQueryServiceRequest = {
        cusip,
        dataType,
        endDate: dateRange.endDate,
        exceptionDate,
        page,
        pageSize,
        parentAccountId,
        startDate: dateRange.startDate
      };
      return ReconExceptionService.searchRelated(query);
    },
    {
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Related exception search failed: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  const getVestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    }
  );

  const columnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        return (
          <DataTableStackCell
            primary={String(cellData.data.id)}
            primaryLinkProps={{
              to: `/ops/recon-exceptions/${cellData.data.id}`
            }}
          />
        );
      },
      field: 'id',
      headerName: 'ID',
      minWidth: 75,
      pinned: 'left'
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        // fetch friendly display name for status
        const displayStatus = formatters.getValueKey(
          BreakageStatus,
          cellData.data.status
        );

        return (
          <DataTableBadgeCell
            color={BreakageStatusColorMap[cellData.data.status]}>
            {formatters.displayCase(displayStatus)}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        // fetch friendly display name for data type
        const displayDataType = formatters.getValueKey(
          BreakageDataType,
          cellData.data.dataType
        );
        // fetch friendly display name for process
        const displayProcess = formatters.getValueKey(
          BreakageProcess,
          cellData.data.process
        );
        return (
          <DataTableStackCell
            primary={`${formatters.displayCase(displayDataType)}`}
            secondary={`${formatters.displayCase(displayProcess)}`}
            wrap
          />
        );
      },
      field: 'dataType',
      headerName: 'Break Type / Break Process',
      minWidth: 255
    },
    {
      field: 'exceptionDate',
      headerName: 'Exception Date',
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY'),
      width: 165
    },
    {
      field: 'security.cusip',
      headerName: 'Cusip',
      minWidth: 125,
      sortable: true
    },
    {
      field: 'notes',
      headerName: 'Notes',
      maxWidth: 320,
      minWidth: 125,
      sortable: true,
      tooltipField: 'notes'
    },
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        if (!cellData.data.assignee) {
          return null;
        }
        const matchedAssignee = getVestwellStaffQuery.data?.find(
          staffUser => staffUser.userId === cellData.data.assignee
        );
        return (
          <DataTableStackCell
            primary={matchedAssignee?.label || cellData.data.assignee}
          />
        );
      },
      field: 'assignee',
      headerName: 'Assignee',
      maxWidth: 200,
      minWidth: 165
    },
    {
      comparator: numericComparator,
      field: 'parentValue',
      headerName: 'Parent Value',
      minWidth: 165,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'comparisonValue',
      headerName: 'Comparison Value',
      minWidth: 165,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'valueDifference',
      headerName: 'Value Difference',
      minWidth: 165,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'parentUnits',
      headerName: 'Parent Units',
      minWidth: 165,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'comparisonUnit',
      headerName: 'Comparison Units',
      minWidth: 165,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'unitDifference',
      headerName: 'Unit Difference',
      minWidth: 165,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    }
  ];

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleUpdateDateRange = (values: {
    startDate: string;
    endDate: string;
  }) => {
    const newDateRange = {
      endDate: values.endDate,
      startDate: values.startDate
    };
    setDateRange(newDateRange);
  };

  return (
    <Box sx={sx}>
      <Card data-testid='related-exceptions'>
        <Formik
          initialValues={{
            endDate: dateRange.endDate,
            startDate: dateRange.startDate
          }}
          onSubmit={handleUpdateDateRange}>
          {() => (
            <Form data-testid='related-exceptions-update-date-range'>
              <Box
                sx={{
                  borderBottom: 1,
                  borderBottomColor: 'grey.300',
                  display: 'flex',
                  gap: 2,
                  pb: 1,
                  pt: 2,
                  px: 2
                }}>
                <FormControl
                  sx={{
                    width: '160px'
                  }}>
                  <Field
                    as={DatePicker}
                    data-testid='related-exceptions-start-date-picker'
                    label='Start Date'
                    name='startDate'
                    size='small'
                    variant='outlined'
                  />
                </FormControl>
                <FormControl
                  sx={{
                    width: '160px'
                  }}>
                  <Field
                    as={DatePicker}
                    data-testid='related-exceptions-end-date-picker'
                    label='End Date'
                    name='endDate'
                    size='small'
                    variant='outlined'
                  />
                </FormControl>

                <FormControl
                  sx={{
                    pt: '2px'
                  }}>
                  <Button
                    data-testid='related-exceptions-update-date-range-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </FormControl>
              </Box>
            </Form>
          )}
        </Formik>

        <CardContent
          disablePadding
          loading={searchRelatedExceptionsQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs}
            columnSizing='fit'
            data-testid='data-related-exceptions-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-related-exceptions-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No related exceptions found.'
                />
              </Stack>
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={[5, 10, 25]}
            pagination
            paginationSource='server'
            paginationSx={{ py: 0.375 }}
            paginationTotal={
              searchRelatedExceptionsQuery.data?.pagination?.total
            }
            rowData={
              searchRelatedExceptionsQuery.data &&
              Array.isArray(searchRelatedExceptionsQuery.data?.results)
                ? searchRelatedExceptionsQuery.data.results.filter(
                    ({ id }) => id !== currentExceptionId
                  )
                : []
            }
          />
        </CardContent>
      </Card>
    </Box>
  );
};
