import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionCodes } from '@/hooks/suba/useTransactionCodes.hook';
import { HouseAccountDto } from '@/models/suba/house-accounts/HouseAccountDto.model';
import { SubAccountDto } from '@/models/suba/SubAccountDTO.model';
import { TransferCashPostRequest } from '@/models/suba/transfer-cash/transfer-cash-post-request.model';
import { ParentAccountService } from '@/services/suba/accounts/ParentAccount.service';
import { SubAccountService } from '@/services/suba/accounts/SubAccount.service';
import { BalanceService } from '@/services/suba/balances/Balance.service';
import { TransferCashService } from '@/services/suba/transfer-cash/TransferCash.service';
import formatters from '@/utils/Formatters';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  DrawerProps,
  FormControl,
  FormHelperText,
  Unstable_Grid2 as Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query';
import { OperationalSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/OperationalSubAccountList';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';
import {
  HouseSubAccountType,
  SubAccountType
} from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import { Field, Form, Formik, useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

type AccountType = 'house' | 'sub';

type AutocompleteOption = {
  accountType: AccountType; // used only for house -> house validation
  group: 'house' | 'ops' | 'plan' | 'sub';
  label: string;
  subAccountId: string;
  sublabel?: string;
};

type PreviousValues = {
  [key: string]: ValidPreviousValue | false;
};

type TransferCashFormData = {
  amount: string;
  depositCode: '' | TransactionTypeCode;
  fromSubAccountId: string;
  fromAccountType?: '' | AccountType;
  note?: string;
  toSubAccountId: string;
  toAccountType?: '' | AccountType;
  withdrawalCode: '' | TransactionTypeCode;
};

type ValidPreviousValue =
  | {
      accountType: SubAccountType;
      coreAccountId?: string;
      planId?: string;
      subAccountId: string;
    }
  | {
      opsAccountType: HouseSubAccountType;
      subAccountId: string;
    };

const accountIdSchema = yup
  .string()
  .required()
  .test(
    'same-account',
    'Please double-check the From and To accounts. They are the same.',
    (value, context) =>
      !(context.parent.fromSubAccountId === context.parent.toSubAccountId)
  );

const getAutocompleteValue = ({
  showPlanOpsSubAccountPredefinedOptions,
  subAccount
}: {
  showPlanOpsSubAccountPredefinedOptions?: boolean;
  subAccount: HouseAccountDto | SubAccountDto | ValidPreviousValue;
}): AutocompleteOption => {
  if ('opsAccountType' in subAccount) {
    return {
      accountType: 'house',
      group: 'house' as const,
      label: `VW House Account - ${formatters.displayCase(subAccount.opsAccountType)}`,
      subAccountId: subAccount.subAccountId
    };
  }

  if (
    'parentAccount' in subAccount &&
    subAccount.parentAccount?.accountType === ParentAccountType.House
  ) {
    return {
      accountType: 'house',
      group: 'house' as const,
      label: `VW House Account - ${formatters.displayCase(subAccount.accountType)}`,
      subAccountId: subAccount.subAccountId
    };
  }

  if (
    subAccount.planId &&
    OperationalSubAccountList.includes(subAccount.accountType)
  ) {
    return {
      accountType: 'sub',
      group: 'plan' as const,
      label: `Plan - ${formatters.displayCase(subAccount.accountType)}`,
      subAccountId: subAccount.subAccountId
    };
  }

  if (OperationalSubAccountList.includes(subAccount.accountType)) {
    return {
      accountType: 'sub',
      group: 'ops' as const,
      label: showPlanOpsSubAccountPredefinedOptions
        ? `Parent - ${formatters.displayCase(subAccount.accountType)}`
        : formatters.displayCase(subAccount.accountType),
      subAccountId: subAccount.subAccountId
    };
  }

  return {
    accountType: 'sub',
    group: 'sub' as const,
    label: subAccount.subAccountId, // matches user input, never offered as a menu option
    subAccountId: subAccount.subAccountId
  };
};

const TransferCashFormDataSchema = yup.object({
  amount: yup.string().required(),
  depositCode: yup.string().required(),
  fromAccountType: yup.string().required(),
  fromSubAccountId: accountIdSchema,
  note: yup.string().max(50, 'Must be 50 characters or less'),
  toAccountType: yup.string().required(),
  toSubAccountId: accountIdSchema,
  withdrawalCode: yup.string().required()
});

type AccountFieldsProps = {
  accountIdFieldName: 'fromSubAccountId' | 'toSubAccountId';
  accountTypeFieldName: 'fromAccountType' | 'toAccountType';
  autocompleteInputValue: string;
  autocompleteValue: null | AutocompleteOption;
  custodianHouseAccountsQuery: ReturnType<
    typeof useQuery<
      unknown,
      unknown,
      Awaited<ReturnType<typeof ParentAccountService.getHouseAccounts>>
    >
  >;
  houseOpsSubAccountsQuery: ReturnType<
    typeof useQuery<
      unknown,
      unknown,
      Awaited<ReturnType<typeof SubAccountService.searchSubAccounts>>
    >
  >;
  parentAccountId: string;
  parentAccountType: ParentAccountType;
  planId: string;
  planOpsSubAccountsQuery: ReturnType<
    typeof useQuery<
      unknown,
      unknown,
      Awaited<ReturnType<typeof SubAccountService.searchSubAccounts>>
    >
  >;
  previousValues: PreviousValues;
  setAutocompleteInputValue: ReturnType<typeof useState<string>>[1];
  setAutocompleteValue: ReturnType<
    typeof useState<null | AutocompleteOption>
  >[1];
  showCustodianHouseOpsSubAccountPredefinedOptions: boolean;
  showHouseOpsSubAccountPredefinedOptions: boolean;
  showPlanOpsSubAccountPredefinedOptions: boolean;
  showSuperOmnibusOpsSubAccountPredefinedOptions: boolean;
  /** Pre-selected sub-account */
  subAccount?: SubAccountDto;
  setPreviousValues: ReturnType<typeof useState<PreviousValues>>[1];
  superOmnibusOpsSubAccountsQuery: ReturnType<
    typeof useQuery<
      unknown,
      unknown,
      Awaited<ReturnType<typeof SubAccountService.searchSubAccounts>>
    >
  >;
};

const AccountFields: FC<AccountFieldsProps> = props => {
  const formikContext = useFormikContext<Partial<TransferCashFormData>>();
  const snackbar = useSnackbar();
  const [isAutocompleteMenuOpen, setIsAutocompleteMenuOpen] = useState(false);

  const predefinedOptions = useMemo(
    () =>
      [
        // queries may have cached data even when disabled so we must condition access
        ...((props.showCustodianHouseOpsSubAccountPredefinedOptions &&
          props.custodianHouseAccountsQuery.data) ||
          []),
        ...((props.showHouseOpsSubAccountPredefinedOptions &&
          props.houseOpsSubAccountsQuery.data?.results) ||
          []),
        ...((props.showSuperOmnibusOpsSubAccountPredefinedOptions &&
          props.superOmnibusOpsSubAccountsQuery.data?.results) ||
          []),
        ...((props.showPlanOpsSubAccountPredefinedOptions &&
          props.planOpsSubAccountsQuery.data?.results) ||
          [])
      ].map(subAccount =>
        getAutocompleteValue({
          showPlanOpsSubAccountPredefinedOptions:
            props.showPlanOpsSubAccountPredefinedOptions,
          subAccount
        })
      ),
    [
      props.custodianHouseAccountsQuery.data,
      props.houseOpsSubAccountsQuery.data,
      props.planOpsSubAccountsQuery.data,
      props.showCustodianHouseOpsSubAccountPredefinedOptions,
      props.showPlanOpsSubAccountPredefinedOptions,
      props.showSuperOmnibusOpsSubAccountPredefinedOptions,
      props.superOmnibusOpsSubAccountsQuery.data
    ]
  );

  // used to distinguish raw user values from those in the Autocomplete menu
  const predefinedOptionLabels = useMemo(
    () => predefinedOptions.map(option => option.label),
    [predefinedOptions]
  );

  useEffect(() => {
    // initialize autocomplete value if given pre-selected subAccount
    if (
      !props.subAccount ||
      props.accountIdFieldName !== 'fromSubAccountId' ||
      (typeof props.autocompleteValue !== 'string' &&
        props.subAccount?.subAccountId ===
          props.autocompleteValue?.subAccountId)
    )
      return;

    props.setAutocompleteValue(
      getAutocompleteValue({
        subAccount: props.subAccount
      })
    );
    props.setPreviousValues(prevState => ({
      ...prevState,
      [props.subAccount.subAccountId]: {
        accountType:
          'opsAccountType' in props.subAccount
            ? (props.subAccount.opsAccountType as unknown as SubAccountType)
            : props.subAccount.accountType,
        subAccountId: props.subAccount.subAccountId
      }
    }));
  }, [props.subAccount]);

  const validateAndSetAutocompleteValue = useCallback(
    async (autocompleteInputValue: string) => {
      let isValid = !!props.previousValues[autocompleteInputValue];
      let subAccounts:
        | Awaited<ReturnType<typeof SubAccountService.searchSubAccounts>>
        | undefined;

      if (props.previousValues[autocompleteInputValue] === undefined) {
        try {
          subAccounts = await SubAccountService.searchSubAccounts({
            disableParticipantSearch: true,
            parentAccountId: props.parentAccountId,
            query: autocompleteInputValue
          });

          if (subAccounts.pagination.total === 1) isValid = true;
        } catch (err) {
          snackbar.showSnackbar({
            message: `Failed to verify sub account: ${err.message}`,
            severity: 'error'
          });
        }

        if (isValid && subAccounts?.results.length) {
          props.setPreviousValues(prevState => ({
            ...prevState,
            [autocompleteInputValue]: {
              accountType: subAccounts.results[0].accountType,
              coreAccountId: subAccounts.results[0].coreAccountId,
              planId: subAccounts.results[0].planId,
              subAccountId: subAccounts.results[0].subAccountId
            }
          }));
        } else {
          snackbar.showSnackbar({
            message: 'No matching sub accounts found',
            severity: 'warning'
          });
        }
      }

      if (!isValid) return;

      if (props.autocompleteValue?.subAccountId !== autocompleteInputValue) {
        props.setAutocompleteValue({
          ...getAutocompleteValue({
            subAccount:
              subAccounts?.results?.[0] ||
              // fallback used for previously validated inputs
              (props.previousValues[
                autocompleteInputValue
              ] as ValidPreviousValue)
          }),
          label: autocompleteInputValue // retain user input of validated ID
        });
      }
    },
    [
      props.accountIdFieldName,
      props.accountTypeFieldName,
      props.parentAccountId,
      props.previousValues
    ]
  );

  useEffect(() => {
    if (
      !props.autocompleteValue ||
      formikContext.values[props.accountIdFieldName] ===
        props.autocompleteValue.subAccountId
    )
      return;

    formikContext.setValues(values => ({
      ...values,
      [props.accountIdFieldName]: props.autocompleteValue.subAccountId,
      [props.accountTypeFieldName]: props.autocompleteValue.accountType
    }));
  }, [
    formikContext.values,
    props.accountIdFieldName,
    props.accountTypeFieldName,
    props.autocompleteValue
  ]);

  const isAutocompleteDisabled = useMemo(() => {
    if (
      formikContext.values.fromSubAccountId ===
        props.subAccount?.subAccountId &&
      formikContext.values.toSubAccountId === props.subAccount?.subAccountId
    ) {
      // keep 'to' account editable for user to fix scenario where pre-selected account is the same as what was entered
      return props.accountIdFieldName === 'fromSubAccountId';
    }

    if (
      // pre-selected 'from' account
      formikContext.values.fromSubAccountId ===
        props.subAccount?.subAccountId &&
      props.accountIdFieldName === 'fromSubAccountId'
    ) {
      return true;
    }

    if (
      // pre-selected 'to' account
      formikContext.values.toSubAccountId === props.subAccount?.subAccountId &&
      props.accountIdFieldName === 'toSubAccountId'
    ) {
      return true;
    }

    return false;
  }, [formikContext.values, props.accountIdFieldName, props.subAccount]);

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete<AutocompleteOption, false, false, true>
          PaperComponent={
            props.autocompleteInputValue === '' &&
            (props.custodianHouseAccountsQuery.data ||
              props.planOpsSubAccountsQuery.data ||
              props.superOmnibusOpsSubAccountsQuery.data)
              ? ({ children }) => <Paper>{children}</Paper>
              : Paper
          }
          autoSelect
          disabled={isAutocompleteDisabled}
          filterOptions={o => o} // noop per docs for async requests
          freeSolo
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.label
          }
          groupBy={option => option.group}
          handleHomeEndKeys
          inputValue={props.autocompleteInputValue}
          loading={
            props.custodianHouseAccountsQuery?.isFetching ||
            props.planOpsSubAccountsQuery?.isFetching ||
            props.superOmnibusOpsSubAccountsQuery?.isFetching
          }
          onChange={async (event, value, reason) => {
            if (
              (reason === 'blur' || reason === 'createOption') &&
              typeof value === 'string'
            ) {
              if (predefinedOptionLabels.includes(value)) {
                props.setAutocompleteValue(
                  predefinedOptions.find(option => option.label === value)
                );
              } else {
                validateAndSetAutocompleteValue(value);
              }
            } else if (reason === 'selectOption' && typeof value !== 'string') {
              props.setAutocompleteValue(value);
            } else if (reason === 'clear') {
              props.setAutocompleteValue(null);
              setIsAutocompleteMenuOpen(false);
            }
          }}
          onClose={() => setIsAutocompleteMenuOpen(false)}
          onInputChange={(event, value, reason) => {
            props.setAutocompleteInputValue(value);
            if (reason === 'reset') return;
            formikContext.setValues({
              ...formikContext.values,
              [props.accountTypeFieldName]: '',
              [props.accountIdFieldName]: ''
            });
          }}
          onOpen={() => {
            setIsAutocompleteMenuOpen(true);
          }}
          open={isAutocompleteMenuOpen}
          options={props.autocompleteInputValue === '' ? predefinedOptions : []}
          renderGroup={params => (
            <Stack component='ul' key={params.key} mb={1} p={0} spacing={1}>
              {params.children}
              {params.group !== predefinedOptions.slice(-1)[0].group && (
                <Divider />
              )}
            </Stack>
          )}
          renderInput={inputProps => (
            <TextField
              {...inputProps}
              aria-label={
                props.accountIdFieldName === 'fromSubAccountId'
                  ? 'From Sub Account'
                  : 'To Sub Account'
              }
              inputProps={{
                ...inputProps.inputProps,
                'data-testid':
                  props.accountIdFieldName === 'fromSubAccountId'
                    ? `from-sub-account-autocomplete-input`
                    : `to-sub-account-autocomplete-input`
              }}
              label='Sub Account'
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.subAccountId}>
              <Stack spacing={0.5}>
                <Typography variant='body1'>{option.label}</Typography>
              </Stack>
            </li>
          )}
          selectOnFocus
          size='small'
          value={props.autocompleteValue}
        />
      </FormControl>
    </>
  );
};

const AccountPlaceholder: FC = () => (
  <Paper
    sx={{
      backgroundColor: theme => theme.palette.grey[50],
      padding: 2
    }}
    variant='outlined'>
    <Stack spacing={1}>
      <Typography variant='caption'>—</Typography>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Confirmed Balance</TextLabel>
          <TextValue>—</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Pending Balance</TextLabel>
          <TextValue>—</TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  </Paper>
);

type TransferCardProps = {
  parentAccountId: string;
  planId: string;
  subAccount?: SubAccountDto;
};

const TransferCard: FC<TransferCardProps> = props => {
  const formikContext = useFormikContext<TransferCashFormData>();
  const [fromAutocompleteInputValue, setFromAutocompleteInputValue] =
    useState('');
  const [toAutocompleteInputValue, setToAutocompleteInputValue] = useState('');
  const [fromAutocompleteValue, setFromAutocompleteValue] =
    useState<AutocompleteOption>(null);
  const [toAutocompleteValue, setToAutocompleteValue] =
    useState<AutocompleteOption>(null);
  const [previousValues, setPreviousValues] = useState<PreviousValues>({});

  // pre-defined option queries

  const parentAccountQuery = useQuery(
    ['ParentAccountService.getById', props.parentAccountId],
    () => ParentAccountService.getById(props.parentAccountId),
    {
      enabled: !!props.parentAccountId
    }
  );

  const showCustodianHouseOpsSubAccountPredefinedOptions =
    parentAccountQuery.data?.accountType &&
    parentAccountQuery.data.accountType !== ParentAccountType.House;

  const showHouseOpsSubAccountPredefinedOptions =
    parentAccountQuery.data?.accountType &&
    parentAccountQuery.data.accountType === ParentAccountType.House;

  const showPlanOpsSubAccountPredefinedOptions =
    parentAccountQuery.data?.accountType === ParentAccountType.PlanLevel ||
    (parentAccountQuery.data?.accountType === ParentAccountType.SuperOmnibus &&
      !!props.planId);

  const showSuperOmnibusOpsSubAccountPredefinedOptions =
    parentAccountQuery.data?.accountType === ParentAccountType.SuperOmnibus;

  const custodianHouseAccountsQuery = useQuery(
    ['ParentAccountService.getHouseAccounts', props.parentAccountId],
    () => ParentAccountService.getHouseAccounts(props.parentAccountId),
    {
      enabled: showCustodianHouseOpsSubAccountPredefinedOptions
    }
  );

  const houseOpsSubAccountsQuery = useQuery(
    [
      'SubAccountService.searchSubAccounts',
      props.parentAccountId,
      {
        accountType: OperationalSubAccountList,
        parentAccountId: props.parentAccountId
      }
    ],
    () =>
      SubAccountService.searchSubAccounts({
        accountType: OperationalSubAccountList,
        parentAccountId: props.parentAccountId
      }),
    {
      enabled: showHouseOpsSubAccountPredefinedOptions
    }
  );

  const planOpsSubAccountsQuery = useQuery(
    [
      'SubAccountService.searchSubAccounts',
      props.parentAccountId,
      {
        accountType: OperationalSubAccountList,
        hasPlanId: true,
        parentAccountId: props.parentAccountId,
        planId: props.planId
      }
    ],
    () =>
      SubAccountService.searchSubAccounts({
        accountType: OperationalSubAccountList,
        hasPlanId: true,
        parentAccountId: props.parentAccountId,
        planId: props.planId
      }),
    {
      enabled: parentAccountQuery.data && showPlanOpsSubAccountPredefinedOptions
    }
  );

  const superOmnibusOpsSubAccountsQuery = useQuery(
    [
      'SubAccountService.searchSubAccounts',
      props.parentAccountId,
      {
        accountType: OperationalSubAccountList,
        hasPlanId: false,
        parentAccountId: props.parentAccountId
      }
    ],
    () =>
      SubAccountService.searchSubAccounts({
        accountType: OperationalSubAccountList,
        hasPlanId: false,
        parentAccountId: props.parentAccountId
      }),
    {
      enabled:
        parentAccountQuery.data?.accountType === ParentAccountType.SuperOmnibus
    }
  );

  useEffect(() => {
    if (
      custodianHouseAccountsQuery?.isInitialLoading ||
      houseOpsSubAccountsQuery?.isInitialLoading ||
      planOpsSubAccountsQuery?.isInitialLoading ||
      superOmnibusOpsSubAccountsQuery?.isInitialLoading
    )
      return;

    const previousValues: PreviousValues = {};

    for (const subAccount of custodianHouseAccountsQuery.data || []) {
      previousValues[subAccount.subAccountId] = {
        accountType: subAccount.opsAccountType as unknown as SubAccountType,
        subAccountId: subAccount.subAccountId
      };
    }

    for (const subAccount of [
      ...(houseOpsSubAccountsQuery.data?.results || []),
      ...(planOpsSubAccountsQuery.data?.results || []),
      ...(superOmnibusOpsSubAccountsQuery.data?.results || [])
    ]) {
      previousValues[subAccount.subAccountId] = {
        accountType: subAccount.accountType,
        subAccountId: subAccount.subAccountId
      };
    }

    setPreviousValues(prevState => ({
      ...prevState,
      ...previousValues
    }));
  }, [
    custodianHouseAccountsQuery.data,
    houseOpsSubAccountsQuery.data,
    planOpsSubAccountsQuery.data,
    superOmnibusOpsSubAccountsQuery.data
  ]);

  // sub account balance queries

  const fromSubAccountBalancesQuery = useQuery(
    ['BalancesService.get', formikContext.values.fromSubAccountId],
    () => BalanceService.get(formikContext.values.fromSubAccountId),
    {
      enabled: !!previousValues[formikContext.values.fromSubAccountId]
    }
  );

  const toSubAccountBalancesQuery = useQuery(
    ['BalancesService.get', formikContext.values.toSubAccountId],
    () => BalanceService.get(formikContext.values.toSubAccountId),
    {
      enabled: !!previousValues[formikContext.values.toSubAccountId]
    }
  );

  useEffect(() => {
    formikContext.validateForm();
  }, [fromSubAccountBalancesQuery.data, toSubAccountBalancesQuery.data]);

  // transaction type code queries

  const incomingTransactionTypeCodesQuery = useTransactionCodes({
    transactionBaseType: [
      TransactionBaseType.Deposit,
      TransactionBaseType.IncomeIn
    ]
  });

  const outgoingTransactionTypeCodesQuery = useTransactionCodes({
    transactionBaseType: [
      TransactionBaseType.IncomeOut,
      TransactionBaseType.Withdrawal
    ]
  });

  // callbacks

  const handleSwapAccounts = async () => {
    setFromAutocompleteInputValue(toAutocompleteInputValue);
    setFromAutocompleteValue(toAutocompleteValue);
    setToAutocompleteInputValue(fromAutocompleteInputValue);
    setToAutocompleteValue(fromAutocompleteValue);

    await formikContext.setValues({
      ...formikContext.values,
      fromAccountType: formikContext.values.toAccountType,
      fromSubAccountId: formikContext.values.toSubAccountId,
      toAccountType: formikContext.values.fromAccountType,
      toSubAccountId: formikContext.values.fromSubAccountId
    });
  };

  return (
    <Card>
      <Grid container padding={2} spacing={2}>
        <Grid xs>
          <Stack spacing={2}>
            <Typography variant='subtitle1'>From</Typography>
            <AccountFields
              accountIdFieldName='fromSubAccountId'
              accountTypeFieldName='fromAccountType'
              autocompleteInputValue={fromAutocompleteInputValue}
              autocompleteValue={fromAutocompleteValue}
              custodianHouseAccountsQuery={custodianHouseAccountsQuery}
              houseOpsSubAccountsQuery={houseOpsSubAccountsQuery}
              parentAccountId={props.parentAccountId}
              parentAccountType={parentAccountQuery.data?.accountType}
              planId={props.planId}
              planOpsSubAccountsQuery={planOpsSubAccountsQuery}
              previousValues={previousValues}
              setAutocompleteInputValue={setFromAutocompleteInputValue}
              setAutocompleteValue={setFromAutocompleteValue}
              setPreviousValues={setPreviousValues}
              showCustodianHouseOpsSubAccountPredefinedOptions={
                showCustodianHouseOpsSubAccountPredefinedOptions
              }
              showHouseOpsSubAccountPredefinedOptions={
                showHouseOpsSubAccountPredefinedOptions
              }
              showPlanOpsSubAccountPredefinedOptions={
                showPlanOpsSubAccountPredefinedOptions
              }
              showSuperOmnibusOpsSubAccountPredefinedOptions={
                showSuperOmnibusOpsSubAccountPredefinedOptions
              }
              subAccount={props.subAccount}
              superOmnibusOpsSubAccountsQuery={superOmnibusOpsSubAccountsQuery}
            />
            {formikContext.values.fromSubAccountId ? (
              <Paper
                sx={{
                  backgroundColor: theme => theme.palette.primary.light,
                  padding: 2
                }}
                variant='outlined'>
                <Stack spacing={1}>
                  <Typography
                    data-testid='from-sub-account-id'
                    variant='caption'>
                    ID: {formikContext.values.fromSubAccountId}
                  </Typography>
                  <TextStack direction='column' spacing={1}>
                    <TextStackItem>
                      <TextLabel>Confirmed Balance</TextLabel>
                      <TextValue data-testid='from-sub-account-confirmed-balance'>
                        <Stack direction='row' spacing={1.5}>
                          {fromSubAccountBalancesQuery.data?.cash.confirmed !==
                          undefined
                            ? formatters.formatDollars(
                                fromSubAccountBalancesQuery.data?.cash.confirmed
                              )
                            : '—'}
                          {typeof fromAutocompleteValue !== 'string' &&
                            fromAutocompleteValue?.group !== 'house' &&
                            fromSubAccountBalancesQuery.data?.cash.confirmed &&
                            parseFloat(
                              fromSubAccountBalancesQuery.data.cash.confirmed
                            ) > 0 &&
                            formikContext.values.amount !==
                              fromSubAccountBalancesQuery.data.cash
                                .confirmed && (
                              <SvgIcon
                                color='primary'
                                cursor='pointer'
                                data-testid='use-from-sub-account-confirmed-balance-button'
                                onClick={() =>
                                  formikContext.setFieldValue(
                                    'amount',
                                    fromSubAccountBalancesQuery.data?.cash
                                      .confirmed
                                  )
                                }>
                                <DownloadDoneOutlinedIcon />
                              </SvgIcon>
                            )}
                        </Stack>
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Pending Balance</TextLabel>
                      <TextValue data-testid='from-sub-account-pending-balance'>
                        {fromSubAccountBalancesQuery.data?.cash.pending
                          ? formatters.formatDollars(
                              fromSubAccountBalancesQuery.data?.cash.pending
                            )
                          : '—'}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </Stack>
              </Paper>
            ) : (
              <AccountPlaceholder />
            )}
            <FormControl
              error={
                formikContext.touched.withdrawalCode &&
                Boolean(formikContext.errors.withdrawalCode)
              }
              fullWidth
              size='small'>
              <InputLabel htmlFor='outgoing-transaction-type'>
                Outgoing Transaction Type
              </InputLabel>
              <Field
                SelectDisplayProps={{
                  'data-testid': 'outgoing-transaction-type-select'
                }}
                as={Select}
                id='outgoing-transaction-type'
                label='Outgoing Transaction Type'
                labelId='outgoing-transaction-type-select-label'
                name='withdrawalCode'>
                {(outgoingTransactionTypeCodesQuery.data || []).map(item => (
                  <MenuItem
                    key={item.transactionTypeCode}
                    value={item.transactionTypeCode}>
                    {formatters.displayCase(item.transactionTypeCode)}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                {formikContext.touched.withdrawalCode &&
                  formikContext.errors.withdrawalCode}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid
          alignItems='center'
          display='flex'
          flexDirection='column'
          justifyContent='stretch'
          xs={0.5}>
          <Divider
            orientation='vertical'
            sx={{ flexGrow: 1, height: 'auto' }}
          />
          <IconButton
            color='primary'
            data-testid='swap-from-to-button'
            onClick={handleSwapAccounts}>
            <SwapHorizontalCircleOutlinedIcon />
          </IconButton>
          <Divider
            orientation='vertical'
            sx={{ flexGrow: 1, height: 'auto' }}
          />
        </Grid>
        <Grid xs>
          <Stack spacing={2}>
            <Typography variant='subtitle1'>To</Typography>
            <AccountFields
              accountIdFieldName='toSubAccountId'
              accountTypeFieldName='toAccountType'
              autocompleteInputValue={toAutocompleteInputValue}
              autocompleteValue={toAutocompleteValue}
              custodianHouseAccountsQuery={custodianHouseAccountsQuery}
              houseOpsSubAccountsQuery={houseOpsSubAccountsQuery}
              parentAccountId={props.parentAccountId}
              parentAccountType={parentAccountQuery.data?.accountType}
              planId={props.planId}
              planOpsSubAccountsQuery={planOpsSubAccountsQuery}
              previousValues={previousValues}
              setAutocompleteInputValue={setToAutocompleteInputValue}
              setAutocompleteValue={setToAutocompleteValue}
              setPreviousValues={setPreviousValues}
              showCustodianHouseOpsSubAccountPredefinedOptions={
                showCustodianHouseOpsSubAccountPredefinedOptions
              }
              showHouseOpsSubAccountPredefinedOptions={
                showHouseOpsSubAccountPredefinedOptions
              }
              showPlanOpsSubAccountPredefinedOptions={
                showPlanOpsSubAccountPredefinedOptions
              }
              showSuperOmnibusOpsSubAccountPredefinedOptions={
                showSuperOmnibusOpsSubAccountPredefinedOptions
              }
              subAccount={props.subAccount}
              superOmnibusOpsSubAccountsQuery={superOmnibusOpsSubAccountsQuery}
            />
            {formikContext.values.toSubAccountId ? (
              <Paper
                sx={{
                  backgroundColor: theme => theme.palette.primary.light,
                  padding: 2
                }}
                variant='outlined'>
                <Stack spacing={1}>
                  <Typography data-testid='to-sub-account-id' variant='caption'>
                    ID: {formikContext.values.toSubAccountId}
                  </Typography>
                  <TextStack direction='column' spacing={1}>
                    <TextStackItem>
                      <TextLabel>Confirmed Balance</TextLabel>
                      <TextValue data-testid='to-sub-account-confirmed-balance'>
                        <Stack direction='row' spacing={2}>
                          {toSubAccountBalancesQuery.data?.cash.confirmed !==
                          undefined
                            ? formatters.formatDollars(
                                toSubAccountBalancesQuery.data?.cash.confirmed
                              )
                            : '—'}
                          {typeof toAutocompleteValue !== 'string' &&
                            toAutocompleteValue?.group !== 'house' &&
                            toSubAccountBalancesQuery.data?.cash.confirmed &&
                            parseFloat(
                              toSubAccountBalancesQuery.data.cash.confirmed
                            ) > 0 &&
                            formikContext.values.amount !==
                              toSubAccountBalancesQuery.data.cash.confirmed && (
                              <SvgIcon
                                color='primary'
                                cursor='pointer'
                                data-testid='use-to-sub-account-confirmed-balance-button'
                                onClick={() =>
                                  formikContext.setFieldValue(
                                    'amount',
                                    toSubAccountBalancesQuery.data?.cash
                                      .confirmed
                                  )
                                }>
                                <DownloadDoneOutlinedIcon />
                              </SvgIcon>
                            )}
                        </Stack>
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Pending Balance</TextLabel>
                      <TextValue data-testid='to-sub-account-pending-balance'>
                        {toSubAccountBalancesQuery.data?.cash.pending
                          ? formatters.formatDollars(
                              toSubAccountBalancesQuery.data?.cash.pending
                            )
                          : '—'}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </Stack>
              </Paper>
            ) : (
              <AccountPlaceholder />
            )}
            <FormControl
              error={
                formikContext.touched.depositCode &&
                Boolean(formikContext.errors.depositCode)
              }
              fullWidth
              size='small'>
              <InputLabel htmlFor='incoming-transaction-type'>
                Incoming Transaction Type
              </InputLabel>
              <Field
                SelectDisplayProps={{
                  'data-testid': 'incoming-transaction-type-select'
                }}
                as={Select}
                id='incoming-transaction-type'
                label='Incoming Transaction Type'
                labelId='incoming-transaction-type-select-label'
                name='depositCode'>
                {(incomingTransactionTypeCodesQuery.data || []).map(item => (
                  <MenuItem
                    key={item.transactionTypeCode}
                    value={item.transactionTypeCode}>
                    {formatters.displayCase(item.transactionTypeCode)}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                {formikContext.touched.depositCode &&
                  formikContext.errors.depositCode}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography variant='subtitle1'>Transfer Amount</Typography>
        </Grid>
        <Grid xs={4}>
          <FormControl
            error={
              formikContext.touched.amount &&
              Boolean(formikContext.errors.amount)
            }
            fullWidth
            size='small'>
            <InputLabel htmlFor='amount-input'>Amount</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              id='transfer-amount-input'
              inputProps={{
                'data-testid': 'transfer-amount-input'
              }}
              label='Amount'
              name='amount'
              startAdornment={
                <InputAdornment position='start'>$</InputAdornment>
              }
              type='number'
              validate={value =>
                (fromSubAccountBalancesQuery.data?.cash?.confirmed || 0) < value
                  ? 'Amount must be less than or equal to selected source account’s confirmed balance'
                  : undefined
              }
            />
            <FormHelperText>
              {formikContext.touched.amount && formikContext.errors.amount}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={8}>
          <FormControl
            error={
              formikContext.touched.note && Boolean(formikContext.errors.note)
            }
            fullWidth
            size='small'>
            <InputLabel htmlFor='note-input'>Note</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              id='note-input'
              inputProps={{
                'data-testid': 'note-input'
              }}
              label='Note'
              name='note'
            />
            <FormHelperText>
              {formikContext.touched.note && formikContext.errors.note}
            </FormHelperText>
          </FormControl>
        </Grid>
        {formikContext.values.toSubAccountId &&
          formikContext.values.toAccountType &&
          (formikContext.errors.toSubAccountId ||
            formikContext.errors.toAccountType) && (
            <Grid xs={12}>
              <Alert
                data-testid='transfer-cash-drawer-validation-alert'
                severity='error'>
                {formikContext.errors.toSubAccountId ||
                  formikContext.errors.toAccountType}
              </Alert>
            </Grid>
          )}
      </Grid>
    </Card>
  );
};

type TransferCashDrawerProps = DrawerProps & {
  onClose: () => void;
  onSubmit: (transferRequest: TransferCashPostRequest) => void;
  parentAccountId: string;
  planId: string;
  subAccount?: SubAccountDto;
};

export const TransferCashDrawer: FC<TransferCashDrawerProps> = ({
  onSubmit,
  parentAccountId,
  planId,
  subAccount,
  ...drawerProps
}) => {
  const navigate = useNavigate();
  const isFetchingBalances = useIsFetching(['BalancesService.get']);
  const isFetchingHouseAccounts = useIsFetching([
    'ParentAccountService.getHouseAccounts'
  ]);
  const isFetchingSubAccounts = useIsFetching([
    'SubAccountService.searchSubAccounts',
    parentAccountId
  ]);
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const preselectedSubAccount = useMemo(() => {
    if (!subAccount) return undefined;
    return getAutocompleteValue({ subAccount });
  }, [subAccount]);

  const handleSubmit = async (formData: TransferCashFormData) => {
    if (formData.depositCode === '' || formData.withdrawalCode === '') {
      return;
    }

    try {
      const transferRequest = {
        amount: (+formData.amount).toFixed(2),
        comment: formData.note,
        depositCode: formData.depositCode,
        fromId: formData.fromSubAccountId,
        fromType: 'sub:subAccountId',
        toId: formData.toSubAccountId,
        toType: 'sub:subAccountId',
        withdrawalCode: formData.withdrawalCode
      };

      await TransferCashService.post(transferRequest);

      const workflowSearchUrl = `/ops/accounts/${parentAccountId}/conductor?query=${encodeURIComponent(
        JSON.stringify({
          workflowNameTradeCalculatorMethodPairings: [
            { workflowName: 'cashTransferRequest' }
          ]
        })
      )}`;

      queryClient.invalidateQueries([
        'WorkflowService.search',
        parentAccountId
      ]);

      snackbar.showSnackbar({
        action: (
          <Button
            color='inherit'
            endIcon={<ArrowForwardIcon />}
            href={workflowSearchUrl}
            onClick={() => navigate(workflowSearchUrl)}
            size='small'>
            View Transfer
          </Button>
        ),
        message: 'Cash transfer submitted',
        severity: 'success'
      });

      if (typeof onSubmit === 'function') onSubmit(transferRequest);
    } catch (err) {
      snackbar.showSnackbar({
        message: `Failed to submit cash transfer with error: ${err.message}`,
        severity: 'error'
      });
    }
  };

  return (
    <Formik
      initialValues={{
        amount: '',
        depositCode: '',
        fromAccountType: preselectedSubAccount?.accountType || '',
        fromSubAccountId: preselectedSubAccount?.subAccountId || '',
        note: '',
        toAccountType: '',
        toSubAccountId: '',
        tracerId: '',
        withdrawalCode: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={TransferCashFormDataSchema}>
      {formikBag => (
        <Drawer
          PaperProps={{
            sx: {
              width: 720
            }
          }}
          anchor='right'
          data-testid='transfer-cash-drawer'
          open={drawerProps.open}
          sx={{
            // note: unable to add to theme due to conflict with other drawers and https://github.com/mui/material-ui/issues/36300
            zIndex: theme => theme.zIndex.vestwellAppBar + 1
          }}
          {...drawerProps}>
          {(isFetchingBalances ||
            isFetchingHouseAccounts ||
            isFetchingSubAccounts) > 0 && (
            <LinearProgress
              sx={{ left: 0, position: 'absolute', right: 0, top: 0 }}
            />
          )}
          <Form>
            <Stack justifyContent='space-between' minHeight='100vh'>
              <Typography padding={2} variant='h6'>
                Transfer Cash
              </Typography>
              <Box
                bgcolor='grey.50'
                borderBottom={theme => `1px solid ${theme.palette.grey[300]}`}
                borderTop={theme => `1px solid ${theme.palette.grey[300]}`}
                flexGrow={1}
                minWidth={720}
                p={2}>
                <TransferCard
                  parentAccountId={parentAccountId}
                  planId={planId}
                  subAccount={subAccount}
                />
              </Box>
              <Box>
                <Stack
                  direction='row'
                  justifyContent='right'
                  paddingX={3}
                  paddingY={2}
                  spacing={2}>
                  <Button onClick={() => drawerProps.onClose()} variant='text'>
                    Cancel
                  </Button>
                  <LoadingButton
                    data-testid='confirm-transfer-button'
                    disabled={!formikBag.dirty || !formikBag.isValid}
                    loading={formikBag.isSubmitting}
                    onClick={() => formikBag.handleSubmit()} // intentionally not a submit button to avoid unintentional form submission on enter key
                    variant='contained'>
                    Confirm & Transfer
                  </LoadingButton>
                </Stack>
              </Box>
            </Stack>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
};
