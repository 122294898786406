import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionCodes } from '@/hooks/suba/useTransactionCodes.hook';
import { BalanceTotalsDto } from '@/models/suba/balance-totals/BalanceTotalsDTO.model';
import { InitiateDepositRequest } from '@/models/suba/workflows/InitiateDepositRequest.model';
import { TransactionAccountSummary } from '@/routes/suba/common/components/TransactionAccountSummary.component';
import { ApiServiceError } from '@/services/Api.service';
import { PlanService } from '@/services/Plan.service';
import BalanceTotalsService from '@/services/suba/balance-totals/BalanceTotals.service';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import formatters from '@/utils/Formatters';
import {
  testDecimals,
  testYear
} from '@/utils/validations/helpers/YupValidationFunctions';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Stack
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Payroll } from '@vestwell-api/scala';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SourceOfFunds } from '@vestwell-sub-accounting/models/common/SourceOfFunds';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';

export type CreateAchRequestFormValues = {
  accountNumber: string;
  amount: string;
  contributionYear: string;
  depositCode: TransactionTypeCode;
  forfeitureAmount: string;
  paygroupId: string;
  routingNumber: string;
  sourceOfFunds: SourceOfFunds;
  spendingBudgetAmount: string;
  toSubAccount: string;
};

type CreateAchRequestDialogProps = DialogProps & {
  accountId: string;
  initialValues?: Partial<CreateAchRequestFormValues>;
  onClose: () => void;
  open: boolean;
  parentAccountId?: string;
  planId: string;
};

yup.setLocale({
  mixed: {
    required: 'Required'
  }
});

const DepositCodeFieldGroup = () => {
  const { errors, touched } = useFormikContext<CreateAchRequestFormValues>();

  const getTransactionCodesQuery = useTransactionCodes({
    transactionBaseType: [
      TransactionBaseType.Deposit,
      TransactionBaseType.IncomeIn
    ]
  });

  return (
    <Stack direction='row' maxWidth={632} spacing={2}>
      <FormControl
        error={touched.toSubAccount && Boolean(errors.toSubAccount)}
        size='small'
        sx={{
          minWidth: 200
        }}>
        <InputLabel htmlFor='to-sub-account'>To Sub Account</InputLabel>
        <Field
          as={OutlinedInput}
          data-test-id='create-ach-request-dialog-to-sub-account-input'
          disabled
          id='to-sub-account'
          label='To Sub Account'
          name='toSubAccount'
        />
      </FormControl>

      {getTransactionCodesQuery.isFetching ? (
        <Skeleton height={40} variant='rectangular' width={420} />
      ) : (
        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel htmlFor='deposit-code-menu-label'>
            Deposit Code
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-ach-request-depositCode'
            }}
            as={Select}
            error={touched.depositCode && Boolean(errors.depositCode)}
            label='Deposit Code'
            labelId='deposit-code-menu-label'
            name='depositCode'>
            {getTransactionCodesQuery.data.map(
              ({ transactionTypeCode, label }) => (
                <MenuItem key={transactionTypeCode} value={transactionTypeCode}>
                  {formatters.displayCase(label)}
                </MenuItem>
              )
            )}
          </Field>
        </FormControl>
      )}
    </Stack>
  );
};

const PaymentMethodFieldGroup = (props: {
  availableForfeitureBalance: string;
  availableSpendingBudgetBalance: string;
  isFetchingBalances: boolean;
}) => {
  const { errors, touched } = useFormikContext<CreateAchRequestFormValues>();

  return (
    <>
      <Divider sx={{ py: 1 }} textAlign='left'>
        Payment Method
      </Divider>

      <FormControl size='small' variant='outlined'>
        <InputLabel htmlFor='method-menu-label'>Method</InputLabel>
        <Field
          MenuProps={{
            'data-testid': 'menu-ach-request-method'
          }}
          as={Select}
          error={touched.sourceOfFunds && Boolean(errors.sourceOfFunds)}
          label='Method'
          labelId='method-menu-label'
          name='sourceOfFunds'
          sx={{ width: 200 }}>
          {Object.values(SourceOfFunds).map(sourceOfFundsOption => (
            <MenuItem key={sourceOfFundsOption} value={sourceOfFundsOption}>
              {sourceOfFundsOption}
            </MenuItem>
          ))}
        </Field>
      </FormControl>

      <Stack direction='row' spacing={2}>
        <FormControl
          data-testid='ach-request-dialog-amount-input'
          error={touched.amount && Boolean(errors.amount)}
          size='small'
          sx={{ width: 200 }}>
          <InputLabel htmlFor='amount-input'>Amount</InputLabel>
          <Field
            as={OutlinedInput}
            autoComplete='off'
            id='amount-input'
            label='Amount'
            name='amount'
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            type='number'
          />
          <FormHelperText data-testid='ach-request-dialog-amount-input-helper-text'>
            {(touched.amount && errors.amount) || ' '}
          </FormHelperText>
        </FormControl>
        <FormControl
          data-testid='ach-request-dialog-forfeiture-amount-input'
          error={touched.forfeitureAmount && Boolean(errors.forfeitureAmount)}
          size='small'
          sx={{ width: 200 }}>
          <InputLabel htmlFor='forfeiture-amount-input'>
            Forfeiture Amount
          </InputLabel>
          <Field
            as={OutlinedInput}
            autoComplete='off'
            disabled={parseFloat(props.availableForfeitureBalance) <= 0}
            id='forfeiture-amount-input'
            label='Forfeiture Amount'
            name='forfeitureAmount'
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            type='number'
          />
          <FormHelperText data-testid='ach-request-dialog-forfeiture-amount-input-helper-text'>
            {(touched.forfeitureAmount && errors.forfeitureAmount) ||
              `Available: ${
                props.isFetchingBalances
                  ? 'calculating...'
                  : props.availableForfeitureBalance === 'unknown'
                    ? 'Unknown'
                    : formatters.formatDollars(props.availableForfeitureBalance)
              }`}
          </FormHelperText>
        </FormControl>
        <FormControl
          data-testid='ach-request-dialog-spending-budget-amount-input'
          error={
            touched.spendingBudgetAmount && Boolean(errors.spendingBudgetAmount)
          }
          size='small'
          sx={{ width: 200 }}>
          <InputLabel htmlFor='spending-budget-amount-input'>
            Spending Budget Amount
          </InputLabel>
          <Field
            as={OutlinedInput}
            autoComplete='off'
            disabled={parseFloat(props.availableSpendingBudgetBalance) <= 0}
            id='spending-budget-amount-input'
            label='Spending Budget Amount'
            name='spendingBudgetAmount'
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            type='number'
          />
          <FormHelperText data-testid='ach-request-dialog-spending-budget-amount-input-helper-text'>
            {(touched.spendingBudgetAmount && errors.spendingBudgetAmount) ||
              `Available: ${
                props.isFetchingBalances
                  ? 'calculating...'
                  : props.availableSpendingBudgetBalance === 'unknown'
                    ? 'Unknown'
                    : formatters.formatDollars(
                        props.availableSpendingBudgetBalance
                      )
              }`}
          </FormHelperText>
        </FormControl>
      </Stack>
    </>
  );
};

const PaymentDetailsGroup = (props: { planId: string }) => {
  const { errors, touched, setFieldValue } =
    useFormikContext<CreateAchRequestFormValues>();

  const [selectedGroup, setSelectedGroup] =
    useState<Payroll.GetSetup.ResponseBody>();

  const getPayrollSetupsQuery = useQuery(
    ['PlanService.getPayrollSetups', props.planId],
    () => PlanService.getPayrollSetups(props.planId)
  );

  const setSelected = (id: number) => {
    setFieldValue('paygroupId', id);
    const selection = getPayrollSetupsQuery.data.find(setup => setup.id === id);
    setFieldValue('accountNumber', selection.bankAccount.account);
    setFieldValue('routingNumber', selection.bankAccount.routingNumber);
    setSelectedGroup(selection);
  };

  useEffect(() => {
    if (
      !getPayrollSetupsQuery.isLoading &&
      getPayrollSetupsQuery.data &&
      getPayrollSetupsQuery.data.length
    ) {
      setSelected(getPayrollSetupsQuery.data[0].id);
    }
  }, [getPayrollSetupsQuery.isLoading, getPayrollSetupsQuery.data]);

  return (
    <>
      <Box sx={{ py: 1 }}>
        <Divider textAlign='left'>Payment Details</Divider>
      </Box>
      <Stack maxWidth={632} spacing={2}>
        {getPayrollSetupsQuery.isLoading ? (
          <Skeleton height={40} variant='rectangular' width={420} />
        ) : (
          <>
            <FormControl fullWidth size='small' variant='outlined'>
              <InputLabel htmlFor='pay-group-input-label'>Pay Group</InputLabel>
              <Field
                MenuProps={{
                  'data-testid': 'menu-ach-request-pay-group'
                }}
                as={Select}
                error={touched.paygroupId && Boolean(errors.paygroupId)}
                label='Pay Group'
                labelId='pay-group-input-label'
                name='paygroupId'
                onChange={event => setSelected(event.target.value)}>
                {getPayrollSetupsQuery.data?.map((paygroup, idx) => (
                  <MenuItem
                    disabled={!paygroup.bankAccount}
                    key={paygroup.id}
                    value={paygroup.id}>
                    <Stack
                      alignItems='center'
                      direction='row'
                      justifyContent='space-between'
                      width='100%'>
                      {paygroup.payGroupName || `Unnamed Pay Group ${idx + 1}`}
                      {!paygroup.bankAccount && (
                        <small>No bank data available</small>
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            {selectedGroup && (
              <>
                <Stack>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='pay-group-bank-name-label'>
                      Bank Name
                    </InputLabel>
                    <Field
                      as={OutlinedInput}
                      disabled
                      label='Bank Name'
                      name='accountNumber'
                      size='small'
                      value={selectedGroup.bankAccount?.bankName}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <FormControl sx={{ width: 200 }}>
                    <InputLabel htmlFor='pay-group-bank-name-label'>
                      Account Number
                    </InputLabel>
                    <Field
                      as={OutlinedInput}
                      disabled
                      label='Account Number'
                      name='accountNumber'
                      size='small'
                      value={selectedGroup.bankAccount?.account}
                    />
                  </FormControl>
                  <FormControl sx={{ width: 200 }}>
                    <InputLabel htmlFor='pay-group-bank-name-label'>
                      Routing Number
                    </InputLabel>
                    <Field
                      as={OutlinedInput}
                      disabled
                      label='Routing Number'
                      name='routingNumber'
                      size='small'
                      value={selectedGroup.bankAccount?.routingNumber}
                    />
                  </FormControl>
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

const MetaFieldGroup = () => {
  const { errors, touched } = useFormikContext<CreateAchRequestFormValues>();

  return (
    <>
      <Box sx={{ py: 1 }}>
        <Divider textAlign='left'>Meta</Divider>
      </Box>
      <Stack alignItems='flex-start' direction='row' spacing={2}>
        <FormControl
          data-testid='ach-request-contribution-year-input'
          error={touched.contributionYear && Boolean(errors.contributionYear)}
          size='small'
          sx={{ width: 200 }}>
          <InputLabel htmlFor='contribution-year-menu-label'>
            Contribution Year
          </InputLabel>
          <Field
            as={OutlinedInput}
            id='contribution-year-menu-label'
            label='Contribution Year'
            name='contributionYear'
            type='number'
          />
          <FormHelperText data-testid='ach-request-contribution-year-input-helper-text'>
            {(touched.contributionYear && errors.contributionYear) || ' '}
          </FormHelperText>
        </FormControl>
      </Stack>
    </>
  );
};

export const CreateAchRequestDialog: React.FunctionComponent<
  CreateAchRequestDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();

  const [availableForfeitureBalance, setAvailableForfeitureBalance] =
    useState('');
  const [availableSpendingBudgetBalance, setAvailableSpendingBudgetBalance] =
    useState('');

  const queryClient = useQueryClient();
  const initiateDepositMutation = useMutation<
    { tracerId: string; status: string; startDate: string },
    ApiServiceError,
    InitiateDepositRequest
  >(data => WorkflowService.initiateDeposit(data), {
    onError: () => {
      showSnackbar({
        message: `Couldn't submit ACH request at this time. Please try again`,
        severity: 'error'
      });
    },
    onSuccess: () => {
      showSnackbar({
        message: `New ACH request has been submitted`,
        severity: 'success'
      });
      queryClient.invalidateQueries(['WorkflowService.search']);
      props.onClose();
    }
  });

  const getBalanceTotalsServiceQuery = useQuery(
    ['BalanceTotalsService.get'],
    () =>
      BalanceTotalsService.get({
        parentAccountId: props.parentAccountId
      }),
    {
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to fetch operational account balances: ${err.message}`,
          severity: 'error'
        });

        setAvailableForfeitureBalance('unknown');
        setAvailableSpendingBudgetBalance('unknown');
      },
      onSuccess: (result: { forfeiture: string; spendingBudget: string }) => {
        setAvailableForfeitureBalance(result.forfeiture);

        setAvailableSpendingBudgetBalance(result.spendingBudget);
      },
      select: balanceTotals => {
        const availableAmounts = {};
        balanceTotals
          .filter(
            balance =>
              balance.accountType === SubAccountType.forfeiture ||
              balance.accountType === SubAccountType.spendingBudget
          )
          .forEach(balance => {
            availableAmounts[balance.accountType] =
              balance.confirmedCashBalance;
          });

        return availableAmounts;
      }
    }
  );

  const validationSchema = yup.object({
    amount: yup
      .number()
      .required()
      .moreThan(-1, 'Must be a positive number')
      .test('is-decimal', 'Must be two decimal places', testDecimals(2)),
    contributionYear: yup
      .number() // .max(Number(new Date().getFullYear()))
      // .min(2010)
      .test('is-year', 'Must be a 4 digit year', testYear()),
    depositCode: yup.string().required(),
    forfeitureAmount: yup
      .number()
      .moreThan(-1, 'Must be a positive number')
      .test('is-decimal', 'Must be two decimal places', testDecimals(2))
      .test(
        'enough-available',
        `Max available is: ${formatters.formatDollars(
          availableForfeitureBalance
        )}`,
        async value => {
          if (!value) {
            return true;
          }

          const balanceTotals = queryClient.getQueryData<BalanceTotalsDto[]>([
            'BalanceTotalsService.get'
          ]);

          if (!balanceTotals) {
            return true;
          }

          // `select` option from useQuery() does not affect what gets stored in the query cache
          // so we have to manually search for our value again
          const availableForfeitureBalance = balanceTotals?.filter(
            balance => balance.accountType === SubAccountType.forfeiture
          )[0]?.confirmedCashBalance;

          return value <= parseFloat(availableForfeitureBalance);
        }
      ),
    paygroupId: yup.string(),
    sourceOfFunds: yup.string().required(),
    spendingBudgetAmount: yup
      .number()
      .moreThan(-1, 'Must be a positive number')
      .test('is-decimal', 'Must be two decimal places', testDecimals(2))
      .test(
        'enough-available',
        `Max available is: ${formatters.formatDollars(
          availableSpendingBudgetBalance
        )}`,
        async value => {
          if (!value) {
            return true;
          }

          const balanceTotals = queryClient.getQueryData<BalanceTotalsDto[]>([
            'BalanceTotalsService.get'
          ]);

          if (!balanceTotals) {
            return true;
          }

          // `select` option from useQuery() does not affect what gets stored in the query cache
          // so we have to manually search for our value again
          const availableSpendingBudgetBalance = balanceTotals?.filter(
            balance => balance.accountType === SubAccountType.spendingBudget
          )[0]?.confirmedCashBalance;

          return value <= parseFloat(availableSpendingBudgetBalance);
        }
      )
  });

  const handleSubmit = async (formValues: CreateAchRequestFormValues) => {
    initiateDepositMutation.mutate({
      accountNumber: formValues.accountNumber,
      amount: parseFloat(formValues.amount).toFixed(2),
      contributionYear: formValues.contributionYear.toString(),
      depositCode: formValues.depositCode,
      planId: props.planId,
      routingNumber: formValues.routingNumber,
      sourceOfFunds: formValues.sourceOfFunds,
      tracerId: `UI-INITIATE-DEPOSIT-${v4()}`,
      useForfeitureAmount: parseFloat(
        formValues.forfeitureAmount || '0.00'
      ).toFixed(2),
      useSpendingBudgetAmount: parseFloat(
        formValues.spendingBudgetAmount || '0.00'
      ).toFixed(2)
    });
  };

  return (
    <Formik
      initialValues={{
        accountNumber: '',
        amount: '',
        contributionYear: dayjs().year().toString(),
        depositCode: TransactionTypeCode.Deposit,
        forfeitureAmount: '',
        paygroupId: '',
        routingNumber: '',
        sourceOfFunds: SourceOfFunds.ACH_PULL,
        spendingBudgetAmount: '',
        // NOTE: the following usage of the formatter is
        // ultimately being used on a disabled input
        // not ideal, but no other way that wouldn't be too involved
        toSubAccount: formatters.formatSubAccountName({
          accountType: SubAccountType.moneyIn
        }),
        ...props.initialValues
      }}
      onSubmit={(values: CreateAchRequestFormValues) => handleSubmit(values)}
      validationSchema={validationSchema}>
      {({ handleSubmit: handleFormSubmit, isValid, dirty }) => (
        <Dialog
          fullWidth
          maxWidth='md'
          onClose={() => props.onClose()}
          open={props.open}>
          <DialogTitle>New ACH Request</DialogTitle>
          <TransactionAccountSummary
            accountId={props.accountId}
            accountLevel={AccountLevel.ParentAccount}
          />
          <DialogContent
            sx={{
              paddingTop: 3
            }}>
            <Form data-testid='query-form'>
              <Stack spacing={2}>
                <DepositCodeFieldGroup />
                <PaymentMethodFieldGroup
                  availableForfeitureBalance={availableForfeitureBalance}
                  availableSpendingBudgetBalance={
                    availableSpendingBudgetBalance
                  }
                  isFetchingBalances={getBalanceTotalsServiceQuery.isFetching}
                />
                <PaymentDetailsGroup planId={props.planId} />
                <MetaFieldGroup />

                {initiateDepositMutation.error && (
                  <Alert severity='error'>
                    {initiateDepositMutation.error.message}
                  </Alert>
                )}
              </Stack>
              {/* hidden submit button for keyboard submission without breaking Dialog overflow fixed title/actions */}
              <Box component='input' sx={visuallyHidden} type='submit' />
            </Form>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button
              data-testid='ach-request-dialog-nevermind-button'
              disabled={initiateDepositMutation.isLoading}
              onClick={() => props.onClose()}>
              Nevermind
            </Button>
            <LoadingButton
              data-testid='ach-request-dialog-submit-button'
              disabled={!(isValid && dirty)}
              loading={initiateDepositMutation.isLoading}
              onClick={() => handleFormSubmit()}
              type='submit'
              variant='contained'>
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
