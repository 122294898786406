import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { DepositPlanDto } from '@/models/suba/deposit-plans/DepositPlanDTO.model';
import { DepositPlanSearchRequest } from '@/models/suba/deposit-plans/DepositPlanSearchRequest.model';
import ApiService from '@/services/Api.service';

class DepositPlanService {
  static async get(depositPlanId: string): Promise<DepositPlanDto> {
    return ApiService.getJson<DepositPlanDto>(
      `/deposit-plans/${depositPlanId}`
    );
  }

  static async search(
    params: DepositPlanSearchRequest
  ): Promise<PaginatedApiResponse<DepositPlanDto[]>> {
    return ApiService.getJson<PaginatedApiResponse<DepositPlanDto[]>>(
      `/deposit-plans`,
      params
    );
  }
}

export default DepositPlanService;
