import CopyToClipboard from '@/components/copy-to-clipboard';
import { useSnackbar } from '@/contexts/SnackBarContext';
import DetailLabel from '@/routes/ops/investments/common/DetailLabel.component';
import DetailValue from '@/routes/ops/investments/common/DetailValue.component';
import SortingIcon from '@/routes/ops/investments/common/SortingIcon.component';
import { HasModelFunds } from '@/routes/ops/investments/data-grid/RiskAndTargetUtil';
import { AllocationCell } from '@/routes/ops/investments/investment-table/AllocationCell.component';
import AllocationTable from '@/routes/ops/investments/investment-table/AllocationTable.component';
import InvestmentTable from '@/routes/ops/investments/investment-table/InvestmentTable.component';
import NumericEditor from '@/routes/ops/investments/investment-table/numericEditor';
import { TickerLookupSearchResult } from '@/routes/ops/investments/investment-table/TickerSelect.component';
import { Box, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import { CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { every, times, uniqBy } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import ModelEditDetailView from './models/ModelEditDetailView.component';

export interface RiskSeriesProps {
  refetchRiskSeriesGrid: () => void;
  riskSeries: RiskSeriesData;
  riskSeriesGrid: RiskSeriesGridData;
  lookupTickerCallback?: (
    ticker: string,
    numberOfResults: number
  ) => Promise<TickerLookupSearchResult[]>;
  saveCallback?: (
    updatedRiskSeries: RiskSeriesData,
    updatedModelGridProps: RiskSeriesGridData
  ) => Promise<[RiskSeriesData, RiskSeriesGridData]>;
  readonly: boolean;
  programCount: number;
}

export interface RiskSeriesData {
  riskSeriesId: number;
  name: string;
  description?: string;
}

export interface RiskSeriesModelProps extends HasModelFunds {
  riskModelId?: number;
  modelName: string;
  riskLevel: number;
  description?: string;
}

export interface RiskSeriesGridData {
  allocations: Record<string, any>[];
  errors?: Record<string, string>[];
  models: RiskSeriesModel[];
}

export type RiskSeriesModel = {
  riskModelId?: number;
  description?: string;
  modelName: string;
  riskLevel: number;
  riskSeriesId: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  copyContainer: {
    alignItems: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '100px',
    display: 'flex',
    padding: `2px 8px`
  },
  copyIcon: {
    height: theme.spacing(2)
  },
  darkSubtitle: {
    color: 'rgb(0, 0, 0, 0.87)',
    fontSize: 13,
    letterSpacing: 0.25
  }
}));

const DetailValueCell = (props: any) => {
  const classes = useStyles();
  const { context, rowIndex, value, valueFormatted, column, eGridCell } = props;
  const editable = props.colDef.editable(props);
  const isExistingColumnError =
    typeof context.errors.models !== 'string' &&
    context.errors.models?.[Number(column.colId)];
  if (rowIndex === 4) {
    return (
      <AllocationCell
        allocations={props.context.values.allocations}
        colId={props.colDef.colId}
      />
    );
  }

  if (rowIndex === 2) {
    eGridCell.setAttribute('data-test-id', `model-risk-level-${column.colId}`);
    return (
      <DetailValue
        color={isExistingColumnError && !value ? 'red' : 'rgba(0, 0, 0, 0.6)'}>
        {valueFormatted}
      </DetailValue>
    );
  }

  if (rowIndex === 3) {
    eGridCell.setAttribute('data-test-id', `model-description-${column.colId}`);
    if (!value && editable) {
      return <DetailValue>{valueFormatted}</DetailValue>;
    }
  }

  if (rowIndex === 1) {
    eGridCell.setAttribute('data-test-id', `model-name-${column.colId}`);
    if (!value && editable) {
      return (
        <DetailValue
          color={isExistingColumnError ? 'red' : 'rgba(0, 0, 0, 0.6)'}>
          {isExistingColumnError ? 'Name Required' : 'Risk Series'}
        </DetailValue>
      );
    }
  }
  if (rowIndex === 0 && value) {
    return (
      <div
        className={classes.copyContainer}
        data-testid={`risk-model-id-container-${column.colId}`}>
        <Typography
          className={classes.darkSubtitle}
          data-testid={`risk-model-${column.colId}`}
          display='inline'>
          {value}
        </Typography>
        <CopyToClipboard
          copyName='Model ID'
          copyValue={value}
          iconCssClass={classes.copyIcon}
          size='small'
        />
      </div>
    );
  }

  return <DetailValue>{value || ''}</DetailValue>;
};

const MODEL_ID_ROW = 'riskModelId';
const MODEL_NAME_ROW = 'modelName';
const RISK_LEVEL_ROW = 'riskLevel';
const DESCRIPTION_ROW = 'description';
const NUMBER_OF_MODELS = 10;

const getLabel = (field: string) => {
  switch (field) {
    case MODEL_ID_ROW:
      return 'Model ID';
    case MODEL_NAME_ROW:
      return 'Model Name';
    case RISK_LEVEL_ROW:
      return 'Risk Level';
    case DESCRIPTION_ROW:
      return 'Description';
  }
};

const RiskSeriesSchema: Yup.SchemaOf<RiskSeriesData> = Yup.object().shape({
  allocations: Yup.array()
    .min(1, 'Must have at least one allocation')
    .test(
      'unique-symbol',
      'Allocations must have unique tickers.',
      function (value) {
        return (value || []).length === uniqBy(value || [], 'symbol').length;
      }
    )
    .required('Required'),
  description: Yup.string(),
  models: Yup.array()
    .of(
      Yup.object().shape({
        modelName: Yup.string().required('Required'),
        riskLevel: Yup.number().required('Required')
      })
    )
    .test('unique-name', 'Models Must Have Unique Names', function (value) {
      return (value || []).length === uniqBy(value || [], 'modelName').length;
    })
    .test(
      'unique-riskLevel',
      'Models Must Have Unique Risk Level',
      function (value) {
        return (value || []).length === uniqBy(value || [], 'riskLevel').length;
      }
    )
    .test(
      'valid-allocations',
      'Invalid Fund Allocations',
      function (value, context) {
        const models =
          value?.map((_, index) => {
            return context.parent.allocations.reduce(
              (accumulator: Decimal, allocation: Array<any>) =>
                Decimal.add(accumulator, allocation[index] || '0'),
              new Decimal(0)
            );
          }) || [];
        return every(models, m => m.equals(new Decimal(100)));
      }
    )
    .min(1, 'Must have at least one model')
    .required('Required'),
  name: Yup.string().required('Required'),
  riskSeriesId: Yup.number().required('Required')
});

const RiskSeries = (riskSeriesProps: RiskSeriesProps): JSX.Element => {
  const {
    riskSeries: initialRiskSeries,
    saveCallback,
    lookupTickerCallback,
    refetchRiskSeriesGrid,
    riskSeriesGrid: initialRiskSeriesGrid,
    programCount
  } = riskSeriesProps;

  const snackbar = useSnackbar();

  const modelGrid = useRef(null);
  const tickerGrid = useRef(null);
  const [fundNameCellWidth, setFundNameCellWidth] = useState(undefined);
  const [sort, setSort] = useState<null | 'asc' | 'desc'>(null);

  const [riskSeriesState, setRiskSeriesState] = useState(initialRiskSeries);
  const [riskSeriesGridState, setRiskSeriesGridState] = useState(
    initialRiskSeriesGrid
  );

  const sortTickers = useCallback(
    params => {
      if (params.rowIndex === 4) {
        const newSort = sort ? (sort === 'asc' ? 'desc' : null) : 'asc';

        tickerGrid.current.columnApi.applyColumnState({
          defaultState: { sort: null },
          state: [{ colId: 'symbol', sort: newSort }]
        });
        setSort(newSort);
      }
    },
    [sort]
  );

  const form = useFormik<RiskSeriesGridData & RiskSeriesData>({
    enableReinitialize: true,
    initialValues: {
      ...riskSeriesState,
      ...riskSeriesGridState
    },
    onSubmit: async values => {
      try {
        const updatedRiskSeriesDetails = {
          description: values.description,
          name: values.name,
          riskSeriesId: values.riskSeriesId
        };

        return await saveCallback?.(updatedRiskSeriesDetails, {
          allocations: values.allocations,
          models: values.models
        }).then(([updatedRiskSeries, updatedRiskSeriesGrid]) => {
          setRiskSeriesState(updatedRiskSeries);
          if (updatedRiskSeriesGrid.errors) {
            snackbar.showSnackbar({
              message: 'Risk Models were created/updated with errors',
              severity: 'error'
            });
            refetchRiskSeriesGrid();
          } else {
            setRiskSeriesGridState(updatedRiskSeriesGrid);
            snackbar.showSnackbar({
              message: 'Save Successful',
              severity: 'success'
            });
          }
        });
      } catch (err: any) {
        snackbar.showSnackbar({
          message: err.message,
          severity: 'error'
        });
      }
    },
    validationSchema: RiskSeriesSchema
  });

  const getDetailColumn = useCallback(
    (columnIndex: number) => {
      return {
        cellEditorSelector: (params: any) => {
          if (params.node.rowIndex === 2) {
            return {
              component: NumericEditor,
              params: { ...params, snackbar }
            };
          }
          return { component: 'agTextCellEditor' };
        },
        cellRenderer: DetailValueCell,
        colId: columnIndex.toString(),
        editable: (params: any) => {
          if (riskSeriesProps.readonly) return false;
          if ([0, 4].includes(params.node.rowIndex)) return false;
          if (columnIndex > form.values.models.length) return false;

          if (params.node.rowIndex === 3 || params.node.rowIndex === 2) {
            return !!form.values.models[columnIndex];
          }

          return true;
        },
        field: `value${columnIndex}`,
        flex: 0.75,
        headerName: '',
        minWidth: 165,
        sortable: false,
        suppressKeyboardEvent: (params: any) => {
          // skip allocation row
          if (
            params.event.type === 'keydown' &&
            params.event.key === 'Enter' &&
            ((params.node.rowIndex === 3 && params.editing) ||
              params.node.rowIndex === 4)
          ) {
            tickerGrid.current?.api.setFocusedCell(0, params.colDef.colId);
            params.api.stopEditing();
            return true;
          }
          return false;
        },
        valueFormatter: (params: any) => {
          if (params.value === undefined) {
            const editable = params.colDef.editable(params);
            if (params.data.field === DESCRIPTION_ROW && editable) {
              return 'Add Description';
            }
            return '';
          }
          return params.value?.toString();
        },
        valueGetter: (params: any) => {
          const model: {
            riskModelId?: number;
            description?: string;
            modelName: string;
            riskLevel: number;
          } = form.values.models[columnIndex];

          if (params.data.field === MODEL_ID_ROW) {
            return model?.riskModelId;
          }

          // riskLevel formatter
          if (params.data.field === RISK_LEVEL_ROW) {
            return model?.riskLevel;
          }

          // modelName formatter
          if (params.data.field === MODEL_NAME_ROW) {
            return model?.modelName;
          }

          // description formatter
          if (params.data.field === DESCRIPTION_ROW) {
            return model?.description;
          }

          return '';
        }
      };
    },
    [form.values, riskSeriesProps.readonly]
  );

  const TICKER_WIDTH = 140;

  const modelColumnLength = useMemo(() => {
    return form.values?.models.length + 1 > NUMBER_OF_MODELS
      ? form.values?.models.length + 1
      : NUMBER_OF_MODELS;
  }, [form.values?.models.length]);

  const topColumnDefs = useMemo(
    () => [
      {
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <Typography
              color='rgba(0, 0, 0, 0.87)'
              sx={{ display: 'flex' }}
              variant='body2'>
              {params.value || ''}
              {params.node.rowIndex === 4 && <SortingIcon sort={sort} />}
            </Typography>
          );
        },
        field: 'sublabel',
        flex: 1,
        headerName: '',
        maxWidth: TICKER_WIDTH,
        minWidth: TICKER_WIDTH,
        onCellClicked: (params: CellClickedEvent) => sortTickers(params),
        pinned: true,
        valueGetter: (params: any) => params.data.sublabel || ' ',
        width: TICKER_WIDTH
      },
      {
        cellRenderer: DetailLabel,
        field: 'label',
        headerName: '',
        pinned: true,
        width: fundNameCellWidth
      },
      ...times(modelColumnLength, index => getDetailColumn(index))
    ],
    [fundNameCellWidth, modelColumnLength, getDetailColumn, sortTickers, sort]
  );

  const getDetailValueRowData = useCallback(
    (
      field:
        | typeof MODEL_ID_ROW
        | typeof MODEL_NAME_ROW
        | typeof RISK_LEVEL_ROW
        | typeof DESCRIPTION_ROW
    ) => {
      let row = { field, label: getLabel(field) };
      times(modelColumnLength, (index: number) => {
        const model: {
          riskModelId?: number;
          modelName?: string;
          riskLevel?: number;
          description?: string;
        } = form.values?.models ? form.values?.models[index] : {};
        if (model && model[field]) {
          row = {
            ...row,
            [`value${index}`]: model[field]
          };
        }
      });
      return row;
    },
    [form.values.models, modelColumnLength]
  );

  const defaultColDef = {
    editable: false,
    filter: false,
    resizable: false,
    sortable: false,
    suppressMenu: true,
    suppressMovable: true
  };

  const onEditDetailCellEditRequest = (params: any) => {
    // new modelName column
    if (
      !params.context.values.models ||
      (params.data.field === MODEL_NAME_ROW &&
        !params.context.values.models[params.colDef.colId])
    ) {
      const newModelProps = Array.from(params.context.values.models || []);
      const riskLevelHigh = params.context.values.models.reduce(
        (high: number, ts: { riskLevel: number }) =>
          (ts.riskLevel || 0) > high ? ts.riskLevel || 0 : high,
        0
      );
      const modelName = params.value;

      newModelProps.push({
        description: undefined,
        modelName,
        riskLevel: parseInt(riskLevelHigh) + 1
      });

      params.context.setFieldValue('models', newModelProps);
      return;
    }

    params.context.setFieldValue(
      `models[${params.colDef.colId}].${params.data.field}`,
      params.value
    );
  };

  return (
    <ModelEditDetailView
      content={
        <>
          <Box>
            <Box>
              <InvestmentTable
                alignedGrids={
                  tickerGrid?.current ? [tickerGrid.current] : undefined
                }
                columnDefs={topColumnDefs}
                context={form}
                defaultColDef={defaultColDef}
                gridRef={modelGrid}
                headerHeight={0}
                onCellEditRequest={onEditDetailCellEditRequest}
                rowData={[
                  getDetailValueRowData(MODEL_ID_ROW),
                  getDetailValueRowData(MODEL_NAME_ROW),
                  getDetailValueRowData(RISK_LEVEL_ROW),
                  getDetailValueRowData(DESCRIPTION_ROW),
                  { label: 'Allocation', sublabel: 'Ticker' }
                ]}
              />
            </Box>
            <AllocationTable
              alignedGrids={
                modelGrid?.current ? [modelGrid.current] : undefined
              }
              allocations={form.values.allocations}
              defaultColDef={defaultColDef}
              gridRef={tickerGrid}
              lookupTickerCallback={lookupTickerCallback}
              models={form.values.models}
              readonly={riskSeriesProps.readonly}
              setFieldValue={form.setFieldValue}
              setFundNameCellWidth={setFundNameCellWidth}
            />
          </Box>
        </>
      }
      dirty={form?.dirty}
      errors={form?.errors}
      investmentOptionId={initialRiskSeries.riskSeriesId}
      isSubmitting={form?.isSubmitting}
      isValid={form?.isValid}
      lookupTickerCallback={lookupTickerCallback}
      modelSeriesType='Risk'
      onSaveModel={form.handleSubmit}
      programCount={programCount}
      readonly={riskSeriesProps.readonly}
      setFieldValue={form?.setFieldValue}
      values={form?.values}
    />
  );
};

RiskSeries.defaultProps = {
  readonly: false
};

export default RiskSeries;
