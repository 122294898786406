import SimpleDropdown from '@/components/simple-dropdown';
import STATE_CODES from '@/consts/states.constants';
import { Grid, TextField } from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useMemo } from 'react';

type AddressGridProps = {
  keyPrefix: string;
  type: 'payee' | 'participant' | '';
};

const AddressGrid: React.FunctionComponent<AddressGridProps> = (
  props: AddressGridProps
) => {
  const { keyPrefix, type } = props;
  const formik: FormikContextType<any> = useFormikContext();

  const fieldIds = useMemo(() => {
    return {
      address: `${keyPrefix}${type}Address`,
      city: `${keyPrefix}${type}City`,
      country: `${keyPrefix}${type}ForeignCountry`,
      payeeName: `${keyPrefix}${type}Name`,
      state: `${keyPrefix}${type}State`,
      zip: `${keyPrefix}${type}Zip`
    };
  }, [keyPrefix, type]);

  return (
    <>
      <Grid
        columnSpacing={{ md: 3, sm: 2, xs: 1 }}
        container
        rowSpacing={2}
        sx={{ py: 2 }}>
        <Grid item xs={12}>
          <TextField
            error={
              get(formik.touched, fieldIds.payeeName) &&
              Boolean(get(formik.errors, fieldIds.payeeName))
            }
            fullWidth
            helperText={
              (get(formik.touched, fieldIds.payeeName) &&
                get(formik.errors, fieldIds.payeeName)?.toString()) ||
              ''
            }
            id={fieldIds.payeeName}
            label='Recipient Name'
            name={fieldIds.payeeName}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds.payeeName)}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={
              get(formik.touched, fieldIds.address) &&
              Boolean(get(formik.errors, fieldIds.address))
            }
            fullWidth
            helperText={
              (get(formik.touched, fieldIds.address) &&
                get(formik.errors, fieldIds.address)?.toString()) ||
              ''
            }
            id={fieldIds.address}
            label='Address'
            name={fieldIds.address}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds.address)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={
              get(formik.touched, fieldIds.city) &&
              Boolean(get(formik.errors, fieldIds.city))
            }
            fullWidth
            helperText={
              (get(formik.touched, fieldIds.city) &&
                get(formik.errors, fieldIds.city)?.toString()) ||
              ''
            }
            id={fieldIds.city}
            label='City'
            name={fieldIds.city}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds.city)}
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleDropdown
            fieldId={fieldIds.state}
            fieldName='State'
            fieldValues={STATE_CODES}
            size='small'
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            error={
              get(formik.touched, fieldIds.zip) &&
              Boolean(get(formik.errors, fieldIds.zip))
            }
            helperText={
              (get(formik.touched, fieldIds.zip) &&
                get(formik.errors, fieldIds.zip)?.toString()) ||
              ''
            }
            id={fieldIds.zip}
            label='Zip'
            name={fieldIds.zip}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds.zip)}
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleDropdown
            fieldId={fieldIds.country}
            fieldName='Country'
            fieldValues={['USA', 'Mexico', 'Canada', 'Other']}
            size='small'
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressGrid;
