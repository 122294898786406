import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { AlertDto } from '@/models/suba/alerts/AlertDTO.model';
import {
  DepositType,
  depositTypeNames
} from '@/models/suba/alerts/DepositType.model';
import formatters from '@/utils/Formatters';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';

import { useMemo } from 'react';

type RolloversAlertDetailFieldsProps = {
  alert: AlertDto;
};

export const RolloversAlertDetailFields = ({
  alert
}: RolloversAlertDetailFieldsProps): JSX.Element => {
  // fetch friendly display name for alert type
  const displayType = formatters.getValueKey(AlertType, alert.alertType);
  // fetch friendly display name for alert sub type
  const displaySubType = formatters.getValueKey(
    AlertSubType,
    alert.alertSubType
  );

  const planLinks = useMemo(() => {
    const links = [];
    if (alert.planId) {
      links.push({
        label: `ID: ${alert.planId}`,
        target: '_blank',
        to: `/plans/${alert.planId}/plan`
      });
    }
    if (alert.parentAccountId) {
      links.push({
        label: 'Parent Acct. ID:',
        target: '_blank',
        to: `/ops/accounts/${alert.parentAccountId}/sub-accounts`
      });
    }
    return links;
  }, [alert.planId, alert.parentAccountId]);

  const transactionLinks = useMemo(() => {
    const links = [];

    // a new transaction is created whenever updated transactions are reversed
    const originalParentAccountTransactionId =
      alert?.details?.event?.transactionIds[0];
    const originalSubAccountTransactionId =
      alert?.details?.event?.transactionIds[1];

    const updatedParentAccountTransactionId =
      alert?.details?.updatedParentAccountTransactionId;
    const updatedSubAccountTransactionId =
      alert?.details?.updatedSubAccountTransactionId;

    if (
      updatedParentAccountTransactionId ||
      originalParentAccountTransactionId
    ) {
      // parent account deposit
      links.push({
        label: `Parent Acct.`,
        target: '_blank',
        to: `/ops/accounts/${
          alert.parentAccountId
        }/transactions?query=${encodeURIComponent(
          JSON.stringify({
            sourceTransactionId:
              updatedParentAccountTransactionId ||
              originalParentAccountTransactionId
          })
        )}`
      });
    }

    if (updatedSubAccountTransactionId || originalSubAccountTransactionId) {
      // sub account moneyIn
      links.push({
        label: `MoneyIn`,
        target: '_blank',
        to: `/ops/accounts/${
          alert.parentAccountId
        }/transactions?query=${encodeURIComponent(
          JSON.stringify({
            accountLevel: AccountLevel.SubAccount,
            sourceTransactionId:
              updatedSubAccountTransactionId || originalSubAccountTransactionId
          })
        )}`
      });
    }

    return links;
  }, [alert.details, alert.parentAccountId]);

  return (
    <TextStack
      direction='column'
      id='alert-detail-fields'
      spacing={1}
      sx={{ mb: 1.5, mt: 3.5, p: 0 }}>
      <TextStackItem>
        <TextLabel>Type / Sub Type</TextLabel>
        <TextValue>
          {formatters.displayCase(displayType)} /{' '}
          {formatters.displayCase(displaySubType)}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Participant</TextLabel>
        <TextValue>
          {/** Purposefully blank because it is only linked to a participant post-processing which hasn't been implemented yet */}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Plan</TextLabel>
        <TextValue links={planLinks}>{alert.plan?.name}</TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Custodian Account</TextLabel>
        <TextValue>{alert.custodianAccountNumber}</TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Amount</TextLabel>
        <TextValue>
          {alert.details?.event?.body?.[0]?.amount
            ? formatters.formatDollars(alert.details.event.body[0].amount)
            : ''}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Deposit Type</TextLabel>
        <TextValue>
          {
            depositTypeNames[
              alert.details?.event?.body?.[0]?.depositType as DepositType
            ]
          }
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Deposit Date</TextLabel>
        <TextValue>
          {alert.details?.event?.body?.[0]?.depositDate
            ? formatters.formatFromIsoDateCustom(
                alert.details.event.body[0].depositDate,
                'MM/DD/YYYY'
              )
            : ''}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Check Number</TextLabel>
        <TextValue>{alert.details?.event?.body?.[0]?.checkImageId}</TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Bank Account Number</TextLabel>
        <TextValue>
          {alert.details?.event?.body?.[0]?.bankAccountNumber}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Bank Routing Number</TextLabel>
        <TextValue>
          {alert.details?.event?.body?.[0]?.bankRoutingNumber}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Transaction</TextLabel>
        <TextValue links={transactionLinks}> </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {alert.createdAt
            ? formatters.formatFromIsoDate(alert.createdAt)
            : null}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
