import Badge from '@/components/badge';
import { WorkflowStatusDto } from '@/models/suba/workflows/WorkflowStatusDTO.model';
import { WorkflowStatusEnumsObject } from '@/models/suba/workflows/WorkflowStatusEnumObject.model';
import formatters from '@/utils/Formatters';
import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material';

type WorkflowStatusHistoryDialogProps = {
  open: boolean;
  onClose: () => void;
  workflowStatuses?: WorkflowStatusDto[];
};

export const WorkflowStatusHistoryDialog = ({
  open,
  onClose,
  workflowStatuses = []
}: WorkflowStatusHistoryDialogProps): JSX.Element => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogContent
        sx={{
          p: 2,
          pb: 0.5
        }}>
        {workflowStatuses.map((workflowStatus, workflowStatusIndex) => {
          // fetch friendly display name for workflowStatus
          const displayWorkflowStatus = formatters.getValueKey(
            WorkflowStatusEnumsObject,
            workflowStatus.workflowStatus
          );
          return (
            <Box key={workflowStatus.id} sx={{ mb: 1.5 }}>
              <Stack direction='row' spacing={3}>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: 1.17
                  }}>
                  {formatters.displayCase(displayWorkflowStatus)}
                </Typography>
                {workflowStatusIndex === 0 && (
                  <Badge color='neutral'>Current</Badge>
                )}
              </Stack>
              <Typography
                sx={{
                  color: theme => theme.palette.grey[600],
                  fontSize: 15,
                  lineHeight: 1.17
                }}>
                {formatters.formatFromIsoDate(workflowStatus.createdAt)}
              </Typography>
            </Box>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
