import { ConfirmedTransactionSearchRequest } from '@/models/suba/transactions/ConfirmedTransactionSearchRequest.model';
import { PendingTransactionSearchRequest } from '@/models/suba/transactions/PendingTransactionSearchRequest.model';
import { TransactionDto } from '@/models/suba/transactions/TransactionDTO.model';
import { TransactionService } from '@/services/suba/transactions/Transaction.service';
import { DOMInteraction } from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import { AgGridReact } from 'ag-grid-react';
import { kebabCase, omit } from 'lodash';
import { MutableRefObject } from 'react';

import { transactionDetailCellRendererFields } from '../components/TransactionDetailCellRenderer.component';

const formatTransactions = (params: {
  gridRef: MutableRefObject<AgGridReact>;
  includeColumns?: string[];
  transactions: TransactionDto[];
}) => {
  // fetch columns from grid reference to support column reordering
  const gridColumns =
    params.gridRef?.current?.columnApi?.getAllGridColumns() || [];
  return params.transactions.map(transaction => {
    const formattedTransaction: Record<string, any> = {};

    // add the properties from the columns with matching header and formatting
    gridColumns.forEach((col, colIndex) => {
      if (colIndex === 0) return; // don't include the action column

      const userProvidedColDef = col.getUserProvidedColDef();
      if (!userProvidedColDef) return;

      // don't include columns that are missing from the includeColumns prop if one was provided
      if (
        userProvidedColDef.field &&
        params.includeColumns &&
        !params.includeColumns?.includes(userProvidedColDef.field)
      ) {
        return;
      }

      if (userProvidedColDef.field === 'transactionBaseType') {
        // since this field is actually a combination of base type and type
        // we need to manually format it and add it as separate fields
        const displayBaseType = formatters.getValueKey(
          TransactionBaseType,
          transaction.transactionBaseType
        );
        formattedTransaction['Base Type'] = displayBaseType
          ? formatters.displayCase(displayBaseType)
          : '';
        formattedTransaction.Type = transaction.transactionTypeCode
          ? formatters.displayCase(transaction.transactionTypeCode)
          : '';
      } else if (userProvidedColDef.field === 'security.symbol') {
        // since this field is actually a combination of the desired output of symbol and cusip
        // we need to manually format it and add it as separate fields
        formattedTransaction.Ticker = transaction.security?.symbol || '';
        formattedTransaction.Cusip = transaction.security?.cusip || '';
      } else if (
        typeof userProvidedColDef.valueFormatter !== 'undefined' &&
        typeof userProvidedColDef.valueFormatter !== 'string'
      ) {
        // special formatting was specified
        formattedTransaction[userProvidedColDef.headerName as string] =
          userProvidedColDef.valueFormatter({
            value: transaction[userProvidedColDef.field as keyof TransactionDto]
          } as any);
      } else if (typeof userProvidedColDef.cellRenderer === 'function') {
        // the column uses a custom renderer so use it and convert it to text
        formattedTransaction[userProvidedColDef.headerName as string] =
          DOMInteraction.JSXToTextContent(
            userProvidedColDef.cellRenderer({ data: transaction } as any)
          );
      } else {
        formattedTransaction[userProvidedColDef.headerName as string] =
          transaction[userProvidedColDef.field as keyof TransactionDto];
      }
    });

    // add the properties from the expandable section of the row
    Object.keys(transactionDetailCellRendererFields).forEach(fieldId => {
      const field = transactionDetailCellRendererFields[fieldId];
      formattedTransaction[field.label] = field.valueFormatter(
        transaction[fieldId as keyof TransactionDto]
      );
    });

    return formattedTransaction;
  });
};

type CommonExportParams = {
  accountLevel: AccountLevel;
  gridRef: MutableRefObject<AgGridReact>;
  includeColumns?: string[];
} & (
  | {
      accountId: string;
      accountLevel: AccountLevel;
    }
  | {
      accountLevel: AccountLevel.SubAccount;
      planId: string;
    }
);

export const exportConfirmedTransactions = async (
  params: CommonExportParams & {
    searchParams: ConfirmedTransactionSearchRequest;
  }
) => {
  // rerun the query with a page of 1 and pageSize of 0 to get all data
  // then convert the data to csv and download it
  const transactionsResponse = await TransactionService.searchConfirmed({
    ...omit(params.searchParams, 'accountId', 'planId'),
    ...('planId' in params
      ? { accountLevel: AccountLevel.SubAccount, planId: params.planId }
      : { accountId: params.accountId, accountLevel: params.accountLevel }),
    page: 1,
    pageSize: 0,
    statuses: params.searchParams.statuses
  });

  DOMInteraction.triggerDownload(
    await json2csvParser(
      formatTransactions({
        gridRef: params.gridRef,
        includeColumns: params.includeColumns,
        transactions: transactionsResponse.results
      })
    ),
    `${'accountId' in params ? params.accountId : params.planId}-confirmed-transactions-${kebabCase(params.accountLevel)}.csv`
  );
};

export const exportPendingTransactions = async (
  params: CommonExportParams & {
    searchParams: PendingTransactionSearchRequest;
  }
) => {
  // rerun the query with a page of 1 and pageSize of 0 to get all data
  // then convert the data to csv and download it
  const transactionsResponse = await TransactionService.searchPending({
    ...omit(params.searchParams, 'accountId', 'planId'),
    ...('planId' in params
      ? { accountLevel: AccountLevel.SubAccount, planId: params.planId }
      : { accountId: params.accountId, accountLevel: params.accountLevel }),
    page: 1,
    pageSize: 0,
    statuses: params.searchParams.statuses
  });

  DOMInteraction.triggerDownload(
    await json2csvParser(
      formatTransactions({
        gridRef: params.gridRef,
        includeColumns: params.includeColumns,
        transactions: transactionsResponse.results
      })
    ),
    `${'accountId' in params ? params.accountId : params.planId}-pending-transactions-${kebabCase(params.accountLevel)}.csv`
  );
};
