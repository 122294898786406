import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { AlertDto, AlertTypeTotals } from '@/models/suba/alerts/AlertDTO.model';
import { AlertSearchRequest } from '@/models/suba/alerts/AlertSearchRequest.model';
import { AlertUpdateRequest } from '@/models/suba/alerts/AlertUpdateRequest.model';
import { AlertUpdateTransactionTypeRequest } from '@/models/suba/alerts/AlertUpdateTransactionTypeRequest.model';
import { InvestRolloverContributionRequest } from '@/models/suba/alerts/InvestRolloverContributionRequest.model';
import ApiService from '@/services/Api.service';

class AlertService {
  static async search(
    params: AlertSearchRequest
  ): Promise<PaginatedApiResponse<AlertDto[]>> {
    const result = await ApiService.getJson<PaginatedApiResponse<AlertDto[]>>(
      `alerts/`,
      params
    );
    return result;
  }

  static async searchTypeTotals(
    params: AlertSearchRequest
  ): Promise<AlertTypeTotals> {
    const result = await ApiService.getJson<AlertTypeTotals>(
      `alerts/search-type-totals`,
      params
    );
    return result;
  }

  static async getById(id: number): Promise<AlertDto> {
    const result = await ApiService.getJson<AlertDto>(`alerts/${id}`);
    return result;
  }

  static async update(
    id: number,
    params: AlertUpdateRequest
  ): Promise<AlertDto> {
    const result = await ApiService.patchJson<AlertUpdateRequest, AlertDto>(
      `alerts/${id}`,
      {
        ...params
      }
    );
    return result;
  }

  static async transferDepositCash(id: number): Promise<AlertDto> {
    const result = await ApiService.post<AlertDto>(
      `alerts/${id}/transfer-deposit-cash`
    );
    return result;
  }

  static async updateTransactionType(
    id: number,
    params: AlertUpdateTransactionTypeRequest
  ): Promise<AlertDto> {
    const result = await ApiService.patchJson<
      AlertUpdateTransactionTypeRequest,
      AlertDto
    >(`alerts/${id}/transaction-type`, {
      ...params
    });
    return result;
  }

  static async investRolloverContribution(
    id: number,
    params?: InvestRolloverContributionRequest
  ): Promise<AlertDto> {
    const result = await ApiService.postJson<
      InvestRolloverContributionRequest,
      AlertDto
    >(`alerts/${id}/invest-rollover-contribution`, params);
    return result;
  }
}

export default AlertService;
