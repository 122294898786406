import { CardContent } from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell
} from '@/components/data-table/DataTable.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { DisbursementRequestQueueStatusColorMap } from '@/models/suba/distributions/DisbursementRequestQueueStatusColorMap.model';
import { DisbursementStatusColorMap } from '@/models/suba/distributions/DisbursementStatusColorMap.model';
import { WorkflowContext } from '@/routes/suba/accounts/common/contexts/WorkflowContext';
import { DistributionService } from '@/services/suba/distributions/Distribution.service';
import formatters from '@/utils/Formatters';
import { Card, Divider, Unstable_Grid2 as Grid, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DistributionRequestQueueDisbursementsApiResource } from '@vestwell-sub-accounting/models/conductor/DistributionRequestQueueApiResource';

import { AxiosError } from 'axios';
import { useContext, useMemo } from 'react';

type DisbursementDetailCellRendererProps = {
  data: DistributionRequestQueueDisbursementsApiResource;
};

const DisbursementDetailCellRenderer = (
  props: DisbursementDetailCellRendererProps
) => {
  return (
    <Grid columns={14} container mt={0} p={2} spacing={{ lg: 4, xs: 2 }}>
      <Grid lg py={{ lg: 4, xs: 0 }} xs={14}>
        <Stack justifySelf='stretch' spacing={2}>
          <Divider textAlign='left'>General</Divider>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Fee Amount</TextLabel>
              <TextValue>
                {formatters.formatDollars(props.data.feeAmount)}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Check Method</TextLabel>
              <TextValue>
                {formatters.displayCase(
                  props.data.disbursementMethod.replace('ach', 'ACH')
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Overnight Account Number</TextLabel>
              <TextValue>{props.data.overnightAccountNumber}</TextValue>
            </TextStackItem>
          </TextStack>
          <Divider textAlign='left'>Payee</Divider>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Name</TextLabel>
              <TextValue>{props.data.payeeName}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Address Line 1</TextLabel>
              <TextValue>{props.data.payeeAddress}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Address Line 2</TextLabel>
              <TextValue>
                {props.data.payeeCity} {props.data.payeeState},{' '}
                {props.data.payeeZip} {props.data.payeeForeignCountry}
              </TextValue>
            </TextStackItem>
          </TextStack>
        </Stack>
      </Grid>
      <Grid xs='auto'>
        <Divider orientation='vertical' />
      </Grid>
      <Grid lg py={{ lg: 4, xs: 0 }} xs={14}>
        <Stack alignItems='stretch' justifySelf='stretch' spacing={2}>
          <Divider textAlign='left'>Receiving Bank</Divider>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Bank Name</TextLabel>
              <TextValue>{props.data.receivingBankName}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Account Name</TextLabel>
              <TextValue>{props.data.receivingBankAccountName}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Account Number</TextLabel>
              <TextValue>{props.data.receivingBankAccountNumber}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Routing Number</TextLabel>
              <TextValue>{props.data.receivingBankRoutingNumber}</TextValue>
            </TextStackItem>
          </TextStack>
          <Divider textAlign='left'>Memo</Divider>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Reference Memo</TextLabel>
              <TextValue>{props.data.referenceMemo}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Further Credit To Name</TextLabel>
              <TextValue>{props.data.furtherCreditToName}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Further Credit To Account</TextLabel>
              <TextValue>{props.data.furtherCreditToAccountNumber}</TextValue>
            </TextStackItem>
          </TextStack>
        </Stack>
      </Grid>
      <Grid xs='auto'>
        <Divider orientation='vertical' />
      </Grid>
      <Grid lg py={{ lg: 4, xs: 0 }} xs={14}>
        <Stack justifySelf='stretch' spacing={2}>
          <Divider textAlign='left'>Taxes</Divider>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>1099 Codes</TextLabel>
              <TextValue>Code {props.data._1099Code}</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Federal Withholding %</TextLabel>
              <TextValue>
                {formatters.formatPercent(
                  Number(props.data.federalWithholdingPercent),
                  { decimals: 2, fixed: true }
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>State Withholding %</TextLabel>
              <TextValue>
                {formatters.formatPercent(
                  Number(props.data.stateWithholdingPercent),
                  { decimals: 2, fixed: true }
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>State Withholding Code</TextLabel>
              <TextValue>{props.data.stateWithholdingCode}</TextValue>
            </TextStackItem>
          </TextStack>
          <Divider textAlign='left'>Roth</Divider>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Roth Basis Amount</TextLabel>
              <TextValue>
                {formatters.formatDollars(props.data.rothOrAfterTaxBasisAmount)}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Roth Initial Year</TextLabel>
              <TextValue>{props.data.rothInitialYear}</TextValue>
            </TextStackItem>
          </TextStack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const DisbursementsTab = () => {
  const workflow = useContext(WorkflowContext);

  const detailCellRenderer = useMemo(() => {
    return DisbursementDetailCellRenderer;
  }, []);

  const distributionQuery = useQuery(
    ['DistributionService.getByTracerId', workflow?.tracerId],
    () => DistributionService.getByTracerId(workflow?.tracerId),
    {
      enabled: Boolean(workflow?.tracerId),
      onError: (err: AxiosError) => {
        console.error(err.response?.data ? err.response.data : err.message);
      }
    }
  );

  return (
    <Card>
      <CardContent
        disablePadding
        overlayLoading={distributionQuery.isInitialLoading}>
        <DataTable
          columnDefs={[
            {
              cellRenderer: 'agGroupCellRenderer',
              width: 60
            },
            {
              field: 'id',
              headerName: 'Disbursement ID'
            },
            {
              cellRenderer: ({ data }) => (
                <DataTableBadgeCell
                  color={
                    DisbursementRequestQueueStatusColorMap[
                      data.disbursementRequestQueueStatus
                    ]
                  }>
                  {formatters.displayCase(data.disbursementRequestQueueStatus)}
                </DataTableBadgeCell>
              ),
              field: 'disbursementRequestQueueStatus',
              headerName: 'Request Status'
            },
            {
              cellRenderer: ({ data }) => (
                <DataTableBadgeCell
                  color={DisbursementStatusColorMap[data.status]}>
                  {formatters.displayCase(data.status)}
                </DataTableBadgeCell>
              ),
              field: 'status',
              headerName: 'Disbursement Status'
            },
            {
              field: 'amount',
              type: 'numericColumn',
              valueFormatter: ({ value }) =>
                value === 'full' ? 'Full' : formatters.formatDollars(value)
            },
            {
              field: 'disbursementType',
              headerName: 'Type',
              valueFormatter: ({ value }) => formatters.displayCase(value)
            },
            {
              field: 'disbursementMethod',
              headerName: 'Method',
              valueFormatter: ({ value }) =>
                formatters.displayCase(value.replace('ach', 'ACH'))
            },
            {
              field: 'taxType',
              valueFormatter: ({ value }) => formatters.displayCase(value)
            }
          ]}
          columnSizing='fit'
          detailCellRenderer={detailCellRenderer}
          detailRowAutoHeight
          rowData={distributionQuery.data?.disbursements}
        />
      </CardContent>
    </Card>
  );
};
