import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { DividendActivityQueueDto } from '@/models/suba/dividends/dividend-activity-queue-dto.model';
import { DividendActivityTransactionDto } from '@/models/suba/dividends/dividend-activity-transaction-dto.model';
import { TransactionDto } from '@/models/suba/transactions/TransactionDTO.model';
import DividendService from '@/services/suba/dividends/Dividend.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import { useState } from 'react';

type AssociatedParentAccountDialogProps = {
  dividendActivityQueue: DividendActivityQueueDto;
  onClose?: () => void;
  open: boolean;
};

export const AssociatedParentAccountDialog = (
  props: AssociatedParentAccountDialogProps
) => {
  const { showSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState<keyof TransactionDto | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >();

  const searchDividendActivityTransactionsQuery = useQuery(
    [
      'DividendService.searchDividendActivityTransactions',
      props.dividendActivityQueue,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () =>
      DividendService.searchDividendActivityTransactions({
        dividendActivityId: props.dividendActivityQueue.id,
        orderBy: orderBy || 'tradeDate',
        orderByDirection: orderByDirection || 'desc',
        page,
        pageSize,
        transactionBaseType: [TransactionBaseType.Buy]
      }),
    {
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Transactions search failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const columnDefs: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: DividendActivityTransactionDto }) => {
        const name =
          props.dividendActivityQueue.parentAccount?.accountType ===
            'SuperOmnibus' ||
          props.dividendActivityQueue.parentAccount?.accountType === 'House'
            ? props.dividendActivityQueue.parentAccount?.accountName
            : props.dividendActivityQueue.parentAccount?.planParentAccount
                .planId;
        return (
          <DataTableStackCell
            primary={name || ''}
            primaryLinkProps={
              props.dividendActivityQueue.parentAccount?.id
                ? {
                    to: `/ops/accounts/${props.dividendActivityQueue.parentAccount.id}`
                  }
                : undefined
            }
            secondary={
              cellData.data?.subAccount.id
                ? `ID: ${cellData.data?.subAccount.id}`
                : ''
            }
          />
        );
      },
      field: 'parentAccount.parentAccountId',
      headerName: 'Sub Accounts',
      minWidth: 420,
      sortable: false
    },
    {
      cellRenderer: (cellData: { data: DividendActivityTransactionDto }) => {
        return cellData.data.amount
          ? formatters.formatDollars(cellData.data.amount)
          : '';
      },
      field: 'amount',
      headerName: 'Ttl. Paid Amount',
      resizable: true,
      sortable: true,
      type: 'numericColumn'
    },
    {
      cellRenderer: (cellData: { data: DividendActivityTransactionDto }) => {
        return cellData.data.units
          ? formatters.formatDecimal(cellData.data.units, 3)
          : '';
      },
      field: 'units',
      headerName: 'Ttl. Reinvest Units',
      resizable: true,
      sortable: true,
      type: 'numericColumn'
    }
  ];

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof TransactionDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return (
    <Dialog fullWidth maxWidth='md' open={props.open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        Associated Parent Account
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Box
          data-testid='associated-parent-account-summary'
          sx={{
            bgcolor: theme => theme.palette.primary.light,
            px: 3.25,
            py: 2
          }}>
          <Typography>
            {props.dividendActivityQueue.parentAccount?.accountType ===
              'SuperOmnibus' ||
            props.dividendActivityQueue.parentAccount?.accountType === 'House'
              ? props.dividendActivityQueue.parentAccount?.accountName
              : props.dividendActivityQueue.parentAccount?.planParentAccount
                  .planId}
          </Typography>
          <Typography
            sx={{
              color: theme => theme.palette.grey[600],
              fontSize: 14
            }}>
            ID: ${props.dividendActivityQueue.parentAccount.id}
          </Typography>
        </Box>
        <Card>
          <CardContent
            disablePadding
            overlayLoading={searchDividendActivityTransactionsQuery.isFetching}>
            <DataTable
              columnDefs={columnDefs}
              columnSizing='flex'
              data-testid='parent-account-transactions-data-table'
              emptyPlaceholderComponent={
                <Stack
                  alignItems='center'
                  data-testid='no-data-parent-account-transactions-table'
                  justifyContent='center'
                  sx={{ height: '100%' }}>
                  <CardPlaceholder
                    icon={<SearchIcon fontSize='inherit' />}
                    subtitle='No results found.'
                  />
                </Stack>
              }
              onPageChanged={handlePageChanged}
              onPageSizeChanged={handlePageSizeChanged}
              onSortChanged={handleSortChanged}
              page={page}
              pageSize={pageSize}
              pagination
              paginationSource='server'
              paginationTotal={
                searchDividendActivityTransactionsQuery.data?.pagination?.total
              }
              rowData={
                searchDividendActivityTransactionsQuery.data?.results || []
              }
              sort={
                orderBy
                  ? [
                      {
                        colId: orderBy,
                        sort: orderByDirection
                      }
                    ]
                  : []
              }
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
