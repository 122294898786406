import LinearLoading from '@/components/linear-loading';
import ParentAccountOrderService from '@/services/suba/parent-account-orders/ParentAccountOrder.service';
import formatters from '@/utils/Formatters';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { FC, useMemo } from 'react';

type ParentAccountOrderInfoBannerProps = {
  parentAccountOrderId: number;
};

export const ParentAccountOrderInfoBanner: FC<
  ParentAccountOrderInfoBannerProps
> = props => {
  const getParentAccountOrderQuery = useQuery(
    ['ParentAccountOrderService.getById', props.parentAccountOrderId],
    () => ParentAccountOrderService.getById(props.parentAccountOrderId),
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
      }
    }
  );

  const orderAmount = useMemo(
    () =>
      getParentAccountOrderQuery.data?.orderAmount
        ? formatters.formatDollars(getParentAccountOrderQuery.data?.orderAmount)
        : '',
    [getParentAccountOrderQuery.data?.orderAmount]
  );
  const orderUnits = useMemo(
    () =>
      getParentAccountOrderQuery.data?.orderUnits
        ? formatters.formatDollars(getParentAccountOrderQuery.data?.orderUnits)
        : '',
    [getParentAccountOrderQuery.data?.orderUnits]
  );
  const tradeDate = useMemo(
    () =>
      getParentAccountOrderQuery.data?.tradeDate
        ? dayjs(getParentAccountOrderQuery.data?.tradeDate).format('MM/DD/YYYY')
        : '',
    [getParentAccountOrderQuery.data?.tradeDate]
  );
  const tradeType = useMemo(
    () => capitalize(getParentAccountOrderQuery.data?.tradeType),
    [getParentAccountOrderQuery.data?.tradeType]
  );

  return (
    <Box
      aria-busy={getParentAccountOrderQuery.isInitialLoading}
      data-testid='parent-account-info-banner'>
      {getParentAccountOrderQuery.isInitialLoading ? (
        <LinearLoading />
      ) : (
        <Box
          sx={{
            bgcolor: theme => theme.palette.primary.light,
            px: 3.25,
            py: 2
          }}>
          <Typography data-testid='parent-account-order-info'>
            {getParentAccountOrderQuery.data?.securityOrderType ===
              SecurityOrderType.DOLLAR_ALL ||
            getParentAccountOrderQuery.data?.securityOrderType ===
              SecurityOrderType.DOLLAR_ORDER
              ? `${orderAmount} ${tradeType} order for ${getParentAccountOrderQuery.data?.security.symbol} (${getParentAccountOrderQuery.data?.security.cusip}), traded on ${tradeDate}`
              : `${orderUnits} units ${tradeType} order for ${getParentAccountOrderQuery.data?.security.symbol} (${getParentAccountOrderQuery.data?.security.cusip}), traded on ${tradeDate}`}
          </Typography>
          <Typography
            data-testid='parent-account-order-id'
            sx={{
              color: theme => theme.palette.grey[600],
              fontSize: 12,
              lineHeight: 2
            }}>
            ID: {getParentAccountOrderQuery.data?.ourParentOrderId}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
