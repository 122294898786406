import { TransferCashDeferRequest } from '@/models/suba/transfer-cash/transfer-cash-defer-request.model';
import { TransferCashDeferResponse } from '@/models/suba/transfer-cash/transfer-cash-defer-response.model';
import { TransferCashManualCompletionRequest } from '@/models/suba/transfer-cash/transfer-cash-manual-completion-request.model';
import { TransferCashManualCompletionResponse } from '@/models/suba/transfer-cash/transfer-cash-manual-completion-response.model';
import { TransferCashPostRequest } from '@/models/suba/transfer-cash/transfer-cash-post-request.model';
import { TransferCashPostResponse } from '@/models/suba/transfer-cash/transfer-cash-post-response.model';
import { TransferCashRetryRequest } from '@/models/suba/transfer-cash/transfer-cash-retry-request.model';
import { TransferCashRetryResponse } from '@/models/suba/transfer-cash/transfer-cash-retry-response.model';
import ApiService from '@/services/Api.service';

export class TransferCashService {
  static async post(params: TransferCashPostRequest) {
    return ApiService.postJson<
      TransferCashPostRequest,
      TransferCashPostResponse
    >(`transfer-cash/`, params);
  }

  static async defer(params: TransferCashDeferRequest) {
    return ApiService.postJson<
      TransferCashDeferRequest,
      TransferCashDeferResponse
    >(`transfer-cash/defer`, params);
  }

  static async retry(params: TransferCashRetryRequest) {
    return ApiService.postJson<
      TransferCashRetryRequest,
      TransferCashRetryResponse
    >(`transfer-cash/retry`, params);
  }

  static async manualCompletion(params: TransferCashManualCompletionRequest) {
    return ApiService.postJson<
      TransferCashManualCompletionRequest,
      TransferCashManualCompletionResponse
    >(`transfer-cash/manual-completion`, params);
  }
}
