import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import {
  ReconExceptionApiQueryServiceRequest,
  ReconExceptionDto,
  ReconExceptionsGetTotalsResponse
} from '@/models/suba/recon/ReconException.model';
import { UpdateReconExceptionRequest } from '@/models/suba/recon/UpdateReconExceptionRequest.model';
import ApiService from '@/services/Api.service';

class ReconExceptionService {
  static async search(
    params: ReconExceptionApiQueryServiceRequest
  ): Promise<PaginatedApiResponse<ReconExceptionDto[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<ReconExceptionDto[]>
    >(`recon-exceptions/`, params);
    return result;
  }

  static async searchRelated(
    params: ReconExceptionApiQueryServiceRequest
  ): Promise<PaginatedApiResponse<ReconExceptionDto[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<ReconExceptionDto[]>
    >(`recon-exceptions/related`, params);
    return result;
  }

  static async getById(id: number): Promise<ReconExceptionDto> {
    const result = await ApiService.getJson<ReconExceptionDto>(
      `recon-exceptions/${id}`
    );
    return result;
  }

  static async update(
    id: number,
    params: UpdateReconExceptionRequest
  ): Promise<void> {
    return ApiService.patchJson<UpdateReconExceptionRequest, void>(
      `recon-exceptions/${id}`,
      { ...params }
    );
  }

  static async getTotalCounts(
    params:
      | {
          parentAccountId: string;
        }
      | {
          planId: string;
        }
  ): Promise<ReconExceptionsGetTotalsResponse> {
    return ApiService.getJson(`recon-exceptions/total-counts`, params);
  }
}

export default ReconExceptionService;
