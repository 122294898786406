import AccessControl from '@/components/access-control/AccessControl.component';
import LinearLoading from '@/components/linear-loading';
import { useHeap } from '@/hooks';
import { useFixPlanRoute } from '@/hooks/useFixPlanRoute.hook';
import { PlanV2Dto } from '@/models';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { router } from '@/router';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import { pick } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage, useToggle } from 'react-use';

import { Header, SpreadSheet } from './components';
import { generateCorrections, generateReversals } from './helpers';
import { Correction, GridRow } from './types';

const useStyles = makeStyles(() => ({
  totalCard: {
    marginRight: 32
  },
  totalContainer: {
    marginBottom: 20
  },
  totalText: {
    marginBottom: 4
  }
}));

type PlanContributionCorrectionRouteProps = {
  sponsorPlanId: string;
  ucid: string;
  planType: string;
};

const PlanContributionCorrectionRoute: FunctionComponent = () => {
  const [initialRows, setInitialRows] =
    useSessionStorage<GridRow[]>('initialRows');
  const [rows, setRows] = useSessionStorage<GridRow[]>('rows');
  const [info, setInfo] = useSessionStorage<{
    modifiedRowIndexes: number[];
    type: 'file' | 'manual';
  }>('info', { modifiedRowIndexes: [], type: 'manual' });

  const [corrections, setCorrections] = useSessionStorage<
    Correction[] | undefined
  >('corrections', undefined);

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<PlanContributionCorrectionRouteProps>();

  const [isCancelOpen, toggleCancelOpen] = useToggle(false);
  const [errors, setErrors] = useState({});

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, params.sponsorPlanId],
    () => PlanService.getPlanById(params.sponsorPlanId),
    {
      enabled: !!params.sponsorPlanId,
      staleTime: Infinity
    }
  );

  useFixPlanRoute();

  const participants = useQuery(
    [PlanService.getParticipantsBasicDetails.name, plan.data?.data?.id],
    () =>
      PlanService.getParticipantsBasicDetails(plan.data?.data?.id, {
        masked: false
      }),
    { enabled: !!plan.data?.data?.id }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      plan.data?.data?.id,
      params.ucid
    ],
    async () => {
      return ContributionService.getContributionDetails({
        planId: plan.data?.data?.id,
        sort: 'lastName',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: params.ucid
      });
    },
    {
      enabled: !!params.ucid && !!plan.data?.data,
      refetchOnWindowFocus: false
    }
  );

  const planFeatures = useQuery<PlanFeaturesDto>(
    [PlanService.getPlanFeatures.name, plan.data?.data?.id],
    () => PlanService.getPlanFeatures(plan.data?.data?.id),
    {
      enabled: !!plan.data
    }
  );

  const total = useMemo(
    () => formatters.formatDollars(contribution.data?.totals?.total),
    [contribution.data]
  );

  const isReviewDisabled = useMemo(
    () =>
      !!Object.keys(errors ?? {})?.length ||
      (info.type === 'manual' && !info.modifiedRowIndexes?.length),
    [errors, info]
  );

  const user = useMemo(() => userService.getUser(), []);

  useHeap(user);

  const onCancel = useCallback(
    () =>
      navigate(
        `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/overview`,
        { replace: true }
      ),
    [params, navigate]
  );

  const onReview = useCallback(() => {
    const result = generateCorrections(
      initialRows,
      rows,
      planFeatures.data?.data?.attributes
    );
    setCorrections(result);
  }, [setCorrections, initialRows, rows, planFeatures.data]);

  useEffect(() => {
    if (
      initialRows ||
      !participants.data ||
      !contribution.data ||
      !planFeatures.data
    )
      return;

    const participantsHash = new Map(
      participants.data?.data?.map(participant => [
        participant.id,
        participant.attributes?.ssn
      ])
    );

    const data =
      contribution.data.participantData?.map((participant, index) => ({
        ...pick(participant, [
          'participantId',
          'lastName',
          'firstName',
          'contribution'
        ]),
        ...pick(participant.accountMeta, [
          'sd',
          'rc',
          'sh',
          'em',
          'ps',
          'ln',
          'qc',
          'qm',
          ...(planFeatures.data?.data?.attributes?.allowPrevailingWage
            ? ['pw']
            : []),
          ...(planFeatures.data?.data?.attributes?.isAfterTaxContributionAllowed
            ? ['at']
            : [])
        ]),
        ssn: formatSsn(participantsHash.get(participant.participantId) ?? ''),
        uuid: index?.toString()
      })) ?? [];

    setInitialRows(data);
    setRows(data);
  }, [
    participants.data,
    contribution.data,
    initialRows,
    setInitialRows,
    setRows,
    planFeatures.data
  ]);

  useEffect(() => {
    if (location?.state?.isFullReversal && initialRows && planFeatures.data) {
      const result = generateReversals(
        initialRows,
        planFeatures.data?.data?.attributes
      );
      setCorrections(result);
    }
  }, [location, setCorrections, initialRows, planFeatures.data]);

  useEffect(() => {
    if (!corrections) return;

    if (location?.state?.isFullReversal) {
      navigate(
        `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/corrections/overview`,
        {
          replace: true,
          state: { isFullReversal: location?.state?.isFullReversal }
        }
      );
      return;
    }

    navigate(
      `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/corrections/overview`,
      {
        state: { isFullReversal: location?.state?.isFullReversal }
      }
    );
  }, [corrections, navigate, location, params]);

  useEffect(() => {
    const cleanSessionStorage = router.subscribe(state => {
      if (
        state.location.pathname !==
        `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/corrections/overview`
      ) {
        sessionStorage.removeItem('corrections');
        sessionStorage.removeItem('initialRows');
        sessionStorage.removeItem('rows');
        sessionStorage.removeItem('info');
      }
    });

    return () => cleanSessionStorage();
  }, [params, location.pathname]);

  return (
    <div>
      {location?.state?.isFullReversal && <LinearLoading />}
      {!location?.state?.isFullReversal && (
        <>
          <Box sx={{ mb: 3 }}>
            <Header
              label={`Correct ${contribution.data?.payrollDate} Contribution`}
              payrollDate={contribution.data?.payrollDate}
              planId={params.sponsorPlanId}
              planName={plan.data?.data?.attributes?.name}
              ucid={params.ucid}>
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_CONTRIBUTION_CORRECTIONS
                ]}>
                <Button
                  data-testid='correction-cancel-button'
                  onClick={toggleCancelOpen}
                  sx={{ mr: 2 }}
                  variant='outlined'>
                  Cancel
                </Button>
                <LoadingButton
                  data-testid='correction-review-button'
                  disabled={isReviewDisabled}
                  onClick={onReview}
                  variant='contained'>
                  Next: Review Changes
                </LoadingButton>
              </AccessControl>
            </Header>
          </Box>
          <Box className={classes.totalContainer} display='flex'>
            <div className={classes.totalCard}>
              <Typography
                className={classes.totalText}
                color='GrayText'
                variant='body2'>
                Total Contribution
              </Typography>
              <Typography data-testid='correction-total'>{total}</Typography>
            </div>
            <div>
              <Typography
                className={classes.totalText}
                color='GrayText'
                variant='body2'>
                Paygroup
              </Typography>
              <Typography data-testid='correction-paygroup'>
                {contribution.data?.payGroupName ?? '--'}
              </Typography>
            </div>
          </Box>
          <SpreadSheet
            errors={errors}
            info={info}
            initialRows={initialRows}
            planFeatures={planFeatures.data?.data?.attributes}
            planId={params.sponsorPlanId}
            rows={rows}
            setErrors={setErrors}
            setInfo={setInfo}
            setInitialRows={setInitialRows}
            setRows={setRows}
            sponsorId={params.sponsorPlanId}
            ucid={params.ucid}
          />
        </>
      )}
      <Dialog
        data-testid='correction-dialog'
        fullWidth
        maxWidth='sm'
        onClose={toggleCancelOpen}
        open={isCancelOpen}>
        <DialogTitle>Cancel Correction</DialogTitle>
        <DialogContent>
          <DialogContentText>All changes will be removed.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='correction-dialog-close-button'
            onClick={toggleCancelOpen}>
            Back to Edit
          </Button>
          <Button
            data-testid='correction-dialog-confirm-button'
            onClick={onCancel}
            variant='contained'>
            Cancel Correction
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlanContributionCorrectionRoute;
