import Badge from '@/components/badge';
import CopyToClipboard from '@/components/copy-to-clipboard';
import LinearLoading from '@/components/linear-loading';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import {
  CreateSdbaSetupRequestDto,
  CreateSdbaSetupSchema,
  SdbaService,
  UpdateSdbaSetupRequestDto,
  UpdateSdbaSetupSchema
} from '@/services/Sdba.service';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import * as yup from 'yup';

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

const StyledTextStack = styled(TextStack)(() => ({
  flexBasis: '50%'
}));

const StyledTextLabel = styled(TextLabel)(() => ({
  width: '130px'
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: 14,
  fontWeight: 500,
  textTransform: 'uppercase'
}));

export const SdbaSetup: React.FC<{ planId: number }> = props => {
  const { userHasValidToken } = useUserToken();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const sdbaSetupQuery = useQuery(
    ['SdbaService.getSdbaSetup', props.planId],
    () => SdbaService.getSdbaSetup(props.planId),
    {
      enabled: userHasValidToken && !!props.planId,
      staleTime: Infinity
    }
  );

  const createSdbaSetupMutation = useMutation(
    ['SdbaService.createSdbaSetup', props.planId],
    async (body: CreateSdbaSetupRequestDto) =>
      await SdbaService.createSdbaSetup(props.planId, body)
  );

  const updateSdbaSetupMutation = useMutation(
    ['SdbaService.updateSdbaSetup', props.planId],
    async (body: UpdateSdbaSetupRequestDto) =>
      await SdbaService.updateSdbaSetup(props.planId, body)
  );

  const showSdbaSetupModal = (action: 'create' | 'edit') => {
    const isEditSdbaSetup = action === 'edit';

    const sdbaSetupCreateAndEditFields = [
      {
        /* eslint-disable sort-keys-plus/sort-keys */
        fields: {
          minVestedBalance: {
            dataInputTestId: 'sdba-setup-modal-min-vested-balance-input',
            dataLabelTestId: 'sdba-setup-modal-min-vested-balance-label',
            initialValue: isEditSdbaSetup
              ? sdbaSetupQuery.data?.minVestedBalance?.toString()
              : '8000',
            isFieldRequired: true,
            groupLabel: 'Participant Settings',
            label: 'Min Vested Balance $'
          },
          maxBalance: {
            dataInputTestId: 'sdba-setup-modal-max-sdba-balance-input',
            dataLabelTestId: 'sdba-setup-modal-max-sdba-balance-label',
            initialValue: isEditSdbaSetup
              ? sdbaSetupQuery.data?.maxBalance?.toString()
              : '50',
            isFieldRequired: true,
            groupLabel: 'Participant Settings',
            label: 'Max SDBA Balance %'
          },
          sdbaPlanId: {
            dataInputTestId: 'sdba-setup-modal-plan-registration-id-input',
            dataLabelTestId: 'sdba-setup-modal-plan-registration-id-label',
            initialValue: isEditSdbaSetup
              ? sdbaSetupQuery.data?.sdbaPlanId
              : '',
            isFieldRequired: true,
            groupLabel: 'Registration',
            label: 'Registration ID'
          },
          planAccessCode: {
            dataInputTestId: 'sdba-setup-modal-plan-access-code-input',
            dataLabelTestId: 'sdba-setup-modal-plan-access-code-label',
            initialValue: isEditSdbaSetup
              ? sdbaSetupQuery.data?.planAccessCode
              : '',
            isFieldRequired: true,
            groupLabel: 'Registration',
            label: 'Access Code'
          }
        },
        /* eslint-enable sort-keys-plus/sort-keys */
        stepValidationSchema: yup.object({
          maxBalance: yup
            .number()
            .typeError('Max SDBA Balance must be a number')
            .integer('Max SDBA Balance must be an integer')
            .min(0, 'Max SDBA Balance must be greater than or equal to 0')
            .max(75, 'Max SDBA Balance must be less than or equal to 75')
            .required('Max SDBA Balance is required'),
          minVestedBalance: yup
            .number()
            .typeError('Min Vested Balance must a number')
            .integer('Min Vested Balance must be an integer')
            .min(
              8000,
              'Min Vested Balance must be greater than or equal to 8000'
            )
            .required('Min Vested Balance is required'),
          planAccessCode: yup
            .string()
            .trim()
            .required('Access Code is required'),
          sdbaPlanId: yup
            .string()
            .trim()
            .required('Registration ID is required')
        }),
        title: `${isEditSdbaSetup ? 'Edit' : 'Set Up'} Self-Directed Brokerage Account ${isEditSdbaSetup ? 'Settings' : ''}`
      }
    ];

    openDialog({
      disableSubmitWhenInvalid: true,
      onSubmit: async currentValues => {
        try {
          if (!isEditSdbaSetup) {
            await createSdbaSetupMutation.mutateAsync(
              await CreateSdbaSetupSchema.validate(currentValues)
            );
          } else {
            const editedValues = Object.keys(currentValues).reduce(
              (editedValues, field) => {
                if (currentValues[field] !== sdbaSetupQuery.data?.[field]) {
                  editedValues[field] = currentValues[field];
                }

                return editedValues;
              },
              {} as UpdateSdbaSetupRequestDto
            );

            await updateSdbaSetupMutation.mutateAsync(
              await UpdateSdbaSetupSchema.validate(editedValues)
            );
          }

          showSnackbar({
            message: `Self-Directed Brokerage Account ${isEditSdbaSetup ? 'Settings edited' : 'created'}`,
            severity: 'success'
          });

          queryClient.invalidateQueries([
            'SdbaService.getSdbaSetup',
            props.planId
          ]);
        } catch (err: any) {
          showSnackbar({
            message: `Error ${
              !isEditSdbaSetup ? 'creating' : 'editing'
            } Self-Directed Brokerage Account!`,
            severity: 'error'
          });

          return Promise.reject(err);
        }
      },
      steps: sdbaSetupCreateAndEditFields
    });
  };

  return (
    <Card data-testid='sdba-setup-card' elevation={0} variant='outlined'>
      <LinearLoading fadeIn={sdbaSetupQuery.isLoading} />
      <CardHeader
        action={
          sdbaSetupQuery.data ? (
            <StyledButton
              data-testid='sdba-setup-card-button'
              onClick={() => showSdbaSetupModal('edit')}>
              Edit
            </StyledButton>
          ) : (
            <StyledButton
              data-testid='sdba-setup-card-button'
              onClick={() => showSdbaSetupModal('create')}
              variant='outlined'>
              Enable
            </StyledButton>
          )
        }
        data-testid='sdba-setup-card-header'
        sx={{ p: 3 }}
        title='Self-Directed Brokerage Account'
      />
      {sdbaSetupQuery.data && (
        <>
          <Divider sx={{ ml: 3, mr: 3 }} />
          <CardContent
            data-testid='sdba-setup-card-content'
            // TODO: The "important" is needed here only because of the theme level padding bottom override that should be removed
            sx={{ p: '24px !important' }}>
            <Stack
              direction='row'
              divider={<Divider flexItem orientation='vertical' />}
              spacing={3}>
              <StyledTextStack direction='column'>
                <TextStackItem>
                  <StyledTextLabel>Set Up Status</StyledTextLabel>
                  <TextValue data-testid='sdba-setup-card-status-badge'>
                    <Badge
                      color={
                        sdbaSetupQuery.data?.sdbaStatus === 'Active'
                          ? 'success'
                          : 'neutral'
                      }
                      sx={{
                        bottom: 2,
                        position: 'relative'
                      }}>
                      {sdbaSetupQuery.data?.sdbaStatus}
                    </Badge>
                  </TextValue>
                </TextStackItem>

                <TextStackItem>
                  <StyledTextLabel>Plan Registration ID</StyledTextLabel>
                  <TextValue data-testid='sdba-setup-card-plan-registration-id'>
                    {sdbaSetupQuery.data?.sdbaPlanId}
                  </TextValue>
                </TextStackItem>

                <TextStackItem>
                  <StyledTextLabel>Plan Access Code</StyledTextLabel>
                  <TextValue data-testid='sdba-setup-card-plan-access-code'>
                    {sdbaSetupQuery.data?.planAccessCode}
                  </TextValue>
                </TextStackItem>

                <TextStackItem data-testid='sdba-setup-card-copy-to-clipboard'>
                  <CopyToClipboard
                    copyName='Registration Url'
                    copyValue='http://www.schwab.com/pcraopen'
                    size='small'
                    tooltipSx={{
                      right: -200,
                      top: -4
                    }}>
                    <StyledTypography>Copy Registration Url</StyledTypography>
                  </CopyToClipboard>
                </TextStackItem>
              </StyledTextStack>

              <StyledTextStack direction='column'>
                <TextStackItem>
                  <StyledTextLabel>Min Vested Balance</StyledTextLabel>
                  <TextValue data-testid='sdba-setup-card-min-vested-balance'>
                    ${sdbaSetupQuery.data?.minVestedBalance}
                  </TextValue>
                </TextStackItem>

                <TextStackItem>
                  <StyledTextLabel>Max SDBA Balance</StyledTextLabel>
                  <TextValue data-testid='sdba-setup-card-max-sdba-balance'>
                    {sdbaSetupQuery.data?.maxBalance}%
                  </TextValue>
                </TextStackItem>
              </StyledTextStack>
            </Stack>
          </CardContent>
        </>
      )}
    </Card>
  );
};
