import Card from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useGetSubaPlanQuery } from '@/hooks/suba/useGetSubaPlanQuery.hook';
import { useParentAccountById } from '@/hooks/suba/useParentAccountById.hook';
import { useSearchAlertTypeTotals } from '@/hooks/suba/useSearchAlertTypeTotals.hook';
import { SubaPlanIncludeOption } from '@/models/suba/suba-plans/SubaPlanIncludeOption.model';
import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import formatters from '@/utils/Formatters';
import {
  Alert,
  Button,
  CardContent,
  Unstable_Grid2 as Grid,
  Link,
  Skeleton,
  Typography
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';

import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useSubaPlansDetailRouteParams } from '../hooks/useSubaPlansDetailRouteParams.hook';

export const SubaPlanDetailsHeader: FC = () => {
  const routeParams = useSubaPlansDetailRouteParams();

  const getSubaPlanQuery = useGetSubaPlanQuery({
    include: [SubaPlanIncludeOption.marketValueAndCashBalance],
    planId: routeParams.planId
  });

  const getParentAccountByIdQuery = useParentAccountById({
    accountId: routeParams.accountId
  });

  const getAlertTypeTotals = useSearchAlertTypeTotals({
    alertStatus: [
      AlertStatus.Blocked,
      AlertStatus.InProcess,
      AlertStatus.Open,
      AlertStatus.OutreachNeeded,
      AlertStatus.Waiting
    ],
    planId: routeParams.planId
  });

  const getReconExceptionTotalCountsQuery = useQuery(
    ['ReconExceptionsService.getTotalCounts', routeParams.planId],
    async () =>
      ReconExceptionService.getTotalCounts({
        planId: routeParams.planId
      })
  );

  return (
    <Grid
      alignItems='stretch'
      columns={4}
      container
      data-testid='plan-detail-header-fields'
      spacing={2}>
      <Grid lg={1} xs={2}>
        <Card data-testid='balance-card' minHeight='100%' minWidth='auto'>
          <CardContent>
            <Stack justifyContent='space-between' spacing={2}>
              <Stack spacing={2}>
                <Typography variant='body1'>Balance</Typography>
                <TextStack
                  direction='column'
                  spacing={0.5}
                  textValueAlign='right'>
                  <TextStackItem>
                    <TextLabel>Market value</TextLabel>
                    <TextValue data-testid='market-value'>
                      {getSubaPlanQuery.data?.totalMarketValue
                        ? formatters.formatDollars(
                            getSubaPlanQuery.data?.totalMarketValue
                          )
                        : undefined}
                    </TextValue>
                  </TextStackItem>

                  <TextStackItem>
                    <TextLabel>Total cash balance</TextLabel>
                    <TextValue data-testid='total-cash-balance'>
                      {getSubaPlanQuery.data?.cashBalance
                        ? formatters.formatDollars(
                            getSubaPlanQuery.data?.cashBalance
                          )
                        : undefined}
                    </TextValue>
                  </TextStackItem>

                  <TextStackItem>
                    <TextLabel>Sub account debit</TextLabel>
                    <TextValue data-testid='sub-account-debit'>
                      {getSubaPlanQuery.data?.debitSubAccountsCount >= 0 &&
                        `${getSubaPlanQuery.data?.debitSubAccountsCount} sub acct.`}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Stack>

              {getSubaPlanQuery.data?.debitSubAccountsCount > 0 && (
                <Alert
                  action={
                    <Button
                      color='warning'
                      component={RouterLink}
                      data-testid='negative-balance-alert-view-button'
                      size='small'
                      to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/sub-accounts?filterDebitSubAccounts=true`}>
                      View
                    </Button>
                  }
                  data-testid='negative-balance-alert'
                  severity='warning'>
                  Negative balance
                </Alert>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={1} xs={2}>
        <Card
          data-testid='short-positions-card'
          minHeight='100%'
          minWidth='auto'>
          <CardContent>
            <Stack justifyContent='space-between' spacing={2}>
              <Stack spacing={2}>
                <Typography variant='body1'>Short Positions</Typography>
                <TextStack
                  direction='column'
                  spacing={0.5}
                  textValueAlign='right'>
                  <TextStackItem>
                    <TextLabel>Parent account</TextLabel>
                    <TextValue data-testid='parent-account-short-positions'>
                      {
                        getParentAccountByIdQuery.data
                          ?.parentAccountShortPositionsCount
                      }
                    </TextValue>
                  </TextStackItem>

                  <TextStackItem>
                    <TextLabel>Sub accounts short</TextLabel>
                    <TextValue data-testid='sub-accounts-short-positions'>
                      {
                        getParentAccountByIdQuery.data
                          ?.subAccountShortPositionsCount
                      }
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Stack>

              {(getParentAccountByIdQuery.data?.subAccountShortPositionsCount >
                0 ||
                getParentAccountByIdQuery.data
                  ?.parentAccountShortPositionsCount > 0) && (
                <Stack>
                  <Alert
                    action={
                      <Button
                        color='warning'
                        component={RouterLink}
                        data-testid='short-positions-view-button'
                        size='small'
                        to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/holdings?filterToShortPositions=true`}>
                        View
                      </Button>
                    }
                    data-testid='short-positions-alert'
                    severity='warning'>
                    Short Positions
                  </Alert>
                </Stack>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={1} xs={2}>
        <Card
          data-testid='outstanding-tickets-card'
          minHeight='100%'
          minWidth='auto'>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant='body1'>Outstanding Tickets</Typography>

              <TextStack
                direction='column'
                spacing={0.5}
                textValueAlign='right'>
                {getAlertTypeTotals.isFetching ? (
                  <Skeleton height={40} variant='rectangular' />
                ) : (
                  <TextStackItem>
                    <TextLabel>Alerts</TextLabel>
                    <TextValue>
                      <Link
                        component={RouterLink}
                        data-testid='alert-tickets-link'
                        to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/alerts`}>
                        {!getAlertTypeTotals.isFetching &&
                          getAlertTypeTotals.isSuccess &&
                          getAlertTypeTotals.data &&
                          `${Object.values(getAlertTypeTotals.data).reduce(
                            (sum, count) => (sum += count),
                            0
                          )} tickets`}
                        {!getAlertTypeTotals.isFetching &&
                          getAlertTypeTotals.isError && (
                            <span data-testid='alert-tickets-placeholder'>
                              --
                            </span>
                          )}
                      </Link>
                    </TextValue>
                  </TextStackItem>
                )}
                {getReconExceptionTotalCountsQuery.isFetching ? (
                  <Skeleton height={40} variant='rectangular' />
                ) : (
                  <TextStackItem>
                    <TextLabel>Recon</TextLabel>
                    <TextValue>
                      <Link
                        component={RouterLink}
                        data-testid='recon-tickets-link'
                        to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/recon`}>
                        {!getReconExceptionTotalCountsQuery.isFetching &&
                          getReconExceptionTotalCountsQuery.isSuccess &&
                          getReconExceptionTotalCountsQuery.data &&
                          `${getReconExceptionTotalCountsQuery.data?.counts[0].totalCount} tickets`}
                        {!getReconExceptionTotalCountsQuery.isFetching &&
                          getReconExceptionTotalCountsQuery.isError && (
                            <span data-testid='recon-tickets-placeholder'>
                              --
                            </span>
                          )}
                      </Link>
                    </TextValue>
                  </TextStackItem>
                )}
              </TextStack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid lg={1} xs={2}>
        <Card data-testid='general-info-card' minHeight='100%' minWidth='auto'>
          <CardContent>
            <TextStack direction='column' spacing={0.5}>
              <TextStackItem>
                <TextLabel>Plan Type</TextLabel>
                <TextValue data-testid='plan-type'>
                  {formatters.displayCase(getSubaPlanQuery.data?.planSetupType)}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Parent Account</TextLabel>
                <TextValue
                  copyLabel={`ID: ${getParentAccountByIdQuery.data?.parentAccountId}`}
                  copyValue={getParentAccountByIdQuery.data?.parentAccountId}
                  copyable
                  data-testid='parent-account'>
                  {formatters.displayCase(
                    getParentAccountByIdQuery.data?.accountType
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Custodian</TextLabel>
                <TextValue
                  copyLabel={`ID: ${getParentAccountByIdQuery.data?.custodianAccountNumber}`}
                  copyValue={
                    getParentAccountByIdQuery.data?.custodianAccountNumber
                  }
                  copyable
                  data-testid='custodian'>
                  {formatters.displayCase(
                    getParentAccountByIdQuery.data?.custodianId
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Created</TextLabel>
                <TextValue data-testid='created-date'>
                  {getParentAccountByIdQuery.data?.createdAt
                    ? formatters.formatFromIsoDate(
                        getParentAccountByIdQuery.data?.createdAt
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
