import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useDialog } from '@/contexts/DialogContext';
import { ConversionResponse } from '@/models/BulkCashAllocation.model';
import {
  ConversionStatus,
  ConversionType,
  DOCUMENT_TYPES,
  UpdateConversionDto
} from '@/models/ConversionDTO.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { userService } from '@/services/User.service';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMemo } from 'react';

import { ActionTypes } from './ActionCenterStepperFilters.component';

interface ActionHistoryConversionTableCollapsibleComponentProps {
  row: ConversionResponse;
  actionType: ActionTypes;
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: grey[100],
  padding: theme.spacing(4)
}));

const StyledLink = styled(Link)(() => ({
  cursor: 'pointer'
}));

export const ActionHistoryConversionTableCollapsibleComponent = (
  props: ActionHistoryConversionTableCollapsibleComponentProps
): JSX.Element => {
  const { openDialog, closeDialog } = useDialog();
  const queryClient = useQueryClient();
  const userInfo = userService.getUser();

  const isUploadingUser = useMemo(
    () => userInfo?.nickname === props.row?.submittedBy?.id,
    [userInfo, props.row]
  );

  const { mutate: downloadDocument } = useMutation(
    ['ActionCenterService.getDocument'],
    (documentId: number) => {
      return ActionCenterService.getDocument(documentId);
    },
    {
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  const approveUpload = useMutation(
    ['ActionCenterService.approveBulkUpload'],
    (dto: UpdateConversionDto) =>
      ActionCenterService.patchBulkConversion(
        dto,
        props.row.conversionType === ConversionType.CashAllocation
          ? DOCUMENT_TYPES.CASH_ALLOCATION
          : DOCUMENT_TYPES.LOANS
      ),
    {
      onSuccess: data => {
        queryClient.refetchQueries([
          'ActionCenterService.bulkConversionsList',
          props.actionType.toString()
        ]);

        if (data?.errors?.length > 0) {
          openDialog({
            customContent: (
              <>
                <DialogTitle>Bulk Conversion Upload Errors</DialogTitle>
                <DialogContent>
                  <Stack spacing={2}>
                    {data?.errors?.map((value, index) => (
                      <Typography key={index}>{value.message}</Typography>
                    ))}
                  </Stack>
                  <DialogActions>
                    <Button onClick={closeDialog}>Close</Button>
                  </DialogActions>
                </DialogContent>
              </>
            ),
            dialogProps: {
              maxWidth: 'md'
            }
          });
        }
      }
    }
  );

  return (
    <>
      <StyledGrid container>
        <Grid xs={6}>
          <Stack
            data-testid={`action-history-table-collapsible-action-${props.row.documentId}`}
            spacing={2}>
            <Typography variant='subtitle1'>Details</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Download File</TextLabel>
                <TextValue>
                  <Stack>
                    {props.row.fileName}
                    <StyledLink
                      onClick={() =>
                        props.row.documentId
                          ? downloadDocument(props.row.documentId)
                          : null
                      }>
                      <Stack direction='row'>
                        Download
                        <FileDownloadOutlinedIcon fontSize='small' />
                      </Stack>
                    </StyledLink>
                  </Stack>
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Submitted by</TextLabel>
                <TextValue>{props.row.submittedBy.id}</TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
        </Grid>
        {!isUploadingUser &&
          props.row.status == ConversionStatus.PendingApproval && (
            <Grid xs={6}>
              <LoadingButton
                loading={approveUpload.isLoading}
                onClick={() =>
                  approveUpload.mutate({
                    documentId: props.row.documentId,
                    id: props.row.id,
                    isApproved: true,
                    status:
                      props.row.conversionType === ConversionType.CashAllocation
                        ? ConversionStatus.PendingInvestment
                        : ConversionStatus.Complete,
                    version: props.row.version
                  })
                }
                variant='contained'>
                Approve
              </LoadingButton>
            </Grid>
          )}
      </StyledGrid>
    </>
  );
};
