import EARLIEST_POSSIBLE_DATE from '@/consts/dates.constants';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import { Field, FormikProps } from 'formik';
import React, { useMemo } from 'react';

interface OverrideVestwellEligibilityFormProps {
  fieldId: string;
  isStateIRA: boolean;
  initialOverride?: boolean;
  initialEligibilityStatus?: string;
  initialNote?: string;
  variant: 'salaryDeferral' | 'employerMatch' | 'profitSharing';
}

type OverrideEligibilityFormValues = {
  checked: boolean;
  entryDate?: dayjs.Dayjs;
  note?: string;
  status?: string;
};

const defaultOptions = ['Eligible', 'Ineligible'];
export const nonStateOptions = ['Terminated', 'Terminated With Balance'];

const OverrideVestwellEligibilityForm: React.FC<
  OverrideVestwellEligibilityFormProps
> = props => {
  const {
    fieldId,
    isStateIRA,
    initialEligibilityStatus,
    initialNote,
    initialOverride
  } = props;
  const options = useMemo(
    () =>
      isStateIRA ? defaultOptions : defaultOptions.concat(nonStateOptions),
    [isStateIRA]
  );

  return (
    <FormControl fullWidth>
      <Field name={fieldId}>
        {(fieldProps: { form: FormikProps<OverrideEligibilityFormValues> }) => {
          const formValues = fieldProps.form.values[fieldId];
          const initialFormValues = fieldProps.form.initialValues[fieldId];
          const isError =
            isStateIRA &&
            formValues.status === initialEligibilityStatus &&
            initialNote === formValues.note &&
            initialOverride
              ? false
              : Boolean(
                  fieldProps.form.touched[fieldId] &&
                    formValues.checked &&
                    fieldProps.form.touched[fieldId].note &&
                    (!formValues.note || formValues.note.trim().length === 0)
                );

          return (
            <FormGroup>
              <Stack spacing={2.5}>
                <FormControlLabel
                  control={<Checkbox checked={formValues.checked} />}
                  label={
                    isStateIRA
                      ? 'Override Vestwell Eligibility'
                      : 'Override Eligibility Status'
                  }
                  onChange={(event, checked) => {
                    const newFormValues = { ...formValues };
                    newFormValues.checked = checked;
                    fieldProps.form.setFieldValue(fieldId, newFormValues);
                  }}
                />

                <Grid container justifyContent='center' spacing={2.5}>
                  <Grid xs={9}>
                    <FormControl
                      error={
                        fieldProps.form.errors[fieldId]?.status &&
                        fieldProps.form.touched[fieldId]?.status
                      }
                      fullWidth
                      variant='standard'>
                      <InputLabel id='select-status-label'>
                        {isStateIRA
                          ? 'VW Eligibility Status'
                          : 'Eligibility Status'}
                      </InputLabel>
                      <Select
                        disabled={!formValues.checked}
                        id='select-status'
                        label='eligibility-status'
                        labelId='select-status'
                        onChange={event => {
                          const newFormValues = { ...formValues };
                          newFormValues.status = event.target.value;
                          fieldProps.form.setFieldValue(fieldId, newFormValues);
                        }}
                        onFocus={() =>
                          fieldProps.form.setTouched({
                            [fieldId]: {
                              ...fieldProps.form.touched[fieldId],
                              status: true
                            }
                          })
                        }
                        value={formValues.status ?? ''}>
                        {options.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {fieldProps.form.touched[fieldId]?.status &&
                          (fieldProps.form.errors[fieldId]?.status ?? '')}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {formValues.status === 'Eligible' &&
                    formValues.checked &&
                    !isStateIRA && (
                      <Grid xs={9}>
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='MM/DD/YYYY'
                              label='Entry Date'
                              minDate={dayjs(EARLIEST_POSSIBLE_DATE)}
                              onChange={newValue => {
                                if (newValue) {
                                  const newFormValues = { ...formValues };
                                  newFormValues.entryDate = newValue;
                                  fieldProps.form.setFieldValue(
                                    fieldId,
                                    newFormValues
                                  );
                                } else {
                                  const newFormValues = { ...formValues };
                                  newFormValues.entryDate =
                                    initialFormValues.entryDate;
                                  fieldProps.form.setFieldValue(
                                    fieldId,
                                    newFormValues
                                  );
                                }
                              }}
                              slotProps={{
                                textField: {
                                  helperText:
                                    formValues.entryDate?.isValid() &&
                                    formValues.entryDate?.year() >= 1901
                                      ? ''
                                      : 'Please enter a valid Date.',
                                  variant: 'standard'
                                }
                              }}
                              value={dayjs(formValues.entryDate)}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    )}

                  <Grid xs={9}>
                    <TextField
                      InputLabelProps={{ required: false }}
                      disabled={!formValues.checked}
                      error={isError}
                      fullWidth
                      helperText={
                        isError ? 'Please enter override notes.' : null
                      }
                      id='note-textarea'
                      label='Note'
                      multiline
                      onChange={event => {
                        const newFormValues = { ...formValues };
                        newFormValues.note = event.target.value;
                        fieldProps.form.setFieldValue(fieldId, newFormValues);
                      }}
                      onFocus={() =>
                        fieldProps.form.setTouched({
                          [fieldId]: {
                            ...fieldProps.form.touched[fieldId],
                            note: true
                          }
                        })
                      }
                      value={formValues.note ?? ''}
                      variant='standard'
                    />
                  </Grid>
                </Grid>
                {props.variant === 'salaryDeferral' && (
                  <FormControlLabel
                    control={<Checkbox checked={formValues.isLtpt} />}
                    data-testid='ltpt'
                    label='Long-term part-time'
                    onChange={(event, checked) =>
                      fieldProps.form.setFieldValue(fieldId, {
                        ...formValues,
                        isLtpt: checked
                      })
                    }
                  />
                )}
              </Stack>
            </FormGroup>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default OverrideVestwellEligibilityForm;
export type { OverrideVestwellEligibilityFormProps };
