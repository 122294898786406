import { useSnackbar } from '@/contexts/SnackBarContext';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';

import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';

export type FundOption = {
  cusip: string;
  symbol: string;
  fundName: string;
};

type InvestmentElectionMappingFundAutocompleteProps = {
  options: FundOption[];
  onCommit: (params: {
    value?: any;
    oldCusip: string;
    remove?: boolean;
  }) => void;
  data: {
    newCusip: FundOption;
    oldCusip: string;
  };
  value?: string;
};

export const InvestmentElectionMappingFundAutocomplete = forwardRef(
  (
    props: InvestmentElectionMappingFundAutocompleteProps,
    ref
  ): React.JSX.Element => {
    const { data: rowData, options, onCommit, value: initialValue } = props;
    const [fundOptions, setFundOptions] = useState(options);
    const [fieldValue, setFieldValue] = useState(initialValue || '');
    const snackbar = useSnackbar();

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return fieldValue;
        },
        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          if (!fieldValue) {
            onCommit({
              oldCusip: rowData.oldCusip,
              remove: true,
              value: {
                symbol: initialValue
              }
            });
          }

          if (
            fieldValue &&
            fieldValue?.length >= 2 &&
            fieldValue.toUpperCase() !== (initialValue || '').toUpperCase()
          ) {
            const matchingOption = fundOptions.find(
              o => o.symbol === fieldValue.toUpperCase()
            );

            if (matchingOption) {
              onCommit({
                oldCusip: rowData.oldCusip,
                value: matchingOption
              });
            } else if (!initialValue) {
              snackbar.showSnackbar({
                message: `No matching ticker found for ${fieldValue.toUpperCase()}`,
                severity: 'error'
              });
            }
          }
          return true;
        }
      };
    });

    const handleChange = useCallback(
      (event: React.SyntheticEvent, newValue: FundOption) => {
        if (newValue) onCommit({ oldCusip: rowData.oldCusip, value: newValue });
      },
      [onCommit, rowData]
    );

    const loadOptions = useCallback(
      event => {
        const symbol = event.target.value;
        if (symbol && symbol.length >= 2) {
          setFundOptions(
            options.filter(option =>
              option.symbol.toLowerCase().startsWith(symbol.toLowerCase())
            )
          );
        }
      },
      [options]
    );

    return (
      <Autocomplete
        clearOnBlur={false}
        getOptionLabel={option => option.symbol}
        inputValue={fieldValue}
        isOptionEqualToValue={(option, value) => option.cusip === value.cusip}
        onBlur={loadOptions}
        onChange={handleChange}
        onInputChange={(event, value) => {
          loadOptions(event);
          setFieldValue(value);
        }}
        options={fundOptions}
        ref={ref}
        renderInput={params => (
          <TextField {...params} autoFocus size='medium' />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.cusip}>
            <Box>
              <Box>
                {option.symbol} | {option.cusip}
              </Box>
              <Typography variant='body2'>{option.fundName}</Typography>
            </Box>
          </li>
        )}
        sx={{
          backgroundColor: theme => theme.palette.common.white,
          width: 300
        }}
      />
    );
  }
);

InvestmentElectionMappingFundAutocomplete.displayName =
  'InvestmentElectionMappingFundAutocomplete';
