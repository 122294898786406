import { CellComponentProps } from '@/components/collapsible-table';
import ContributionTransactions from '@/routes/plans/plan-detail/ContributionTransactions/ContributionTransactions.component';
import { Box, Drawer, Link, TableCell, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  showTransactionsButton: {
    marginLeft: theme.spacing(1)
  },
  size: {
    fontSize: theme.spacing(2)
  },
  transactionsDrawer: theme.mixins.toolbar
}));

const columnsWithBorder = [
  'total',
  'employeeContribution',
  'companyContribution'
];

const PlanContributionProcessedTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const classes = useStyles();
  const { row, column } = props;
  const isWithBorder = columnsWithBorder.some(
    cellField => cellField === column.field
  );
  const [state, setState] = useState(false);
  const [transaction, setTransaction] = useState({
    participantId: undefined,
    participantName: ''
  });
  let field = <Box>{row[column.field]}</Box>;

  if (column.field === 'employee' && row.participantId) {
    field = (
      <Link
        component={RouterLink}
        to={`/participants/${row.participantId}`}
        underline='hover'>
        {row[column.field]}
      </Link>
    );
  }

  if (column.field === 'total' && row.isVestwellSubaccounting) {
    field = (
      <Link
        component='button'
        onClick={() => {
          setTransaction({
            participantId: row.participantId,
            participantName: row.employee
          });
          setState(true);
        }}
        underline='hover'
        variant='body1'>
        {row[column.field]}
      </Link>
    );
  }

  return (
    <>
      <TableCell
        className={clsx(classes.size, isWithBorder ? classes.border : '')}
        component='th'
        scope='row'>
        <Box>{field}</Box>
      </TableCell>
      <Drawer
        anchor='right'
        onClose={() => {
          setTransaction({
            participantId: undefined,
            participantName: ''
          });
          setState(false);
        }}
        open={state}>
        {transaction.participantId && (
          <>
            <div className={classes.transactionsDrawer} />
            <ContributionTransactions
              closeDrawer={() => {
                setState(false);
              }}
              participantId={transaction.participantId}
              participantName={transaction.participantName}
            />
          </>
        )}
      </Drawer>
    </>
  );
};

export default PlanContributionProcessedTableCell;
