import JSONViewer from '@/components/json-viewer';
import SimpleDropdown from '@/components/simple-dropdown';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionCodes } from '@/hooks/suba/useTransactionCodes.hook';
import { useSubaErrors } from '@/hooks/useSubaErrors.hook';
import { ParticipantInfo, WithdrawalDto } from '@/models';
import { FormattedParticipantInfo } from '@/models/ParticipantInfo.model';
import {
  CustomDisbursement,
  CustomWithdrawalAccount,
  CustomWithdrawalDisbursementDto,
  DisbursementSubaccountsDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import { Form, Formik, FormikProps } from 'formik';
import { FC, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { AccountHolderCard } from './AccountHolderCard.component';
import {
  sanitizeCustomDisbursement,
  validationDisbursementSchema
} from './customWithdrawalRequest';
import { DisbursementSubaccountCard } from './DisbursementSubaccountCard.component';
import ParticipantInfoCard from './ParticipantInfoCard.component';
import WithdrawalDisbursementCard from './WithdrawalDisbursementCard.component';

type WithdrawalDisbursementDialogProps = {
  participantId: number;
  dto?: WithdrawalDto;
  isOpen: boolean;
  onClose: () => void;
};

const initSubAcct = {
  accountId: undefined,
  applyVesting: false,
  error: undefined,
  forfeitureFlag: false,
  fundingSource: undefined,
  subAccountTaxType: undefined
};

const ParticipantCardContainer = styled(Card)<{ isSelected: boolean }>(
  props => {
    return {
      border: props.isSelected
        ? `2px solid ${props.theme.palette.primary.main}`
        : ''
    };
  }
);

type CustomDisbursementFormData = {
  accountHolderAddress: string | undefined;
  accountHolderCity: string | undefined;
  accountHolderFederalIdentifier: string | undefined;
  accountHolderForeignCountry: string | undefined;
  accountHolderName: string | undefined;
  accountHolderState: string | undefined;
  accountHolderZip: string | undefined;
  accountType: string | undefined;
  accounts: CustomWithdrawalAccount[] | (typeof initSubAcct)[];
  accountsResponse: undefined;
  beneficiaryId: number | undefined;
  contributionYear: number | undefined;
  disbursements: CustomDisbursement[] | undefined;
  disbursementsResponse: undefined;
  earnings: boolean;
  jiraTicket: string;
  participantAddress: string | undefined;
  participantCity: string | undefined;
  participantFirstName: string | undefined;
  participantForeignCountry: string;
  participantId: number | undefined;
  participantLastName: string | undefined;
  participantSsn: string | undefined;
  participantState: string | undefined;
  participantZip: string | undefined;
  saveResponse: undefined;
  withdrawalCode: string | undefined;
};

export const WithdrawalDisbursementDialog: FC<
  WithdrawalDisbursementDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const [isOpenPreview, toggleIsOpenPreview] = useToggle(false);
  const [previewResponse, setPreviewData] = useState('');
  const { subaErrors } = useSubaErrors();
  const getTransactionCodesQuery = useTransactionCodes({
    transactionBaseType: [TransactionBaseType.Withdrawal]
  });

  const disbursementWithdrawalCode = useMemo(() => {
    return getTransactionCodesQuery.data?.map(i => {
      return {
        option: i.label,
        value: i.transactionTypeCode
      };
    });
  }, [getTransactionCodesQuery.data]);

  const participantQuery = useQuery<ParticipantInfo>(
    ['ParticipantService.getParticipantById', props.participantId?.toString()],
    async () => ParticipantService.getParticipantById(props.participantId),
    {
      enabled: Boolean(props.participantId)
    }
  );

  const distributionAccounts = useQuery<DisbursementSubaccountsDto>(
    [
      'ParticipantService.getDisbursementSubAccounts',
      participantQuery.data?.sponsorPlanId
    ],
    async () =>
      ParticipantService.getDisbursementSubAccounts(
        participantQuery.data?.sponsorPlanId
      ),
    {
      enabled: Boolean(participantQuery.data?.sponsorPlanId)
    }
  );

  const mutation = useMutation(
    ['ParticipantService.submitCustomDisbursement'],
    (data: CustomWithdrawalDisbursementDto) => {
      return ParticipantService.submitCustomDisbursement(data);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed submitting of custom disbursement',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Successfully submitted!',
          severity: 'success'
        });
        props.onClose();
      }
    }
  );

  const initDisbursement: CustomDisbursement = useMemo(() => {
    return {
      _1099Code: '',
      amount: undefined,
      disbursementMethod: '',
      error: undefined,
      federalWithholdingPercent: '',
      feeAmount: undefined,
      fullAmount: false,
      furtherCreditToAccountNumber: '',
      furtherCreditToName: '',
      isRollover: false,
      needsTaxRecords: true,
      overnightAccountNumber: '670413772',
      overnightCheck: false,
      payeeAddress: '',
      payeeCity: '',
      payeeForeignCountry: 'USA',
      payeeName: ``,
      payeeState: '',
      payeeZip: '',
      receivingBankAccountName: '',
      receivingBankAccountNumber: '',
      receivingBankName: '',
      receivingBankRoutingNumber: '',
      referenceMemo: '',
      rothInitialYear: '',
      rothOrAfterTaxBasisAmount: '',
      rothQualifiedWithdrawal: false,
      stateWithholdingCode: participantQuery.data?.address?.state,
      stateWithholdingPercent: '',
      taxType: undefined,
      taxableAmountNotDetermined: false,
      useParticipantAddress: true
    };
  }, [participantQuery.data]);

  const initialVals: CustomDisbursementFormData = {
    accountHolderAddress: '',
    accountHolderCity: '',
    accountHolderFederalIdentifier: '',
    accountHolderForeignCountry: '',
    accountHolderName: '',
    accountHolderState: '',
    accountHolderZip: '',
    accountType: '',
    accounts:
      props.dto?.attributes.context.customWithdrawalDetails.accounts.map(i => {
        return { ...initSubAcct, ...i };
      }) ?? [initSubAcct],
    accountsResponse: undefined,
    beneficiaryId:
      props.dto?.attributes.context.customWithdrawalDetails.beneficiaryId,
    contributionYear:
      props.dto?.attributes.context.customWithdrawalDetails.contributionYear ??
      undefined,
    disbursements: [initDisbursement],
    disbursementsResponse: undefined,
    earnings: false,
    jiraTicket: '',
    participantAddress: participantQuery.data?.address?.address1,
    participantCity: participantQuery.data?.address?.city,
    participantFirstName: participantQuery.data?.firstName,
    participantForeignCountry: 'USA',
    participantId: participantQuery.data?.participantId,
    participantLastName: participantQuery.data?.lastName,
    participantSsn: participantQuery.data?.ssn,
    participantState: participantQuery.data?.address?.state,
    participantZip: participantQuery.data?.address?.zip,
    saveResponse: undefined,
    withdrawalCode: props.dto?.attributes.withdrawalReason ?? ''
  };

  const submitHandler = async (v: CustomDisbursementFormData) => {
    const dto: CustomWithdrawalDisbursementDto = sanitizeCustomDisbursement({
      accountHolderAddress: v.accountHolderAddress,
      accountHolderCity: v.accountHolderCity,
      accountHolderFederalIdentifier: v.accountHolderFederalIdentifier,
      accountHolderForeignCountry: v.accountHolderForeignCountry.toLowerCase(),
      accountHolderName: v.accountHolderName,
      accountHolderState: v.accountHolderState,
      accountHolderZip: v.accountHolderZip,
      accountType: v.accountType,
      details: {
        disbursement: v.disbursements[0]
      },
      forceSkipLocationValidation: false,
      jiraTicket: v.jiraTicket,
      participantId: props.participantId,
      planId: participantQuery.data?.sponsorPlanId,
      sendEmail: false,
      withdrawalCode: v.withdrawalCode
    });

    await mutation.mutateAsync(dto);
  };

  const handleParticipantCard =
    (formik: FormikProps<CustomDisbursementFormData>, id?: number) => () => {
      formik.setFieldValue('beneficiaryId', id);
    };

  const onConfirmSubmit = (formik: FormikProps<CustomDisbursementFormData>) => {
    formik.submitForm();
  };

  return (
    <>
      <Dialog fullWidth maxWidth='lg' open={props.isOpen}>
        <Formik
          initialValues={initialVals}
          onSubmit={submitHandler}
          validationSchema={validationDisbursementSchema}>
          {formik => (
            <Form>
              <DialogTitle>Custom Disbursement Request</DialogTitle>
              <DialogContent>
                <Grid2 container spacing={4}>
                  <Grid2 xs={4}>
                    <Stack spacing={2}>
                      <Stack direction='row'>
                        <TextField
                          error={
                            formik.touched.jiraTicket &&
                            Boolean(formik.errors.jiraTicket)
                          }
                          fullWidth
                          helperText={
                            (formik.touched.jiraTicket &&
                              formik.errors.jiraTicket) ??
                            ''
                          }
                          id='jiraTicket'
                          label='Jira Ticket'
                          name='jiraTicket'
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          size='small'
                          value={formik.values.jiraTicket}
                        />
                        {formik.touched.jiraTicket &&
                          !formik.errors.jiraTicket && (
                            <CheckCircleOutline
                              color='success'
                              fontSize='large'
                            />
                          )}
                        {formik.touched.jiraTicket &&
                          formik.errors.jiraTicket && (
                            <CloseOutlined color='error' fontSize='large' />
                          )}
                      </Stack>
                      <SimpleDropdown
                        fieldId='withdrawalCode'
                        fieldName='Withdrawal Code*'
                        fieldOptions={disbursementWithdrawalCode}
                        onChange={event => {
                          formik.setFieldValue(
                            'withdrawalCode',
                            event.target.value
                          );
                        }}
                        size='small'
                      />
                    </Stack>
                  </Grid2>
                  <Grid2 xs={12}>
                    <Typography mb={2} variant='h6'>
                      Participant info
                    </Typography>
                    <ParticipantCardContainer
                      isSelected={!formik.values.beneficiaryId}
                      variant='outlined'>
                      <CardActionArea
                        onClick={handleParticipantCard(formik, undefined)}>
                        <CardContent>
                          <ParticipantInfoCard
                            participant={
                              {
                                address: {
                                  address1:
                                    participantQuery.data?.address?.address1,
                                  address2:
                                    participantQuery.data?.address?.address2,
                                  city: participantQuery.data?.address?.city,
                                  state: participantQuery.data?.address?.state,
                                  zip: participantQuery.data?.address?.zip
                                },
                                firstName: participantQuery.data?.firstName,
                                id: participantQuery.data?.participantId,
                                isBeneficiary: false,
                                lastName: participantQuery.data?.lastName,
                                ssn: participantQuery.data?.ssn
                              } as FormattedParticipantInfo
                            }
                          />
                        </CardContent>
                      </CardActionArea>
                    </ParticipantCardContainer>
                  </Grid2>
                  <Grid2 xs={12}>
                    <Typography variant='h6'>Account Holder Info</Typography>
                    <AccountHolderCard />
                  </Grid2>
                  <Grid2 xs={12}>
                    <Typography variant='h6'>Sub Account Info</Typography>
                    {subaErrors.hasAccountErrors && (
                      <Alert severity='error'>{subaErrors.accountErrors}</Alert>
                    )}
                    <DisbursementSubaccountCard
                      errors={subaErrors}
                      planAccounts={distributionAccounts.data?.data}
                    />
                  </Grid2>
                  <Grid2 xs={12}>
                    <Typography variant='h6'>Disbursement</Typography>
                    {subaErrors.hasDisbursementsErrors && (
                      <Alert severity='error'>
                        {subaErrors.disbursementsErrors}
                      </Alert>
                    )}
                    <WithdrawalDisbursementCard
                      errors={subaErrors}
                      index={0}
                      isCustomDisbursement={true}
                      key='disbursement'
                    />
                  </Grid2>
                </Grid2>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    const sanitizedDto: CustomWithdrawalDisbursementDto =
                      sanitizeCustomDisbursement({
                        accountHolderAddress:
                          formik.values.accountHolderAddress,
                        accountHolderCity: formik.values.accountHolderCity,
                        accountHolderFederalIdentifier:
                          formik.values.accountHolderFederalIdentifier,
                        accountHolderForeignCountry:
                          formik.values.accountHolderForeignCountry.toLowerCase(),
                        accountHolderName: formik.values.accountHolderName,
                        accountHolderState: formik.values.accountHolderState,
                        accountHolderZip: formik.values.accountHolderZip,
                        accountType: formik.values.accountType,
                        details: {
                          disbursement: formik.values.disbursements[0]
                        },
                        forceSkipLocationValidation: false,
                        jiraTicket: formik.values.jiraTicket,
                        participantId: props.participantId,
                        planId: participantQuery.data?.sponsorPlanId,
                        sendEmail: false,
                        withdrawalCode: formik.values.withdrawalCode
                      });

                    toggleIsOpenPreview();
                    setPreviewData(JSON.stringify(sanitizedDto, null, 2));
                  }}
                  variant='contained'>
                  Preview Request
                </Button>
                <LoadingButton
                  loading={mutation.isLoading}
                  onClick={() => onConfirmSubmit(formik)}
                  variant='contained'>
                  SUBMIT
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Dialog fullWidth maxWidth='sm' open={isOpenPreview}>
        <DialogTitle>Preview Request</DialogTitle>
        <DialogContent>
          <JSONViewer json={previewResponse} />
        </DialogContent>

        <DialogActions>
          <Button color='primary' onClick={toggleIsOpenPreview} variant='text'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

WithdrawalDisbursementDialog.displayName = 'WithdrawalDisbursementDialog';
