import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  GetSubaWithholdingContext,
  SubaWithholdingDto
} from '@/models/SubaWithholdingDTO.model';
import {
  CustomDisbursement,
  CustomWithdrawalDetailsDto,
  CustomWithdrawalDisbursement,
  CustomWithdrawalDisbursementDto,
  CustomWithdrawalResponse,
  CustomWithdrawalRothBasisResponse,
  CustomWithdrawalRothCostBasisDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { memoizedDebounce } from '@/utils/MemoizedDebounce';
import {
  UseBaseMutationResult,
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

import { isNumber, omit } from 'lodash';
import * as yup from 'yup';

const ticketExist = async (issueKey: string) => {
  const result =
    await ParticipantService.checkIfWithdrawalTicketExist(issueKey);
  return result;
};
const debouncedTicketExist: (issueKey: string) => Promise<boolean> =
  memoizedDebounce(ticketExist, 300);

export const validationSchema = (): yup.AnySchema => {
  const ticketRegex = /^([A-Z0-9]+)-([0-9]+)/g;

  return yup.object({
    accounts: yup.array().of(
      yup.object().shape({
        accountId: yup.string().optional()
      })
    ),
    contributionYear: yup
      .number()
      .when('withdrawalCode', {
        is: (withdrawalCode: string) => withdrawalCode === '402G',
        then: schema =>
          schema
            .test(
              'len',
              'Must be exactly 4 digits',
              val => !val || val?.toString().length == 4
            )
            .test(
              'in future',
              'Must be <= current year',
              val => !val || val <= new Date().getFullYear()
            )
            .optional()
      })
      .optional(),
    disbursements: yup.array().of(
      yup.object().shape({
        _1099Code: yup
          .string()
          .required('1099 Code is required')
          .max(2, '2 character max'),
        amount: yup.number().when('fullAmount', {
          is: (value: boolean) => !value,
          then: schema =>
            schema
              .required('Amount is required')
              .min(0, 'Amount must be greater than 0')
        }),
        disbursementMethod: yup
          .string()
          .required('Delivery Method is required'),
        err: yup.string(),
        federalWithholdingPercent: yup
          .number()
          .required('Federal Withholding Percent is required')
          .test(
            'federalWithholdingPercent',
            'Must be between 0 and 100',
            value => isNumber(value) && value >= 0 && value <= 100
          ),
        feeAmount: yup
          .number()
          .required('Fee Amount is required')
          .min(0, 'Fee Amount must be greater than or equal to 0'),
        overnightAccountNumber: yup.string().when('checkMethod', {
          is: (checkMethod: string) => checkMethod === 'fedex',
          then: schema =>
            schema.required('Overnight Account Number is required')
        }),
        payeeAddress: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('Address is required')
        }),
        payeeCity: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('City is required')
        }),
        payeeForeignCountry: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('Country is required')
        }),
        payeeName: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema =>
            schema
              .required('Recipient name is required')
              .max(60, '60 characters max')
        }),
        payeeState: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('State is required')
        }),
        payeeZip: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('Zip is required')
        }),
        receivingBankAccountName: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema => schema.required('Bank Account Name is required')
        }),
        receivingBankAccountNumber: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema => schema.required('Bank Account Number is required')
        }),
        receivingBankName: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema => schema.required('Bank Name is required')
        }),
        receivingBankRoutingNumber: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema =>
            schema
              .required('Bank Routing Number is required')
              .test(
                'receivingBankRoutingNumber',
                'Must be 9 digits',
                value => !!value && /^\d{9}$/.test(value)
              )
        }),
        referenceMemo: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) => disbursementMethod === 'CHECK',
          then: schema => schema.max(20, '20 characters max')
        }),
        rothInitialYear: yup.number().when('taxType', {
          is: (taxType: string) => taxType === 'roth',
          then: schema =>
            schema
              .required('Roth initial year is required')
              .test(
                'len',
                'Must be exactly 4 digits',
                val => val?.toString().length == 4
              )
        }),
        stateWithholdingCode: yup
          .string()
          .required('State Withholding Code is required'),
        stateWithholdingPercent: yup
          .number()
          .required('State Withholding Percent is required')
          .test(
            'stateWithholdingPercent',
            'Must be between 0 and 100',
            value => isNumber(value) && value >= 0 && value <= 100
          ),
        taxType: yup.string().required('Tax Type is required')
      })
    ),
    jiraTicket: yup
      .string()
      .matches(ticketRegex, 'Jira ticket format should be {Board}-{Number}')
      .required('Jira Ticket is required')
      .test(
        'Check if jira ticket exist',
        'Invalid jira ticket',
        async value => {
          let checkResult;
          if (value && ticketRegex.test(value)) {
            checkResult = await debouncedTicketExist(value);
          } else {
            await new Promise(resolve => {
              setTimeout(resolve, 50);
            });
          }
          return checkResult ?? false;
        }
      ),
    withdrawalCode: yup.string().required('Withdrawal code is required')
  });
};

export const validationDisbursementSchema = (): yup.AnySchema => {
  const ticketRegex = /^([A-Z0-9]+)-([0-9]+)/g;

  return yup.object().shape({
    accountType: yup.string().required('Account type is required'),
    disbursements: yup.array().of(
      yup.object().shape({
        _1099Code: yup.string().when('needsTaxRecords', {
          is: (value: boolean) => value,
          then: schema =>
            schema.required('1099 Code is required').max(2, '2 character max')
        }),
        amount: yup.number().when('fullAmount', {
          is: (value: boolean) => !value,
          then: schema =>
            schema
              .required('Amount is required')
              .min(0, 'Amount must be greater than 0')
        }),
        disbursementMethod: yup
          .string()
          .required('Delivery Method is required'),
        err: yup.string(),
        federalWithholdingPercent: yup.number().when('needsTaxRecords', {
          is: (value: boolean) => value,
          then: schema =>
            schema
              .required('Federal Withholding Percent is required')
              .typeError('Should be a number')
              .test(
                'federalWithholdingPercent',
                'Must be between 0 and 100',
                value => isNumber(value) && value >= 0 && value <= 100
              )
        }),
        feeAmount: yup
          .number()
          .required('Fee Amount is required')
          .min(0, 'Fee Amount must be greater than or equal to 0'),
        needsTaxRecords: yup
          .boolean()
          .required('Needs Tax Records is required'),
        overnightAccountNumber: yup.string().when('checkMethod', {
          is: (checkMethod: string) => checkMethod === 'fedex',
          then: schema =>
            schema.required('Overnight Account Number is required')
        }),
        payeeAddress: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('Address is required')
        }),
        payeeCity: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('City is required')
        }),
        payeeForeignCountry: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('Country is required')
        }),
        payeeName: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema =>
            schema
              .required('Recipient name is required')
              .max(60, '60 characters max')
        }),
        payeeState: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('State is required')
        }),
        payeeZip: yup.string().when('useParticipantAddress', {
          is: (value: boolean) => !value,
          then: schema => schema.required('Zip is required')
        }),
        receivingBankAccountName: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema => schema.required('Bank Account Name is required')
        }),
        receivingBankAccountNumber: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema => schema.required('Bank Account Number is required')
        }),
        receivingBankName: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema => schema.required('Bank Name is required')
        }),
        receivingBankRoutingNumber: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) =>
            ['ACH', 'WIRE'].includes(disbursementMethod),
          then: schema =>
            schema
              .required('Bank Routing Number is required')
              .test(
                'receivingBankRoutingNumber',
                'Must be 9 digits',
                value => !!value && /^\d{9}$/.test(value)
              )
        }),
        referenceMemo: yup.string().when('disbursementMethod', {
          is: (disbursementMethod: string) => disbursementMethod === 'CHECK',
          then: schema => schema.max(20, '20 characters max')
        }),
        rothInitialYear: yup
          .number()
          .typeError('Should be a number')
          .when(['needsTaxRecords', 'taxType', 'taxableAmountNotDetermined'], {
            is: (
              needsTaxRecords: boolean,
              taxType: string,
              taxableAmountNotDetermined: boolean
            ) =>
              needsTaxRecords &&
              !taxableAmountNotDetermined &&
              taxType === 'roth',
            then: schema =>
              schema
                .required('Roth initial year is required')
                .test(
                  'len',
                  'Must be exactly 4 digits',
                  val => val?.toString().length == 4
                )
          }),
        rothOrAfterTaxBasisAmount: yup
          .number()
          .typeError('Should be a number')
          .when(['needsTaxRecords', 'taxType', 'taxableAmountNotDetermined'], {
            is: (
              needsTaxRecords: boolean,
              taxType: string,
              taxableAmountNotDetermined: boolean
            ) =>
              needsTaxRecords &&
              !taxableAmountNotDetermined &&
              taxType === 'roth',
            then: schema =>
              schema.required('Roth or After Tax Basis Amount is required')
          }),
        stateWithholdingCode: yup.string().when('needsTaxRecords', {
          is: (value: boolean) => value,
          then: schema => schema.required('State Withholding Code is required')
        }),
        stateWithholdingPercent: yup.number().when('needsTaxRecords', {
          is: (value: boolean) => value,
          then: schema =>
            schema
              .required('State Withholding Percent is required')
              .test(
                'stateWithholdingPercent',
                'Must be between 0 and 100',
                value => isNumber(value) && value >= 0 && value <= 100
              )
        }),
        taxType: yup.string().required('Tax Type is required'),
        taxableAmountNotDetermined: yup
          .boolean()
          .when(['needsTaxRecords', 'taxType'], {
            is: (needsTaxRecords: boolean, taxType: string) =>
              needsTaxRecords && taxType === 'roth',
            then: schema =>
              schema.required('Taxable Amount Not Determined is required')
          })
      })
    ),
    jiraTicket: yup
      .string()
      .matches(ticketRegex, 'Jira ticket format should be {Board}-{Number}')
      .required('Jira Ticket is required')
      .test(
        'Check if jira ticket exist',
        'Invalid jira ticket',
        async value => {
          if (value && !ticketRegex.test(value)) {
            return false;
          }

          return await debouncedTicketExist(value);
        }
      ),
    withdrawalCode: yup.string().required('Withdrawal code is required')
  });
};

export const saveValidationSchema = yup.object({
  withdrawalCode: yup.string().required('Withdrawal code is required')
});

export const withholdingValidationSchema = yup.object({
  amount: yup.number().required('Amount is required'),
  taxType: yup.string().required('Tax type is required')
});

export const sanitizeCustomRequest = (
  dto: CustomWithdrawalDetailsDto
): CustomWithdrawalDetailsDto => {
  dto.details.disbursements = dto.details.disbursements.map(i => {
    let updated = {};

    switch (i.disbursementMethod) {
      case 'CHECK':
        updated = {
          furtherCreditToAccountNumber: undefined,
          furtherCreditToName: undefined,
          overnightAccountNumber: i.overnightCheck
            ? i.overnightAccountNumber
            : undefined,
          receivingBankAccountName: undefined,
          receivingBankAccountNumber: undefined,
          receivingBankName: undefined,
          receivingBankRoutingNumber: undefined
        };
        break;
      case 'ACH':
        updated = {
          furtherCreditToAccountNumber: undefined,
          furtherCreditToName: undefined,
          overnightAccountNumber: undefined,
          overnightCheck: false,
          referenceMemo: undefined
        };
        break;
      case 'WIRE':
        updated = {
          furtherCreditToAccountNumber: i.furtherCreditToAccountNumber,
          furtherCreditToName: i.furtherCreditToName,
          overnightAccountNumber: undefined,
          overnightCheck: false,
          referenceMemo: undefined
        };
        break;
    }

    return {
      ...i,
      ...updated,
      _1099Code: i._1099Code || undefined,
      disbursementMethod: i.disbursementMethod || undefined,
      federalWithholdingPercent: i.federalWithholdingPercent || undefined,
      receivingBankAccountName: i.receivingBankAccountName || undefined,
      receivingBankAccountNumber: i.receivingBankAccountNumber || undefined,
      receivingBankName: i.receivingBankName || undefined,
      receivingBankRoutingNumber: i.receivingBankRoutingNumber || undefined,
      rothIRADestination: i.isRollover ? i.rothIRADestination : undefined,
      rothInitialYear: i.taxType === 'roth' ? i.rothInitialYear : undefined,
      rothQualifiedWithdrawal:
        i.taxType === 'roth' ? i.rothQualifiedWithdrawal : false,
      stateWithholdingCode: i.stateWithholdingCode || undefined,
      stateWithholdingPercent: i.stateWithholdingPercent || undefined
    } as CustomWithdrawalDisbursement;
  });

  return dto;
};

export const sanitizeCustomDisbursement = (
  dto: CustomWithdrawalDisbursementDto
): CustomWithdrawalDisbursementDto => {
  let updated = {};

  switch (dto.details.disbursement.disbursementMethod) {
    case 'CHECK':
      updated = {
        furtherCreditToAccountNumber: undefined,
        furtherCreditToName: undefined,
        overnightAccountNumber: dto.details.disbursement.overnightCheck
          ? dto.details.disbursement.overnightAccountNumber
          : undefined,
        receivingBankAccountName: undefined,
        receivingBankAccountNumber: undefined,
        receivingBankName: undefined,
        receivingBankRoutingNumber: undefined
      };
      break;
    case 'ACH':
      updated = {
        furtherCreditToAccountNumber: undefined,
        furtherCreditToName: undefined,
        overnightAccountNumber: undefined,
        overnightCheck: false,
        referenceMemo: undefined
      };
      break;
    case 'WIRE':
      updated = {
        furtherCreditToAccountNumber:
          dto.details.disbursement.furtherCreditToAccountNumber,
        furtherCreditToName: dto.details.disbursement.furtherCreditToName,
        overnightAccountNumber: undefined,
        overnightCheck: false,
        referenceMemo: undefined
      };
      break;
  }

  dto.taxableAmountNotDetermined = dto.details.disbursement.needsTaxRecords
    ? dto.details.disbursement.taxableAmountNotDetermined
    : undefined;
  dto.rothOrAfterTaxBasisAmount =
    dto.details.disbursement.taxType === 'roth' &&
    dto.details.disbursement.needsTaxRecords &&
    !dto.taxableAmountNotDetermined
      ? dto.details.disbursement.rothOrAfterTaxBasisAmount
      : undefined;

  dto.details.disbursement = omit(
    {
      ...dto.details.disbursement,
      ...updated,
      ...(!dto.details.disbursement.needsTaxRecords
        ? {
            _1099Code: undefined,
            federalWithholdingPercent: undefined,
            rothInitialYear: undefined,
            rothQualifiedWithdrawal: false,
            stateWithholdingCode: undefined,
            stateWithholdingPercent: undefined
          }
        : {
            _1099Code: dto.details.disbursement._1099Code || undefined,
            federalWithholdingPercent:
              dto.details.disbursement.federalWithholdingPercent || undefined,
            rothInitialYear:
              dto.details.disbursement.taxType === 'roth' &&
              !dto.taxableAmountNotDetermined
                ? dto.details.disbursement.rothInitialYear
                : undefined,
            rothQualifiedWithdrawal:
              dto.details.disbursement.taxType === 'roth' &&
              !dto.taxableAmountNotDetermined
                ? dto.details.disbursement.rothQualifiedWithdrawal
                : false,
            stateWithholdingCode:
              dto.details.disbursement.stateWithholdingCode || undefined,
            stateWithholdingPercent:
              dto.details.disbursement.stateWithholdingPercent || undefined
          }),
      disbursementMethod:
        dto.details.disbursement.disbursementMethod || undefined,
      receivingBankAccountName:
        dto.details.disbursement.receivingBankAccountName || undefined,
      receivingBankAccountNumber:
        dto.details.disbursement.receivingBankAccountNumber || undefined,
      receivingBankName:
        dto.details.disbursement.receivingBankName || undefined,
      receivingBankRoutingNumber:
        dto.details.disbursement.receivingBankRoutingNumber || undefined,
      rothIRADestination: dto.details.disbursement.isRollover
        ? dto.details.disbursement.rothIRADestination
        : undefined
    } as CustomDisbursement,
    [
      'needsTaxRecords',
      'taxableAmountNotDetermined',
      'rothOrAfterTaxBasisAmount'
    ]
  );

  return dto;
};

export const useSaveCustomWithdrawalRequest = (
  onClose: () => void,
  withdrawalId?: number
): UseMutationResult<
  CustomWithdrawalResponse,
  Error,
  CustomWithdrawalDetailsDto,
  () => void
> => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ['ParticipantService.useSaveCustomWithdrawalRequest'],
    (data: CustomWithdrawalDetailsDto) => {
      if (withdrawalId !== undefined)
        return ParticipantService.updateCustomWithdrawal(data, withdrawalId);
      else
        return ParticipantService.createAndSubmitCustomWithdrawalRequest(
          data,
          false
        );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });

        queryClient.invalidateQueries();
        onClose();
      }
    }
  );
};

export const useUpdateCustomWithdrawalRequest = (
  withdrawalId?: number
): UseMutationResult<
  CustomWithdrawalResponse,
  Error,
  CustomWithdrawalDetailsDto,
  () => void
> => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ['ParticipantService.updateCustomWithdrawal'],
    (data: CustomWithdrawalDetailsDto) => {
      return ParticipantService.updateCustomWithdrawal(data, withdrawalId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed to update custom withdrawal',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        showSnackbar({
          message: 'Successfully updated!',
          severity: 'success'
        });
        queryClient.invalidateQueries();
      }
    }
  );
};

export const useSubmitCustomWithdrawalRequest = (
  onClose: () => void,
  onSettled: () => void,
  setWithdrawalId: (id: number) => void,
  withdrawalId?: number
): UseMutationResult<
  CustomWithdrawalResponse,
  Error,
  CustomWithdrawalDetailsDto,
  () => void
> => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    ['ParticipantService.submitCustomWithdrawal'],
    (data: CustomWithdrawalDetailsDto) => {
      return ParticipantService.submitCustomWithdrawal(data, withdrawalId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed submitting of custom withdrawal',
          severity: 'error'
        });
      },
      onSettled: onSettled,
      onSuccess: async res => {
        setWithdrawalId(res.withdrawalId);

        if (res.submitted) {
          showSnackbar({
            message: 'Successfully updated and submitted!',
            severity: 'success'
          });

          queryClient.invalidateQueries();
          onClose();
        } else
          showSnackbar({
            message: 'Failed to submit due to validation errors!',
            severity: 'warning'
          });
      }
    }
  );
};

export const useRothBasisAmounts = (): UseBaseMutationResult<
  CustomWithdrawalRothBasisResponse,
  Error,
  CustomWithdrawalRothCostBasisDto,
  () => CustomWithdrawalRothBasisResponse
> => {
  return useMutation(
    ['ParticipantService.useRothBasisAmounts'],
    async (data: CustomWithdrawalRothCostBasisDto) => {
      return ParticipantService.getRothBasisAmounts(data);
    }
  );
};

export const useValidateCustomWithdrawal = (): UseBaseMutationResult<
  any,
  Error,
  CustomWithdrawalDetailsDto,
  () => any
> => {
  return useMutation(
    ['ParticipantService.useRothBasisAmounts'],
    async (data: CustomWithdrawalDetailsDto) => {
      return ParticipantService.validateCustomWithdrawal(data);
    }
  );
};

export const useWithholdingInfo = (
  participantId?: number
): UseBaseMutationResult<
  SubaWithholdingDto,
  Error,
  GetSubaWithholdingContext,
  () => SubaWithholdingDto
> => {
  return useMutation(
    ['ParticipantService.getSubaWithholding'],
    async (data: GetSubaWithholdingContext) => {
      return ParticipantService.getSubaWithholding(data, participantId).then(
        d => {
          return {
            data: {
              ...d.data,
              federalWithholdingPercent: d.data.federalWithholdingPercent.slice(
                0,
                -1
              ),
              stateWithholdingPercent: d.data.stateWithholdingPercent
                ? d.data.stateWithholdingPercent.slice(0, -1)
                : undefined
            }
          };
        }
      );
    }
  );
};
