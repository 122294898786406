import Badge from '@/components/badge';
import Card from '@/components/card';
import { WithdrawalStp } from '@/models/WithdrawalsDTO.model';
import formatters from '@/utils/Formatters';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useToggle } from '@vestwell-frontend/hooks';

import { FC } from 'react';

type WithdrawalStpResultCardProps = {
  stpResult: WithdrawalStp[];
};

const StyledGridCell = styled(Grid)(props => ({
  ':not(:first-child)': {
    paddingLeft: props.theme.spacing(2)
  },
  ':not(:last-child)': {
    borderRight: `1px solid ${props.theme.palette.grey[300]}`
  }
}));

export const WithdrawalStpResultCard: FC<
  WithdrawalStpResultCardProps
> = props => {
  const [showDetails, toggleShowDetails] = useToggle();
  return (
    <Card>
      <CardHeader
        title={
          <Grid container>
            {props.stpResult.map(stp => (
              <StyledGridCell key={stp.ruleType} xs={6}>
                <Stack>
                  <Typography variant='h6'>
                    {stp.ruleType === 'FRAUD'
                      ? 'Fraud Checks'
                      : 'Distribution Checks'}
                  </Typography>
                  <Stack direction='row' spacing={2}>
                    <Badge color={stp.passed ? 'success' : 'warning'}>
                      {stp.passed ? 'Passed STP' : 'Failed STP'}
                    </Badge>
                    <Typography>
                      {formatters.formatFromIsoDate(stp.updatedAt)}
                    </Typography>
                  </Stack>
                </Stack>
              </StyledGridCell>
            ))}
          </Grid>
        }
      />
      <Collapse in={showDetails} timeout='auto' unmountOnExit>
        <Divider />
        <CardContent>
          <Grid container>
            {props.stpResult.map(stp => (
              <StyledGridCell key={stp.ruleType} xs={6}>
                {stp.result.map(result => (
                  <Stack
                    direction='row'
                    key={result.ruleName}
                    pb={2}
                    spacing={1}>
                    {result.passed ? (
                      <CheckCircleIcon color='success' />
                    ) : (
                      <ErrorIcon color='error' />
                    )}
                    <Stack>
                      <Typography variant='subtitle2'>
                        {formatters.displayCase(result.ruleName)}
                      </Typography>
                      <Typography variant='body2'>{result.reason}</Typography>
                    </Stack>
                  </Stack>
                ))}
              </StyledGridCell>
            ))}
          </Grid>
        </CardContent>
      </Collapse>
      <Divider />
      <CardActions>
        <Button
          onClick={toggleShowDetails}
          startIcon={
            showDetails ? <CodeOffIcon /> : <CodeIcon />
          }>{`${showDetails ? 'Hide' : 'Show'} Details`}</Button>
      </CardActions>
    </Card>
  );
};

WithdrawalStpResultCard.displayName = 'WithdrawalStpResultCard';
