import AccessControl from '@/components/access-control/AccessControl.component';
import SplitButton from '@/components/split-button';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanYearConfiguration } from '@/models/YearEndTestingDTO.model';
import { PlanService } from '@/services/Plan.service';
import {
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Switch,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import AnnualTestingNotifications from './AnnualTestingNotifications.component';
import { YearEndTestResults } from './YearEndTestResults.component';

interface PlanAnnualTestingProps {
  sponsorPlanId: number;
}

export const PlanAnnualTestingTab: FC<PlanAnnualTestingProps> = props => {
  const [year, setYear] = useState(null);
  const [isPageForSponsor, setPageForSponsor] = useState(true);
  const [openTestExecutionRow, toggleOpenTestExecutionRow] = useToggle(false);
  const [openContributionRow, toggleOpenContributionRow] = useToggle(false);

  const yearEndTestingYears = useQuery<number[]>(
    ['PlanService.getSponsorPlanYearEndTestingYears', props.sponsorPlanId],
    () => {
      return PlanService.getSponsorPlanYearEndTestingYears(props.sponsorPlanId);
    },
    {
      onError: () => setYear(new Date().getFullYear()),
      onSuccess: (data: number[]) => {
        setYear(data[0]);
      }
    }
  );

  const yearEndTestingConfigQuery = useQuery(
    ['PlanService.getPlanYearEndConfiguration', props.sponsorPlanId, year],
    () => PlanService.getPlanYearEndConfiguration(props.sponsorPlanId, year),
    {
      enabled: Boolean(props.sponsorPlanId) && !!year,
      onSuccess: (data: PlanYearConfiguration) => {
        setPageForSponsor(data.shouldDisplayYearEndPlanToDo);
      }
    }
  );

  const handleMenuItemClick = useCallback(
    (index: number, value: string | number) => {
      setYear(+value);
      toggleOpenTestExecutionRow(false);
      toggleOpenContributionRow(false);
    },
    []
  );

  const options = useMemo(() => {
    return yearEndTestingYears.data?.map(y => {
      return {
        label: y,
        value: y
      };
    });
  }, [yearEndTestingYears.data]);

  const handlePageViewChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!yearEndTestingYears.data || !yearEndTestingConfigQuery.data) return;
    setPageForSponsor(event.target.checked);
    if (event.target.checked) {
      await PlanService.postPlanYearEndConfiguration(props.sponsorPlanId, year);
    } else {
      await PlanService.deletePlanYearEndConfiguration(
        props.sponsorPlanId,
        year
      );
    }
  };

  return (
    <Grid
      container
      data-testid={`loading-${yearEndTestingConfigQuery.isFetching}`}
      direction='column'
      spacing={2}>
      <Grid alignItems='center' display='flex' justifyContent='space-between'>
        <Grid alignItems='center' display='flex'>
          <Typography mr={2} variant='subtitle1'>
            Year
          </Typography>
          <SplitButton
            dataTestId='select-year-button'
            label={year?.toString()}
            onMenuItemClick={handleMenuItemClick}
            options={options}
            variant='outlined'
          />
        </Grid>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
          <FormControlLabel
            control={
              <Switch
                checked={isPageForSponsor}
                data-testid='sponsor-view-switch'
                inputProps={{
                  // @ts-expect-error: Mui is missing types
                  'data-testid': 'sponsor-view-switch-input'
                }}
                onChange={handlePageViewChange}
              />
            }
            data-testid='sponsor-view-switch-label'
            label='Display annual testing page for sponsor'
            sx={{ textTransform: 'capitalize' }}
          />
        </AccessControl>
      </Grid>
      <Grid>
        <YearEndTestResults
          openContributionRow={openContributionRow}
          openTestExecutionRow={openTestExecutionRow}
          sponsorPlanId={props.sponsorPlanId}
          toggleOpenContributionRow={toggleOpenContributionRow}
          toggleOpenTestExecutionRow={toggleOpenTestExecutionRow}
          year={year}
        />
      </Grid>
      <Grid>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
          <AnnualTestingNotifications sponsorPlanId={props.sponsorPlanId} />
        </AccessControl>
      </Grid>
    </Grid>
  );
};
