import { PlanFeatures } from '@/models/PlanFeaturesDto.model';

import { Correction, GridRow } from '../types';

export const generateReversals = (
  data: GridRow[],
  planFeatures?: PlanFeatures
): Correction[] => {
  return data
    ?.filter(row => row?.contribution)
    ?.map(row => {
      return {
        at: 0,
        at_original: row?.at || 0,
        em: 0,
        em_original: row?.em || 0,
        first_name: row.firstName,
        last_name: row.lastName,
        ln: 0,
        ln_original: row?.ln || 0,
        participant_id: row.participantId,
        ps: 0,
        ps_original: row?.ps || 0,
        qc: 0,
        qc_original: row?.qc || 0,
        qm: 0,
        qm_original: row?.qm || 0,
        rc: 0,
        rc_original: row?.rc || 0,
        sd: 0,
        sd_original: row?.sd || 0,
        sh: 0,
        sh_original: row?.sh || 0,
        ssn: row.ssn,
        ...(planFeatures?.allowPrevailingWage
          ? { pw: 0, pw_original: row?.pw || 0 }
          : {})
      };
    });
};
