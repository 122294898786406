import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParentAccountDto } from '@/models/suba/ParentAccountDTO.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';

import { AxiosError } from 'axios';

export const useParentAccountByCustodianQuery = (params: {
  custodianAccountNumber?: string;
  custodianId?: CustodianId;
}): UseQueryResult<ParentAccountDto | undefined> => {
  const { showSnackbar } = useSnackbar();

  return useQuery(
    [
      'ParentAccountService.get',
      params.custodianAccountNumber,
      params.custodianId
    ],
    () =>
      params.custodianAccountNumber === undefined
        ? undefined
        : ParentAccountService.get({
            custodianAccountNumber: params.custodianAccountNumber,
            custodianId: params.custodianId
          }),
    {
      enabled: Boolean(params.custodianAccountNumber && params.custodianId),
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get parent account: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
