import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import Badge from '@/components/badge';
import CircularLoading from '@/components/circular-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  Program,
  ProgramConverter,
  ProgramPlan,
  ProgramPlanDto
} from '@/models/ops/investments/Program.model';
import { PlanAdminStatus } from '@/models/suba/common/PlanAdminStatus.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  ProgramPlansListDto,
  ProgramService
} from '@/services/ops/investments/Program.service';
import { PlanService } from '@/services/Plan.service';
import { Close, Search } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Divider,
  InputAdornment,
  ListItem,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import { useFormik } from 'formik';
import { identity, isEqual, isString } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FullReEnrollmentModal } from './FullReEnrollmentModal.component';
import {
  ReEnrollmentModal,
  ReEnrollmentType
} from './ReEnrollmentModal.component';

const itemHeight = (theme: Theme) => theme.spacing(5);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteWidth: {
      width: theme.spacing(65)
    },
    clearUser: {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      marginLeft: 'auto',
      marginRight: theme.spacing(1)
    },
    header: {
      fontSize: theme.spacing(3)
    },
    idField: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2)
    },
    inputIcon: {
      paddingRight: theme.spacing(1)
    },
    nameField: {
      marginLeft: theme.spacing(1)
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    planInfoInput: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      height: itemHeight(theme),
      paddingTop: theme.spacing(0.75)
    },
    saveButton: { height: itemHeight(theme) },
    section: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    statusField: {
      marginLeft: theme.spacing(1),
      whiteSpace: 'nowrap'
    }
  })
);

export interface ProgramDetailsAddPlanProps {
  programId: number;
  programName: string;
  setPlanId: (planId: number) => void;
  modelIds: Pick<
    Program,
    'targetDateSeriesId' | 'riskSeriesId' | 'goalSeriesId' | 'fundLineupId'
  >;
}

export const ProgramDetailsAddPlan = (
  props: ProgramDetailsAddPlanProps
): JSX.Element => {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const hasOnboardingPermission = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_INVESTMENTS_REENROLLMENT_SUBMIT]
  });
  const hasAllPermission = useHasPermissions({
    requires: [
      FeatureLevelPermissions.WRITE_INVESTMENTS_REENROLLMENT_GLOBAL_SUBMIT
    ]
  });

  const [query, setQuery] = useState('');
  const [isReEnrollmentModalOpen, toggleReEnrollmentModal] = useToggle(false);
  const [isFullReEnrollmentModalOpen, toggleFullReEnrollmentModal] =
    useToggle(false);

  const programPlansQuery = useQuery<ProgramPlansListDto>(
    ['PlanService.getNullProgramPlans', props.programId, query],
    () => PlanService.getNullProgramPlans(query),
    {
      enabled: Boolean(query.trim().length)
    }
  );

  const programPlansData = programPlansQuery.data?.data || [];
  const options = programPlansData.map(plan =>
    ProgramConverter.toProgramPlan(plan)
  );

  let noOptionsText = 'Type to search';
  const hasResults = options.length > 0;
  const hasQuery = query.trim().length > 0;

  if (hasQuery && !hasResults) {
    if (programPlansQuery.isSuccess) {
      noOptionsText = `No results found for "${query}"`;
    } else if (programPlansQuery.isError) {
      noOptionsText = `Encountered an error searching for "${query}"`;
    } else if (!programPlansQuery.isFetching) {
      noOptionsText = 'Type to search';
    } else if (programPlansQuery.isFetching) {
      noOptionsText = 'Searching...';
    }
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {} as ProgramPlan,
    onSubmit: async values => {
      try {
        await PlanService.updatePlanProgram(values.id, props.programId);
        snackbar.showSnackbar({
          message: 'Program added to Plan',
          severity: 'success'
        });
        props.setPlanId(values.id);
        form.resetForm({});
      } catch (error) {
        snackbar.showSnackbar({
          message: `Failed to added Program to Plan: ${error}`,
          severity: 'error'
        });
      }
    }
  });

  const programPlansListQuery = useQuery<{
    data: ProgramPlanDto[];
    meta: { count: number };
  }>(
    [
      'ProgramService.getProgramPlans',
      props.programId,
      1,
      10,
      '',
      'id',
      'asc',
      form.values.id
    ],
    () =>
      ProgramService.getProgramPlans(props.programId, 1, 10, '', 'id', 'asc')
  );

  const postNoReenrollment = useMutation(
    [
      'ProgramService.postProgramChangeNoReenrollment',
      props.programId,
      form.values.id
    ],
    () =>
      ProgramService.postProgramChangeNoReenrollment(
        props.programId,
        form.values.id
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: `Couldn’t submit changes to plan id ${form.values.id}`,
          severity: 'error'
        });
      },
      onSettled: toggleReEnrollmentModal,
      onSuccess: () => {
        form.resetForm();
        programPlansQuery.refetch();
        props.setPlanId(form.values.id);
        programPlansListQuery.refetch();
        snackbar.showSnackbar({
          message: `Successfully submitted changes to plan id ${form.values.id}`,
          severity: 'success'
        });
      }
    }
  );

  const plansInfo = useQuery(
    ['PlanService.getPlanById', form.values.id],
    () => PlanService.getPlanById(form.values.id),
    { enabled: !!form.values.id }
  );

  const onReEnrollmentSubmit = useCallback(
    async (reEnrollmentOption: ReEnrollmentType) => {
      if (reEnrollmentOption === 'full') {
        toggleFullReEnrollmentModal();
        toggleReEnrollmentModal();
      } else if (reEnrollmentOption === 'partial') {
        navigate(
          `/ops/investments/re-enrollment/plan/${form.values.id}/new-program/${props.programId}`
        );
      } else {
        postNoReenrollment.mutate();
      }
    },
    [form.values.id, props.programId]
  );

  const onFullReenrollmentSuccess = useCallback(() => {
    form.resetForm();
    programPlansQuery.refetch();
    props.setPlanId(form.values.id);
    programPlansListQuery.refetch();
  }, [form.values.id]);

  const isReEnrolmentNeeded = useMemo(
    () => !!plansInfo.data?.data?.relationships?.program,
    [plansInfo.data?.data?.relationships?.program]
  );

  const hasReEnrollmentPermission = useMemo(() => {
    return (
      hasAllPermission.isAllowed ||
      (hasOnboardingPermission.isAllowed &&
        plansInfo.data?.data?.attributes.status === PlanAdminStatus.Onboarding)
    );
  }, [
    plansInfo.data?.data?.attributes.status,
    hasAllPermission,
    hasOnboardingPermission
  ]);

  const onAssociationClick = useCallback(() => {
    if (isReEnrolmentNeeded) {
      toggleReEnrollmentModal();
    } else {
      form.handleSubmit();
    }
  }, [isReEnrolmentNeeded]);

  return (
    <>
      <Paper className={classes.paper} elevation={0} variant='outlined'>
        <div className={classes.section}>
          <Typography className={classes.header} variant='h5'>
            Add Plan to Program
          </Typography>
        </div>
        <Divider />
        <div className={classes.section}>
          <Stack direction='column' spacing={2}>
            <Stack direction='row' spacing={3}>
              {Boolean(form.values.id) && (
                <Paper
                  className={clsx(
                    classes.planInfoInput,
                    classes.autocompleteWidth
                  )}
                  variant='outlined'>
                  <Stack direction='row'>
                    <Typography className={classes.idField}>
                      {form.values.id}
                    </Typography>
                    <Typography className={classes.nameField} noWrap>
                      {form.values.name}
                    </Typography>
                    <Box className={classes.statusField}>
                      <Badge color='success' size='small'>
                        {form.values.status}
                      </Badge>
                    </Box>
                    <Box className={classes.clearUser}>
                      {form.isSubmitting && <CircularLoading />}
                      {!form.isSubmitting && (
                        <Close
                          onClick={() => {
                            setQuery('');
                            form.resetForm();
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                </Paper>
              )}
              {!form.values.id && (
                <Autocomplete
                  className={classes.autocompleteWidth}
                  clearIcon={<Close />}
                  disablePortal={false}
                  filterOptions={identity}
                  freeSolo
                  getOptionLabel={option =>
                    isString(option) ? '' : option.name
                  }
                  isOptionEqualToValue={isEqual}
                  noOptionsText={noOptionsText}
                  onChange={(event, value) => {
                    if (value && !isString(value)) form.setValues(value);
                  }}
                  openOnFocus
                  options={options}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          placeholder: 'Search by Plan ID or Plan Name',
                          startAdornment: programPlansQuery.isFetching ? (
                            <InputAdornment position='start'>
                              <CircularLoading />
                            </InputAdornment>
                          ) : (
                            <InputAdornment position='start'>
                              <Search />
                            </InputAdornment>
                          ),
                          style: {
                            height: itemHeight(theme),
                            paddingBottom: '0px',
                            paddingLeft: theme.spacing(1.75),
                            paddingRight: '0px',
                            paddingTop: '0px'
                          }
                        }}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            paddingLeft: '0px'
                          }
                        }}
                        onChange={event => setQuery(event.target.value)}
                      />
                    );
                  }}
                  renderOption={(optionProps, option) => {
                    return (
                      <ListItem {...optionProps} key={option.id}>
                        <Stack direction='row'>
                          <Typography className={classes.idField}>
                            {option.id}
                          </Typography>
                          <Typography className={classes.nameField} noWrap>
                            {option.name}
                          </Typography>
                          <Box className={classes.statusField}>
                            <Badge color='success' size='small'>
                              {option.status}
                            </Badge>
                          </Box>
                        </Stack>
                      </ListItem>
                    );
                  }}
                />
              )}
              <LoadingButton
                className={classes.saveButton}
                disabled={
                  !form.dirty ||
                  (isReEnrolmentNeeded && !hasReEnrollmentPermission)
                }
                endIcon={<PersonAddIcon />}
                loading={plansInfo.isFetching}
                onClick={onAssociationClick}
                variant='outlined'>
                Create association
              </LoadingButton>
            </Stack>
            <Typography variant='body2'>
              Plan additions to programs may take up to 15 minutes to display.
            </Typography>
          </Stack>
        </div>
      </Paper>
      <ReEnrollmentModal
        currentProgramId={
          plansInfo.data?.data?.relationships?.program?.data?.id
        }
        currentProgramName={
          plansInfo.data?.data?.relationships?.program?.data?.name
        }
        isModalOpen={isReEnrollmentModalOpen}
        isSubmitting={postNoReenrollment.isLoading}
        newModelIds={props.modelIds}
        newProgramId={props.programId}
        newProgramName={props.programName}
        onClose={toggleReEnrollmentModal}
        onSubmit={onReEnrollmentSubmit}
      />
      <FullReEnrollmentModal
        currentProgramId={
          plansInfo.data?.data?.relationships?.program?.data?.id
        }
        currentProgramName={
          plansInfo.data?.data?.relationships?.program?.data?.name
        }
        isModalOpen={isFullReEnrollmentModalOpen}
        newProgramId={props.programId}
        newProgramName={props.programName}
        onClose={toggleFullReEnrollmentModal}
        onSuccess={onFullReenrollmentSuccess}
        planId={form.values.id}
        planName={form.values.name}
      />
    </>
  );
};
