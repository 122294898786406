import { TradeRequestWorkflowVariations } from '@/models/suba/workflows/TradeRequestWorkflowVariations.model';
import {
  DepositRequestWorkflowDto,
  InvestContributionWorkflowDto,
  TradeRequestRebalanceWorkflowDto,
  WorkflowDto
} from '@/models/suba/workflows/WorkflowDTO.model';
import { WorkflowStatusEnumsObject } from '@/models/suba/workflows/WorkflowStatusEnumObject.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import DepositService from '@/services/suba/deposit/Deposit.service';
import PendingRequestService from '@/services/suba/pending-requests/PendingRequest.service';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import { userService } from '@/services/User.service';
import { Button, Stack, Typography } from '@mui/material';
import {
  DepositRequestHoldingTankStatus,
  InvestContributionHoldingTankStatus,
  TradeRequestHoldingTankStatus
} from '@vestwell-sub-accounting/models/common/HoldingTankStatus';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TradeCalculatorMethod } from '@vestwell-sub-accounting/models/common/TradeCalculatorMethod';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';
import { DepositRequestWorkflowStatus } from '@vestwell-sub-accounting/models/conductor/WorkflowStatus.model';
import { PendingRequestStatuses } from '@vestwell-sub-accounting/models/integrationsGateway/PendingRequestStatuses';

import React, { FC, useState } from 'react';

import {
  ActionConfirmationDialog,
  ActionConfirmationDialogProps
} from './dialogs/ActionConfirmationDialog.component';

interface ActionButtonProps extends React.HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  children?: React.ReactNode;
  onClick: () => void;
}
const ActionButton: FC<ActionButtonProps> = props => {
  const { children, onClick, 'data-testid': testId } = props;

  return (
    <Button
      color='primary'
      data-testid={testId}
      onClick={onClick}
      sx={{ borderRadius: '999px', fontSize: 13, lineHeight: 1.4, py: 0.75 }}
      variant='outlined'>
      {children}
    </Button>
  );
};

interface WorkflowActionsBaseProps {
  workflow: WorkflowDto;
  setConfirmationDialogConfig: React.Dispatch<
    React.SetStateAction<ActionConfirmationDialogProps>
  >;
}

type WorkflowInvestContributionActionsProps = Omit<
  WorkflowActionsBaseProps,
  'workflow'
> & {
  workflow: InvestContributionWorkflowDto;
};

export const WorkflowInvestContributionActions: FC<
  WorkflowInvestContributionActionsProps
> = props => {
  return (
    <>
      {props.workflow.queue && (
        <>
          {props.workflow.queue.status ===
            InvestContributionHoldingTankStatus.failed && (
            <ActionButton
              data-testid='reprocess-workflow-button'
              onClick={() => {
                props.setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to reprocess this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.reprocess(props.workflow.id);
                  },
                  open: true,
                  successMessage: 'Workflow reprocessed'
                }));
              }}>
              Reprocess
            </ActionButton>
          )}
          {props.workflow.relatedWorkflows?.find(
            relatedWorkflow =>
              relatedWorkflow.workflowName ===
              WorkflowName.queueReversalByTracerId
          ) === undefined &&
            props.workflow.queue?.status ===
              InvestContributionHoldingTankStatus.finished && (
              <ActionButton
                data-testid='reverse-workflow-button'
                onClick={() => {
                  props.setConfirmationDialogConfig(prevConfig => ({
                    ...prevConfig,
                    inputs: [
                      {
                        initialValue: SubAccountType.error,
                        label: 'Operational Sub Account Type',
                        name: 'operationalSubAccountType',
                        options: [
                          { label: 'Error', value: SubAccountType.error },
                          { label: 'Breakage', value: SubAccountType.breakage },
                          { label: 'Dividend', value: SubAccountType.dividend }
                        ],
                        type: 'select'
                      }
                    ],
                    message: 'Are you sure you want to reverse this workflow?',
                    onConfirm: async (values: Record<string, unknown>) => {
                      await WorkflowService.reverse(
                        props.workflow.tracerId,
                        String(values.operationalSubAccountType) ||
                          SubAccountType.error
                      );
                    },
                    open: true,
                    successMessage: 'Workflow reversed'
                  }));
                }}>
                Reverse
              </ActionButton>
            )}
          {props.workflow.queue?.status ===
            InvestContributionHoldingTankStatus.queued && (
            <ActionButton
              data-testid='defer-workflow-button'
              onClick={() => {
                props.setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to defer this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.defer(props.workflow.tracerId);
                  },
                  open: true,
                  successMessage: 'Workflow deferred'
                }));
              }}>
              Defer
            </ActionButton>
          )}
          {props.workflow.queue?.status ===
            InvestContributionHoldingTankStatus.queued && (
            <ActionButton
              data-testid='force-processing-workflow-button'
              onClick={() => {
                props.setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message:
                    'Are you sure you want to force processing of this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.forceProcessing(
                      props.workflow.tracerId
                    );
                  },
                  open: true,
                  successMessage: 'Workflow forced'
                }));
              }}>
              Force Processing
            </ActionButton>
          )}
        </>
      )}
    </>
  );
};

type WorkflowTradeRequestRebalanceActionsProps = Omit<
  WorkflowActionsBaseProps,
  'workflow'
> & {
  workflow: TradeRequestRebalanceWorkflowDto;
};

export const WorkflowTradeRequestRebalanceActions: FC<
  WorkflowTradeRequestRebalanceActionsProps
> = props => {
  return (
    <>
      {props.workflow.queue && (
        <>
          {props.workflow.queue?.status ===
            TradeRequestHoldingTankStatus.failed && (
            <ActionButton
              data-testid='reprocess-workflow-button'
              onClick={() => {
                props.setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to reprocess this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.reprocess(props.workflow.id);
                  },
                  open: true,
                  successMessage: 'Workflow reprocessed'
                }));
              }}>
              Reprocess
            </ActionButton>
          )}
          {props.workflow.relatedWorkflows?.find(
            relatedWorkflow =>
              relatedWorkflow.workflowName ===
              WorkflowName.queueReversalByTracerId
          ) === undefined &&
            props.workflow.queue?.status ===
              TradeRequestHoldingTankStatus.finished && (
              <ActionButton
                data-testid='reverse-workflow-button'
                onClick={() => {
                  props.setConfirmationDialogConfig(prevConfig => ({
                    ...prevConfig,
                    inputs: [
                      {
                        initialValue: SubAccountType.error,
                        label: 'Operational Sub Account Type',
                        name: 'operationalSubAccountType',
                        options: [
                          { label: 'Error', value: SubAccountType.error },
                          { label: 'Breakage', value: SubAccountType.breakage },
                          { label: 'Dividend', value: SubAccountType.dividend }
                        ],
                        type: 'select'
                      }
                    ],
                    message: 'Are you sure you want to reverse this workflow?',
                    onConfirm: async (values: Record<string, unknown>) => {
                      await WorkflowService.reverse(
                        props.workflow.tracerId,
                        String(values.operationalSubAccountType) ||
                          SubAccountType.error
                      );
                    },
                    open: true,
                    successMessage: 'Workflow reversed'
                  }));
                }}>
                Reverse
              </ActionButton>
            )}
          {props.workflow.queue?.status ===
            TradeRequestHoldingTankStatus.queued && (
            <ActionButton
              data-testid='defer-workflow-button'
              onClick={() => {
                props.setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to defer this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.defer(props.workflow.tracerId);
                  },
                  open: true,
                  successMessage: 'Workflow deferred'
                }));
              }}>
              Defer
            </ActionButton>
          )}
          {props.workflow.queue?.status ===
            TradeRequestHoldingTankStatus.queued && (
            <ActionButton
              data-testid='force-processing-workflow-button'
              onClick={() => {
                props.setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message:
                    'Are you sure you want to force processing of this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.forceProcessing(
                      props.workflow.tracerId
                    );
                  },
                  open: true,
                  successMessage: 'Workflow forced'
                }));
              }}>
              Force Processing
            </ActionButton>
          )}
        </>
      )}
    </>
  );
};

export const WorkflowNewPlanActions: FC<WorkflowActionsBaseProps> = props => {
  return (
    <>
      {props.workflow.workflowStatus ===
        WorkflowStatusEnumsObject.PendingCustodianAccount &&
        props.workflow.pendingRequest?.status !==
          PendingRequestStatuses.pending && (
          <ActionButton
            data-testid='resend-to-custodian-workflow-button'
            onClick={() => {
              props.setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message: 'Are you sure you want to resend this workflow?',
                onConfirm: async () => {
                  if (props.workflow.pendingRequest?.id) {
                    // shouldn't ever be the case that pendingRequest id would be undefined at this point but typescript cares
                    await PendingRequestService.resendToCustodian(
                      props.workflow.pendingRequest.id
                    );
                  }
                },
                open: true,
                successMessage: 'Workflow resent'
              }));
            }}>
            Resend to Custodian
          </ActionButton>
        )}
    </>
  );
};

type WorkflowDepositRequestActionsProps = Omit<
  WorkflowActionsBaseProps,
  'workflow'
> & {
  workflow: DepositRequestWorkflowDto;
};

export const WorkflowDepositRequestActions: FC<
  WorkflowDepositRequestActionsProps
> = props => {
  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_DEPOSIT_REQUEST_EDIT
  );
  return (
    <>
      {props.workflow.workflowStatus ===
        WorkflowStatusEnumsObject.PendingACHProcessing &&
        props.workflow.pendingRequest?.status !==
          PendingRequestStatuses.pending && (
          <ActionButton
            data-testid='resend-to-custodian-workflow-button'
            onClick={() => {
              props.setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message: 'Are you sure you want to resend this workflow?',
                onConfirm: async () => {
                  if (props.workflow.pendingRequest?.id) {
                    // shouldn't ever be the case that pendingRequest id would be undefined at this point but typescript cares
                    await PendingRequestService.resendToCustodian(
                      props.workflow.pendingRequest.id
                    );
                  }
                },
                open: true,
                successMessage: 'Workflow resent'
              }));
            }}>
            Resend to Custodian
          </ActionButton>
        )}
      {[
        DepositRequestWorkflowStatus.Queued,
        DepositRequestWorkflowStatus.PendingACHProcessing,
        DepositRequestWorkflowStatus.ACHConfirmed,
        DepositRequestWorkflowStatus.Failed
      ].includes(props.workflow.workflowStatus) &&
        hasWritePermissions &&
        PendingRequestStatuses.processed ===
          props.workflow.pendingRequest?.status && (
          <ActionButton
            data-testid='deposit-request-defer-workflow-button'
            onClick={() => {
              props.setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message: 'Are you sure you want to defer this workflow?',
                onConfirm: async () => {
                  if (props.workflow.pendingRequest?.id) {
                    await DepositService.manuallyComplete({
                      id: props.workflow.pendingRequest.id,
                      status: DepositRequestHoldingTankStatus.deferred
                    });
                  }
                },
                open: true,
                successMessage: 'Workflow deferred'
              }));
            }}>
            Defer
          </ActionButton>
        )}
      {[
        DepositRequestWorkflowStatus.PendingACHProcessing,
        DepositRequestWorkflowStatus.Failed
      ].includes(props.workflow.workflowStatus) &&
        hasWritePermissions &&
        PendingRequestStatuses.processed ===
          props.workflow.pendingRequest?.status && (
          <ActionButton
            data-testid='deposit-request-confirm-ach-button'
            onClick={() => {
              props.setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message:
                  'Are you sure you want to confirm ACH on this workflow?',
                onConfirm: async () => {
                  await DepositService.manuallyComplete({
                    id: props.workflow.pendingRequest.id,
                    status: DepositRequestHoldingTankStatus.confirmedAch
                  });
                },
                open: true,
                successMessage: 'ACH confirmed'
              }));
            }}>
            Confirm ACH
          </ActionButton>
        )}
      {[
        DepositRequestWorkflowStatus.PendingACHProcessing,
        DepositRequestWorkflowStatus.ACHConfirmed,
        DepositRequestWorkflowStatus.Failed
      ].includes(props.workflow.workflowStatus) &&
        props.workflow.workflowName === WorkflowName.depositRequest &&
        hasWritePermissions &&
        PendingRequestStatuses.processed ===
          props.workflow.pendingRequest?.status && (
          <ActionButton
            data-testid='deposit-request-confirm-funding-button'
            onClick={() => {
              props.setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message:
                  'Are you sure you want to confirm funding on this workflow?',
                onConfirm: async () => {
                  await DepositService.manuallyComplete({
                    id: props.workflow.pendingRequest.id,
                    status: DepositRequestHoldingTankStatus.confirmedFunding
                  });
                },
                open: true,
                successMessage: 'Funding confirmed'
              }));
            }}>
            Confirm Funding
          </ActionButton>
        )}
    </>
  );
};

interface WorkflowDetailActionsProps {
  workflow: WorkflowDto;
  workflowType: WorkflowName | TradeRequestWorkflowVariations;
}
export const WorkflowDetailActions: FC<WorkflowDetailActionsProps> = props => {
  const [confirmationDialogConfig, setConfirmationDialogConfig] =
    useState<ActionConfirmationDialogProps>({
      inputs: [],
      message: '',
      onCancel: () => {
        setConfirmationDialogConfig(prevConfig => ({
          ...prevConfig,
          open: false
        }));
      },
      onConfirm: async () => {},
      open: false,
      successMessage: ''
    });

  return (
    <>
      <ActionConfirmationDialog
        {...confirmationDialogConfig}
        onConfirm={async values => {
          if (typeof confirmationDialogConfig.onConfirm === 'function') {
            await confirmationDialogConfig.onConfirm(values);
          }
          setConfirmationDialogConfig(prevConfig => ({
            ...prevConfig,
            open: false
          }));
        }}
      />
      <Stack
        alignItems='center'
        data-testid='workflow-actions'
        direction='row'
        spacing={1}
        sx={{ mt: 3 }}>
        <Typography>Available Actions</Typography>
        {props.workflowType === WorkflowName.investContribution &&
          props.workflow.workflowName === WorkflowName.investContribution && (
            <WorkflowInvestContributionActions
              setConfirmationDialogConfig={setConfirmationDialogConfig}
              workflow={props.workflow}
            />
          )}
        {props.workflowType ===
          TradeRequestWorkflowVariations.tradeRequestRebalance &&
          props.workflow.workflowName === WorkflowName.tradeRequest &&
          'input' in props.workflow &&
          'tradeCalculatorMethod' in props.workflow.input &&
          props.workflow.input.tradeCalculatorMethod ===
            TradeCalculatorMethod.RebalanceFull && (
            <WorkflowTradeRequestRebalanceActions
              setConfirmationDialogConfig={setConfirmationDialogConfig}
              workflow={props.workflow as TradeRequestRebalanceWorkflowDto}
            />
          )}
        {props.workflowType === WorkflowName.newPlan && (
          <WorkflowNewPlanActions
            setConfirmationDialogConfig={setConfirmationDialogConfig}
            workflow={props.workflow}
          />
        )}
        {props.workflowType === WorkflowName.depositRequest &&
          props.workflow.workflowName === WorkflowName.depositRequest && (
            <WorkflowDepositRequestActions
              setConfirmationDialogConfig={setConfirmationDialogConfig}
              workflow={props.workflow}
            />
          )}
      </Stack>
    </>
  );
};
