import AccessControl from '@/components/access-control/AccessControl.component';
import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { useOpsTooling } from '@/contexts/OpsToolingContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import usePayrollSetups from '@/hooks/usePayrollSetups';
import { PlanV2Dto } from '@/models';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import {
  initialPlanEmployeeFilters,
  PlanEmployeeFilters,
  PlanParticipantsInfo
} from '@/models/PlanParticipantsDTO.model';
import { Recordkeeper } from '@/models/Recordkeeper.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import PlanEmployeesRegistrationTab from '@/routes/plans/plan-detail/PlanEmployees/PlanEmployeesRegistrationTab.component';
import { PlanEmployeesScheduleEmails } from '@/routes/plans/plan-detail/PlanEmployees/PlanEmployeesScheduleEmails.component';
import { EsaService } from '@/services/Esa.service';
import { OpsToolingType } from '@/services/payroll-integrations.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { Search, WarningAmber } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { getHeaderColumns } from './columnsHelpers';
import { PlanEmployeesFilter } from './PlanEmployeesFilter.component';
import PlanEmployeeTableCell from './PlanEmployeeTableCell.component';

const useStyles = makeStyles((theme: Theme) => ({
  loadingButton: {
    height: theme.spacing(5)
  }
}));

interface PlanParticipantsProps {
  isStateIRA: boolean;
  sponsorPlanId: string;
  plan: PlanV2Dto;
  isEsa?: boolean;
}

export const PlanEmployees: React.FC<PlanParticipantsProps> = props => {
  const classes = useStyles();
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const myPermissions = token?.permissions || [];
  const canRemoveOrMerge = myPermissions.find(
    p =>
      p === FeatureLevelPermissions.WRITE_EMPLOYEES_MERGE_ACCOUNTS ||
      p === FeatureLevelPermissions.WRITE_EMPLOYEES_REMOVE_EMPLOYEE
  );

  const planFeatures = useQuery<PlanFeaturesDto>(
    [PlanService.getPlanFeatures.name, +props.sponsorPlanId],
    () => PlanService.getPlanFeatures(+props.sponsorPlanId),
    {
      enabled: !!props.sponsorPlanId
    }
  );

  const createHeaderColumns = useCallback(
    newChoice =>
      getHeaderColumns(
        newChoice,
        canRemoveOrMerge,
        props.isStateIRA,
        props.isEsa,
        planFeatures.data?.data?.attributes?.hasDualEligibility,
        props.plan?.data?.relationships?.recordKeeper?.data?.id
      ),
    [props.isStateIRA, canRemoveOrMerge, props.isEsa, planFeatures.data]
  );

  const [toggleChoice, setToggleChoice] = useState('overview');
  const [headerColumns, setHeaderColumns] = useState<GridColDef[]>(
    createHeaderColumns(toggleChoice)
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [esaGroupId, setEsaGroupId] = useState(0);
  const [filters, setFilters] = useState<PlanEmployeeFilters>(
    initialPlanEmployeeFilters
  );
  const { showSnackbar } = useSnackbar();

  const { showOpsToolingDrawer } = useOpsTooling();
  const payrollSetupsQuery = usePayrollSetups(+props.sponsorPlanId);
  const isApiPlan = payrollSetupsQuery.data?.isApiPlan || false;

  const handleToggleChoiceChange = (
    _: React.MouseEvent<HTMLElement>,
    newChoice: string
  ) => {
    setToggleChoice(newChoice);
    const newColumns = createHeaderColumns(newChoice);
    setHeaderColumns(newColumns);
  };

  const planDesignQuery = useQuery(
    ['PlanService.getPlanDesignById', +props.sponsorPlanId, props.isStateIRA],
    () => PlanService.getPlanDesignById(props.sponsorPlanId),
    { enabled: !props.isStateIRA }
  );

  const statusQueryKey = useMemo(() => {
    if (filters.statuses.length > 0) {
      return props.isStateIRA
        ? {
            filter: 'status',
            statuses: filters.statuses
          }
        : {
            eligibility: filters.statuses,
            filter: 'eligibility'
          };
    }
  }, [filters.statuses]);

  const planParticipantQuery = useQuery<PlanParticipantsInfo>(
    [
      'PlanService.getParticipantsByPlanId',
      props.sponsorPlanId,
      pageNumber,
      rowsPerPage,
      debouncedSearchTerm,
      esaGroupId,
      filters.statuses,
      filters.preTaxDeferralEnd,
      filters.preTaxDeferralStart,
      filters.rothDeferralEnd,
      filters.rothDeferralStart
    ],
    async () => {
      return PlanService.getParticipantsByPlanId({
        esaGroupId,
        pageNumber,
        pageSize: rowsPerPage,
        planId: +props.sponsorPlanId,
        preTaxDeferralEnd: +filters.preTaxDeferralEnd,
        preTaxDeferralStart: +filters.preTaxDeferralStart,
        rothDeferralEnd: +filters.rothDeferralEnd,
        rothDeferralStart: +filters.rothDeferralStart,
        searchTerm: debouncedSearchTerm,
        sort: ['lastName', 'name'],
        ...statusQueryKey
      });
    },
    {
      enabled: toggleChoice !== 'registration',
      keepPreviousData: true
    }
  );

  const { refetch: refetchParticipantsWithContributions } = useQuery(
    [
      'PlanService.getParticipantsEligibilityReportByPlan',
      +props.sponsorPlanId
    ],
    () =>
      PlanService.getParticipantsEligibilityReportByPlan(+props.sponsorPlanId),
    {
      enabled: false,
      refetchOnWindowFocus: false
    }
  );

  const esaEmployeesGroups = useQuery(
    ['EsaService.getEsaPlanGroups', +props.sponsorPlanId],
    () => EsaService.getEsaPlanGroups(+props.sponsorPlanId),
    {
      enabled: props.isEsa,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const esaOptions = useMemo(
    () => [
      ...(esaEmployeesGroups.data || []),
      { groupId: 0, key: 0, name: 'All' }
    ],
    [esaEmployeesGroups]
  );

  const esaGroupsMap = useMemo(() => {
    const groups = new Map();
    esaEmployeesGroups.data?.map(group =>
      groups.set(group.groupId, { ...group })
    );
    return groups;
  }, [esaEmployeesGroups]);

  const tableData = useMemo(() => {
    if (props.isEsa && esaEmployeesGroups.isLoading) return [];
    return (
      planParticipantQuery?.data?.participants.map(p => ({
        ...p,
        debouncedSearchTerm,
        pageNumber,
        plan: props.plan,
        planDesignQuerySuccess: planDesignQuery.isSuccess || props.isStateIRA,
        rowsPerPage,
        scope: toggleChoice,
        sponsorPlanId: props.sponsorPlanId,
        stateIraPerEmployerStatus: formatters.formatStateIraPerEmployerStatus(
          p.stateIraPerEmployerStatus
        ),
        ...(props.isEsa && esaGroupsMap.size
          ? {
              esaGroupName: p.esaGroupId
                ? esaGroupsMap.get(p.esaGroupId)?.name
                : '--'
            }
          : {})
      })) || []
    );
  }, [
    planParticipantQuery?.data?.participants,
    pageNumber,
    props.plan,
    rowsPerPage,
    debouncedSearchTerm,
    props.sponsorPlanId,
    toggleChoice,
    planDesignQuery.isSuccess,
    props.isEsa,
    esaEmployeesGroups.data,
    esaGroupsMap
  ]);

  const runEligibility = useMutation(
    (planId: string) => PlanService.refreshPlanEligibility(planId),
    {
      onError: () => {
        showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message:
            'Eligibility is rerunning... You may need to refresh the page to see updated statuses',
          severity: 'warning'
        });
        // Invalidate queries to ensure any related components reflect these changes
        queryClient.invalidateQueries({
          queryKey: ['PlanService.getParticipantsByPlanId']
        });
      }
    }
  );

  const handleOpsToolingMenuClick = useCallback(() => {
    showOpsToolingDrawer(+props.sponsorPlanId, OpsToolingType.employeeInfo);
  }, [props.sponsorPlanId]);

  const searchEmployees = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);
    setPageNumber(1);
  };

  const downloadCSVFileName = `participant-eligibility-${
    props.sponsorPlanId
  }-${formatters.formatFromIsoDateCustom(
    new Date().toISOString(),
    'MM/DD/YYYY'
  )}`;

  const getParticipantsWithContributionsInfo = async () => {
    const data = await refetchParticipantsWithContributions();

    if (data.isError) {
      throw new Error('Error fetching participants with contributions');
    }

    return planFeatures.data?.data?.attributes?.hasDualEligibility
      ? data.data?.map(record => ({
          ...record,
          hasDualEligibility:
            planFeatures.data?.data?.attributes?.hasDualEligibility
        }))
      : data.data;
  };

  const updateFilters = (filterValues: PlanEmployeeFilters) =>
    setFilters(filterValues);

  return (
    <>
      <Grid columns={12} container rowGap={3}>
        <Grid
          columnGap={2}
          columns={8}
          container
          direction='row'
          md={12}
          rowSpacing={2}
          xs={8}>
          <Grid md={2.5}>
            <TextField
              InputProps={{
                'aria-placeholder': 'Search employees',
                onChange: searchEmployees,
                placeholder: 'Search employees',
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                )
              }}
              disabled={planParticipantQuery.isError}
              fullWidth
              id='search-employees'
              size='small'
              variant='outlined'
            />
          </Grid>

          {props.isEsa && (
            <Grid md={1.5} xs={2.5}>
              <FormControl fullWidth>
                <InputLabel id='select-employee-group' size='small'>
                  Employee Group
                </InputLabel>
                <Select
                  data-testid='select-employee-group'
                  label='Employee Group'
                  labelId='select-employee-group'
                  onChange={event => {
                    setEsaGroupId(+event.target.value);
                  }}
                  size='small'
                  value={esaGroupId}>
                  {esaOptions.map(group => (
                    <MenuItem
                      data-testid={`employee-group-${group.groupId}`}
                      key={group.groupId}
                      value={group.groupId}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {!!planParticipantQuery.data?.participants?.length &&
            !props.isEsa && (
              <Grid display='flex'>
                <DownloadCSVButton
                  buttonProps={{
                    size: 'small',
                    variant: 'outlined'
                  }}
                  fileName={downloadCSVFileName}
                  formatInfoWith={formatters.formatEligibilityReportInfo}
                  getInfo={getParticipantsWithContributionsInfo}
                  text='DOWNLOAD ELIGIBILITY REPORT'
                />
              </Grid>
            )}

          <Grid display='flex'>
            <AccessControl
              requires={[FeatureLevelPermissions.READ_COMPANY_PAYROLL]}>
              {isApiPlan && (
                <LoadingButton
                  className={classes.loadingButton}
                  data-testid='openOpsToolingEmployees'
                  loading={false}
                  onClick={handleOpsToolingMenuClick}
                  size='small'
                  variant='outlined'>
                  View Payroll Provider Data - Employees
                </LoadingButton>
              )}
            </AccessControl>
          </Grid>

          <Grid>
            <ToggleButtonGroup
              color='primary'
              exclusive
              onChange={handleToggleChoiceChange}
              size='small'
              value={toggleChoice}>
              <ToggleButton
                disabled={toggleChoice === 'overview'}
                value='overview'>
                OVERVIEW
              </ToggleButton>
              {(props.plan.data.relationships.recordKeeper.data.id ===
                Recordkeeper.SubA ||
                props.plan.data.relationships.recordKeeper.data.id ===
                  Recordkeeper.ESA) && (
                <ToggleButton
                  data-testid='registration-toggle'
                  disabled={toggleChoice === 'registration'}
                  value='registration'>
                  REGISTRATION
                </ToggleButton>
              )}
              <ToggleButton
                disabled={toggleChoice === 'eligibility'}
                value='eligibility'>
                ELIGIBILITY
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid>
            <Button
              data-testid='run-plan-eligibility'
              disabled={runEligibility.isLoading}
              onClick={async () => {
                runEligibility.mutate(props.sponsorPlanId);
              }}
              variant='outlined'>
              Run Plan Eligibilty
            </Button>
          </Grid>
        </Grid>

        {toggleChoice === 'registration' &&
          (props.plan.data.relationships.recordKeeper.data.id ===
            Recordkeeper.SubA ||
            props.plan.data.relationships.recordKeeper.data.id ===
              Recordkeeper.ESA) && (
            <Grid xs={12}>
              <PlanEmployeesRegistrationTab
                search={debouncedSearchTerm}
                sponsorPlanId={props.sponsorPlanId}
              />
            </Grid>
          )}

        {toggleChoice === 'registration' &&
          props.plan.data.relationships.recordKeeper.data.id ===
            Recordkeeper.SubA &&
          planDesignQuery.isSuccess &&
          planDesignQuery.data?.data?.overview?.adminStatus !== 'Active' &&
          (planDesignQuery.data?.data?.overview?.planType === 'ESA' ||
            ![null, undefined].includes(
              planDesignQuery.data?.data?.employeeContribution?.autoEnrollAmount
            )) && (
            <Grid xs={12}>
              <Box display='flex' justifyContent='flex-end'>
                <PlanEmployeesScheduleEmails
                  sponsorPlanId={props.sponsorPlanId}
                />
              </Box>
            </Grid>
          )}

        {toggleChoice !== 'registration' && (
          <Card sx={{ width: '100%' }} variant='outlined'>
            <Grid
              container
              display='flex'
              flexDirection='row'
              wrap='nowrap'
              xs={12}>
              <Grid display='flex'>
                <PlanEmployeesFilter
                  filters={filters}
                  isStateIRA={props.isStateIRA}
                  participantData={planParticipantQuery.data}
                  updateFilters={updateFilters}
                />
              </Grid>
              <Grid flexGrow={1} xs={6}>
                <CollapsibleTable
                  backgroundPaperElevation={0}
                  cellComponent={PlanEmployeeTableCell}
                  columns={headerColumns}
                  data-testid='plan-participant-table'
                  isLoading={planParticipantQuery.isFetching}
                  noDataPlaceholderComponent={
                    <Stack
                      alignItems='center'
                      data-testid='no-data-employees-table'
                      justifyContent='center'
                      sx={{ height: '100%' }}>
                      <CardPlaceholder
                        icon={
                          planParticipantQuery.isError ? (
                            <WarningAmber fontSize='inherit' />
                          ) : (
                            <Search fontSize='inherit' />
                          )
                        }
                        subtitle={
                          planParticipantQuery.isFetching
                            ? 'Loading employees...'
                            : planParticipantQuery.isError
                              ? 'Error retrieving employees'
                              : debouncedSearchTerm
                        }
                      />
                    </Stack>
                  }
                  pager={{
                    metaCount: planParticipantQuery.data?.count,
                    onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                      return setPageNumber(zeroIndexedPageNumber + 1);
                    },
                    onRowsPerPageChanged: (newRowsPerPage: number) => {
                      return setRowsPerPage(newRowsPerPage);
                    },
                    pageNumber: pageNumber - 1,
                    rowsPerPage
                  }}
                  rootPaperElevation={0}
                  tableData={tableData}
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </>
  );
};

PlanEmployees.displayName = 'PlanEmployees';

PlanEmployees.defaultProps = {
  isEsa: false
};
