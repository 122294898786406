import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { DocumentDto } from '@/models';
import {
  FUND,
  getInvestmentType,
  MANAGED,
  MS_401K_MANAGER,
  NLI,
  Program,
  ProgramFund,
  RISK,
  RuleType,
  RuleValue,
  TARGET
} from '@/models/ops/investments/Program.model';
import { SubaPlanFundStatus } from '@/models/SubaccountingPlanFund.model';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import InvestmentService from '@/services/Investment.service';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustodianFundsTab from './CustodianFundsTab.component';
import FundLineupTab from './FundLineupTab/FundLineupTab.component';
import ManagedAccountTab from './ManagedAccountTab/ManagedAccountTab.component';
import RiskSeriesTab from './RiskSeriesTab/RiskSeriesTab.component';
import { SdbaSetup } from './SdbaSetup/SdbaSetup.component';
import TargetSeriesTab from './TargetSeriesTab/TargetSeriesTab.component';

const useStyles = makeStyles((theme: Theme) => ({
  card: { padding: theme.spacing(3) },
  cardContext: { padding: 0, paddingTop: theme.spacing(2.25) },
  cardHeader: { padding: 0 },
  paper: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%'
  },
  tabPanel: {
    padding: 0
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  }
}));

interface PlanInvestmentsTabsProps {
  programData: Program;
  programFundsData: ProgramFund[];
  planFundsStatusData: SubaPlanFundStatus[];
  isCustodian: boolean;
  riskSeriesId?: number;
  fundLineupId?: number;
  targetDateSeriesId?: number;
  goalSeriesId?: number;
  planId: number;
  programId: number;
  ms401kManagerLineUp?: number;
  nliTargetDateSeriesId?: number;
}

function VerticalDivider() {
  return (
    <Divider
      flexItem
      orientation='vertical'
      style={{ marginLeft: 'auto' }}
      variant='middle'
    />
  );
}

const CUSTODIAN = 'Custodian Funds';
const CUSTODIAN_TAB = {
  label: CUSTODIAN,
  value: CUSTODIAN.toLowerCase().replaceAll(' ', '-')
};

const AVAILABLE_TABS = {
  [FUND]: {
    isExisting: (programData: Program) =>
      programData?.fundLineupId !== undefined &&
      !!programData.programRule.find(rule => rule.ruleValue === RuleValue.menu),
    label: FUND,
    value: FUND.toLowerCase().replaceAll(' ', '-')
  },
  [MANAGED]: {
    isExisting: (programData: Program) =>
      programData?.goalSeriesId !== undefined &&
      !!programData.programRule.find(
        rule => rule.ruleValue === RuleValue.dynamic_goal
      ),
    label: MANAGED,
    value: MANAGED.toLowerCase().replaceAll(' ', '-')
  },
  [MS_401K_MANAGER]: {
    isExisting: (programData: Program) =>
      programData?.externalProviders?.some(
        i => 'ms_rp' === i.investmentType || 'ms_ma' === i.investmentType
      ) &&
      !!programData.programRule.find(
        rule => rule.ruleValue === RuleValue.ms_managed
      ),
    label: MS_401K_MANAGER,
    value: MS_401K_MANAGER.toLowerCase().replaceAll(' ', '-')
  },
  [NLI]: {
    isExisting: (programData: Program) =>
      programData?.externalProviders?.find(i => 'nli' === i.investmentType)
        ?.targetSeriesInvestmentOptionId !== undefined &&
      !!programData.programRule.find(rule => rule.ruleValue === RuleValue.nli),
    label: NLI,
    value: NLI.toLowerCase().replaceAll(' ', '-')
  },
  [RISK]: {
    isExisting: (programData: Program) =>
      programData?.riskSeriesId !== undefined &&
      !!programData.programRule.find(rule => rule.ruleValue === RuleValue.risk),
    label: RISK,
    value: RISK.toLowerCase().replaceAll(' ', '-')
  },
  [TARGET]: {
    isExisting: (programData: Program) =>
      programData?.targetDateSeriesId !== undefined &&
      !!programData.programRule.find(
        rule => rule.ruleValue === RuleValue.target
      ),
    label: TARGET,
    value: TARGET.toLowerCase().replaceAll(' ', '-')
  }
};

interface DefaultTab {
  value: string;
  label: string;
  isExisting: (programData: Program) => boolean;
}

const getDefaultTabValue = (tabs: DefaultTab[], isCustodian: boolean) => {
  if (tabs[0]) {
    return tabs[0].value;
  }
  if (isCustodian) {
    return CUSTODIAN_TAB.value;
  }
  return '';
};

const PlanInvestmentsTabs: React.FC<PlanInvestmentsTabsProps> = props => {
  const availableTabs = useMemo(
    () =>
      Object.values(AVAILABLE_TABS).filter(tabData =>
        tabData.isExisting(props.programData)
      ),
    [props.programData]
  );
  const [defaultTabValue] = useState(
    getDefaultTabValue(availableTabs, props.isCustodian)
  );
  const [tabValue, setTabValue] = useState(defaultTabValue);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const { isFetching: isXLSDownloading, refetch: onExportButtonClick } =
    useQuery<DocumentDto>(
      ['InvestmentService.getInvestmentOptionsDownload', props.programId],
      () => InvestmentService.getInvestmentOptionsDownload(props.programId),
      {
        cacheTime: 0,
        enabled: false,
        onSuccess: (dto: DocumentDto) => {
          InMemoryFileDownloadService.triggerFileDownload(
            dto.base64Data,
            dto.originalFileName
          );
        }
      }
    );

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setTabValue(newValue);
    navigate(`${location.pathname}#${newValue}`);
  };

  useEffect(() => {
    if (location.hash) {
      const newTabValue = [
        ...availableTabs,
        ...(props.isCustodian ? [CUSTODIAN_TAB] : [])
      ].find(tab => tab.value === location.hash.replace('#', ''))?.value;

      return newTabValue
        ? setTabValue(newTabValue)
        : setTabValue(defaultTabValue);
    }
  }, [availableTabs, defaultTabValue, location.hash, props.isCustodian]);

  const defaultInvestmentTypeRuleValue =
    props.programData?.programRule.find(
      rule => rule.ruleType === RuleType.default_type
    )?.ruleValue || '';

  const defaultInvestmentType = getInvestmentType(
    defaultInvestmentTypeRuleValue
  );

  const programDesc = [
    { label: 'Program Owner', value: 'Vestwell' },
    { label: 'Default Investment Type', value: defaultInvestmentType },
    { label: 'Default Model Id', value: props.programData?.defaultModelId },
    {
      label: 'Minimum Age',
      value: props.programData?.managedAccount?.minimumAge
    },
    {
      label: 'Investment for Participants Under Minimum Age',
      value: getInvestmentType(
        props.programData?.managedAccount?.fallbackRule || ''
      )
    }
  ];

  const programCusips = props.programFundsData.map(item => item.cusip);
  const allCusips = programCusips.concat(
    props.planFundsStatusData.map(item => item.attributes.planFund.cusip)
  );
  const cusips = allCusips.filter((n, i) => allCusips.indexOf(n) === i);

  return (
    <>
      <Card
        className={classes.card}
        data-testid='plan-investments-card'
        elevation={0}
        variant='outlined'>
        <CardHeader
          action={
            <Button
              disabled={isXLSDownloading}
              onClick={() => onExportButtonClick()}
              variant='outlined'>
              Export xls
            </Button>
          }
          className={classes.cardHeader}
          title={props.programData?.name}
        />
        <CardContent className={classes.cardContext}>
          <TextStack direction='row' rowColumnWidth='dynamic'>
            {programDesc
              .filter(item => item.value)
              .map(item => (
                <TextStackItem key={item.label} maxWidth='100%'>
                  <TextLabel>{item.label}</TextLabel>
                  <TextValue>{item.value}</TextValue>
                </TextStackItem>
              ))}
          </TextStack>
        </CardContent>
      </Card>
      <Paper
        className={classes.paper}
        data-testid='plan-investments-sub-tabs'
        elevation={0}
        variant='outlined'>
        {(Boolean(tabValue) || availableTabs.length != 0) && (
          <TabContext
            value={tabValue || availableTabs[0]?.value.replaceAll(' ', '-')}>
            <Box className={classes.tabs}>
              <Tabs
                onChange={handleTabChange}
                sx={{
                  '& .Mui-selected': {
                    backgroundColor: '#2196f30a'
                  }
                }}
                value={
                  tabValue || availableTabs[0]?.value.replaceAll(' ', '-')
                }>
                {availableTabs.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
                {props.isCustodian && <VerticalDivider />}
                {props.isCustodian && (
                  <Tab
                    key={CUSTODIAN_TAB.value}
                    label={
                      <>
                        <Typography variant='button'>
                          {CUSTODIAN_TAB.label}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 12,
                            marginRight: 'auto',
                            textTransform: 'none'
                          }}>
                          {`${cusips.length} funds`}
                        </Typography>
                      </>
                    }
                    value={CUSTODIAN_TAB.value}
                  />
                )}
              </Tabs>
            </Box>
            <TabPanel
              className={classes.tabPanel}
              key={RISK}
              value={AVAILABLE_TABS[RISK].value}>
              {props.riskSeriesId && (
                <RiskSeriesTab riskSeriesId={props.riskSeriesId} />
              )}
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              key={FUND}
              value={AVAILABLE_TABS[FUND].value}>
              {props.fundLineupId && (
                <FundLineupTab fundLineupId={props.fundLineupId} />
              )}
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              key={TARGET}
              value={AVAILABLE_TABS[TARGET].value}>
              {props.targetDateSeriesId && (
                <TargetSeriesTab
                  targetDateSeriesId={props.targetDateSeriesId}
                />
              )}
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              key={MANAGED}
              value={AVAILABLE_TABS[MANAGED].value}>
              {props.goalSeriesId && (
                <ManagedAccountTab goalSeriesId={props.goalSeriesId} />
              )}
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              key={MS_401K_MANAGER}
              value={AVAILABLE_TABS[MS_401K_MANAGER].value}>
              {props.ms401kManagerLineUp && (
                <FundLineupTab fundLineupId={props.ms401kManagerLineUp} />
              )}
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              key={NLI}
              value={AVAILABLE_TABS[NLI].value}>
              {props.nliTargetDateSeriesId && (
                <TargetSeriesTab
                  targetDateSeriesId={props.nliTargetDateSeriesId}
                />
              )}
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              key={CUSTODIAN_TAB.value}
              value={CUSTODIAN_TAB.value}>
              <CustodianFundsTab
                planId={props.planId}
                programId={props.programId}
              />
            </TabPanel>
          </TabContext>
        )}
      </Paper>
      <SdbaSetup planId={props.planId} />
    </>
  );
};

export default PlanInvestmentsTabs;
