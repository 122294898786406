import { DepositManuallyCompleteDto } from '@/models/suba/deposit/DepositManuallyCompleteDto.model';
import { DepositManuallyCompleteRequest } from '@/models/suba/deposit/DepositManuallyCompleteRequest.model';
import ApiService from '@/services/Api.service';

class DepositService {
  static async manuallyComplete(body: DepositManuallyCompleteRequest) {
    return ApiService.patchJson<
      DepositManuallyCompleteRequest,
      DepositManuallyCompleteDto
    >(`/deposit/manually-complete`, body);
  }
}

export default DepositService;
