import { useSnackbar } from '@/contexts/SnackBarContext';
import { ReportingService } from '@/services/SigmaReports.service';
import TpaService from '@/services/Tpa.service';
import { Search } from '@mui/icons-material';
import { DateRange } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PickersShortcutsItem } from '@mui/x-date-pickers';
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import { useToggle } from '@vestwell-frontend/hooks';
import { Button } from '@vestwell-frontend/ui';

import dayjs, { Dayjs } from 'dayjs';
import { FC, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { PlanInputSearch } from './components/PlanInputSearch';

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchResult: {
    maxHeight: '51vh',
    overflowY: 'scroll',
    position: 'absolute',
    width: theme.spacing(60)
  },
  iframeContainer: {
    flex: '1 1 auto',
    height: `${window.innerHeight}px`,
    marginBottom: `${theme.spacing(15)} !important`,
    overflow: 'hidden'
  }
}));

const Reports: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [isFocused, setIsFocused] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [iframeUrl, setIframeUrl] = useState('');
  const [generated, toggleGenerated] = useToggle(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [reportType, setReportType] = useState('par');
  const [isOpened, setIsOpened] = useState(false);

  const reportTypes = [
    {
      label: 'Participant Activity Report',
      value: 'par'
    },
    {
      label: 'Audit Report',
      value: 'audit'
    }
  ];

  const TPAsQuery = useQuery(
    ['TpaService.getSelf'],
    () => {
      return TpaService.getSelf();
    },
    {
      staleTime: Infinity
    }
  );

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
      label: 'This Week'
    },
    {
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
      label: 'Last Week'
    },
    {
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
      label: 'Last 7 Days'
    },
    {
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
      label: 'Current Month'
    },
    {
      getValue: () => {
        const startOfYear = dayjs().startOf('year');
        const today = dayjs();
        return [startOfYear, today];
      },
      label: 'Year to Date'
    },
    {
      getValue: () => {
        const startOfYear = dayjs().startOf('year').subtract(1, 'year');
        const endOfYear = dayjs().endOf('year').subtract(1, 'year');
        return [startOfYear, endOfYear];
      },
      label: 'Prior Year'
    },
    { getValue: () => [null, null], label: 'Reset' }
  ];

  const getSigmaURL = async () => {
    const tpaId = Array.isArray(TPAsQuery.data?.data)
      ? 0
      : TPAsQuery.data?.data?.id;
    await ReportingService.getSigmaUrl(
      +searchTerm,
      [dateRange[0].unix() * 1000, dateRange[1].unix() * 1000],
      reportType,
      tpaId
    )
      .then(url => {
        setIframeUrl(url);
        toggleGenerated();
      })
      .catch(e => {
        snackbar.showSnackbar({
          message: e.message,
          severity: 'error'
        });
      });
  };

  return (
    <>
      <Typography mb={2} mt={3.5} variant='h4'>
        Reports
      </Typography>
      <Stack divider={<Divider flexItem />} spacing={2} sx={{ height: '100%' }}>
        <Grid alignItems='flex-end' columns={9} container spacing={2}>
          <Grid item xs={2.5}>
            <Typography variant='h6'>Report Type</Typography>
          </Grid>
          <Grid item xs={2.1}>
            <Typography variant='h6'>Plan</Typography>
          </Grid>
          <Grid item xs={2.1}>
            <Typography variant='h6'>Dates</Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item sx={{ paddingTop: '0px !important' }} xs={2.5}>
            <FormControl fullWidth size='medium'>
              <Select
                displayEmpty
                fullWidth
                onChange={event => {
                  setReportType(event.target.value);
                  setSearchTerm('');
                  setDateRange([null, null]);
                }}
                value={reportType}>
                {reportTypes.map(report => (
                  <MenuItem key={report.label} value={report.value}>
                    {report.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ paddingTop: '0px !important' }} xs={2.1}>
            <TextField
              InputProps={{
                placeholder: 'Search by Plan ID or Name',
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                )
              }}
              data-testid='reports-search-plans-field'
              fullWidth
              onBlur={() => {
                setIsFocused(false);
              }}
              onChange={e => {
                abortController.abort();
                setAbortController(new AbortController());
                setSearchTerm(e.target.value);
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
              value={searchTerm}
              variant='outlined'
            />
            {(isFocused || isOpened) && searchTerm.trim().length > 0 && (
              <Paper
                className={classes.globalSearchResult}
                onMouseDown={() => setIsOpened(true)}
                onMouseLeave={() => setIsOpened(false)}>
                <PlanInputSearch
                  abortSignal={abortController.signal}
                  isFocused
                  searchTerm={debouncedSearchTerm}
                  setSearchTerm={setSearchTerm}
                />
              </Paper>
            )}
          </Grid>
          <Grid item sx={{ paddingTop: '0px !important' }} xs={2.1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{ end: 'End Date', start: 'Start Date' }}
                onChange={value => setDateRange(value)}
                slotProps={{
                  actionBar: { actions: [] },
                  field: { sx: { width: '100%' } },
                  shortcuts: {
                    items: shortcutsItems
                  }
                }}
                slots={{ field: SingleInputDateRangeField }}
                value={dateRange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            sx={{ alignSelf: 'stretch', paddingTop: '0px !important' }}
            xs={2}>
            <Button
              disabled={!searchTerm || !dateRange[0] || !dateRange[1]}
              onClick={() => {
                getSigmaURL();
              }}
              sx={{ height: '100%' }}>
              {generated ? 'Update Report' : 'Generate Report'}
            </Button>
          </Grid>
        </Grid>
        {!!iframeUrl && (
          <Box className={classes.iframeContainer}>
            <iframe
              src={iframeUrl}
              style={{
                border: 'none',
                height: '100%',
                overflow: 'auto',
                width: '100%'
              }}
              title='Placeholder'
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Reports;
