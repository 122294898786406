import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { ParentAccountOrderDto } from '@/models/suba/parent-account-orders/ParentAccountOrderDTO.model';
import { ParentAccountOrderExecuteRequest } from '@/models/suba/parent-account-orders/ParentAccountOrderExecuteRequest.model';
import { ParentAccountOrderSearchRequest } from '@/models/suba/parent-account-orders/ParentAccountOrderSearchRequest.model';
import ApiService from '@/services/Api.service';

class ParentAccountOrderService {
  static async getById(id: number): Promise<ParentAccountOrderDto> {
    return ApiService.getJson<ParentAccountOrderDto>(
      `/parent-account-orders/${id}`
    );
  }

  static async search(
    params: ParentAccountOrderSearchRequest
  ): Promise<PaginatedApiResponse<ParentAccountOrderDto[]>> {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<ParentAccountOrderDto[]>
      >(`parent-account-orders/`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async defer(id: number): Promise<void> {
    return ApiService.patchJson<void, void>(
      `/parent-account-orders/defer/${id}`,
      undefined
    );
  }

  static async resubmit(id: number): Promise<void> {
    return ApiService.patchJson<void, void>(
      `/parent-account-orders/resubmit/${id}`,
      undefined
    );
  }

  static async execute(
    params: ParentAccountOrderExecuteRequest
  ): Promise<string> {
    return ApiService.postJson(`/parent-account-orders/execute`, params);
  }
}

export default ParentAccountOrderService;
