import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card/Card.component';
import DataTable from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker';
import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import PriceService from '@/services/suba/prices/Price.service';
import formatters from '@/utils/Formatters';
import { Search as SearchIcon } from '@mui/icons-material';
import { Button, FormControl, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DataProvider } from '@vestwell-sub-accounting/models/securitiesAndPricing/DataProvider';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';

const displayDataProvider = {
  [DataProvider.bloomberg]: 'Bloomberg',
  [DataProvider.matrix]: 'Matrix',
  [DataProvider.morningstar]: 'Morningstar',
  [DataProvider.nasd]: 'NASD',
  [DataProvider.nscc]: 'NSCC',
  [DataProvider.xignite]: 'Xignite'
};

export const SecuritiesDetailPricesTab = ({
  security
}: {
  security: SecurityDto;
}): JSX.Element => {
  const [effectiveEndDate, setEffectiveEndDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );

  const [effectiveStartDate, setEffectiveStartDate] = useState(
    dayjs().subtract(30, 'days').format('YYYY-MM-DD')
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const searchParams = {
    cusip: security.cusip,
    effectiveEndDate,
    effectiveStartDate,
    page,
    pageSize
  };

  const { data, isFetching } = useQuery(
    ['securityPrices', ...Object.values(searchParams)],
    () => PriceService.search(searchParams),
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity
    }
  );

  const handleSubmit = (values: {
    effectiveEndDate: string;
    effectiveStartDate: string;
  }) => {
    setEffectiveEndDate(values.effectiveEndDate);
    setEffectiveStartDate(values.effectiveStartDate);
  };

  return (
    <Card data-testid='prices-card'>
      <CardHeader loading={data?.results && isFetching} title='Prices' />
      <CardContent disablePadding overlayLoading={data?.results === undefined}>
        <DataTable
          columnDefs={[
            {
              field: 'effectiveDate',
              valueFormatter: ({ value }) =>
                formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
            },
            {
              field: 'closePrice',
              headerName: 'Price',
              type: 'numericColumn',
              valueFormatter: ({ value }) => formatters.formatDollars(value, 2)
            },
            {
              field: 'dataProvider',
              headerName: 'Source',
              valueFormatter: ({ value }) =>
                displayDataProvider[
                  value as keyof typeof displayDataProvider
                ] || value
            }
          ]}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-prices-message'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No prices found'
              />
            </Stack>
          }
          filterSidePanelComponent={
            <Formik
              enableReinitialize
              initialValues={{
                effectiveEndDate,
                effectiveStartDate
              }}
              onSubmit={handleSubmit}>
              <Form data-testid='prices-search-form'>
                <Stack spacing={2}>
                  <FormControl variant='outlined'>
                    <Field
                      as={DatePicker}
                      autoComplete='off'
                      data-testid='effective-start-date-input'
                      disableFuture
                      label='Effective Date Start'
                      name='effectiveStartDate'
                      size='small' // FormControl doesn't pass to our DatePicker
                      sx={{ width: 200 }}
                      variant='outlined'
                    />
                  </FormControl>
                  <FormControl variant='outlined'>
                    <Field
                      as={DatePicker}
                      autoComplete='off'
                      data-testid='effective-end-date-input'
                      disableFuture
                      label='Effective Date End'
                      name='effectiveEndDate'
                      size='small' // FormControl doesn't pass to our DatePicker
                      sx={{ width: 200 }}
                      variant='outlined'
                    />
                  </FormControl>
                  <Button
                    data-testid='apply-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </Stack>
              </Form>
            </Formik>
          }
          onPageChanged={newPage => setPage(newPage)}
          onPageSizeChanged={newPageSize => setPageSize(newPageSize)}
          page={page}
          pageSize={pageSize}
          pagination
          paginationSource='server'
          paginationTotal={data?.pagination?.total}
          rowData={data?.results}
        />
      </CardContent>
    </Card>
  );
};
