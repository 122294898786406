import { useSnackbar } from '@/contexts/SnackBarContext';
import { TpaDto } from '@/models/TpaDTO.model';
import PlansIndex from '@/routes/plans/plans/PlansIndex';
import { SponsorPlanV1 } from '@/routes/plans/plans/PlansIndex/PlansIndex.component';
import TpaService from '@/services/Tpa.service';
import { Search } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface AddTpaPlansToolbarProps {
  forceRefetch: () => void;
  tpaId: number;
  setLastOperation: () => void;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addBtn: {
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: theme.spacing(2),
      width: theme.spacing(22)
    },
    plansContainer: {
      height: '77%',
      marginLeft: theme.spacing(4.5),
      marginTop: theme.spacing(4),
      overflowX: 'hidden',
      overflowY: 'scroll',
      paddingRight: theme.spacing(4)
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    }
  })
);

const AddTpaPlansToolbar = (props: AddTpaPlansToolbarProps): JSX.Element => {
  const classes = useStyles();

  const { forceRefetch: forceTpaPlansRefetch, tpaId, setLastOperation } = props;
  const { showSnackbar } = useSnackbar();

  const tpaQuery = useQuery<TpaDto>(
    ['TpaService.getTpa', tpaId],
    () => {
      return TpaService.getTpa(+tpaId);
    },
    {
      enabled: Boolean(tpaId),
      staleTime: Infinity
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState<SponsorPlanV1 | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSearchParams({});
    setPlan(null);
    setOpen(false);
  };

  const validation = useMutation((data: { tpaId: number; planId: number }) =>
    TpaService.validateTpaPlanRelationship(data.tpaId, data.planId)
  );

  const linkTpaToPlan = useMutation(
    (data: { tpaId: number; planId: number }) => {
      return TpaService.linkPlanToTpa(data.tpaId, data.planId);
    }
  );

  const addPlan = async () => {
    if (plan) {
      try {
        const result = await validation.mutateAsync({
          planId: plan.sponsorPlanId,
          tpaId
        });

        if (result.errors.length > 0) {
          showSnackbar({ message: result.errors[0], severity: 'error' });
          return;
        }

        await linkTpaToPlan.mutateAsync({
          planId: plan.sponsorPlanId,
          tpaId
        });
        setLastOperation();
        forceTpaPlansRefetch();
        handleClose();
        showSnackbar({
          message: 'Plan was successfully added!',
          severity: 'success'
        });
      } catch (e) {
        showSnackbar({ message: 'Failed!', severity: 'error' });
      }
    }
  };

  const receivePlan = (selectedPlan: SponsorPlanV1) => {
    setPlan(selectedPlan);
  };

  useEffect(() => {
    if (searchParams) {
      setSearchParams({});
    }
  }, []);

  return (
    <Grid container data-testid='tpa-plans-table-toolbar' p={2} width='100%'>
      <Grid alignItems='center' display='flex'>
        <Typography component='div' variant='h5'>
          Plans
        </Typography>
      </Grid>
      <Grid style={{ flexGrow: '1' }} />
      <Grid>
        <TextField
          InputProps={{
            'aria-placeholder': 'Search Plans',
            onChange: e => props.setSearchTerm(e.target.value?.trim()),
            placeholder: 'Search Plans',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          data-testid='search-plans'
          style={{ width: '40ch' }}
          variant='outlined'
        />
      </Grid>
      <Grid alignItems='center' display='flex'>
        <Button
          className={classes.addBtn}
          data-testid='tpa-plans-table-btn'
          onClick={handleOpen}
          variant='contained'>
          ADD PLAN
        </Button>
      </Grid>
      <Dialog
        data-testid='add-plan-dialog'
        fullWidth
        maxWidth='lg'
        onClose={handleClose}
        open={open}>
        <DialogTitle>Add Plan</DialogTitle>
        <DialogContent>
          <PlansIndex
            firmIds={
              tpaQuery.data?.data?.relationships?.tpaSubtype?.tpaSubtypeId === 2
                ? [1245]
                : tpaQuery.data?.data?.relationships?.tpaSubtype
                      ?.tpaSubtypeId === 3
                  ? [1418]
                  : undefined
            }
            isModal
            receivePlan={receivePlan}
            statuses={
              tpaQuery.data?.data?.relationships?.tpaSubtype?.tpaSubtypeId !== 1
                ? ['Onboarding']
                : undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='tpa-plans-modal-cancel-btn'
            onClick={() => handleClose()}>
            CANCEL
          </Button>
          <Button
            disabled={plan === null}
            onClick={addPlan}
            variant='contained'>
            ADD PLAN
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AddTpaPlansToolbar;
