import AppConfig from '@/App.config';
import {
  CreatedUserDto,
  CreateUserDto,
  GetRecentUsersLoginsDto,
  PatchUserEmailAdminPayload,
  PatchUserLockBodyDto,
  PatchUserLockResponseDto,
  PostUserEmailChangeAdminPayload,
  PostUserForgotPasswordDto,
  UserAssociationsDto,
  UserForgotPasswordLinkDto,
  UserMfaMethodDto,
  UsersDto,
  UsersItemDto
} from '@/models/UsersDTO.model';
import { VssStateSaverDto } from '@/models/VssStateSaver.model';
import ApiService from '@/services/Api.service';

import { userService } from './User.service';

class UserManagementService {
  static async getUsers(
    email: string,
    pageNumber = 1,
    pageSize = 10
  ): Promise<UsersDto> {
    const dto = await ApiService.getJson<UsersDto>('/users', {
      email,
      pageNumber,
      pageSize
    });

    if (!dto) {
      throw new Error(`invalid JSON received from backend for users`);
    }

    return dto;
  }

  static async createUser(newUser: { email: string }): Promise<CreatedUserDto> {
    const dto = await ApiService.postJson<CreateUserDto, CreatedUserDto>(
      `/users`,
      newUser
    );

    if (!dto) {
      throw new Error(`invalid JSON received from backend for users`);
    }

    return dto;
  }

  static async getUserById(userId: number): Promise<UsersItemDto> {
    const dto = await ApiService.getJson<UsersItemDto>(`/users/${userId}`);

    if (!dto) {
      throw new Error(`invalid JSON received from backend for users`);
    }

    return dto;
  }

  static getSentinelRedirectUrl(
    entityType: string,
    entityId: number | string,
    tpaData?: { tpaId?: number; sponsorPlanId: number }
  ): string {
    const jwt = userService.getAccessToken();
    let url = '';
    let apiHost;
    let server;

    switch (AppConfig.environment) {
      case 'qa':
        apiHost = window.location.host;
        server = apiHost.match(/((ci|qa)[\d]+)/)?.[0];
        // use this format for the url so that the cookies stick
        url = apiHost.match('vestwell-admin-ui')?.length
          ? `https://vestwell-admin-api.${server}.ue1.vestwell.com`
          : `https://admin-api.${server}.internal.vestwell`;
        break;
      case 'local':
        url = `http://signin.vestwell.local:8088`;
        break;
      case 'production':
        url = AppConfig.apiHost;
        break;
      default:
        throw new Error(`Unrecognized environment`);
    }
    return `${url}/sentinel/${entityType}/${entityId}/redirect${tpaData?.tpaId ? '/tpa' : ''}?jwt=${jwt}${tpaData?.sponsorPlanId ? `&sponsorPlanId=${tpaData.sponsorPlanId}` : ''}`;
  }

  static async getUserAssociations(
    userId?: number
  ): Promise<UserAssociationsDto[]> {
    return ApiService.getJson<UserAssociationsDto[]>(
      `/users/${userId}/associations/v2`,
      {}
    );
  }

  static async getUserForgotPasswordLink(
    userId?: number
  ): Promise<UserForgotPasswordLinkDto> {
    return ApiService.getJson<UserForgotPasswordLinkDto>(
      `/users/${userId}/password/forgot`,
      {}
    );
  }

  static async getUserMfaMethods(userId?: number): Promise<UserMfaMethodDto[]> {
    const dto = await ApiService.getJson<UserMfaMethodDto[]>(
      `/users/${userId}/mfa`,
      {}
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for User's MFA setup`
      );
    }

    return dto;
  }

  static async deleteMfa(userId?: number): Promise<any> {
    return ApiService.deleteJson(`/users/${userId}/mfa`);
  }

  static async postUserForgotPassword(
    userId?: number
  ): Promise<PostUserForgotPasswordDto> {
    return ApiService.postJson<any, PostUserForgotPasswordDto>(
      `/users/${userId}/password/forgot`,
      {}
    );
  }

  static async postUserEmailChangeAdmin(
    email: string,
    userId: number
  ): Promise<any> {
    return ApiService.postJson<PostUserEmailChangeAdminPayload, any>(
      `/users/${userId}/email`,
      { email }
    );
  }

  static async patchUserEmailAdmin(
    email: string,
    oldEmail: string,
    token: string,
    userId: number
  ): Promise<{ status: 200 }> {
    return ApiService.patchJson<PatchUserEmailAdminPayload, { status: 200 }>(
      `/users/${userId}/email`,
      { email, oldEmail, token }
    );
  }

  static async patchUserAccess(
    isAccessRestricted: boolean,
    userId: number
  ): Promise<PatchUserLockResponseDto> {
    return ApiService.patchJson<PatchUserLockBodyDto, PatchUserLockResponseDto>(
      `/users/${userId}/access`,
      { isAccessRestricted }
    );
  }

  static async getUsersReports(
    reportType: 'audit' | 'login_activity',
    fileName: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    return ApiService.getJson<any>(`/users-reports`, {
      endDate,
      fileName,
      reportType,
      startDate
    });
  }

  static async getUsersReportsById(
    fileName: string,
    userId: number
  ): Promise<any> {
    return ApiService.getJson<any>(`/users/${userId}/reports`, {
      fileName
    });
  }

  static async getRecentUsersLogins(
    lastAttemptsCount: number,
    userId: number
  ): Promise<GetRecentUsersLoginsDto[]> {
    return ApiService.getJson<GetRecentUsersLoginsDto[]>(
      `/users/${userId}/last-logins`,
      {
        lastAttemptsCount
      }
    );
  }

  static async deleteUserVssStateSaverRelationship(
    externalId: string,
    userId: number
  ): Promise<any> {
    return ApiService.patchJson<any, any>(
      `/users/${userId}/relationships/vss-state-saver`,
      {
        externalId
      }
    );
  }

  static async getVssStateSaver(surpasId: string): Promise<VssStateSaverDto> {
    return ApiService.getJson<VssStateSaverDto>(
      `/vss-state-savers/${surpasId}`
    );
  }

  static async createVssStateSaverRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsAdded: number }[]> {
    return ApiService.postJson<{ entityId: number }, { rowsAdded: number }[]>(
      `/users/${userId}/relationships/vss-state-saver`,
      body
    );
  }
}

export default UserManagementService;
