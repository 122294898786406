import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import LinearLoading from '@/components/linear-loading';
import TooltipButton from '@/components/tool-tip-button';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { YearEndTestingDto } from '@/models/YearEndTestingDTO.model';
import { CompliancePackageRow } from '@/routes/plans/plan-detail/PlanAnnualTestingTab/CompliancePackageRow.component';
import { TestExecutionRow } from '@/routes/plans/plan-detail/PlanAnnualTestingTab/TestExecutionRow.component';
import { YearEndContributionRow } from '@/routes/plans/plan-detail/PlanAnnualTestingTab/YearEndContributionRow.component';
import { PlanService } from '@/services/Plan.service';
import { Check } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Unstable_Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { BypassYetStepButton } from './BypassYetStepButton';

interface YearEndTestResultsProps {
  sponsorPlanId: number;
  year?: number;
  openContributionRow: boolean;
  toggleOpenContributionRow: () => void;
  openTestExecutionRow: boolean;
  toggleOpenTestExecutionRow: () => void;
}

const useYetEvent = (
  events: YearEndTestingDto['events'] | undefined,
  name: string
) => {
  return useMemo(() => {
    const event = orderBy(
      events?.filter(e => e.eventName === name) ?? [],
      ['createdAt'],
      ['desc']
    )?.[0];

    return event
      ? {
          ...event,
          createdAt: dayjs(event?.createdAt).format('MM/DD/YYYY, H:mm:ss')
        }
      : undefined;
  }, [events, name]);
};

export const YearEndTestResults: FC<YearEndTestResultsProps> = props => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const [open, toggleOpen] = useToggle(false);

  const yearEndTesting = useQuery<YearEndTestingDto>(
    [
      'PlanService.getSponsorPlanYearEndTesting',
      props.sponsorPlanId,
      props.year
    ],
    () => {
      return PlanService.getSponsorPlanYearEndTesting(
        props.sponsorPlanId,
        props.year
      );
    },
    {
      enabled: !!props.year
    }
  );

  const unlock = useMutation(
    () => PlanService.postUnlockSubmission(props.sponsorPlanId, props.year),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await yearEndTesting.refetch();
        snackbar.showSnackbar({
          message: 'Submission unlocked!',
          severity: 'success'
        });
      }
    }
  );

  const postYETStatusMutation = useMutation(
    (action: string) =>
      PlanService.postYETStatus(action, yearEndTesting.data?.processId),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          predicate: ({ queryHash }) =>
            queryHash.includes('getSponsorPlanYearEndTesting')
        });
      }
    }
  );

  const markAsComplete = useCallback(async () => {
    await postYETStatusMutation.mutateAsync('completeDataReview');
    snackbar.showSnackbar({
      message: 'Data Review completed!',
      severity: 'success'
    });
  }, [postYETStatusMutation, snackbar]);

  const unlockSubmission = useCallback(async () => {
    await unlock.mutateAsync();
    toggleOpen();
  }, [toggleOpen, unlock]);

  const sponsorDataSubmittedEvent = useYetEvent(
    yearEndTesting.data?.events,
    'sponsorDataSubmitted'
  );

  const dataReviewEvent = useYetEvent(
    yearEndTesting.data?.events,
    'dataReviewCompleted'
  );

  const yearEndContributionEvent = useYetEvent(
    yearEndTesting.data?.events,
    'yearEndContributionCompleted'
  );

  const testExecutionEvent = useYetEvent(
    yearEndTesting.data?.events,
    'testExecutionCompleted'
  );

  const compliancePackageEvent = useYetEvent(
    yearEndTesting.data?.events,
    'compliancePackageCompleted'
  );

  const completedEvents = useMemo(
    () => [
      ...new Set(yearEndTesting.data?.events?.map(event => event.eventName))
    ],
    [yearEndTesting.data?.events]
  );

  const rows = useMemo(
    () => [
      {
        action: '',
        by:
          sponsorDataSubmittedEvent?.initiatedBy?.authRole === 'sponsor'
            ? 'Employer'
            : sponsorDataSubmittedEvent?.initiatedBy?.id,
        completionDate: sponsorDataSubmittedEvent?.createdAt,
        dataTestId: 'initial-data-collection',
        eventData: sponsorDataSubmittedEvent?.eventData,
        eventName: 'sponsorDataSubmitted',
        isCompleted: !!sponsorDataSubmittedEvent,
        previousStepDone: true,
        step: 'Initial Data Collection'
      },
      {
        action: !dataReviewEvent && yearEndTesting.data?.processId && (
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
            <TooltipButton
              disabled={
                postYETStatusMutation.isLoading ||
                !completedEvents.includes('sponsorDataSubmitted')
              }
              handleOnClick={markAsComplete}
              size='medium'
              testId='complete-data-review-btn'
              tooltipMessage={
                completedEvents?.includes('sponsorDataSubmitted')
                  ? ''
                  : 'Please complete the previous step'
              }>
              MARK AS COMPLETE
            </TooltipButton>
          </AccessControl>
        ),
        by:
          dataReviewEvent?.initiatedBy?.authRole === 'sponsor'
            ? 'Employer'
            : dataReviewEvent?.initiatedBy?.id,
        completionDate: dataReviewEvent?.createdAt,
        dataTestId: 'data-review',
        eventData: dataReviewEvent?.eventData,
        eventName: 'dataReviewCompleted',
        isCompleted: !!dataReviewEvent,
        previousStepDone: !!sponsorDataSubmittedEvent,
        step: 'Data Review'
      }
    ],
    [
      completedEvents,
      dataReviewEvent,
      markAsComplete,
      postYETStatusMutation.isLoading,
      sponsorDataSubmittedEvent,
      yearEndTesting.data?.processId
    ]
  );

  const getYETTestResults = useQuery(
    ['PlanService.getYETTestResults', props.sponsorPlanId, props.year],
    () => {
      return PlanService.getYETTestResults(props.sponsorPlanId, props.year);
    }
  );

  return (
    <Paper data-testid='year-and-test-results-card' variant='outlined'>
      {yearEndTesting.isFetching && <LinearLoading />}
      <Grid container direction='row' justifyContent='space-between' pr={2}>
        <Grid alignItems='center' display='flex' pr={1}>
          <Typography data-testid='container-title' p={2} variant='h5'>
            Year End Testing
          </Typography>
          <Badge
            color={completedEvents.length === 5 ? 'success' : 'lightWarning'}
            data-testid='completed-steps-amount'>
            {completedEvents.length === 5
              ? 'Completed'
              : `${completedEvents.length} / 5 Completed`}
          </Badge>
        </Grid>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
          <Button
            color='primary'
            data-testid='open-unlock-submission-modal-btn'
            disabled={
              !yearEndTesting.data?.checkList?.isYearEndPackagingCompleted ||
              unlock.isLoading
            }
            onClick={toggleOpen}
            size='large'>
            UNLOCK SUBMISSION
          </Button>{' '}
        </AccessControl>
        <Dialog
          data-testid='unlock-submission-modal'
          maxWidth='md'
          onClose={toggleOpen}
          open={open}>
          <DialogTitle>Unlock Submission</DialogTitle>
          <DialogContent>
            Allow Year End data to be edited and resubmitted by Sponsor?
          </DialogContent>
          <DialogActions>
            <Button
              data-testid='cancel-unlock-submission-btn'
              onClick={toggleOpen}
              variant='text'>
              CANCEL
            </Button>
            <Button
              data-testid='sumbit-unlock-submission-btn'
              disabled={
                !yearEndTesting.data?.checkList?.isYearEndPackagingCompleted ||
                unlock.isLoading
              }
              onClick={unlockSubmission}
              variant='contained'>
              UNLOCK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>Step</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Completion Date</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow data-testid={`${row.dataTestId}-row`} key={row.step}>
                <TableCell align='center' />
                <TableCell align='center' padding='none'>
                  {row.isCompleted && (
                    <Check
                      color='primary'
                      data-testid={`${row.dataTestId}-checkmark`}
                    />
                  )}
                </TableCell>
                <TableCell role='rowheader'>{row.step}</TableCell>
                <TableCell>
                  {row.by}
                  {row.eventData?.bypass && ' - Bypassed'}
                </TableCell>
                <TableCell>{row.completionDate}</TableCell>
                <TableCell padding='none'>{row.action}</TableCell>
                <TableCell padding='none'>
                  <BypassYetStepButton
                    completed={!!row.eventData}
                    eventName={row.eventName}
                    previousStepDone={row.previousStepDone}
                    sponsorPlanId={props.sponsorPlanId}
                    year={props.year}
                  />
                </TableCell>
              </TableRow>
            ))}
            <YearEndContributionRow
              completedEvents={completedEvents}
              data={getYETTestResults.data?.yearEndContribution}
              event={yearEndContributionEvent}
              onCompleteStep={postYETStatusMutation.mutate}
              open={props.openContributionRow}
              planId={props.sponsorPlanId}
              sponsorPlanId={props.sponsorPlanId}
              toggleOpen={props.toggleOpenContributionRow}
              year={props.year}
            />
            <TestExecutionRow
              completedEvents={completedEvents}
              data={getYETTestResults.data?.testExecution}
              event={testExecutionEvent}
              onCompleteStep={postYETStatusMutation.mutate}
              open={props.openTestExecutionRow}
              planId={props.sponsorPlanId}
              sponsorPlanId={props.sponsorPlanId}
              toggleOpen={props.toggleOpenTestExecutionRow}
              year={props.year}
            />
            <CompliancePackageRow
              completedEvents={completedEvents}
              event={compliancePackageEvent}
              onCompleteStep={postYETStatusMutation.mutate}
              planId={props.sponsorPlanId}
              sponsorPlanId={props.sponsorPlanId}
              year={props.year}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
