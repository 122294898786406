export const getUiSchema = (
  isEsa?: boolean
): Record<
  string,
  Record<
    string,
    {
      title: string;
      fields?: {
        key: string;
        label: string;
        component?: string;
      }[];
      sections?: {
        title: string;
        fields: {
          key: string;
          label: string;
          component?: string;
        }[];
      }[];
    }
  >
> => ({
  design: {
    distributionFeatures: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'normalRetirementAgeType',
          label: 'Normal Retirement Age Type'
        },
        {
          component: 'Field',
          key: 'normalRetirementAge',
          label: 'Normal Retirement Age'
        },
        {
          component: 'SimpleDropdown',
          key: 'earlyRetirementAgeType',
          label: 'Early Retirement Age Type'
        },
        {
          component: 'Field',
          key: 'earlyRetirementAge',
          label: 'Early Retirement Age'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceFromRolloverAccount',
          label: 'Allow in-service withdrawal from rollover account at any time'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceAtNormalAge',
          label: 'Allow in-service withdrawals at normal retirement age'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceAtNormalAgeFromAllAccounts',
          label:
            'Allow in-service distribution at normal retirement age from all accounts'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceAtEarlyAge',
          label: 'Allow in-service withdrawals at early retirement age'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceAtEarlyAgeFromAllAccounts',
          label:
            'Allow in-service distributions at early retirement age from all accounts'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceFromPartialVestedAccount',
          label: 'Allow in-service distributions from partially vested accounts'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceAtSpecifiedAge',
          label: 'Allow In Service At Specified Age'
        },
        {
          component: 'Field',
          key: 'inServiceSpecifiedAge',
          label: 'Specified age for in-service withdrawals'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowSpecifiedAgeFromRothAccount',
          label: 'Allow specified age in-service withdrawals from Roth accounts'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowInServiceUponDisability',
          label: 'Allow in-service withdrawal upon disability'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipWithdrawals',
          label: 'Allow Hardship Withdrawals'
        },
        {
          component: 'SimpleDropdown',
          key: 'hardshipCriteria',
          label: 'Hardship criteria'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipWithdrawalsFromRothAccount',
          label: 'Allow hardship withdrawals from Roth accounts'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowPartialDistributionsAfterTermination',
          label: 'Allow partial distributions after termination'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipElective',
          label: 'Hardship - Elective Deferral Account'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipFromPartialVestedAccount',
          label: 'Allow Hardships from partially vested accounts'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipMatch',
          label: 'Hardship - Employer Matching Contribution Account'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipProfitSharing',
          label: 'Hardship - Non-Elective Contribution Account'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipRollover',
          label: 'Hardship - Rollover Contribution Account'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipTransfer',
          label: 'Hardship - Transfer Account'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowHardshipSafeHarbor',
          label: 'Hardship - SH, QNEC, QMAC'
        }
      ],
      title: 'Distribution'
    },

    effectiveDate: {
      fields: [
        {
          component: 'DatePicker',
          key: 'originalEffectivePlanDate',
          label: 'Original Effective Plan Date'
        },
        {
          component: 'DatePicker',
          key: 'safeHarborEffectiveDate',
          label: 'Safe Harbor Effective Date'
        },
        {
          component: 'DatePicker',
          key: 'vestwellStartDate',
          label: 'Plan Effective Date'
        },
        {
          component: 'SimpleDropdown',
          key: 'planYearEnd',
          label: 'Plan Year End'
        },
        {
          component: 'DatePicker',
          key: 'salaryRothDeferralEffectiveDate',
          label: 'Deferral Effective Date'
        },
        {
          component: 'DatePicker',
          key: 'profitSharingStartDate',
          label: 'Profit Sharing Effective Date'
        },
        {
          component: 'DatePicker',
          key: 'liquidationDate',
          label: 'Prior Plan Liquidation Date'
        },
        {
          component: 'DatePicker',
          key: 'blackoutStartDate',
          label: 'Blackout Start Date (Incoming)'
        },
        {
          component: 'DatePicker',
          key: 'blackoutEndDate',
          label: 'Blackout End Date (Incoming)'
        }
      ],
      title: 'Plan Effective and Year-End Dates'
    },

    eligibility: {
      fields: [
        {
          component: 'DualEligibility',
          key: 'hasDualEligibility',
          label: 'Has Dual Eligibility'
        },
        {
          key: 'excludeLeasedEmployees',
          label: 'Leased Employees'
        },
        {
          key: 'entryDateFrequency',
          label: 'Entry Date Frequency'
        },
        {
          key: 'minimumAge',
          label: 'Minimum Age Requirement'
        },
        {
          key: 'specialParticipationDate',
          label: 'Special Participation Waiver Date'
        },
        {
          key: 'specialEntryDate',
          label: 'Special Entry Date'
        },
        {
          key: 'serviceCalculationType',
          label: 'Service Calculation Type'
        },
        {
          key: 'lengthOfEmploymentRequired',
          label: 'Length Of Employment Required'
        },
        {
          key: 'hoursRequired',
          label: 'Hours Required'
        },
        {
          key: 'employerMatchEntryDateFrequency',
          label: 'Employer Match - Entry Date Frequency'
        },
        {
          key: 'employerMatchMinimumAge',
          label: 'Employer Match - Minimum Age Requirement'
        },
        {
          key: 'employerMatchServiceCalculationType',
          label: 'Employer Match - Service Calculation Type'
        },
        {
          key: 'employerMatchLengthOfEmploymentRequired',
          label: 'Employer Match - Length Of Employment Required'
        },
        {
          key: 'employerMatchHoursRequired',
          label: 'Employer Match - Hours Required'
        },
        {
          key: 'profitSharingEntryDateFrequency',
          label: 'Profit Sharing - Entry Date Frequency'
        },
        {
          key: 'profitSharingMinimumAge',
          label: 'Profit Sharing - Minimum Age Requirement'
        },
        {
          key: 'profitSharingServiceCalculationType',
          label: 'Profit Sharing - Service Calculation Type'
        },
        {
          key: 'profitSharingLengthOfEmploymentRequired',
          label: 'Profit Sharing - Length Of Employment Required'
        },
        {
          key: 'profitSharingHoursRequired',
          label: 'Profit Sharing - Hours Required'
        }
      ],
      title: 'Eligibility Plan Features'
    },

    employeeContribution: {
      sections: [
        {
          fields: [
            {
              component: 'SimpleDropdown',
              key: 'allowPretaxContribution',
              label: 'Employee Pre-Tax Contribution'
            },
            {
              component: 'SimpleDropdown',
              key: 'allowAfterTaxContribution',
              label: 'Employee After Tax Contribution'
            },
            {
              component: 'SimpleDropdown',
              key: 'isSalaryRothDeferral',
              label: 'Roth Contribution'
            },
            {
              component: 'SimpleDropdown',
              key: 'deferInDollars',
              label: 'Employee Ability to Defer in Dollars'
            }
          ],
          title: 'Deferrals'
        },
        {
          fields: [
            {
              component: 'SimpleDropdown',
              key: 'autoEnrollAmount',
              label: 'Auto Enroll Amount'
            },
            {
              component: 'DatePicker',
              key: 'autoEnrollEffectiveDate',
              label: 'Auto Enroll Effective Date'
            },
            // allowPermissibleWithdrawal and permissibleTimeframe fields must be shown in the Employee Contribution
            // section (since these fields are dependent on autoEnrollAmount), though in DB they are stored in the
            // plan_distribution_features table
            {
              component: 'SimpleDropdown',
              key: 'allowPermissibleWithdrawal',
              label: 'EACA Permissible Withdrawals'
            },
            {
              component: 'Field',
              key: 'permissibleTimeframe',
              label: 'Permissible Timeframe (days)'
            },
            {
              component: 'SimpleDropdown',
              key: 'autoEscalateAmount',
              label: 'Auto Escalate Amount'
            },
            {
              component: 'Field',
              key: 'autoEscalateMaximum',
              label: 'Auto Escalate Maximum %'
            }
          ],
          title: 'Auto Enroll and Escalate'
        }
      ],
      title: 'Employee Contribution'
    },

    employerContribution: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'isSafeHarbor',
          label: 'Safe Harbor Match'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowSafeHarborNonElective',
          label: 'Safe Harbor Non-Elective Contribution'
        },
        {
          component: 'SimpleDropdown',
          key: 'safeHarborMatchType',
          label: 'Safe Harbor Contribution Type'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowNonSafeHarborMatch',
          label: 'Employer Match - Non-Safe Harbor'
        },
        {
          component: 'SimpleDropdown',
          key: 'nonSafeHarborMatchType',
          label: 'Non-Safe Harbor Match Type'
        },
        {
          component: 'ContributionMatchFormula',
          key: 'contributionMatchFormula',
          label: 'Formula'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowEmployerProfitSharing',
          label: 'Employer Profit Sharing'
        },
        {
          component: 'SimpleDropdown',
          key: 'profitSharingStrategy',
          label: 'Profit Sharing Type'
        },
        {
          component: 'SimpleDropdown',
          key: 'documentMatchFrequency',
          label: 'Document Match Frequency'
        },
        {
          component: 'SimpleDropdown',
          key: 'matchFrequency',
          label: 'Safe Harbor Frequency - Funding Intent'
        },
        {
          component: 'SimpleDropdown',
          key: 'discretionaryMatchFundingIntent',
          label: 'Discretionary Match Frequency - Funding Intent'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowRollover',
          label: 'Allow Rollover?'
        },
        {
          component: 'Textarea',
          key: 'notes',
          label: 'Notes about Plan Design'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowPrevailingWage',
          label: 'Prevailing Wage Contribution'
        }
      ],
      title: 'Employer Contribution'
    },
    forceOutPreferences: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'isAllowed',
          label: 'Force Outs Allowed'
        },
        {
          component: 'Field',
          key: 'maxAmount',
          label: 'Maximum Amount'
        },
        {
          component: 'SimpleDropdown',
          key: 'excludeRollover',
          label: 'Exclude Rollovers from Maximum Amount'
        },
        {
          component: 'Field',
          key: 'rolloverMin',
          label: 'Automatic Rollover Minimum'
        },
        {
          component: 'SimpleDropdown',
          key: 'isAutomated',
          label: 'Automate'
        }
      ],
      title: 'Force Out'
    },
    loanFeatures: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'allowLoans',
          label: 'Allow Loans'
        },
        {
          component: 'Field',
          key: 'maxOutstandingLoans',
          label: 'Maximum Outstanding Loans'
        },
        {
          component: 'SimpleDropdown',
          key: 'allowResidencePurchaseExtension',
          label: 'Allow Extended Loan Period of Personal Residence Purchase'
        },
        {
          component: 'Field',
          key: 'maxYearsResidencePurchase',
          label: 'Maximum Years for Principal Residence Purchase'
        },
        {
          component: 'SimpleDropdown',
          key: 'hasPriorLoans',
          label: 'Plan Has Prior Loans'
        },
        {
          component: 'Field',
          key: 'loanEmail',
          label: 'Loan Email'
        }
      ],
      title: 'Loans'
    },

    trustee: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'trustee',
          label: 'Plan Trustee'
        }
      ],
      title: 'Plan Trustee'
    },

    vestingPreferences: {
      sections: [
        {
          fields: [
            {
              component: 'SimpleDropdown',
              key: 'vestingMethod',
              label: 'Vesting Method'
            },
            {
              component: 'Field',
              key: 'hoursOfServiceRequired',
              label: 'Hours Of Service Required'
            },
            {
              component: 'SimpleDropdown',
              key: 'excludeServicePriorTo18YearsOld',
              label: 'Exclude Service Prior to 18 Years Old'
            },
            {
              component: 'SimpleDropdown',
              key: 'excludeServicePriorToPlanExistence',
              label: 'Exclude Service Prior to Plan Existence'
            }
          ],
          title: 'Vesting Calculation Settings'
        },
        {
          fields: [
            {
              component: 'SimpleDropdown',
              key: 'profitSharing',
              label: 'Profit Sharing'
            },
            {
              component: 'SimpleDropdown',
              key: 'QACASafeHarborMatch',
              label: 'QACA Safe Harbor Match'
            },
            {
              component: 'SimpleDropdown',
              key: 'employerMatch',
              label: 'Employer Match'
            },
            {
              component: 'SimpleDropdown',
              key: 'QACASafeHarborNonElective',
              label: 'QACA Safe Harbor Non-Elective'
            }
          ],
          title: 'Vesting Schedule by Source'
        }
      ],
      title: 'Vesting'
    }
  },
  generalInfo: {
    fee: {
      fields: [
        {
          component: 'Field',
          key: 'originationFee',
          label: 'Loan Origination Fee ($)'
        },
        {
          component: 'Field',
          key: 'maintenanceFee',
          label: 'Loan Maintenance Fee ($)'
        },
        {
          component: 'Field',
          key: 'hardshipFee',
          label: 'Hardship Distribution Fee'
        },
        {
          component: 'Field',
          key: 'participantTerminationFee',
          label: 'Participant Termination Distribution Fee'
        },
        {
          component: 'Field',
          key: 'inServiceFee',
          label: 'In-service Distribution Fee'
        },
        {
          component: 'Field',
          key: 'rolloverFee',
          label: 'Prior Rollover Funds Distribution Fee'
        },
        {
          component: 'Field',
          key: 'disabilityFee',
          label: 'Disability Distribution Fee'
        },
        {
          component: 'Field',
          key: 'childFee',
          label: 'Child Distribution Fee'
        },
        {
          component: 'Field',
          key: 'planTerminationFee',
          label: 'Plan Termination Distribution Fee'
        },
        {
          component: 'Field',
          key: 'qdroFee',
          label: 'QDRO Distribution Fee'
        },
        {
          component: 'Field',
          key: 'rmdFee',
          label: 'RMD Fee'
        },
        {
          component: 'Field',
          key: 'deathFee',
          label: 'Death Distribution Fee'
        },
        {
          component: 'Field',
          key: 'correctiveFee',
          label: 'Corrective Distribution Fee'
        },
        {
          component: 'Field',
          key: 'permissiveFee',
          label: 'Permissive Distribution Fee'
        },
        {
          component: 'Field',
          key: 'residualFee',
          label: 'Residual Fee'
        }
      ],
      title: 'Fee'
    },

    recordkeeperAndCustodian: {
      fields: [
        ...(!isEsa
          ? [
              {
                component: 'SimpleDropdown',
                key: 'adoptionAgreementDocumentProvider',
                label: 'Adoption Agreement Document Provider'
              },
              {
                component: 'Field',
                key: 'externalHardDollarBillingAccountNumber',
                label: 'External Hard Dollar Billing Account Number'
              },
              {
                component: 'SimpleDropdown',
                key: 'partnerSystemName',
                label: 'Recordkeeper'
              }
            ]
          : []),
        {
          component: 'Field',
          key: 'custodianAccountNumber',
          label: 'Custodian Account Number'
        },
        ...(!isEsa
          ? [
              {
                component: 'Field',
                key: 'externalPlanId',
                label: 'External Plan ID'
              },
              {
                component: 'PriorProviderComponent',
                key: 'priorProvider',
                label: 'Prior Provider (if conversion)'
              }
            ]
          : []),
        {
          component: 'Field',
          key: 'opportunityId',
          label: 'Opportunity ID'
        }
      ],
      title: isEsa ? 'Custodian' : 'Recordkeeper and Custodian'
    },

    service: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'clientSuccessManagerName',
          label: 'Client Success Manager'
        }
      ],
      title: 'Service'
    },
    status: {
      fields: [
        {
          component: 'SimpleDropdown',
          key: 'adminStatus',
          label: 'Plan Status'
        },
        ...(!isEsa
          ? [
              {
                component: 'SimpleDropdown',
                key: 'deconvertedToMep',
                label: 'Deconverted to MEP'
              }
            ]
          : []),
        {
          component: 'DatePicker',
          key: 'offboardingBlackoutStartDate',
          label: 'Blackout Start Date (Outgoing)'
        },
        {
          component: 'DatePicker',
          key: 'offboardingBlackoutEndDate',
          label: 'Blackout End Date (Outgoing)'
        },
        {
          component: 'DatePicker',
          key: 'lastPayrollDate',
          label: 'Last Payroll Date'
        },
        {
          component: 'DatePicker',
          key: 'terminationDate',
          label: 'Termination Effective Date'
        }
      ],
      title: 'Status'
    }
  }
});

export const getNavigationSchema = (
  isEsa?: boolean
): Record<string, { key: string; label: string }[]> => ({
  design: [
    { key: 'effectiveDate', label: 'Plan Effective and Year-End Dates' },
    { key: 'eligibility', label: 'Eligibility ' },
    {
      key: 'employeeContribution',
      label: 'Employee Contribution'
    },
    {
      key: 'employerContribution',
      label: 'Employer Contribution'
    },
    { key: 'vestingPreferences', label: 'Vesting' },
    { key: 'distributionFeatures', label: 'Distribution' },
    { key: 'loanFeatures', label: 'Loans' },
    { key: 'trustee', label: 'Trustee' },
    { key: 'forceOutPreferences', label: 'Force Out' }
  ],
  generalInfo: [
    { key: 'status', label: 'Status' },
    {
      key: 'recordkeeperAndCustodian',
      label: isEsa ? 'Custodian' : 'Recordkeeper and Custodian'
    },
    { key: 'service', label: 'Service' },
    { key: 'fee', label: 'Fee' }
  ]
});

export const designTabFieldsWithValidationRules: Record<string, string[]> = {
  distributionFeatures: [
    'earlyRetirementAge',
    'inServiceSpecifiedAge',
    'normalRetirementAge'
  ],
  eligibility: ['hoursRequired'],
  employeeContribution: [
    'allowPermissibleWithdrawal',
    'autoEnrollEffectiveDate',
    'autoEscalateMaximum',
    'permissibleTimeframe'
  ],
  employerContribution: [
    'dollarCap',
    'firstTierCap',
    'firstTierPercent',
    'secondTierCap',
    'secondTierPercent'
  ],
  forceOutPreferences: ['maxAmount', 'rolloverMin'],
  loanFeatures: [
    'allowResidencePurchaseExtension',
    'hasPriorLoans',
    'maxOutstandingLoans',
    'maxYearsResidencePurchase'
  ],
  vestingPreferences: ['hoursOfServiceRequired']
};
