import { BalanceDto } from '@/models/suba/balances/BalanceDTO.model';
import { BalanceGetRequest } from '@/models/suba/balances/BalanceGetRequest.model';
import ApiService from '@/services/Api.service';

export class BalanceService {
  static async get(
    accountId: string,
    params?: BalanceGetRequest
  ): Promise<BalanceDto> {
    const result = await ApiService.getJson<BalanceDto>(
      `balances/${accountId}`,
      params
    );
    return result;
  }
}

export default BalanceService;
