import Badge from '@/components/badge/Badge.component';
import { CardContent, CardPlaceholder } from '@/components/card/Card.component';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUpdateAlertMutation } from '@/hooks/suba/useUpdateAlertMutation.hook';
import { FormattedRolloverDto } from '@/models/RolloversDTO.model';
import { RolloverStatusColorMap } from '@/models/suba/common/RolloverStatusColorMap.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import GlobalSearchService from '@/services/GlobalSearch.service';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
  SearchOutlined as SearchOutlinedIcon
} from '@mui/icons-material';
import {
  Autocomplete,
  Card,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import {
  useQuery,
  useQueryClient,
  UseQueryResult
} from '@tanstack/react-query';

import { sortBy } from 'lodash';
import { forwardRef, useContext, useState } from 'react';
import { useDebounce } from 'react-use';

import { CardHeader } from './CardHeader.component';

const RolloverResults = (props: {
  ['data-testid']: string;
  participantId?: number;
  planId?: number;
  query: UseQueryResult<FormattedRolloverDto[]>;
  showParticipantName?: boolean;
}) => {
  const alert = useContext(AlertContext);
  const queryClient = useQueryClient();
  const updateAlertMutation = useUpdateAlertMutation();

  return (
    <CardContent disablePadding loading={props.query.isFetching}>
      {props.query.data === undefined ||
      (Array.isArray(props.query.data) && props.query.data.length === 0) ? (
        <CardPlaceholder
          icon={<SearchIcon fontSize='inherit' />}
          subtitle={
            props.query.data === undefined
              ? 'Rollovers will appear here'
              : 'No results found'
          }
        />
      ) : (
        <DataTable
          columnDefs={[
            props.showParticipantName
              ? {
                  autoHeight: true,
                  cellRenderer: ({ data }: { data: FormattedRolloverDto }) => (
                    <DataTableStackCell
                      primary={data.participantName}
                      secondary={data.accountProvider}
                    />
                  ),
                  field: 'participantName',
                  headerName: 'Participant / Previous Provider',
                  width: 240
                }
              : {
                  field: 'accountProvider',
                  headerName: 'Previous Provider',
                  width: 240
                },
            {
              autoHeight: true,
              // note: amounts below are pre-formatted by ParticipantService.getRollovers and PlanService.getRollovers
              cellRenderer: ({ data }: { data: FormattedRolloverDto }) =>
                data.rothAmount !== '$0.00' ? (
                  <DataTableStackCell
                    primary={data.rothAmount}
                    secondary='Roth'
                  />
                ) : (
                  <DataTableStackCell
                    primary={data.pretaxAmount}
                    secondary='Pre-Tax'
                  />
                ),
              headerName: 'Amount',
              type: 'numericColumn',
              width: 128
            },
            {
              autoHeight: true,
              cellRenderer: ({ data }: { data: FormattedRolloverDto }) => (
                <Stack my={1} spacing={0.5}>
                  <Badge
                    color={RolloverStatusColorMap[data.status]}
                    maxWidth='100%'>
                    {data.status}
                  </Badge>
                  <Typography variant='body2'>
                    {formatters.formatFromIsoDateCustom(
                      data.updatedAt,
                      'MM/DD/YYYY'
                    )}
                  </Typography>
                </Stack>
              ),
              field: 'status',
              headerName: 'Status',
              width: 180
            },
            {
              cellRenderer: ({ data }: { data: FormattedRolloverDto }) => (
                <IconButton
                  data-testid='select-rollover-button'
                  onClick={async () => {
                    if (!alert) return;

                    updateAlertMutation.mutate({
                      alertId: alert.id,
                      updateRequest: {
                        details: {
                          depositPlanId: props.planId
                            ? props.planId.toString()
                            : // fetch plan via query client to prime cache for Rollover component (or use cache if already primed)
                              (
                                await queryClient.fetchQuery(
                                  [
                                    'ParticipantService.getParticipantById',
                                    data.participantId
                                  ],
                                  () =>
                                    ParticipantService.getParticipantById(
                                      data.participantId
                                    )
                                )
                              ).sponsorPlanId.toString(),
                          investorId: data.participantId.toString(),
                          rolloverId: data.id.toString()
                        }
                      }
                    });
                  }}>
                  <CheckCircleIcon />
                </IconButton>
              ),
              cellStyle: {
                paddingLeft: 8,
                paddingRight: 8
              },
              maxWidth: 60
            }
          ].filter(Boolean)}
          columnSizing='flex'
          data-testid={props['data-testid']}
          primaryKey='id'
          rowData={props.query.data}
        />
      )}
    </CardContent>
  );
};

const ParticipantRolloverResults = (props: {
  participantId?: number;
  participantName?: string;
}) => {
  const { showSnackbar } = useSnackbar();

  const rolloversQuery = useQuery(
    ['ParticipantService.getRollovers', props.participantId],
    () => ParticipantService.getRollovers(props.participantId),
    {
      enabled: Boolean(props.participantId),
      onError: (err: Error) => {
        showSnackbar({
          message: `Failed to get rollovers by participant: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <RolloverResults
      data-testid='pending-rollovers-participant-search-results'
      participantId={props.participantId}
      query={rolloversQuery}
    />
  );
};

const PlanRolloverResults = (props: { planId?: number }) => {
  const { showSnackbar } = useSnackbar();

  const rolloversQuery = useQuery(
    ['PlanService.getRollovers', props.planId],
    () => PlanService.getRollovers(props.planId),
    {
      enabled: Boolean(props.planId),
      onError: (err: Error) => {
        showSnackbar({
          message: `Failed to get rollovers by plan: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <RolloverResults
      data-testid='pending-rollovers-plan-search-results'
      planId={props.planId}
      query={rolloversQuery}
      showParticipantName
    />
  );
};

const PlanIdentifiedPendingRollovers = (props: { planId: string }) => {
  const { showSnackbar } = useSnackbar();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [participantId, setParticipantId] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState('');

  const participantSearchQuery = useQuery(
    ['PlanService.getParticipantsByPlanId', searchTerm],
    async () =>
      (
        await PlanService.getParticipantsByPlanId({
          filter: 'firstname,lastname',
          pageNumber: 1,
          pageSize: 100,
          planId: props.planId,
          searchTerm,
          sort: ['lastName', 'firstName']
        })
      ).participants.map(participant => ({
        id: participant.id,
        label: participant.name
      })),
    {
      enabled: searchTerm !== '',
      onError: (err: Error) => {
        showSnackbar({
          message: `Failed to search participants: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  useDebounce(
    () => {
      if (inputValue.length >= 2 || inputValue === '') {
        setSearchTerm(inputValue);
      }
    },
    300,
    [inputValue]
  );

  return (
    <>
      <CardHeader
        loading={participantSearchQuery.isFetching}
        title='Pending Rollovers'
      />
      <Divider />
      <CardContent>
        <Stack direction='row' flexGrow={2}>
          <FormControl fullWidth>
            <Autocomplete<
              {
                id: number;
                label: string;
              },
              false,
              false,
              true
            >
              autoComplete
              data-testid='pending-rollovers-name-input'
              filterOptions={o => o} // noop per docs for async requests
              freeSolo
              getOptionLabel={option =>
                typeof option === 'string' ? option : option.label
              }
              handleHomeEndKeys
              inputValue={inputValue}
              loading={
                participantSearchQuery.isFetching &&
                participantSearchQuery.data === undefined
              }
              onChange={(event, value, reason) => {
                if (typeof value === 'string') {
                  setSearchTerm(value);
                  setIsMenuOpen(true);
                } else if (value) {
                  setParticipantId(value?.id);
                  setIsMenuOpen(true);
                } else if (reason === 'clear') {
                  setSearchTerm('');
                }
              }}
              onClose={event => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter'
                )
                  return;
                setIsMenuOpen(false);
              }}
              onInputChange={(event, value) => {
                setInputValue(value);
              }}
              onOpen={() => {
                setIsMenuOpen(true);
              }}
              open={isMenuOpen}
              openOnFocus
              options={
                searchTerm === '' ? [] : participantSearchQuery.data || []
              }
              renderInput={inputProps => (
                <TextField label='Participant Name' {...inputProps} />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              )}
              selectOnFocus
              size='small'
            />
          </FormControl>
          <IconButton
            color='primary'
            data-testid='identify-plan-search-button'
            type='submit'>
            <SearchOutlinedIcon />
          </IconButton>
        </Stack>
      </CardContent>
      <ParticipantRolloverResults
        participantId={participantId}
        participantName={searchTerm}
      />
    </>
  );
};

const PlanUnidentifiedPendingRollovers = () => {
  const { showSnackbar } = useSnackbar();
  const [entityId, setEntityId] = useState<number | undefined>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchMode, setSearchMode] = useState<'plans' | 'participants'>(
    'plans'
  );
  const [searchTerm, setSearchTerm] = useState('');

  const globalSearchQuery = useQuery(
    ['GlobalSearchService.getGlobalSearchResult', searchMode, searchTerm],
    async () => {
      const globalSearchResult =
        await GlobalSearchService.getGlobalSearchResult(
          searchTerm,
          1,
          100,
          searchMode === 'plans' ? 3 : 2
        );

      const options =
        searchMode === 'participants'
          ? globalSearchResult?.data?.participants?.data?.map(participant => ({
              id: participant.participantId,
              label: `${participant.firstName} ${participant.lastName}`,
              planName: participant.planName
            }))
          : globalSearchResult?.data?.plans?.data?.map(plan => ({
              id: plan.sponsorPlanId,
              label: plan.planName
            }));

      if (!Array.isArray(options) || !options.length) return [];

      return options;
    },
    {
      enabled: searchTerm !== '',
      onError: (err: Error) => {
        showSnackbar({
          message: `Failed to search ${searchMode}: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  useDebounce(
    () => {
      if (inputValue.length >= 2 || inputValue === '') {
        setSearchTerm(inputValue);
      }
    },
    300,
    [inputValue]
  );

  return (
    <>
      <CardHeader
        loading={globalSearchQuery.isFetching}
        title='Pending Rollovers'
      />
      <Divider />
      <CardContent>
        <Stack direction='row' justifyContent='' spacing={1} useFlexGap>
          <ToggleButtonGroup
            exclusive
            onChange={(event, value) => setSearchMode(value)}
            size='small'
            value={searchMode}>
            <ToggleButton
              data-testid='pending-rollovers-plan-button'
              value='plans'>
              Plan
            </ToggleButton>
            <ToggleButton
              data-testid='pending-rollovers-participant-button'
              value='participants'>
              Participant
            </ToggleButton>
          </ToggleButtonGroup>
          <Stack direction='row' flexGrow={2}>
            <FormControl fullWidth>
              <Autocomplete<
                {
                  id: number;
                  label: string;
                  planName?: string;
                },
                false,
                false,
                true
              >
                autoComplete
                data-testid='pending-rollovers-name-input'
                filterOptions={o => o} // noop per docs for async requests
                freeSolo
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.label
                }
                groupBy={option => option.planName}
                handleHomeEndKeys
                inputValue={inputValue}
                loading={
                  globalSearchQuery.isFetching &&
                  globalSearchQuery.data === undefined
                }
                onChange={(event, value, reason) => {
                  if (typeof value === 'string') {
                    setSearchTerm(value);
                    setIsMenuOpen(true);
                  } else if (value) {
                    setEntityId(value.id);
                    setIsMenuOpen(true);
                  } else if (reason === 'clear') {
                    setSearchTerm('');
                  }
                }}
                onClose={event => {
                  if (
                    event.type === 'keydown' &&
                    (event as React.KeyboardEvent<HTMLDivElement>).key ===
                      'Enter'
                  )
                    return;
                  setIsMenuOpen(false);
                }}
                onInputChange={(event, value) => {
                  setInputValue(value);
                }}
                onOpen={() => {
                  setIsMenuOpen(true);
                }}
                open={isMenuOpen}
                openOnFocus
                options={
                  searchTerm === ''
                    ? []
                    : sortBy(globalSearchQuery.data, 'planName') || []
                }
                renderInput={inputProps => (
                  <TextField label='Name' {...inputProps} />
                )}
                selectOnFocus
                size='small'
              />
            </FormControl>
            <IconButton
              color='primary'
              data-testid='identify-plan-search-button'
              type='submit'>
              <SearchOutlinedIcon />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>
      {searchMode === 'participants' ? (
        <ParticipantRolloverResults
          participantId={entityId}
          participantName={searchTerm}
        />
      ) : (
        <PlanRolloverResults planId={entityId} />
      )}
    </>
  );
};

export const PendingRollovers = forwardRef<HTMLDivElement>((props, ref) => {
  const alert = useContext(AlertContext);

  return (
    <Card ref={ref}>
      {alert?.planId || alert?.details?.depositPlanId ? (
        <PlanIdentifiedPendingRollovers
          planId={alert?.planId || alert.details.depositPlanId}
        />
      ) : (
        <PlanUnidentifiedPendingRollovers />
      )}
    </Card>
  );
});

PendingRollovers.displayName = 'PendingRollovers';
