import { TransactionCodeDto } from '@/models/suba/transaction-codes/TransactionCodeDto';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

export const groupTransactionTypeCodesByBaseType = (
  transactionCodes: TransactionCodeDto[]
): Record<TransactionBaseType, TransactionTypeCode[]> => {
  return {
    [TransactionBaseType.Buy]: transactionCodes
      .filter(tcode => tcode.transactionBaseType === TransactionBaseType.Buy)
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.Deposit]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.Deposit
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.FeeReversal]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.FeeReversal
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.Fee]: transactionCodes
      .filter(tcode => tcode.transactionBaseType === TransactionBaseType.Fee)
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.IncomeIn]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.IncomeIn
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.IncomeOut]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.IncomeOut
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.Sell]: transactionCodes
      .filter(tcode => tcode.transactionBaseType === TransactionBaseType.Sell)
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.TransferIn]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.TransferIn
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.TransferOut]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.TransferOut
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort(),
    [TransactionBaseType.Withdrawal]: transactionCodes
      .filter(
        tcode => tcode.transactionBaseType === TransactionBaseType.Withdrawal
      )
      .map(tcode => tcode.transactionTypeCode)
      .sort()
  };
};
