import SecurityService from '@/services/suba/security/Security.service';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlProps as FormControlPropsType,
  FormHelperText,
  FormHelperTextProps as FormHelperTextPropsType,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps as OutlinedInputPropsType,
  Stack,
  SvgIcon
} from '@mui/material';
import { unstable_useId as useId } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';

import TextStack, { TextStackItem, TextValue } from '../text-stack';

type CusipTickerSearchProps = {
  'data-testid'?: string;
  helperTextPlaceholder?: boolean;
  summary?: boolean;
  disabled?: boolean;
  initialValue?: string;
  name?: string;
  FormControlProps?: FormControlPropsType;
  FormHelperTextProps?: FormHelperTextPropsType;
  OutlinedInputProps?: OutlinedInputPropsType;
  onChange?: (
    confirmedRawValue: string,
    cusipTickerValues: { cusip: string; symbol: string }
  ) => void;
  onError?: (error: any) => void;
  onValidating?: (value: boolean) => void;
};

const CusipTickerSearch = React.forwardRef<
  HTMLDivElement,
  CusipTickerSearchProps
>(
  (
    {
      'data-testid': testId,
      helperTextPlaceholder = false,
      summary = false,
      disabled = false,
      initialValue = '',
      name,
      FormControlProps = {},
      FormHelperTextProps = {},
      OutlinedInputProps = {},
      onChange,
      onError,
      onValidating
    },
    ref
  ) => {
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [value, setValue] = useState(initialValue);
    const [searchValue, setSearchValue] = useState(initialValue);
    const [canClearValue, toggleCanClearValue] = useToggle(false);
    const inputId = useId();
    const summaryId = useId();

    useDebounce(
      () => {
        if (typeof onValidating === 'function') onValidating(false);
        if (value !== searchValue && (value.length >= 2 || value === '')) {
          setSearchValue(value.toUpperCase());
        }
      },
      300,
      [value]
    );

    const { error, data, isFetching } = useQuery(
      ['SecurityService.get', searchValue],
      () => SecurityService.get(searchValue.trim()),
      {
        enabled: Boolean(searchValue !== ''),
        staleTime: Infinity
      }
    );

    useEffect(() => {
      if (typeof onValidating === 'function') onValidating(isFetching);
    }, [isFetching, onValidating]);

    useEffect(() => {
      // data is undefined when there is no cached data and the query has been disabled (e.g. for '' search)
      if (data !== undefined) toggleCanClearValue(true);

      if (typeof onChange === 'function' && data?.cusip) {
        onChange(value, { cusip: data.cusip, symbol: data.symbol });
      }
      // we only want to run this when data changes;
      // don't include onChange to avoid render loops when non-memoized functions are provided
    }, [data]);

    useEffect(() => {
      if (error instanceof Error) {
        const axiosError = error as AxiosError;
        if (axiosError.isAxiosError && axiosError.response?.status === 404) {
          setErrorMessage('Ticker doesn’t exist');
        } else {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage(null);
      }
    }, [error]);

    useEffect(() => {
      if (typeof onError === 'function' && errorMessage !== null) {
        onError(new Error(errorMessage));
      }
      // we only want to run this when an error occurs;
      // don't include onError to avoid render loops when non-memoized functions are provided
    }, [errorMessage]);

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (e.key === 'Enter') {
        if (isFetching) {
          e.preventDefault();
        }
        setSearchValue(value);
      }
    };

    const getEndAdornment = () => {
      if (isFetching) {
        return (
          <InputAdornment position='end'>
            <CircularProgress size='20px' />
          </InputAdornment>
        );
      }
      if (disabled) {
        return (
          <InputAdornment position='end'>
            <IconButton disabled>
              <SvgIcon />
            </IconButton>
          </InputAdornment>
        );
      }
      if (canClearValue) {
        return (
          <InputAdornment position='end'>
            <IconButton
              aria-label='clear'
              onClick={() => {
                setValue('');
                setSearchValue('');
                toggleCanClearValue();

                if (typeof onChange === 'function') {
                  onChange('', { cusip: '', symbol: '' });
                }
              }}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        );
      }
      return (
        <InputAdornment position='end'>
          <IconButton aria-label='search' onClick={() => setSearchValue(value)}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    };

    const helperText = FormHelperTextProps?.children || errorMessage || ' ';

    return (
      <Stack ref={ref} spacing={2} width='100%'>
        <FormControl
          error={Boolean(error)}
          size='small'
          {...FormControlProps}
          onBlur={() => setSearchValue(value)}>
          <InputLabel htmlFor={inputId}>CUSIP / Ticker</InputLabel>
          <OutlinedInput
            autoComplete='off'
            disabled={disabled}
            endAdornment={getEndAdornment()}
            id={inputId}
            inputProps={{
              'aria-busy': isFetching,
              'aria-describedby': summaryId,
              'data-testid': testId // for value input only, the hidden input should be accessed via name or jest-dom toHaveFormValues
            }}
            label='CUSIP / Ticker'
            onChange={event => {
              const currentValue = event.target.value;

              toggleCanClearValue();
              setValue(currentValue.toUpperCase());

              if (typeof onChange === 'function' && currentValue === '') {
                onChange('', { cusip: '', symbol: '' });
              }
            }}
            onKeyDown={handleKeyDown}
            sx={{
              // zero padding when buttons are adornments to avoid excessive padding due to their hit areas
              pr: isFetching ? undefined : 0
            }}
            type='text'
            value={value}
            {...OutlinedInputProps}
          />
          {(FormHelperTextProps?.children ||
            errorMessage ||
            helperTextPlaceholder === true) && (
            <FormHelperText error {...FormHelperTextProps}>
              {helperText}
            </FormHelperText>
          )}
          {/* separate hidden input for form submission of matched CUSIP */}
          <input name={name} type='hidden' value={data?.cusip || ''} />
        </FormControl>
        {summary && data && (
          <Box
            id={summaryId}
            sx={{
              bgcolor: theme => theme.palette.primary.light,
              border: '1px solid rgba(33, 150, 243, 0.3)',
              borderRadius: '4px',
              px: 2,
              py: 1.5
            }}>
            <TextStack
              divider
              id='account-detail-header-fields'
              rowColumnWidth='dynamic'>
              <>
                <TextStackItem>
                  <TextValue
                    detail={`CUSIP: ${data.cusip}`}
                    sx={{ whiteSpace: 'nowrap' }}>
                    {data.symbol} &#8226; {data.description}
                  </TextValue>
                </TextStackItem>
              </>
            </TextStack>
          </Box>
        )}
      </Stack>
    );
  }
);

CusipTickerSearch.displayName = 'CusipTickerSearch';

export default CusipTickerSearch;
