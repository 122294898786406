import * as yup from 'yup';

import ApiService from './Api.service';

export const CreateSdbaSetupSchema = yup.object({
  maxBalance: yup.number().integer().min(0).max(75).required(),
  minVestedBalance: yup.number().integer().min(8000).required(),
  planAccessCode: yup.string().trim().required(),
  sdbaPlanId: yup.string().trim().required()
});

export type CreateSdbaSetupRequestDto = yup.InferType<
  typeof CreateSdbaSetupSchema
>;

export const UpdateSdbaSetupSchema = yup.object({
  maxBalance: yup.number().integer().min(0).max(75).optional(),
  minVestedBalance: yup.number().integer().min(8000).optional(),
  planAccessCode: yup.string().trim().optional(),
  sdbaPlanId: yup.string().trim().optional()
});

export type UpdateSdbaSetupRequestDto = yup.InferType<
  typeof UpdateSdbaSetupSchema
>;

export type SdbaSetupResponse = {
  allowSdba: boolean;
  maxBalance: number;
  minVestedBalance: number;
  planAccessCode: string;
  sdbaPlanId: string;
  sdbaStatus: SdbaStatus;
};

export enum SdbaStatus {
  active = 'Active',
  inactive = 'Inactive'
}

export class SdbaService {
  static async getSdbaSetup(planId: number): Promise<SdbaSetupResponse> {
    return ApiService.getJson(`/sdba/${planId}/setup`);
  }

  static async createSdbaSetup(
    planId: number,
    body: CreateSdbaSetupRequestDto
  ): Promise<SdbaSetupResponse> {
    return ApiService.postJson(`/sdba/${planId}/setup`, body);
  }

  static async updateSdbaSetup(
    planId: number,
    body: UpdateSdbaSetupRequestDto
  ): Promise<SdbaSetupResponse> {
    return ApiService.patchJson(`/sdba/${planId}/setup`, body);
  }
}
