import { useSnackbar } from '@/contexts/SnackBarContext';
import TransactionCodeService from '@/services/suba/transaction-codes/TransactionCode.service';
import { groupTransactionTypeCodesByBaseType } from '@/utils/suba/groupTransactionTypeCodesByBaseType';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

export const useTransactionTypeCodesByBaseType = (): UseQueryResult<
  Record<TransactionBaseType, TransactionTypeCode[]>
> => {
  const { showSnackbar } = useSnackbar();

  return useQuery(
    ['transactionTypeCodesByBaseType'],
    async () => {
      const transactionCodes = await TransactionCodeService.search();
      return groupTransactionTypeCodesByBaseType(transactionCodes);
    },
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch transaction codes: ${message}`,
          severity: 'error'
        });
      }
    }
  );
};
