import { CardContent, CardPlaceholder } from '@/components/card';
import { DataTable } from '@/components/data-table/DataTable.component';
import { useSearchPendingTransactionsQuery } from '@/hooks/suba/useSearchPendingTransactionsQuery.hook';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { PendingTransactionSearchRequest } from '@/models/suba/transactions/PendingTransactionSearchRequest.model';
import { TransactionDto } from '@/models/suba/transactions/TransactionDTO.model';
import { TransactionSortKey } from '@/models/suba/transactions/TransactionSortKey.enum';
import { TransactionStatus } from '@/models/suba/transactions/TransactionStatus.model';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useToggle } from '@vestwell-frontend/hooks';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { PendingTransactionStatus } from '@vestwell-sub-accounting/models/common/PendingTransactionStatus';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { TransactionsProps } from '../models/TransactionsProps.model';
import { exportPendingTransactions } from '../utils/exportTransactions';
import { getColDefs } from '../utils/getColDefs';
import { CancelTransactionDialog } from './dialogs/CancelTransactionDialog.component';
import { ConfirmTransactionDialog } from './dialogs/ConfirmTransactionDialog.component';
import { TransactionDetailCellRenderer } from './TransactionDetailCellRenderer.component';
import {
  TransactionsFiltersForm,
  TransactionsFiltersFormValues
} from './TransactionsFiltersForm.component';

type PendingTransactionsProps = TransactionsProps & {
  exportTransactionsRef: MutableRefObject<() => Promise<void>>;
  page: number;
  pageSize: number;
  queryRef: MutableRefObject<UseQueryResult<PaginatedApiResponse<unknown>>>;
  searchParams: PendingTransactionSearchRequest;
  setIsQueryFetching: ReturnType<typeof useToggle>[1];
  setPage: ReturnType<typeof useUrlStateParams<number>>[1];
  setPageSize: ReturnType<typeof useUrlStateParams<number>>[1];
  setSearchParams: ReturnType<
    typeof useUrlStateParams<PendingTransactionSearchRequest>
  >[1];
};

export const PendingTransactions: FC<PendingTransactionsProps> = props => {
  const gridRef = useRef<AgGridReact>(null);
  const [orderBy, setOrderBy] = useState<TransactionSortKey | undefined>(
    props.customOrder?.column
  );
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >(props.customOrder?.direction);
  const [openCancelDialog, toggleOpenCancelDialog] = useToggle(false);
  const [openConfirmationDialog, toggleOpenConfirmationDialog] =
    useToggle(false);
  const [selectedTransactions, setSelectedTransactions] = useState<
    TransactionDto[]
  >([]);

  useEffect(() => {
    if (!props.exportTransactionsRef) return;

    const commonParams = {
      accountId: props.accountId,
      gridRef,
      searchParams: {
        ...props.searchParams,
        orderBy,
        orderByDirection
      }
    };

    props.exportTransactionsRef.current = () =>
      exportPendingTransactions(
        'planId' in props
          ? {
              ...commonParams,
              accountLevel: AccountLevel.SubAccount,
              planId: props.planId
            }
          : {
              ...commonParams,
              accountId: props.accountId,
              accountLevel: props.accountLevel
            }
      );
  }, [
    orderBy,
    orderByDirection,
    props.accountId,
    props.accountLevel,
    props.planId,
    props.searchParams
  ]);

  const pendingTransactionsQuery = useSearchPendingTransactionsQuery(
    {
      ...props.searchParams,
      accountLevel: props.accountLevel,
      orderBy,
      orderByDirection,
      page: props.page,
      pageSize: props.pageSize,
      ...(props.planId
        ? {
            accountLevel: AccountLevel.SubAccount,
            planId: props.planId
          }
        : {
            accountId: props.accountId,
            accountLevel: props.accountLevel
          })
    },
    {
      // avoid abrupt transitions when changing filters that result in a new query
      keepPreviousData: true
    }
  );

  useEffect(() => {
    props.queryRef.current = pendingTransactionsQuery;
  }, [pendingTransactionsQuery]);

  useEffect(() => {
    props.setIsQueryFetching(pendingTransactionsQuery.isFetching);
  }, [pendingTransactionsQuery.isFetching]);

  const handleSubmit = (formData: TransactionsFiltersFormValues) => {
    props.setPage(1);
    props.setSearchParams(
      (prev): PendingTransactionSearchRequest => ({
        ...prev,
        amountFrom:
          formData.amountFrom === '' || formData.amountFrom === null
            ? undefined
            : parseFloat(formData.amountFrom).toFixed(2),
        amountTo:
          formData.amountTo === '' || formData.amountTo === null
            ? undefined
            : parseFloat(formData.amountTo).toFixed(2),
        beginningDate: formData.beginningDate || undefined,
        cusip: formData.cusip || undefined,
        dateType: formData.dateType || PositionDateType.Trade,
        endingDate: formData.endingDate || undefined,
        sourceTransactionId: formData.sourceTransactionId || undefined,
        statuses:
          (formData.statuses as PendingTransactionStatus[]) || undefined,
        tracerId: formData.tracerId || undefined,
        transactionBaseTypes:
          formData.transactionBaseTypes?.length > 0
            ? formData.transactionBaseTypes
            : undefined,
        transactionTypeCode: formData.transactionTypeCode || undefined,
        transferSubAccountId: formData.transferSubAccountId || undefined,
        unitsFrom:
          formData.unitsFrom === '' || formData.unitsFrom === null
            ? undefined
            : parseFloat(formData.unitsFrom).toFixed(3),
        unitsTo:
          formData.unitsTo === '' || formData.unitsTo === null
            ? undefined
            : parseFloat(formData.unitsTo).toFixed(3)
      })
    );
  };

  const columnDefs = useMemo<ColDef[]>(
    () =>
      getColDefs({
        accountLevel: props.accountLevel,
        hideActions: props.hideActions,
        hideColumns: props.hideColumns,
        hideDetailCell: props.hideDetailCell,
        setSelectedTransactions,
        toggleOpenCancelDialog,
        toggleOpenConfirmationDialog,
        transactionStatus: TransactionStatus.Pending
      }),
    [
      props.accountLevel,
      props.hideActions,
      props.hideColumns,
      props.hideDetailCell
    ]
  );

  const detailCellRenderer = useMemo(() => {
    return TransactionDetailCellRenderer;
  }, []);

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as TransactionSortKey);
      setOrderByDirection(newSort[0].sort);
    }
  };

  return (
    <>
      <CancelTransactionDialog
        accountId={props.accountId}
        accountLevel={
          // ensure account summary is aligned with current page to avoid redundancy with table of selected transactions
          (props.hideAccountLevelToggle && props.accountLevel) ||
          AccountLevel.ParentAccount
        }
        onClose={() => {
          toggleOpenCancelDialog(false);
          setSelectedTransactions([]);
        }}
        open={openCancelDialog}
        transactions={selectedTransactions}
      />
      <ConfirmTransactionDialog
        accountId={props.accountId}
        accountLevel={
          // ensure account summary is aligned with current page to avoid redundancy with table of selected transactions
          (props.hideAccountLevelToggle && props.accountLevel) ||
          AccountLevel.ParentAccount
        }
        onClose={() => {
          toggleOpenConfirmationDialog(false);
          setSelectedTransactions([]);
        }}
        open={openConfirmationDialog}
        transaction={selectedTransactions[0]}
      />
      <CardContent
        data-testid='pending-transactions-card-content'
        disablePadding
        overlayLoading={pendingTransactionsQuery.isInitialLoading}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='pending-transactions-data-table'
          detailCellRenderer={
            props.hideDetailCell ? undefined : detailCellRenderer
          }
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='pending-transactions-data-table-placeholder'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  !pendingTransactionsQuery.data
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          filterSidePanelComponent={
            !props.hideFilters ? (
              <Formik
                initialValues={{
                  amountFrom: props.searchParams.amountFrom || '',
                  amountTo: props.searchParams.amountTo || '',
                  beginningDate: props.searchParams.beginningDate || '',
                  cusip: props.searchParams.cusip || '',
                  dateType:
                    props.searchParams.dateType || PositionDateType.Trade,
                  endingDate:
                    props.searchParams.endingDate ||
                    dayjs().format('YYYY-MM-DD'),
                  sourceTransactionId:
                    props.searchParams.sourceTransactionId || '',
                  statuses: props.searchParams.statuses || [
                    PendingTransactionStatus.Pending
                  ],
                  tracerId: props.searchParams.tracerId || '',
                  transactionBaseTypes:
                    props.searchParams.transactionBaseTypes || [],
                  transactionTypeCode:
                    props.searchParams.transactionTypeCode || '',
                  transferSubAccountId:
                    props.searchParams.transferSubAccountId || '',
                  unitsFrom: props.searchParams.unitsFrom || '',
                  unitsTo: props.searchParams.unitsTo || ''
                }}
                onSubmit={handleSubmit}>
                <TransactionsFiltersForm
                  accountLevel={props.accountLevel}
                  transactionStatus={TransactionStatus.Pending}
                />
              </Formik>
            ) : undefined
          }
          gridRef={gridRef}
          onPageChanged={props.setPage}
          onPageSizeChanged={props.setPageSize}
          onSortChanged={handleSortChanged}
          page={props.page}
          pageSize={props.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={pendingTransactionsQuery.data?.pagination?.total}
          rowData={pendingTransactionsQuery.data?.results || []}
          sort={
            orderBy
              ? [
                  {
                    colId: orderBy,
                    sort: orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </>
  );
};
