import { useSnackbar } from '@/contexts/SnackBarContext';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';

import { FC, useState } from 'react';

type ConfirmFlatteningTradesDialogProps = {
  open: boolean;
  onClose: () => void;
  subAccountId: string;
  subAccountName: string;
};

export const ConfirmFlatteningTradesDialog: FC<
  ConfirmFlatteningTradesDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const confirmFlatteningMutation = async subAccountId => {
    setIsLoading(true);
    try {
      await WorkflowService.flattenOperationalSubAccount(subAccountId);
      showSnackbar({
        message: `Flattening trades request has been successfully sent`,
        severity: 'success'
      });
      props.onClose();
    } catch (error) {
      showSnackbar({
        message:
          error?.message ||
          `Couldn't send flattening trades request at this time`,
        severity: 'error'
      });
    }
    setIsLoading(false);
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Flattening Trades</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Alert
            data-testid='flatten-request-confirmation-summary'
            icon={false}
            severity='info'>
            <AlertTitle>{props.subAccountName}</AlertTitle>
            <Typography variant='body2'>ID: {props.subAccountId}</Typography>
          </Alert>
          <Typography>
            The flattening process will create trades to bring every position in
            this subAccount to zero. If the position has negative shares it will
            buy shares, if it has positive shares it will sell. It does not
            proactively touch the cash balance in the account.
          </Typography>
          <Typography>
            <b>
              Once the trades have been executed you will need to flatten the
              cash position using the cash transfer feature.
            </b>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='confirm-flattening-trades-dialog-cancel-button'
          onClick={props.onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid='confirm-flattening-trades-dialog-proceed-button'
          loading={isLoading}
          onClick={() => {
            confirmFlatteningMutation(props.subAccountId);
          }}
          variant='contained'>
          Proceed
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmFlatteningTradesDialog.displayName = 'ConfirmFlatteningTradesDialog';
