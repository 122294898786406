import dayjs from 'dayjs';

import ApiService from './Api.service';

export class ReportingService {
  static async getSigmaUrl(
    planId: number,
    dateRange: object,
    reportType: string,
    tpaId: number
  ) {
    try {
      const sigmaUrl = await ApiService.getJson<string>(
        `/reporting/${planId}`,
        {
          endDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
          reportType,
          startDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
          tpaId
        }
      );

      if (!sigmaUrl) {
        throw new Error(`Unable to generate Sigma URL`);
      }

      return sigmaUrl;
    } catch (error) {
      throw new Error(`Unable to generate Sigma URL: ${error.message}`);
    }
  }
}
