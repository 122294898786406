import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { PendingRequestDto } from '@/models/suba/pending-requests/PendingRequestDTO.model';
import { PendingRequestSearchRequest } from '@/models/suba/pending-requests/PendingRequestSearchRequest.model';
import ApiService from '@/services/Api.service';

class PendingRequestService {
  static async search(
    params: PendingRequestSearchRequest
  ): Promise<PaginatedApiResponse<PendingRequestDto[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<PendingRequestDto[]>
    >(`pending-requests/`, params);
    return result;
  }

  static async resendToCustodian(id: number): Promise<void> {
    const result = await ApiService.patchJson<undefined, void>(
      `pending-requests/resend-to-custodian/${id}`,
      undefined
    );
    return result;
  }
}

export default PendingRequestService;
