import Badge from '@/components/badge/Badge.component';
import { DatePicker } from '@/components/date-picker';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import Tree from '@/components/tree';
import { TreeItem } from '@/components/tree/Tree.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useGetWorkflowByTracerId } from '@/hooks/suba/useGetWorkflowByTracerId.hook';
import { useParentAccountByCustodianQuery } from '@/hooks/suba/useParentAccountByCustodianQuery.hook';
import { useUpdateAlertMutation } from '@/hooks/suba/useUpdateAlertMutation.hook';
import { InvestRolloverContributionRequest } from '@/models/suba/alerts/InvestRolloverContributionRequest.model';
import { RolloverStatusColorMap } from '@/models/suba/common/RolloverStatusColorMap.model';
import { SubaExecutionStatusColorMap } from '@/models/suba/common/SubaExecutionStatusColorMap.model';
import { SubaExecutionStatusDisplayMap } from '@/models/suba/common/SubaExecutionStatusDisplayMap.model';
import { workflowStatusColorMap } from '@/models/suba/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/suba/workflows/WorkflowStatusEnumObject.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import {
  isSubaExecutionComplete,
  isSubaExecutionFailed,
  isWorkflowComplete,
  isWorkflowFailed
} from '@/routes/suba/common/utils';
import ParticipantService from '@/services/Participant.service';
import AlertService from '@/services/suba/alerts/Alert.service';
import DepositPlanService from '@/services/suba/deposit-plans/DepositPlan.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Unstable_Grid2 as Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { AxiosError } from 'axios';
import Dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { forwardRef, useCallback, useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { CardHeader } from './CardHeader.component';
import { TransferFunds } from './TransferFunds.component';

export const Rollover = forwardRef<HTMLDivElement>((props, ref) => {
  const alert = useContext(AlertContext);
  const cashTransferWorkflowQuery = useGetWorkflowByTracerId(
    alert?.details.cashTransferWorkflowTracerId
  );
  const getParentAccountQuery = useParentAccountByCustodianQuery({
    custodianAccountNumber: alert?.custodianAccountNumber,
    custodianId: alert?.details?.event?.body[0]?.custodianId
  });
  const investContributionWorkflowQuery = useGetWorkflowByTracerId(
    alert?.details.investContributionWorkflowTracerId
  );
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateAlertMutation = useUpdateAlertMutation();

  const handleChangeParticipant = useCallback(() => {
    updateAlertMutation.mutate({
      alertId: alert.id,
      updateRequest: {
        details: {
          investContributionWorkflowTracerId: null,
          investorId: null,
          rolloverId: null
        }
      }
    });
  }, [alert, updateAlertMutation]);

  const handleChangePlan = useCallback(() => {
    updateAlertMutation.mutate({
      alertId: alert.id,
      updateRequest: {
        details: {
          depositPlanId: null
        }
      }
    });
  }, [alert, updateAlertMutation]);

  const investRolloverContributionMutation = useMutation(
    ['AlertService.investRolloverContributionMutation'],
    ({
      alertId,
      params
    }: {
      alertId: number;
      params?: InvestRolloverContributionRequest;
    }) => AlertService.investRolloverContribution(alertId, params),
    {
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to invest funds: ${message}`,
          severity: 'error'
        });
      },
      onSuccess: updatedAlert => {
        showSnackbar({ message: 'Trade request submitted' });
        queryClient.setQueryData(
          ['AlertService.getById', updatedAlert.id],
          updatedAlert
        );
        queryClient.invalidateQueries([
          'ParticipantService.getRollover',
          alert?.details?.rolloverId
        ]);
      }
    }
  );

  const depositPlanQuery = useQuery(
    ['DepositPlanService.get', alert?.details?.depositPlanId],
    () => DepositPlanService.get(alert?.details?.depositPlanId.toString()),
    {
      enabled: Boolean(alert?.details?.depositPlanId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get plan details: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const participantQuery = useQuery(
    ['ParticipantService.getParticipantById', alert?.details?.investorId],
    () => ParticipantService.getParticipantById(alert?.details?.investorId),
    {
      enabled: Boolean(alert?.details?.investorId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get participant details: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const rolloverQuery = useQuery(
    ['ParticipantService.getRollover', alert?.details?.rolloverId],
    () =>
      ParticipantService.getRollover(
        +alert?.details?.investorId,
        +alert?.details?.rolloverId
      ),
    {
      enabled: Boolean(
        alert?.details?.investorId && alert?.details?.rolloverId
      ),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get rollover details: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const tradeRequestQuery = useQuery(
    [
      'ParticipantService.getRolloverTradeRequests',
      alert?.details?.rolloverId,
      alert?.details?.investContributionWorkflowTracerId
    ],
    async () => {
      const rolloverTradeRequests =
        await ParticipantService.getRolloverTradeRequests(
          +alert?.details?.investorId,
          +alert?.details?.rolloverId
        );

      if (!Array.isArray(rolloverTradeRequests)) return null;

      return (
        rolloverTradeRequests.find(
          rolloverTradeRequest =>
            rolloverTradeRequest.context?.rolloverId.toString() ===
            alert?.details?.rolloverId
        ) || null
      );
    },
    {
      enabled: Boolean(
        alert?.details?.investorId &&
          alert?.details?.rolloverId &&
          alert?.details?.investContributionWorkflowTracerId
      ),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get rollover trade requests: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const isCashTransferComplete = isWorkflowComplete(
    cashTransferWorkflowQuery.data?.workflowStatus
  );
  const isHouseAccount =
    getParentAccountQuery.data?.accountType === ParentAccountType.House;
  const isRothRollover = Boolean(
    rolloverQuery.data?.data.attributes.rothAmount
  );

  const RolloverInvestmentSchema = useMemo(
    () =>
      Yup.object(
        isRothRollover &&
          !['Canceled', 'Expired'].includes(
            rolloverQuery.data?.data.attributes.status
          )
          ? {
              rothBasis: Yup.number().positive(),
              rothEstablishedDate: Yup.date()
                .min(
                  Dayjs().subtract(50, 'years').toDate(),
                  'Must be in the past 50 years'
                )
                .max(new Date())
            }
          : undefined
      ),
    [isRothRollover]
  );

  return (
    <Card data-testid='rollover-card' ref={ref}>
      <CardHeader
        action={
          <Link
            data-testid='rollover-link'
            target='_blank'
            to={`/participant/${alert?.details?.investorId}/rollovers/${alert?.details?.rolloverId}`}>
            View rollover
          </Link>
        }
        title={
          <>
            Rollover ID: {alert?.details?.rolloverId}
            {rolloverQuery.data?.data.attributes.status && (
              <Badge
                color={
                  RolloverStatusColorMap[
                    rolloverQuery.data?.data.attributes.status
                  ]
                }
                data-testid='rollover-status'
                size='small'>
                {rolloverQuery.data?.data.attributes.status}
              </Badge>
            )}
          </>
        }
      />
      <Divider />
      <CardContent>
        {alert === undefined ||
        cashTransferWorkflowQuery.isInitialLoading ||
        depositPlanQuery.isInitialLoading ||
        investContributionWorkflowQuery.isInitialLoading ||
        participantQuery.isInitialLoading ||
        rolloverQuery.isInitialLoading ||
        tradeRequestQuery.isInitialLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Stack spacing={2}>
              <Grid columns={13} container spacing={2}>
                <Grid xs={6}>
                  <TextStack direction='column'>
                    <TextStackItem>
                      <TextLabel>Participant</TextLabel>
                      <TextValue
                        data-testid='rollover-participant'
                        links={
                          alert?.details?.investorId
                            ? [
                                {
                                  'data-testid': 'rollover-participant-link',
                                  label: `ID: ${alert?.details?.investorId}`,
                                  target: '_blank',
                                  to: `/participants/${alert?.details?.investorId}`
                                }
                              ]
                            : undefined
                        }>
                        {participantQuery.data?.firstName}{' '}
                        {participantQuery.data?.lastName}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Plan</TextLabel>
                      <TextValue
                        data-testid='rollover-plan'
                        links={
                          alert?.details?.depositPlanId
                            ? [
                                {
                                  'data-testid': 'plan-link',
                                  label: `ID: ${alert?.details?.depositPlanId}`,
                                  target: '_blank',
                                  to: `/plans/${alert?.details?.depositPlanId}`
                                }
                              ]
                            : undefined
                        }>
                        {depositPlanQuery.data?.planName}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>
                        Previous Plan
                        <br />
                        Provider
                      </TextLabel>
                      <TextValue data-testid='rollover-previous-plan-provider'>
                        {rolloverQuery.data?.data.attributes.accountProvider}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </Grid>
                <Grid display='flex' justifyContent='center' xs={1}>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid xs={6}>
                  <TextStack direction='column'>
                    <TextStackItem>
                      <TextLabel>Type</TextLabel>
                      <TextValue data-testid='rollover-type'>
                        {isRothRollover ? 'Roth' : 'Pre-tax'}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Amount</TextLabel>
                      <TextValue data-testid='rollover-amount'>
                        {formatters.formatDollars(
                          rolloverQuery.data?.data.attributes.rothAmount ||
                            rolloverQuery.data?.data.attributes.preTaxAmount
                        )}
                      </TextValue>
                    </TextStackItem>
                    {isRothRollover && (
                      <TextStackItem>
                        <TextLabel>Roth Basis</TextLabel>
                        <TextValue
                          data-testid='rollover-roth-basis'
                          tooltip={
                            rolloverQuery.data?.data.attributes
                              ?.rothCostBasis &&
                            (!alert?.details
                              ?.investContributionWorkflowTracerId ||
                              isSubaExecutionFailed(
                                tradeRequestQuery.data?.status
                              ))
                              ? 'Unconfirmed'
                              : undefined
                          }>
                          {rolloverQuery.data?.data.attributes.rothCostBasis
                            ? formatters.formatDollars(
                                rolloverQuery.data?.data.attributes
                                  ?.rothCostBasis
                              )
                            : undefined}
                        </TextValue>
                      </TextStackItem>
                    )}
                    {isRothRollover && (
                      <TextStackItem>
                        <TextLabel>Roth Established Date</TextLabel>
                        <TextValue
                          data-testid='rollover-roth-established-date'
                          tooltip={
                            rolloverQuery.data?.data.attributes
                              ?.rothStartDate &&
                            (!alert?.details
                              ?.investContributionWorkflowTracerId ||
                              isSubaExecutionFailed(
                                tradeRequestQuery.data?.status
                              ))
                              ? 'Unconfirmed'
                              : undefined
                          }>
                          {rolloverQuery.data?.data.attributes.rothStartDate
                            ? formatters.formatFromIsoDateCustom(
                                rolloverQuery.data?.data.attributes
                                  .rothStartDate,
                                'M/D/YYYY'
                              )
                            : undefined}
                        </TextValue>
                      </TextStackItem>
                    )}
                  </TextStack>
                </Grid>
              </Grid>
              {isHouseAccount && (
                <>
                  <TransferFunds
                    onChangePlan={handleChangePlan}
                    prompt='Funds must be transferred to the plan’s account before they can be invested.'
                  />
                  {alert?.details.cashTransferWorkflowTracerId &&
                    !isCashTransferComplete && (
                      <Alert severity='warning'>
                        Rollover investment options will become available once
                        the transfer is complete.
                      </Alert>
                    )}
                </>
              )}
              {((isHouseAccount && isCashTransferComplete) ||
                !isHouseAccount) && (
                <Formik
                  initialValues={{
                    rothBasis:
                      rolloverQuery.data?.data.attributes.rothCostBasis || '',
                    rothEstablishedDate:
                      rolloverQuery.data?.data.attributes?.rothStartDate || ''
                  }}
                  onSubmit={async formValues => {
                    investRolloverContributionMutation.mutate({
                      alertId: alert.id,
                      params: {
                        rothBasis: formValues.rothBasis
                          ? +formValues.rothBasis
                          : undefined,
                        rothEstablishedDate: formValues.rothEstablishedDate
                          ? formValues.rothEstablishedDate
                          : undefined // remove if initial '' value remains
                      }
                    });
                  }}
                  validationSchema={RolloverInvestmentSchema}>
                  {({ errors, isValid, touched }) => (
                    <Form>
                      <Alert
                        action={
                          isSubaExecutionComplete(
                            tradeRequestQuery.data?.status
                          ) &&
                          alert?.alertStatus !== AlertStatus.Closed && (
                            <Button
                              data-testid='rollover-close-ticket-button'
                              onClick={() => {
                                updateAlertMutation.mutate({
                                  alertId: alert.id,
                                  updateRequest: {
                                    alertStatus: AlertStatus.Closed
                                  }
                                });
                              }}
                              size='small'>
                              Close Ticket
                            </Button>
                          )
                        }
                        icon={false}
                        severity={
                          isSubaExecutionFailed(tradeRequestQuery.data?.status)
                            ? 'warning'
                            : isSubaExecutionComplete(
                                  tradeRequestQuery.data?.status
                                )
                              ? 'success'
                              : 'info'
                        }
                        variant='outlined'>
                        <AlertTitle>Invest Rollover</AlertTitle>
                        <Tree spacing={1}>
                          {tradeRequestQuery.data && (
                            <TreeItem>
                              <Stack
                                alignItems='center'
                                direction='row'
                                spacing={1}>
                                <Typography variant='body2'>
                                  Trade request has been submitted.
                                </Typography>
                                <Badge
                                  color={
                                    SubaExecutionStatusColorMap[
                                      tradeRequestQuery.data.status
                                    ] || 'neutral'
                                  }
                                  size='small'>
                                  {SubaExecutionStatusDisplayMap[
                                    tradeRequestQuery.data.status
                                  ] || tradeRequestQuery.data.status}
                                </Badge>
                              </Stack>
                            </TreeItem>
                          )}
                          {investContributionWorkflowQuery.data && (
                            <TreeItem>
                              <Stack
                                alignItems='center'
                                direction='row'
                                spacing={1}>
                                <Link
                                  data-testid='invest-contribution-workflow-link'
                                  target='_blank'
                                  to={`/ops/workflows/${investContributionWorkflowQuery.data.id}`}
                                  typography={{ variant: 'body2' }}>
                                  Tracer ID:{' '}
                                  {
                                    alert.details
                                      .investContributionWorkflowTracerId
                                  }
                                </Link>
                                {investContributionWorkflowQuery.data
                                  .workflowStatus && (
                                  <Badge
                                    color={
                                      workflowStatusColorMap[
                                        investContributionWorkflowQuery.data
                                          ?.workflowStatus
                                      ]
                                    }
                                    size='small'>
                                    {formatters.displayCase(
                                      formatters.getValueKey(
                                        WorkflowStatusEnumsObject,
                                        investContributionWorkflowQuery.data
                                          .workflowStatus
                                      )
                                    )}
                                  </Badge>
                                )}
                              </Stack>
                            </TreeItem>
                          )}
                          {isRothRollover &&
                          ['Canceled', 'Expired'].includes(
                            rolloverQuery.data?.data.attributes.status
                          ) &&
                          !alert?.details.investContributionWorkflowTracerId ? (
                            <Alert
                              data-testid='uneditable-roth-details-alert'
                              severity='warning'>
                              Roth details for{' '}
                              {rolloverQuery.data?.data.attributes.status.toLowerCase()}{' '}
                              rollovers are not editable.
                            </Alert>
                          ) : isRothRollover &&
                            !alert?.details
                              .investContributionWorkflowTracerId ? (
                            <>
                              <Typography variant='body2'>
                                Confirm Roth Basis & Roth Year. Use the value on
                                the check.
                              </Typography>
                              <Stack direction='row' spacing={2}>
                                <FormControl
                                  error={
                                    touched.rothBasis &&
                                    Boolean(errors.rothBasis)
                                  }
                                  size='small'>
                                  <InputLabel htmlFor='roth-basis-input'>
                                    Roth Basis
                                  </InputLabel>
                                  <Field
                                    as={OutlinedInput}
                                    autoComplete='off'
                                    color='secondary'
                                    id='roth-basis-input'
                                    inputProps={{
                                      'data-testid': 'roth-basis-input'
                                    }}
                                    label='Roth Basis'
                                    name='rothBasis'
                                    startAdornment={
                                      <InputAdornment position='start'>
                                        $
                                      </InputAdornment>
                                    }
                                    type='number'
                                  />
                                  <FormHelperText>
                                    {touched.rothBasis && errors.rothBasis}
                                  </FormHelperText>
                                </FormControl>
                                <FormControl
                                  error={Boolean(errors.rothEstablishedDate)}
                                  size='small'>
                                  <Field
                                    as={DatePicker}
                                    autoComplete='off'
                                    data-testid='roth-established-date-picker'
                                    disableFuture
                                    hideHelperText
                                    label='Roth Established Date'
                                    minDate={Dayjs().subtract(50, 'years')}
                                    name='rothEstablishedDate'
                                    size='small' // FormControl doesn't pass to our DatePicker
                                    variant='outlined'
                                  />
                                  <FormHelperText>
                                    {errors.rothEstablishedDate}
                                  </FormHelperText>
                                </FormControl>
                              </Stack>
                            </>
                          ) : null}
                          {isWorkflowFailed(
                            investContributionWorkflowQuery.data?.workflowStatus
                          ) ? (
                            <Stack direction='row' spacing={1}>
                              <LoadingButton
                                data-testid='rollover-retry-invest-button'
                                loading={
                                  investRolloverContributionMutation.isLoading
                                }
                                size='small'
                                type='submit'
                                variant='contained'>
                                Try Again
                              </LoadingButton>
                              {!investRolloverContributionMutation.isLoading && (
                                <Button
                                  data-testid='rollover-change-participant-button'
                                  onClick={handleChangeParticipant}
                                  size='small'>
                                  Change Participant
                                </Button>
                              )}
                            </Stack>
                          ) : !alert?.details
                              ?.investContributionWorkflowTracerId ? (
                            <Stack direction='row' spacing={1}>
                              <LoadingButton
                                data-testid='rollover-invest-button'
                                disabled={!isValid}
                                loading={
                                  investRolloverContributionMutation.isLoading
                                }
                                size='small'
                                type='submit'
                                variant='contained'>
                                Confirm & Invest
                              </LoadingButton>
                              {!investRolloverContributionMutation.isLoading && (
                                <Button
                                  data-testid='rollover-change-participant-button'
                                  onClick={handleChangeParticipant}
                                  size='small'>
                                  Change Participant
                                </Button>
                              )}
                            </Stack>
                          ) : null}
                        </Tree>
                      </Alert>
                    </Form>
                  )}
                </Formik>
              )}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
});

Rollover.displayName = 'Rollover';
