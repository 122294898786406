import Badge from '@/components/badge';
import Card, { CardContent } from '@/components/card';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertDto } from '@/models/suba/alerts/AlertDTO.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import SecurityService from '@/services/suba/security/Security.service';
import formatters from '@/utils/Formatters';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';

import { AxiosError } from 'axios';

const CustodianRejectStatusColorMap: Record<
  string,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  CUSTODIAN_REJECTED: 'warning'
};
const DestinationRejectStatusColorMap: Record<
  string,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  DESTINATION_REJECTED: 'warning'
};

type CustodianDestinationRejectAlertDetailFieldsProps = {
  alert: AlertDto;
};

export const CustodianDestinationRejectAlertDetailFields = ({
  alert
}: CustodianDestinationRejectAlertDetailFieldsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const displayType = formatters.getValueKey(AlertType, alert.alertType);
  const displaySubType = formatters.getValueKey(
    AlertSubType,
    alert.alertSubType
  );

  const getSecurityQuery = useQuery(
    ['SecurityService.get', alert.details?.trade?.cusip],
    () => SecurityService.get(alert.details.trade.cusip),
    {
      enabled: Boolean(alert.details?.trade?.cusip),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting security: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      alert.details.trade?.custodianAccountNumber,
      alert.details.trade?.custodianId
    ],
    () =>
      ParentAccountService.get({
        custodianAccountNumber: alert.details.trade.custodianAccountNumber,
        custodianId: alert.details.trade.custodianId
      }),
    {
      enabled:
        Boolean(alert.details.trade?.custodianAccountNumber) &&
        Boolean(alert.details.trade?.custodianId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting Parent account: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const planLinks = [];
  if (getParentAccountQuery.data?.planId) {
    planLinks.push({
      label: `ID: ${getParentAccountQuery.data.planId}`,
      target: '_blank',
      to: `/plans/${getParentAccountQuery.data.planId}/plan`
    });
  }
  if (getParentAccountQuery.data?.parentAccountId) {
    planLinks.push({
      label: 'View Parent Acct.',
      target: '_blank',
      to: `/ops/accounts/${getParentAccountQuery.data.parentAccountId}/sub-accounts`
    });
  }

  return (
    <Stack direction='column' id='alert-detail-fields' spacing={1}>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Type / Sub Type</TextLabel>
          <TextValue data-testid='custodian-destination-reject-type-value'>
            {formatters.displayCase(displayType)} /{' '}
            {formatters.displayCase(displaySubType)}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Plan</TextLabel>
          <TextValue
            data-testid='custodian-destination-reject-plan-value'
            links={planLinks}>
            {getParentAccountQuery.data?.plan?.name}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Custodian Acct.</TextLabel>
          <TextValue data-testid='custodian-destination-reject-custodian-value'>
            {`${alert.details?.trade.custodianId}: ${alert.details?.trade.custodianAccountNumber}`}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Order ID</TextLabel>
          <TextValue data-testid='custodian-destination-reject-order-id-value'>
            <Link
              target='_blank'
              to={
                '/ops/parent-account-orders/' +
                alert.details.trade.parentOrderId
              }>
              {alert.details.trade.parentOrderId}
            </Link>
          </TextValue>
        </TextStackItem>
        {alert.details.trade.cusip && (
          <TextStackItem>
            <TextLabel>Security</TextLabel>
            <TextValue
              data-testid='custodian-destination-reject-security-value'
              links={[
                {
                  label: formatters.displayCase(
                    getSecurityQuery.data?.description ?? 'Details'
                  ),
                  target: '_blank',
                  to: `/ops/securities/${alert.details.trade.cusip}`
                }
              ]}>
              {formatters.formatSecurityName(
                getSecurityQuery.data?.symbol,
                alert.details.trade.cusip
              )}
            </TextValue>
          </TextStackItem>
        )}
        <TextStackItem>
          <TextLabel>Status</TextLabel>
          <TextValue data-testid='custodian-destination-reject-status-value'>
            <Stack spacing={1}>
              <Badge
                color={
                  (alert.alertSubType === AlertSubType.CustodianReject
                    ? CustodianRejectStatusColorMap
                    : DestinationRejectStatusColorMap)[
                    alert.details?.trade?.status
                  ]
                }
                maxWidth='fit-content'>
                {formatters.displayCase(
                  formatters.snakeToCamelCase(alert.details?.trade?.status)
                )}
              </Badge>
              <Typography color='textSecondary' variant='body2'>
                {alert.details?.trade?.statusReason}
              </Typography>
            </Stack>
          </TextValue>
        </TextStackItem>
      </TextStack>
      <Card>
        <CardContent>
          <Stack direction='column' spacing={1}>
            <Typography color='black' variant='subtitle2'>
              Trade details
            </Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Trade Date</TextLabel>
                <TextValue>
                  {alert.details.trade?.tradeDate
                    ? formatters.formatFromIsoDateCustom(
                        alert.details.trade.tradeDate,
                        'MM/DD/YYYY'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Trate Order Type</TextLabel>
                <TextValue>
                  {formatters.displayCase(alert.details.trade?.tradeType)}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Units</TextLabel>
                <TextValue>
                  {formatters.formatDecimal(alert.details.trade?.tradeUnits, 3)}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
        </CardContent>
      </Card>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Created</TextLabel>
          <TextValue data-testid='custodian-destination-reject-created-value'>
            {alert.createdAt
              ? formatters.formatFromIsoDateCustom(
                  alert.createdAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Last Updated</TextLabel>
          <TextValue data-testid='custodian-destination-reject-updated-value'>
            {alert.updatedAt
              ? formatters.formatFromIsoDateCustom(
                  alert.updatedAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  );
};
