import { useEffect, useState } from 'react';
import { useTitle as useReactUseTitle } from 'react-use';

export const useTitle = (initialPageName = '') => {
  const [pageName, setPageName] = useState(() => initialPageName);

  useEffect(() => {
    if (initialPageName) setPageName(initialPageName);
  }, [initialPageName]);

  useReactUseTitle(
    pageName && !pageName.toLowerCase().includes('undefined')
      ? `${pageName} - Service Portal - Vestwell`
      : 'Service Portal - Vestwell'
  );

  return { setPageName };
};
