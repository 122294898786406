import { DividendDeclarationDto } from '@/models/suba/dividend-declarations/DividendDeclarationDto.model';
import { DividendDeclarationOverallStatus } from '@/models/suba/dividend-declarations/DividendDeclarationOverallStatus.model';
import { DividendActivityQueueStatus } from '@vestwell-sub-accounting/models/common/HoldingTankStatus';

export const determineStatusAndActionsByActivity = (
  dividendDeclaration: DividendDeclarationDto
): {
  overallStatus: DividendDeclarationOverallStatus;
  canViewParentAccounts: boolean;
  canCalculate: boolean;
  canReverse: boolean;
  canRecalculate: boolean;
} => {
  if (!dividendDeclaration.dividendActivities?.length) {
    return {
      canCalculate: false,
      canRecalculate: false,
      canReverse: false,
      canViewParentAccounts: false,
      overallStatus: 'pending'
    };
  } else if (
    dividendDeclaration.dividendActivities.every(activity => {
      return activity.status === DividendActivityQueueStatus.noDivDeclFound;
    })
  ) {
    return {
      canCalculate: true,
      canRecalculate: false,
      canReverse: false,
      canViewParentAccounts: true,
      overallStatus: 'pending'
    };
  } else if (
    dividendDeclaration.dividendActivities.every(activity => {
      return activity.status === DividendActivityQueueStatus.finished;
    })
  ) {
    return {
      canCalculate: false,
      canRecalculate: true,
      canReverse: true,
      canViewParentAccounts: true,
      overallStatus: 'finished'
    };
  } else if (
    dividendDeclaration.dividendActivities.every(activity => {
      return activity.status === DividendActivityQueueStatus.failed;
    })
  ) {
    return {
      canCalculate: false,
      canRecalculate: true,
      canReverse: false,
      canViewParentAccounts: true,
      overallStatus: 'failed'
    };
  } else if (
    dividendDeclaration.dividendActivities.every(activity => {
      return activity.status === DividendActivityQueueStatus.reversed;
    })
  ) {
    return {
      canCalculate: true,
      canRecalculate: false,
      canReverse: false,
      canViewParentAccounts: false,
      overallStatus: 'reversed'
    };
  } else {
    return {
      canCalculate: false,
      canRecalculate: false,
      canReverse: false,
      canViewParentAccounts: true,
      overallStatus: 'varies'
    };
  }
};

export const doesNotExceedPrecision =
  (precision: number) => (value?: number) => {
    const regex = new RegExp(`^-?\\d+(\\.\\d{0,${precision}})?$`);
    return regex.test(value?.toString() || '');
  };

export const hasExactPrecision =
  (numberOfDecimals: number) => (value?: number) =>
    new RegExp(
      `^-?\\s*(?=.*[0-9])\\d*(?:\\.\\d{1,${numberOfDecimals}})?\\s*$`
    ).test(value !== undefined ? value.toString() : '');
