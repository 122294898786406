import { PriceDto } from '@/models/suba/prices/PriceDTO.model';
import ApiService from '@/services/Api.service';

export type PriceSearchResponse = {
  results: PriceDto[];
  pagination: {
    pageSize: number;
    total: number;
  };
};

export type PriceSearchParams = {
  cusip: string;
  effectiveStartDate?: string;
  effectiveEndDate?: string;
  orderBy?: keyof PriceDto;
  orderByDirection?: 'asc' | 'desc';
  page?: number;
  pageSize?: number;
};

class PriceService {
  static async search(params: PriceSearchParams): Promise<PriceSearchResponse> {
    for (const [key, value] of Object.entries(params)) {
      // filter undefined params to avoid request validation errors
      if (value === undefined || value === '')
        delete params[key as keyof PriceSearchParams];
    }

    return ApiService.getJson<PriceSearchResponse>(`/prices`, {
      cusip: params.cusip,
      effectiveEndDate: params.effectiveEndDate,
      effectiveStartDate: params.effectiveStartDate,
      orderBy: params.orderBy,
      orderByDirection: params.orderByDirection,
      page: params.page,
      pageSize: params.pageSize
    });
  }
}

export default PriceService;
