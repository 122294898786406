import { useParentAccountByCustodianQuery } from '@/hooks/suba/useParentAccountByCustodianQuery.hook';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import TransactionService from '@/services/suba/transactions/Transaction.service';
import { Alert, AlertTitle, Grow, LinearProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { FC, useContext } from 'react';

import { ChooseTransactionType } from './common/ChooseTransactionType.component';
import { DepositPlan } from './common/DepositPlan.component';
import { IdentifyPlan } from './common/IdentifyPlan.component';
import { UploadCheckImage } from './common/UploadCheckImage.component';

export const NewConversionDepositExpandedAlertDetails: FC = () => {
  const alert = useContext(AlertContext);

  // a new transaction is created whenever updated transactions are reversed
  const originalParentAccountTransactionId =
    alert?.details?.event?.transactionIds[0];
  const originalSubAccountTransactionId =
    alert?.details?.event?.transactionIds[1];

  const updatedParentAccountTransactionId =
    alert?.details?.updatedParentAccountTransactionId;
  const updatedSubAccountTransactionId =
    alert?.details?.updatedSubAccountTransactionId;

  const { data: parentAccount } = useParentAccountByCustodianQuery({
    custodianAccountNumber: alert?.custodianAccountNumber,
    custodianId: alert?.details?.event?.body[0]?.custodianId
  });

  const isHouseAccount = parentAccount?.accountType === ParentAccountType.House;

  const depositPlanId = isHouseAccount
    ? alert?.details?.depositPlanId
    : parentAccount?.planId;

  const checkImageDocumentId =
    // TODO: SUB-3571 — check images should be associated with the latest transaction but we do not update the document following a workflow execution yet
    // once we do, add a useGetPlanDepositTransactions call and use the returned ID here, prioritized over the others
    updatedParentAccountTransactionId || originalParentAccountTransactionId;

  const { data: checkImageDocument } = useQuery(
    ['TransactionService.getCheckImageDocument', checkImageDocumentId],
    () => TransactionService.getCheckImageDocument(checkImageDocumentId),
    {
      enabled: Boolean(checkImageDocumentId)
    }
  );

  if (!alert) return <LinearProgress />;

  if (
    !originalParentAccountTransactionId &&
    !originalSubAccountTransactionId &&
    !updatedParentAccountTransactionId &&
    !updatedSubAccountTransactionId
  ) {
    return (
      <Alert data-testid='missing-transaction-ids-alert' severity='error'>
        <AlertTitle>No further details or actions are available</AlertTitle>
        This alert is missing a required transaction ID
      </Alert>
    );
  }

  // Users must upload a check image before they can choose a transaction type
  return (
    <Stack spacing={2}>
      <UploadCheckImage alert={alert} />
      <Grow
        in={
          Boolean(checkImageDocument) &&
          alert.alertStatus !== AlertStatus.Closed
        }
        unmountOnExit>
        <ChooseTransactionType />
      </Grow>
      <Grow
        in={isHouseAccount && checkImageDocument && !depositPlanId}
        unmountOnExit>
        <IdentifyPlan isConversion title='Identify Conversion Plan' />
      </Grow>
      <Grow in={Boolean(depositPlanId)} unmountOnExit>
        <DepositPlan
          canChangePlan={isHouseAccount}
          canTransferFunds={isHouseAccount}
          planId={depositPlanId}
        />
      </Grow>
    </Stack>
  );
};
