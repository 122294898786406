import AccessControl from '@/components/access-control/AccessControl.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import { useUserToken } from '@/contexts/UserTokenContext';
import { TpaDto } from '@/models/TpaDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { userService } from '@/services/User.service';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import EditTPAButton from '../tpa-main/EditTPAButton.component';
import ManageTPAUsersTable from './manage-tpa-users/ManageTPAUsersTable.component';
import { TpaPagePlanStats } from './tpa-page-plan-stats/TpaPagePlanStats.component';
import TpaPlansTable from './tpa-plans/TpaPlansTable.component';

export type TPADetailRouteProps = {
  tpaId: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginTop: theme.spacing(2)
    }
  })
);

const TpaDetailPage: React.FunctionComponent = (): JSX.Element => {
  const classes = useStyles();
  const { userHasValidToken } = useUserToken();
  const { tpaId } = useParams<TPADetailRouteProps>();
  const { pathname } = useLocation();
  const hasRequiredAccess = userService.hasPermission(
    FeatureLevelPermissions.READ_TPA
  );
  const userDoesNotHaveAccess = userHasValidToken && !hasRequiredAccess;

  const tpaQuery = useQuery<TpaDto>(
    ['TpaService.getTpa', tpaId],
    () => {
      return TpaService.getTpa(+tpaId);
    },
    {
      enabled: Boolean(tpaId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const tpa = tpaQuery.data || ({} as TpaDto);
  const { error } = tpaQuery;

  if (userDoesNotHaveAccess) {
    return redirectToErrorPage(new Error('403'));
  }

  // alex: do not show TPA 42 as they’re not actually a TPA
  // redirecting to main tpa page
  if (userHasValidToken && pathname === '/tpa/42') {
    return <Navigate to={{ pathname: `/` }} />;
  }

  if (error) {
    return redirectToErrorPage(error as Error);
  }

  return (
    <>
      {tpaQuery.isFetching && <LinearLoading />}
      {tpaQuery.isSuccess && tpa.data && (
        <>
          <Typography
            className={classes.header}
            component='div'
            data-testid='tpa-detail-page-header'
            variant='h4'>
            {tpa.data.attributes?.name}
            <EditTPAButton
              initialValue={tpa.data.attributes?.name}
              size='medium'
              tpaId={tpa.data.id}
            />
          </Typography>
          <TpaPagePlanStats tpaId={tpa.data.id} />
          <ManageTPAUsersTable userGroups={tpa.data?.userGroupNames} />
          <AccessControl
            hideFromTPA
            requires={[FeatureLevelPermissions.WRITE_TPA_DETAILS_PLANS]}>
            <TpaPlansTable tpaId={Number(tpa.data?.id)} />
          </AccessControl>
        </>
      )}
    </>
  );
};

export default TpaDetailPage;
