import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell
} from '@/components/data-table/DataTable.component';
import CorrectedIcon from '@/components/icon/CorrectedIcon';
import { ContributionForTable } from '@/models';
import { ContributionStatusColorMap } from '@/models/suba/alerts/ContributionStatusColorMap.model';
import { ParentAccountDto } from '@/models/suba/ParentAccountDTO.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import { PlanService } from '@/services/Plan.service';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import Decimal from 'decimal.js';
import { FC, useContext, useMemo } from 'react';

import { CardHeader } from '../common/CardHeader.component';
import { AchReversalContext } from './AchReversalContext';

export const AchReversalExpandedRecentContributions: FC = () => {
  const alert = useContext(AlertContext);
  const alertBody = alert.details.event.body[0];
  const { setContext } = useContext(AchReversalContext);

  const parentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      {
        custodianAccountNumber: alertBody.custodianAccountNumber,
        custodianId: alertBody.custodianId
      }
    ],
    (): Promise<ParentAccountDto> => {
      return ParentAccountService.get({
        custodianAccountNumber: alertBody.custodianAccountNumber,
        custodianId: alertBody.custodianId
      });
    }
  );

  const contributionsQuery = useQuery(
    ['PlanService.getContributions', Number(parentAccountQuery?.data?.planId)],
    async () => {
      if (!parentAccountQuery?.data?.planId) return [];
      return await PlanService.getContributions(
        Number(parentAccountQuery?.data?.planId)
      );
    }
  );

  const contributionsByMatchingAmount = useMemo(() => {
    if (contributionsQuery?.data?.length) {
      return formatters
        .formatContributionsResponseForOpsGrid(contributionsQuery.data)
        .filter(contribution => {
          const contributionAmount = new Decimal(contribution.total).abs();
          const matchingAmount = new Decimal(alertBody.amount).abs();
          return contributionAmount.equals(matchingAmount);
        });
    }
  }, [contributionsQuery.data, alert]);

  const columnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: ContributionForTable }) => (
        <Link
          data-testid={`contribution-link-${cellData.data.ucid}`}
          href={`/plans/${parentAccountQuery?.data?.planId}/contributions/${cellData.data.ucid}`}
          target='_blank'>
          {formatters.formatFromIsoDateCustom(
            cellData.data.expectedPayrollDate,
            'MM/DD/YYYY'
          )}
          {cellData.data.hasCorrections && (
            <Box
              component='span'
              sx={{
                lineHeight: 1,
                pl: 0.5
              }}>
              <Tooltip title='This contribution has corrections'>
                <CorrectedIcon sx={{ fontSize: 14 }} />
              </Tooltip>
            </Box>
          )}
        </Link>
      ),
      field: 'contributionDate',
      headerName: 'Pay Date',
      minWidth: 90,
      suppressMenu: true
    },
    {
      autoHeight: true,
      field: 'payGroupName',
      headerName: 'Paygroup',
      minWidth: 140,
      suppressMenu: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ContributionForTable }) => (
        <DataTableBadgeCell
          color={ContributionStatusColorMap[cellData.data.processingStatus]}>
          {formatters.displayCase(cellData.data.processingStatus)}
        </DataTableBadgeCell>
      ),
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      suppressMenu: true
    },
    {
      autoHeight: true,
      field: 'amount',
      headerName: 'Total Amount',
      minWidth: 100,
      suppressMenu: true,
      valueFormatter: (cellData: { data: ContributionForTable }) => {
        return formatters.formatDollars(cellData.data.total);
      }
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ContributionForTable }) => (
        <Stack
          alignItems='center'
          data-testid={`select-contribution-button-${cellData.data.ucid}`}
          direction='row'
          onClick={() =>
            setContext({
              selectedContribution: cellData.data
            })
          }>
          <Typography>
            <CheckCircleIcon color='action' />
          </Typography>
        </Stack>
      ),
      headerName: 'Select',
      maxWidth: 80,
      pinned: 'right',
      suppressMenu: true
    }
  ];

  return (
    <Card data-testid='ach-reversal-expanded-recent-contributions-card'>
      <CardHeader
        subheader='Contributions with the same amount are displayed below. Select one that matches this ACH.'
        title='Recent Contributions'></CardHeader>
      <Divider />
      <CardContent
        disablePadding
        loading={contributionsQuery.isLoading || parentAccountQuery.isLoading}>
        <DataTable
          columnDefs={columnDefs}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-alerts-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found'
              />
            </Stack>
          }
          pageSize={3}
          pageSizeOptions={[3]}
          pagination={true}
          rowData={
            contributionsByMatchingAmount?.length
              ? contributionsByMatchingAmount
              : []
          }></DataTable>
      </CardContent>
    </Card>
  );
};

AchReversalExpandedRecentContributions.displayName =
  'AchReversalExpandedRecentContributions';
