import CircularLoading from '@/components/circular-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ProcessingWindow } from '@/models/suba/current-dates-and-windows/ProcessingWindow.model';
import { ParentAccountOrderDto } from '@/models/suba/parent-account-orders/ParentAccountOrderDTO.model';
import CurrentDatesAndWindowsService from '@/services/suba/current-dates-and-windows/CurrentDatesAndWindows.service';
import ParentAccountOrderService from '@/services/suba/parent-account-orders/ParentAccountOrder.service';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ParentAccountOrderInfoBanner } from './ParentAccountOrderInfoBanner.component';

type ResubmitParentAccountOrderDialogProps = {
  open: boolean;
  parentAccountOrder: ParentAccountOrderDto;
  onClose: () => void;
  onResubmit?: () => void;
};

export const ResubmitParentAccountOrderDialog = ({
  open,
  parentAccountOrder,
  onClose,
  onResubmit
}: ResubmitParentAccountOrderDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    data: currentDatesAndWindows,
    isFetching: isCurrentDatesAndWindowsLoading
  } = useQuery(
    ['CurrentDatesAndWindowsService.get'],
    () => CurrentDatesAndWindowsService.get(),
    {
      cacheTime: 0, // keep response as fresh as we can
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch current trade date and processing windows: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const resubmitParentAccountOrderMutation = useMutation(
    ['PendingTransactionService.confirm'],
    () => {
      return ParentAccountOrderService.resubmit(parentAccountOrder.id);
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed to resubmit parent account order ${parentAccountOrder.ourParentOrderId}`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `The selected order has been updated & pending for resubmit.`,
          severity: 'success'
        });
        // Invalidate all queries to ensure any related components reflect these changes
        queryClient.invalidateQueries();
        onClose();
        if (typeof onResubmit === 'function') {
          onResubmit();
        }
      }
    }
  );

  const isSameTradeDate =
    currentDatesAndWindows?.tradingDate === parentAccountOrder.tradeDate;
  const isWindowsOpen =
    currentDatesAndWindows?.processingWindows?.includes(
      ProcessingWindow.ProcessingIndividualOrdersEtf
    ) &&
    currentDatesAndWindows?.processingWindows?.includes(
      ProcessingWindow.ProcessingIndividualOrdersNscc
    );

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        Resubmit Rejected Parent Account Order
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <ParentAccountOrderInfoBanner
          parentAccountOrderId={Number(parentAccountOrder.id)}
        />
        <Box
          aria-busy={isCurrentDatesAndWindowsLoading}
          data-testid='resubmit-summary'
          m='1.5rem'>
          {isCurrentDatesAndWindowsLoading ? (
            <CircularLoading size='40px' />
          ) : (
            <>
              {!isWindowsOpen && (
                <Typography>
                  Action cannot be completed at this time as the Order
                  Processing window is currently closed.
                </Typography>
              )}
              {isWindowsOpen && (
                <>
                  <Typography>Resubmitting this order will:</Typography>
                  <Stack component='ul' spacing={2}>
                    <li>
                      Defer the current versions of the parentAccount Order,
                      subAccount Order(s) and related pending transaction(s)
                    </li>
                    <li>
                      Create new versions of the subAccount Order(s) and pending
                      transactions and requeue them to be aggregated in the next
                      aggregate & send run {isSameTradeDate ? 'today' : ''}
                    </li>
                    {!isSameTradeDate && (
                      <li>
                        This will update the trade date from the original trade.
                        If you prefer to execute the original trade, giving the
                        investor the price as of that day, please use the
                        Manually Execute option to fill the trades and create
                        the appropriate offsets in the error account
                      </li>
                    )}
                  </Stack>
                </>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        {!isWindowsOpen && (
          <Button
            data-testid='ok-button'
            onClick={() => {
              onClose();
            }}>
            OK
          </Button>
        )}
        {isWindowsOpen && (
          <Button
            data-testid='nevermind-button'
            onClick={() => {
              onClose();
            }}>
            Nevermind
          </Button>
        )}
        {isWindowsOpen && (
          <LoadingButton
            data-testid='resubmit-button'
            disabled={isCurrentDatesAndWindowsLoading}
            loading={resubmitParentAccountOrderMutation.isLoading}
            onClick={() => resubmitParentAccountOrderMutation.mutate()}
            variant='contained'>
            Resubmit
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
