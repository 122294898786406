import { Card, CardContent, TextField } from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { get } from 'lodash';
import { FC } from 'react';

import AddressGrid from './AddressGrid.component';

export const AccountHolderCard: FC = () => {
  const formik: FormikContextType<any> = useFormikContext();
  return (
    <Card elevation={0} variant='outlined'>
      <CardContent>
        <AddressGrid keyPrefix='accountHolder' type='' />
        <TextField
          error={
            get(formik.touched, 'accountHolderFederalIdentifier') &&
            Boolean(get(formik.errors, 'accountHolderFederalIdentifier'))
          }
          fullWidth
          helperText={
            (get(formik.touched, 'accountHolderFederalIdentifier') &&
              get(
                formik.errors,
                'accountHolderFederalIdentifier'
              )?.toString()) ||
            ''
          }
          id='accountHolderFederalIdentifier'
          label='Federal Identifier'
          name='accountHolderFederalIdentifier'
          onChange={formik.handleChange}
          required
          size='small'
          value={get(formik.values, 'accountHolderFederalIdentifier')}
          variant='outlined'
        />
      </CardContent>
    </Card>
  );
};
