import { Divider, Stack, Typography } from '@mui/material';

import { FC } from 'react';

type PlanEligibilityDetailsProps = {
  uiSchema: Record<string, any>;
  data: Record<string, any>;
};

type EligibilitySectionProps = {
  leasedEmployees: string;
  minimumAgeRequirement: string;
  lengthOfEmploymentRequired: string;
  entryDateFrequency: string;
  serviceCalculationType: string;
  hoursRequired: string;
  title?: string;
};

const EligibilitySection: FC<EligibilitySectionProps> = props => (
  <Stack>
    {props.title && (
      <Stack display='flex' flexDirection='row' mb={2}>
        <Stack mr={2}>
          <Typography color='gray' variant='subtitle2'>
            {props.title}
          </Typography>
        </Stack>
        <Stack display='flex' flex={1} justifyContent='center'>
          <Divider />
        </Stack>
      </Stack>
    )}
    <Stack display='flex' flexDirection='row'>
      <Stack flex={1} spacing={1}>
        <Stack data-testid='entryDateFrequency-box'>
          <Typography
            color='gray'
            data-testid='entryDateFrequency-label'
            variant='caption'>
            Entry Date Frequency
          </Typography>
          <Typography data-testid='entryDateFrequency-value' variant='body1'>
            {props.entryDateFrequency || '--'}
          </Typography>
        </Stack>
        <Stack data-testid='minimumAgeRequirement-box'>
          <Typography
            color='gray'
            data-testid='minimumAgeRequirement-label'
            variant='caption'>
            Minimum Age Requirement
          </Typography>
          <Typography data-testid='minimumAgeRequirement-value' variant='body1'>
            {props.minimumAgeRequirement || '--'}
          </Typography>
        </Stack>
        <Stack data-testid='serviceCalculationType-box'>
          <Typography
            color='gray'
            data-testid='serviceCalculationType-label'
            variant='caption'>
            Service Calculation Type
          </Typography>
          <Typography
            data-testid='serviceCalculationType-value'
            variant='body1'>
            {props.serviceCalculationType || '--'}
          </Typography>
        </Stack>
      </Stack>

      <Stack flex={1} spacing={1}>
        <Stack data-testid='lengthOfEmploymentRequired-box'>
          <Typography
            color='gray'
            data-testid='lengthOfEmploymentRequired-label'
            variant='caption'>
            Length Of Employment Required
          </Typography>
          <Typography
            data-testid='lengthOfEmploymentRequired-value'
            variant='body1'>
            {props.lengthOfEmploymentRequired || '--'}
          </Typography>
        </Stack>
        <Stack data-testid='hoursRequired-box'>
          <Typography
            color='gray'
            data-testid='hoursRequired-label'
            variant='caption'>
            Hours Required
          </Typography>
          <Typography data-testid='hoursRequired-value' variant='body1'>
            {props.hoursRequired || '--'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export const PlanEligibilityDetails: FC<
  PlanEligibilityDetailsProps
> = props => {
  console.log(props);

  return (
    <>
      <Typography variant='subtitle1'>{props.uiSchema?.title}</Typography>
      <Stack>
        <Stack display='flex' flexDirection='row' mt={4}>
          <Stack data-testid='hasDualEligibility-box' flex={1} spacing={1}>
            {props.data?.partnerSystemName?.output ===
              'Vestwell Sub-Accounting Platform' && (
              <Stack>
                <Typography
                  color='gray'
                  data-testid='hasDualEligibility-label'
                  variant='caption'>
                  Has Dual Eligibility
                </Typography>
                <Typography
                  data-testid='hasDualEligibility-value'
                  variant='body1'>
                  {props.data?.hasDualEligibility?.output ? 'Yes' : 'No'}
                </Typography>
              </Stack>
            )}
            <Stack data-testid='leasedEmployees-box'>
              <Typography
                color='gray'
                data-testid='leasedEmployees-label'
                variant='caption'>
                Leased Employees
              </Typography>
              <Typography data-testid='leasedEmployees-value' variant='body1'>
                {props.data?.excludeLeasedEmployees?.output || '--'}
              </Typography>
            </Stack>
          </Stack>

          <Stack flex={1} spacing={1}>
            <Stack data-testid='specialParticipationWaiverDate-box'>
              <Typography
                color='gray'
                data-testid='specialParticipationWaiverDate-label'
                variant='caption'>
                Special Participation Waiver Date
              </Typography>
              <Typography
                data-testid='specialParticipationWaiverDate-value'
                variant='body1'>
                {props.data?.specialParticipationDate?.output || '--'}
              </Typography>
            </Stack>
            <Stack data-testid='specialEntryDate-box'>
              <Typography
                color='gray'
                data-testid='specialEntryDate-label'
                variant='caption'>
                Special Entry Date
              </Typography>
              <Typography data-testid='specialEntryDate-value' variant='body1'>
                {props.data?.specialEntryDate?.output || '--'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {props.data?.hasDualEligibility?.output &&
        props.data?.partnerSystemName?.output ===
          'Vestwell Sub-Accounting Platform' ? (
          <>
            <Stack mt={4}>
              <EligibilitySection
                entryDateFrequency={props.data?.entryDateFrequency?.output}
                hoursRequired={props.data?.hoursRequired?.output}
                leasedEmployees={props.data?.excludeLeasedEmployees?.output}
                lengthOfEmploymentRequired={
                  props.data?.lengthOfEmploymentRequired?.output
                }
                minimumAgeRequirement={props.data?.minimumAge?.output}
                serviceCalculationType={
                  props.data?.serviceCalculationType?.output
                }
                title='Salary Deferral and others'
              />
            </Stack>

            <Stack mt={4}>
              <EligibilitySection
                entryDateFrequency={
                  props.data?.employerMatchEntryDateFrequency?.output
                }
                hoursRequired={props.data?.employerMatchHoursRequired?.output}
                leasedEmployees={props.data?.excludeLeasedEmployees?.output}
                lengthOfEmploymentRequired={
                  props.data?.employerMatchLengthOfEmploymentRequired?.output
                }
                minimumAgeRequirement={
                  props.data?.employerMatchMinimumAge?.output
                }
                serviceCalculationType={
                  props.data?.employerMatchServiceCalculationType?.output
                }
                title='Employer Match'
              />
            </Stack>

            <Stack mt={4}>
              <EligibilitySection
                entryDateFrequency={
                  props.data?.profitSharingEntryDateFrequency?.output
                }
                hoursRequired={props.data?.profitSharingHoursRequired?.output}
                leasedEmployees={props.data?.excludeLeasedEmployees?.output}
                lengthOfEmploymentRequired={
                  props.data?.profitSharingLengthOfEmploymentRequired?.output
                }
                minimumAgeRequirement={
                  props.data?.profitSharingMinimumAge?.output
                }
                serviceCalculationType={
                  props.data?.profitSharingServiceCalculationType?.output
                }
                title='Profit Sharing'
              />
            </Stack>
          </>
        ) : (
          <Stack mt={4}>
            <EligibilitySection
              entryDateFrequency={props.data?.entryDateFrequency?.output}
              hoursRequired={props.data?.hoursRequired?.output}
              leasedEmployees={props.data?.excludeLeasedEmployees?.output}
              lengthOfEmploymentRequired={
                props.data?.lengthOfEmploymentRequired?.output
              }
              minimumAgeRequirement={props.data?.minimumAge?.output}
              serviceCalculationType={
                props.data?.serviceCalculationType?.output
              }
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};
