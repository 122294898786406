import Badge from '@/components/badge';
import Card from '@/components/card';
import Link from '@/components/link/Link.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUpdateAlertMutation } from '@/hooks/suba/useUpdateAlertMutation.hook';
import { TransferCashDeferRequest } from '@/models/suba/transfer-cash/transfer-cash-defer-request.model';
import {
  CashTransferCompletionEnum,
  TransferCashManualCompletionRequest
} from '@/models/suba/transfer-cash/transfer-cash-manual-completion-request.model';
import { TransferCashRetryRequest } from '@/models/suba/transfer-cash/transfer-cash-retry-request.model';
import { executionStatusColorMap } from '@/models/suba/workflows/ExecutionStatusColorMap.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import { TransferCashService } from '@/services/suba/transfer-cash/TransferCash.service';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import formatters from '@/utils/Formatters';
import {
  Alert,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';

import { AxiosError } from 'axios';
import { FC, useContext, useState } from 'react';

export const CashTransferRejectedExpandedActions: FC = () => {
  const alert = useContext(AlertContext);

  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [openResubmitDialog, setOpenResubmitDialog] = useState(false);
  const [openDeferDialog, setOpenDeferDialog] = useState(false);
  const [openManuallyCompleteDialog, setOpenManuallyCompleteDialog] =
    useState(false);

  const updateAlertMutation = useUpdateAlertMutation();

  const resubmitTransferMutation = useMutation(
    ['TransferCashService.retry'],
    (params: TransferCashRetryRequest) => {
      return TransferCashService.retry(params);
    },
    {
      onError: (e: AxiosError) => {
        showSnackbar({
          message: e?.message || `Couldn't send resubmit request at this time`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'WorkflowService.getById',
          alert?.details?.request?.tracerId
        ]);
      }
    }
  );

  const deferTransferMutation = useMutation(
    ['TransferCashService.defer'],
    (params: TransferCashDeferRequest) => {
      return TransferCashService.defer(params);
    },
    {
      onError: (e: AxiosError) => {
        showSnackbar({
          message: e?.message || `Couldn't send defer request at this time`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'WorkflowService.getById',
          alert?.details?.request?.tracerId
        ]);
      }
    }
  );

  const manualCompletionMutation = useMutation(
    ['TransferCashService.manualCompletion'],
    (params: TransferCashManualCompletionRequest) => {
      return TransferCashService.manualCompletion(params);
    },
    {
      onError: (e: AxiosError) => {
        showSnackbar({
          message:
            e?.message ||
            `Couldn't send manual completion request at this time`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'WorkflowService.getById',
          alert?.details?.request?.tracerId
        ]);
      }
    }
  );

  const getWorkflowQuery = useQuery(
    ['WorkflowService.getById', alert?.details?.request?.tracerId],
    async () => {
      return WorkflowService.getById(
        `tracer:${alert?.details?.request?.tracerId}`
      );
    }
  );

  if (getWorkflowQuery.isInitialLoading) return <CircularProgress size={24} />;

  const failedStatus =
    getWorkflowQuery.data?.executionStatus ===
    WorkflowExecutionStatusEnum.Failed;

  return (
    <>
      <Card>
        <CardContent>
          <Stack
            data-testid='cash-transfer-rejected-alert-detail-actions'
            spacing={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              spacing={1}>
              <Typography variant='h6'>Available Actions</Typography>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-around'
                spacing={1}>
                <Button
                  color='primary'
                  data-testid='cash-transfer-rejected-alert-detail-actions-resubmit-button'
                  disabled={!failedStatus}
                  onClick={() => {
                    setOpenResubmitDialog(true);
                  }}>
                  Resubmit
                </Button>
                <Button
                  color='primary'
                  data-testid='cash-transfer-rejected-alert-detail-actions-defer-button'
                  disabled={!failedStatus}
                  onClick={() => {
                    setOpenDeferDialog(true);
                  }}>
                  Defer
                </Button>
                <Button
                  color='primary'
                  data-testid='cash-transfer-rejected-alert-detail-actions-manually-complete-button'
                  disabled={!failedStatus}
                  onClick={() => {
                    setOpenManuallyCompleteDialog(true);
                  }}>
                  Manually Complete
                </Button>
              </Stack>
            </Stack>
            {getWorkflowQuery.data?.executionStatus && !failedStatus && (
              <Alert
                action={
                  <Button
                    onClick={() => {
                      updateAlertMutation.mutate({
                        alertId: alert.id,
                        updateRequest: {
                          alertStatus: AlertStatus.Closed
                        }
                      });
                    }}
                    size='small'>
                    Close Ticket
                  </Button>
                }
                data-testid='cash-transfer-rejected-alert'
                icon={false}
                severity='info'
                variant='outlined'>
                <Stack spacing={1}>
                  {getWorkflowQuery.data?.executionStatus ===
                    WorkflowExecutionStatusEnum.Running && (
                    <Typography variant='body2'>
                      Transfer has been resubmitted
                    </Typography>
                  )}
                  {[
                    WorkflowExecutionStatusEnum.Aborted,
                    WorkflowExecutionStatusEnum.Succeeded
                  ].includes(getWorkflowQuery.data?.executionStatus) && (
                    <Typography variant='body2'>
                      Transfer has been deferred / completed
                    </Typography>
                  )}
                  <Stack direction='row' spacing={1}>
                    <Link
                      target='_blank'
                      to={`/ops/workflows/tracer:${alert.tracerId}`}>
                      Tracer ID: {alert.tracerId}
                    </Link>
                    <Badge
                      color={
                        executionStatusColorMap[
                          getWorkflowQuery.data?.executionStatus
                        ]
                      }
                      maxWidth='fit-content'>
                      {formatters.displayCase(
                        formatters.snakeToCamelCase(
                          getWorkflowQuery.data?.executionStatus
                        )
                      )}
                    </Badge>
                  </Stack>
                </Stack>
              </Alert>
            )}
          </Stack>
        </CardContent>
      </Card>
      <ResubmitDialog
        onCancel={() => setOpenResubmitDialog(false)}
        onConfirm={() => {
          setOpenResubmitDialog(false);
          resubmitTransferMutation.mutateAsync({
            cashTransferRequestId: alert?.details?.request?.id,
            transferDate: alert?.details?.request?.transferDate
          });
        }}
        open={openResubmitDialog}
      />
      <DeferDialog
        onCancel={() => setOpenDeferDialog(false)}
        onConfirm={() => {
          setOpenDeferDialog(false);
          deferTransferMutation.mutateAsync({
            cashTransferRequestId: alert?.details?.request?.id
          });
        }}
        open={openDeferDialog}
      />
      <ManuallyCompleteDialog
        onCancel={() => setOpenManuallyCompleteDialog(false)}
        onConfirm={() => {
          setOpenManuallyCompleteDialog(false);
          manualCompletionMutation.mutateAsync({
            completionType: CashTransferCompletionEnum.manualCompletion,
            tracerId: alert?.details?.request?.tracerId
          });
        }}
        open={openManuallyCompleteDialog}
      />
    </>
  );
};

type CashTransferRejectedDialogProps = {
  onConfirm: () => void;
  onCancel: () => void;
} & DialogProps;

const ResubmitDialog = (props: CashTransferRejectedDialogProps) => {
  return (
    <Dialog fullWidth maxWidth='xs' open={props.open}>
      <DialogTitle>Resubmit this transfer?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button color='primary' onClick={props.onCancel}>
          Cancel
        </Button>
        <Button color='primary' onClick={props.onConfirm}>
          Confirm & Resubmit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeferDialog = (props: CashTransferRejectedDialogProps) => {
  return (
    <Dialog fullWidth maxWidth='xs' open={props.open}>
      <DialogTitle>Defer this transfer?</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button color='primary' onClick={props.onCancel}>
          Cancel
        </Button>
        <Button color='primary' onClick={props.onConfirm}>
          Confirm & Defer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ManuallyCompleteDialog = (props: CashTransferRejectedDialogProps) => {
  return (
    <Dialog fullWidth maxWidth='xs' open={props.open}>
      <DialogTitle>Manually complete this transfer?</DialogTitle>
      <DialogContent>
        <Alert severity='error'>
          This only completes the transfer within our system. Only proceed if
          the transfer has already been processed by the custodian.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={props.onCancel}>
          Cancel
        </Button>
        <Button color='primary' onClick={props.onConfirm}>
          Confirm & Manually Complete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CashTransferRejectedExpandedActions.displayName =
  'CashTransferRejectedExpandedActions';
