import { useSnackbar } from '@/contexts/SnackBarContext';
import { WorkflowDto } from '@/models/suba/workflows/WorkflowDTO.model';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  isWorkflowComplete,
  isWorkflowFailed
} from '../../routes/suba/common/utils';

export const useGetWorkflowByTracerId = (
  tracerId: string
): UseQueryResult<WorkflowDto | null> => {
  const { showSnackbar } = useSnackbar();

  return useQuery(
    ['WorkflowService.search', tracerId],
    async () => {
      const response = await WorkflowService.search({
        orderBy: 'createdAt',
        orderByDirection: 'desc',
        tracerId: tracerId
      });

      return Array.isArray(response.results) && response.results.length
        ? response.results[0]
        : null;
    },
    {
      enabled: Boolean(tracerId),
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to get related workflows: ${message}`,
          severity: 'error'
        });
      },
      refetchInterval: currentWorkflow => {
        return isWorkflowComplete(currentWorkflow?.workflowStatus) ||
          isWorkflowFailed(currentWorkflow?.workflowStatus)
          ? false
          : 1000 * 60; // refetch every minute while pending for latest status
      }
    }
  );
};
