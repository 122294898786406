import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import SecurityService from '@/services/suba/security/Security.service';
import { Search } from '@mui/icons-material';
import { Alert, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FundStatus } from '@vestwell-sub-accounting/models/securitiesAndPricing/FundStatus';

import { ColDef } from 'ag-grid-community';
import { groupBy } from 'lodash';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const SecuritiesFundScreenerPage: FC = () => {
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();

  const funds = searchParams.get('funds').split(',');

  const securitiesQuery = useQuery(
    ['SecurityService.get', 'fundScreenerResults', ...funds],
    async () => {
      const res: {
        fulfilled?: PromiseFulfilledResult<SecurityDto>[];
        rejected?: PromiseRejectedResult[];
      } = groupBy(
        await Promise.allSettled(funds.map(fund => SecurityService.get(fund))),
        'status'
      );

      if (
        res.rejected?.some(
          err =>
            !(err.reason.isAxiosError && err.reason.response?.status === 404)
        )
      ) {
        showSnackbar({
          message: 'Sorry, something went wrong',
          severity: 'error'
        });
      }

      return [
        res.fulfilled?.map(e => e.value) || [],
        res.rejected?.map(e => e.reason?.config?.url?.split('/')?.at(-1)) || []
      ];
    },
    {
      enabled: !!funds.length,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        cellRenderer: ({ data }) => {
          return (
            <DataTableStackCell
              primary={`${data.symbol} | ${data.cusip}`}
              primaryLinkProps={{
                to: `/ops/securities/${data.symbol}/general?searchResults=${searchParams.get('funds')}`
              }}
              secondary={data.description}
            />
          );
        },
        field: 'symbol',
        headerName: 'Fund',
        minWidth: 350,
        suppressMenu: true,
        suppressMovable: true
      },
      {
        field: 'issuer',
        headerName: 'Asset Manager',
        suppressMenu: true,
        suppressMovable: true
      },
      {
        field: 'standardFundInformation.mstarAssetClass',
        headerName: 'Asset Class',
        suppressMenu: true,
        suppressMovable: true
      },
      {
        field: 'standardFundInformation.netExpenseRatio',
        headerName: 'Expense Ratio',
        suppressMenu: true,
        suppressMovable: true,
        width: 130
      },
      {
        cellRenderer: params => {
          return (
            <DataTableBadgeCell
              color={params.value === FundStatus.open ? 'success' : 'warning'}>
              {params.value || 'Not Available'}
            </DataTableBadgeCell>
          );
        },
        field: 'custodianFundInformation?.fundStatus',
        headerName: 'Matrix Fund Status',
        suppressMenu: true,
        suppressMovable: true,
        width: 160
      },
      {
        cellRenderer: params => {
          const isNotPermitted =
            params.data.standardFundInformation._12B1Fee &&
            params.data.fundRules?.fundStatus === FundStatus.closed &&
            params.data.standardFundInformation.redemptionFees;

          return (
            <DataTableBadgeCell color={isNotPermitted ? 'warning' : 'success'}>
              {isNotPermitted ? 'Not Permitted' : 'Permitted'}
            </DataTableBadgeCell>
          );
        },
        headerName: 'Vestwell Permitted',
        suppressMenu: true,
        suppressMovable: true,
        width: 160
      },
      {
        field: 'custodianFundInformation?.notes',
        headerName: 'Custodian Note',
        suppressMenu: true,
        suppressMovable: true
      }
    ],
    []
  );

  return (
    <Stack spacing={5}>
      <Stack>
        <NavigationBreadcrumbs
          paths={[{ name: 'Securities', to: '/ops/securities' }]}
        />
        <Typography variant='h4'>Fund Screener Results</Typography>
      </Stack>
      <Card>
        <CardContent disablePadding overlayLoading={securitiesQuery.isFetching}>
          <DataTable
            columnDefs={columnDefs}
            columnSizing='fit'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-fund-screen-results-message'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<Search fontSize='inherit' />}
                  subtitle='No fund screener results found'
                />
              </Stack>
            }
            pageSize={50}
            pagination
            paginationPosition='bottom'
            rowData={securitiesQuery?.data?.[0] || []}
            rowHeight={56}
          />
        </CardContent>
      </Card>
      {!!securitiesQuery?.data?.[1]?.length && (
        <Alert severity='error'>
          Couldn’t find the following symbol(s) or CUSIP(s):{' '}
          {securitiesQuery.data[1].join(', ')}
        </Alert>
      )}
    </Stack>
  );
};
