import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { SubaPlanGetRequest } from '@/models/suba/suba-plans/SubaPlanGetRequest.model';
import { SubaPlanService } from '@/services/suba/suba-plans/SubaPlan.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useGetSubaPlanQuery = (params: SubaPlanGetRequest) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['SubAccountingPlanService.getById', params.planId, params.include?.join()],
    () => SubaPlanService.getByPlanId(params),
    {
      enabled: !!(params.planId && userHasValidToken),
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to get plan: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
