import Card, { CardContent, CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useHoldingsQuery } from '@/hooks/suba/useHoldingsQuery.hook';
import useAccountValue, { DisplayBalance } from '@/hooks/useAccountValue.hook';
import { HoldingsGetRequest } from '@/models/suba/holdings/HoldingsGetRequest.model';
import DOMInteraction from '@/utils/DOMInteraction';
import { json2csvParser } from '@/utils/Json2csvParser';
import { useUrlStateParams } from '@/utils/Url';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  Unstable_Grid2 as Grid,
  ListItemIcon,
  Menu,
  MenuItem
} from '@mui/material';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import dayjs from 'dayjs';
import { kebabCase } from 'lodash';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import { HoldingsDataTable } from './HoldingsDataTable.component';
import { HoldingsFiltersForm } from './HoldingsFiltersForm.component';
import { HoldingsSummary } from './HoldingsSummary.component';

const formatHoldingsExport = (displayBalance: DisplayBalance[]) => {
  return displayBalance
    .filter(balance => balance.subAccountPositions !== undefined)
    .map(balance => {
      return {
        /* eslint-disable sort-keys-plus/sort-keys */
        Symbol: balance.security?.symbol,
        CUSIP: balance.security?.cusip,
        Description: balance.security?.description,
        'Confirmed Units': balance.confirmedUnits,
        Price: balance.price,
        'Confirmed Value': balance.confirmedValue,
        'Pending Units': balance.pendingUnits,
        'Pending Value': balance.pendingValue
        /* eslint-enable sort-keys-plus/sort-keys */
      };
    });
};

const formatSubaccountsHoldingsExport = (displayBalance: DisplayBalance[]) => {
  return displayBalance
    .filter(balance => balance.subAccountPositions === undefined)
    .map(balance => {
      return {
        /* eslint-disable sort-keys-plus/sort-keys */
        Symbol: balance.security?.symbol,
        CUSIP: balance.security?.cusip,
        Description: balance.security?.description,
        'SubAccount Id': balance.accountId,
        'Confirmed Units': balance.confirmedUnits,
        Price: balance.price,
        'Confirmed Value': balance.confirmedValue,
        'Pending Units': balance.pendingUnits,
        'Pending Value': balance.pendingValue
        /* eslint-enable sort-keys-plus/sort-keys */
      };
    });
};

const getExportOptions = (
  props: HoldingsTabProps,
  displayBalance: DisplayBalance[]
): ExportOption[] => {
  return props.subAccountId
    ? [
        {
          fileName: `Holdings-accounts-subaccounts-${props.parentAccountId}`,
          formatter: formatSubaccountsHoldingsExport,
          label: 'Sub Accounts'
        }
      ]
    : props.planId
      ? [
          {
            fileName: `Plan-holdings-${props.planId}`,
            formatter: () => formatHoldingsExport(displayBalance),
            label: 'Plan'
          },
          {
            fileName: `Plan-sub-accounts-holdings-${props.planId}`,
            formatter: () => formatSubaccountsHoldingsExport(displayBalance),
            label: 'Sub Accounts'
          }
        ]
      : [
          {
            fileName: `Holdings-accounts-${props.parentAccountId}`,
            formatter: () => formatHoldingsExport(displayBalance),
            label: 'Parent Account'
          },
          {
            fileName: `Holdings-accounts-subaccounts-${props.parentAccountId}`,
            formatter: () => formatSubaccountsHoldingsExport(displayBalance),
            label: 'Sub Accounts'
          }
        ];
};

type ExportOption = {
  fileName: string;
  formatter: (
    displayBalance: DisplayBalance[]
  ) => Record<string, number | string>[];
  label: string;
};

type HoldingsTabProps = {
  exportOptions?: ExportOption[];
  parentAccountId?: string;
  planId?: string;
  subAccountId?: string;
  urlStateParamName?: string;
};

export const HoldingsTab: FC<HoldingsTabProps> = props => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const { showSnackbar } = useSnackbar();
  const [isExportMenuOpen, toggleExportMenuOpen] = useToggle(false);
  const [isExporting, toggleIsExporting] = useToggle(false);

  const defaultParams: HoldingsGetRequest = {
    asOfDate: dayjs().format('YYYY-MM-DD'),
    dateType: PositionDateType.Trade,
    filterToShortPositions: false
  };

  const [params, setParams] = useUrlStateParams<HoldingsGetRequest>(
    defaultParams,
    props.urlStateParamName || 'holdings',
    value => JSON.stringify(value),
    value => {
      try {
        return JSON.parse(value);
      } catch {
        return defaultParams;
      }
    }
  );

  const holdingsQuery = useHoldingsQuery({
    ...params,
    parentAccountId: props.parentAccountId,
    planId: props.planId,
    subAccountId: props.subAccountId
  });

  const { confirmedValue, displayBalance } = useAccountValue(
    holdingsQuery.data
  );

  const exportOptions = useMemo(
    () =>
      props.subAccountId
        ? [
            {
              fileName: `Holdings-accounts-subaccounts-${props.parentAccountId}`,
              formatter: formatSubaccountsHoldingsExport,
              label: 'Sub Accounts'
            }
          ]
        : props.planId
          ? [
              {
                fileName: `Plan-holdings-${props.planId}`,
                formatter: () => formatHoldingsExport(displayBalance),
                label: 'Plan'
              },
              {
                fileName: `Plan-sub-accounts-holdings-${props.planId}`,
                formatter: () =>
                  formatSubaccountsHoldingsExport(displayBalance),
                label: 'Sub Accounts'
              }
            ]
          : [
              {
                fileName: `Holdings-accounts-${props.parentAccountId}`,
                formatter: () => formatHoldingsExport(displayBalance),
                label: 'Parent Account'
              },
              {
                fileName: `Holdings-accounts-subaccounts-${props.parentAccountId}`,
                formatter: () =>
                  formatSubaccountsHoldingsExport(displayBalance),
                label: 'Sub Accounts'
              }
            ],
    [props.parentAccountId, props.planId, props.subAccountId]
  );

  const handleExport = useCallback(async (option: ExportOption) => {
    toggleIsExporting();

    try {
      DOMInteraction.triggerDownload(
        await json2csvParser(await option.formatter(displayBalance)),
        `${option.fileName}.csv`
      );
    } catch (e) {
      showSnackbar({
        message: 'Failed to download, please try again',
        severity: 'error'
      });
    }

    toggleIsExporting();
    toggleExportMenuOpen();
  }, []);

  return (
    <Card>
      <CardHeader data-testid='holdings-header' title='All Holdings'>
        {exportOptions.length > 1 ? (
          <Box ref={anchorRef}>
            <LoadingButton
              aria-controls={
                isExportMenuOpen ? 'export-options-menu' : undefined
              }
              aria-expanded={isExportMenuOpen ? 'true' : undefined}
              aria-haspopup='menu'
              data-testid='export-csv-menu-button'
              disabled={holdingsQuery.data?.positions.length === 0}
              endIcon={<KeyboardArrowDownIcon />}
              loading={isExporting}
              onClick={toggleExportMenuOpen}
              size='small'
              variant='outlined'>
              Export CSV
            </LoadingButton>
            <Menu
              anchorEl={anchorRef.current}
              onClose={toggleExportMenuOpen}
              open={isExportMenuOpen}>
              {getExportOptions(props, displayBalance).map(option => (
                <MenuItem
                  data-testid={`export-csv-${kebabCase(option.label)}-button`}
                  key={option.label}
                  onClick={() => handleExport(option)}>
                  <ListItemIcon>
                    <FileDownloadOutlinedIcon fontSize='small' />
                  </ListItemIcon>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : exportOptions.length ? (
          <LoadingButton
            data-testid='export-csv-menu-button'
            disabled={holdingsQuery.data?.positions.length === 0}
            loading={isExporting}
            onClick={() => handleExport(exportOptions[0])}
            size='small'
            startIcon={<FileDownloadOutlinedIcon />}
            variant='outlined'>
            Export CSV
          </LoadingButton>
        ) : null}
      </CardHeader>
      <CardContent
        disablePadding
        overlayLoading={holdingsQuery.isInitialLoading}>
        <HoldingsFiltersForm
          initialValues={params}
          onSubmit={values => {
            setParams({
              ...values,
              asOfDate: values.asOfDate,
              dateType: values.dateType,
              filterToShortPositions: values.filterToShortPositions
            });
          }}
        />
        <Divider />
        <Grid container>
          <Grid lg={2} xs={3}>
            <HoldingsSummary
              asOfDate={params.asOfDate}
              confirmedValue={confirmedValue}
              dateType={params.dateType}
              holdings={holdingsQuery.data}
            />
          </Grid>
          <Grid xs='auto'>
            <Divider orientation='vertical' />
          </Grid>
          <Grid xs>
            <HoldingsDataTable data={displayBalance} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
