import { SimpleUpload } from '@/components/simple-upload/SimpleUpload.component';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import { pick } from 'lodash';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState
} from 'react';
import { read, utils } from 'xlsx';

import { GridRow } from '../types';
import HeaderMapping from './HeaderMapping.component';
import UnrecognizedParticipants from './UnrecognizedParticipants.component';

type FileUploadProps = {
  planId: string | number;
  setRows: Dispatch<SetStateAction<GridRow[]>>;
  setInitialRows: Dispatch<SetStateAction<GridRow[]>>;
  setInfo: Dispatch<
    SetStateAction<{ modifiedRowIndexes: []; type: 'file' | 'manual' }>
  >;
  sponsorId: string | number;
  ucid: string;
};

type File = {
  headers?: string[];
  rawData?: (string | number)[][];
};

const FileUpload: FC<FileUploadProps> = props => {
  const [file, setFile] = useState<File | null>();
  const [unrecognizedParticipants, setUnrecognizedParticipants] = useState<
    GridRow[] | null
  >();

  const synonymMapping = useQuery(
    [ContributionService.getSynonymMapping.name],
    () => ContributionService.getSynonymMapping()
  );

  const participants = useQuery(
    [PlanService.getParticipantsBasicDetails.name, props.planId],
    () =>
      PlanService.getParticipantsBasicDetails(props.planId, { masked: false }),
    { enabled: !!props.planId }
  );

  const contribution = useQuery(
    [ContributionService.getContributionDetails.name, props.planId, props.ucid],
    async () => {
      return ContributionService.getContributionDetails({
        planId: props.planId,
        sort: 'lastName',
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });
    },
    {
      enabled: !!props.ucid && !!props.planId && !!props.sponsorId
    }
  );

  const onSelect = useCallback(files => {
    const { Sheets, SheetNames } = read(files[0].data, {
      cellDates: true,
      raw: true,
      type: 'binary'
    });

    const [headers, ...rawData]: (number | string)[][] = utils.sheet_to_json(
      Sheets[SheetNames[0]],
      {
        blankrows: false,
        header: 1,
        raw: true
      }
    );

    setFile({
      headers: headers?.map(header =>
        header?.toString()?.toLowerCase()?.trim()
      ),
      rawData
    });
  }, []);

  const onReset = useCallback(() => {
    setFile(null);
    setUnrecognizedParticipants(null);
  }, []);

  const onHeadersMatched = useCallback(
    (data: Record<string, any>[]) => {
      const participantsHash = new Map(
        participants.data?.data?.map(participant => [
          formatSsn(participant.attributes?.ssn),
          {
            firstName: participant.attributes?.firstName,
            lastName: participant.attributes?.lastName,
            participantId: participant.id
          }
        ])
      );

      const contributionsHash = new Map(
        contribution.data?.participantData?.map(participant => [
          participant.participantId,
          pick(participant.accountMeta, [
            'sd',
            'rc',
            'at',
            'sh',
            'em',
            'ps',
            'ln',
            'qc',
            'qm',
            'pw'
          ])
        ])
      );

      const rows = data
        .filter(row => row.ssn)
        .map((row, index) => {
          const participant = participantsHash.get(row.ssn);
          const contributionRow = contributionsHash.get(
            participant?.participantId ?? -1
          );

          return {
            ...row,
            ...(participant || {}),
            at: isNaN(+row.at) ? contributionRow?.at || 0 : row.at,
            em: isNaN(+row.em) ? contributionRow?.em || 0 : row.em,
            ln: isNaN(+row.ln) ? contributionRow?.ln || 0 : row.ln,
            ps: isNaN(+row.ps) ? contributionRow?.ps || 0 : row.ps,
            pw: isNaN(+row.pw) ? contributionRow?.pw || 0 : row.pw,
            qc: isNaN(+row.qc) ? contributionRow?.qc || 0 : row.qc,
            qm: isNaN(+row.qm) ? contributionRow?.qm || 0 : row.qm,
            rc: isNaN(+row.rc) ? contributionRow?.rc || 0 : row.rc,
            sd: isNaN(+row.sd) ? contributionRow?.sd || 0 : row.sd,
            sh: isNaN(+row.sh) ? contributionRow?.sh || 0 : row.sh,
            uuid: index.toString()
          };
        });

      const unrecognizedRows = rows.filter(row => !row.participantId);

      if (unrecognizedRows.length) {
        return setUnrecognizedParticipants(unrecognizedRows);
      }

      const initialRows = rows?.map(row => ({
        ...row,
        ...((row.participantId && contributionsHash.get(row.participantId)) || {
          at: 0,
          em: 0,
          ln: 0,
          ps: 0,
          pw: 0,
          qc: 0,
          qm: 0,
          rc: 0,
          sd: 0,
          sh: 0
        })
      }));

      props.setInitialRows(initialRows);
      props.setRows(rows);
      props.setInfo(prev => ({
        ...prev,
        modifiedRowIndexes: [],
        type: 'file'
      }));

      onReset();
    },
    [participants.data?.data, contribution.data, onReset, props]
  );

  return (
    <>
      <SimpleUpload
        accept={{
          'application/vnd.ms-excel': ['.xlsx'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xls'
          ],
          'text/csv': ['.csv']
        }}
        onSelect={onSelect}>
        <Button
          component='label'
          data-testid='correction-upload-button'
          startIcon={<FileUploadIcon />}
          variant='outlined'>
          Upload File
        </Button>
      </SimpleUpload>
      {file && (
        <HeaderMapping
          headers={file.headers}
          onCancel={onReset}
          onHeadersMatched={onHeadersMatched}
          rawData={file.rawData}
          synonyms={synonymMapping.data}
        />
      )}
      {unrecognizedParticipants && (
        <UnrecognizedParticipants
          onCancel={onReset}
          rows={unrecognizedParticipants}
        />
      )}
    </>
  );
};

export default FileUpload;
