import { useSnackbar } from '@/contexts/SnackBarContext';
import { ReconExceptionDto } from '@/models/suba/recon/ReconException.model';
import ReconExceptionService from '@/services/suba/recon-exceptions/ReconException.service';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type ToggleFreezeDialogProps = {
  open: boolean;
  onClose: () => void;
  reconException: ReconExceptionDto;
  onToggleFreeze: () => void;
};

export const ToggleFreezeDialog = ({
  open,
  onClose,
  reconException,
  onToggleFreeze
}: ToggleFreezeDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const toggleFreezeMutation = useMutation(
    ['ReconExceptionService.update'],
    () => {
      return ReconExceptionService.update(reconException.id, {
        lockAccountFlag: !reconException.lockAccountFlag
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Error ${
            reconException.lockAccountFlag ? 'unfreezing' : 'freezing'
          } exception`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Exception ${
            reconException.lockAccountFlag ? 'unfrozen' : 'frozen'
          }`,
          severity: 'success'
        });
        onClose();
        // Invalidate the recon exception search query so that we get fresh results
        queryClient.invalidateQueries(['ReconExceptionService.search']);
        onToggleFreeze();
      }
    }
  );

  // format friendly parent account name
  const parentAccountName =
    reconException?.parentAccount?.accountType === 'SuperOmnibus' ||
    reconException?.parentAccount?.accountType === 'House'
      ? reconException?.parentAccount?.accountName
      : reconException?.parentAccount?.plan?.name;

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        {reconException.lockAccountFlag ? `Unfreeze Account` : `Freeze Account`}
      </DialogTitle>
      {reconException && (
        <DialogContent
          sx={{
            p: 0
          }}>
          <Box sx={{ px: 3, py: 1 }}>
            <Typography sx={{ mb: 3.75 }}>
              Are you sure you want to{' '}
              {reconException.lockAccountFlag ? 'unfreeze' : 'freeze'} this
              account?
            </Typography>
            <Typography
              sx={{ fontWeight: theme => theme.typography.fontWeightBold }}
              variant='body1'>
              {parentAccountName}
            </Typography>
            <Typography variant='body2'>
              ID: {reconException.parentAccount?.parentAccountId}
            </Typography>
          </Box>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          onClick={() => {
            onClose();
          }}>
          Nevermind
        </Button>

        <LoadingButton
          disabled={!reconException}
          loading={toggleFreezeMutation.isLoading}
          onClick={() => {
            toggleFreezeMutation.mutate();
          }}
          variant='contained'>
          {reconException.lockAccountFlag ? `Unfreeze` : `Freeze`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
