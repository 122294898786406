import Badge from '@/components/badge';
import Card, { CardContent } from '@/components/card';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertDto } from '@/models/suba/alerts/AlertDTO.model';
import { orderStatusColorMap } from '@/models/suba/common/OrderStatusColorMap.model';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import ParentAccountOrderService from '@/services/suba/parent-account-orders/ParentAccountOrder.service';
import SecurityService from '@/services/suba/security/Security.service';
import formatters from '@/utils/Formatters';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';
import { OrderStatusUpdate } from '@vestwell-sub-accounting/models/common/OrderStatusUpdate';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { AxiosError } from 'axios';

type UnexpectedStatusUpdateDetailFieldsProps = {
  alert: AlertDto;
};

const tradeStatusColorMap: Record<
  OrderStatusUpdate,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  [OrderStatusUpdate.Accepted]: 'success',
  [OrderStatusUpdate.Acknowledged]: 'success',
  [OrderStatusUpdate.Executed]: 'success',
  [OrderStatusUpdate.Rejected]: 'warning',
  [OrderStatusUpdate.CustodianError]: 'warning',
  [OrderStatusUpdate.DestinationReject]: 'warning',
  [OrderStatusUpdate.CustodianReject]: 'warning',
  [OrderStatusUpdate.ExitReject]: 'warning',
  [OrderStatusUpdate.Reconfirm]: 'neutral',
  [OrderStatusUpdate.Exited]: 'info'
};

export const UnexpectedStatusUpdateDetailFields = ({
  alert
}: UnexpectedStatusUpdateDetailFieldsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const displayType = formatters.getValueKey(AlertType, alert.alertType);
  const displaySubType = formatters.getValueKey(
    AlertSubType,
    alert.alertSubType
  );

  const getSecurityQuery = useQuery(
    ['SecurityService.get', alert.details.trade?.cusip],
    () => SecurityService.get(alert.details.trade.cusip),
    {
      enabled: Boolean(alert.details.trade?.cusip),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Getting security failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      alert.details.trade.custodianAccountNumber,
      alert.details.trade.custodianId
    ],
    () =>
      ParentAccountService.get({
        custodianAccountNumber: alert.details.trade.custodianAccountNumber,
        custodianId: alert.details.trade.custodianId
      }),
    {
      enabled:
        Boolean(alert.details.trade?.custodianAccountNumber) &&
        Boolean(alert.details.trade?.custodianId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Getting parent account failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountOrderQuery = useQuery(
    ['ParentAccountOrderService.search', alert.details.trade?.parentOrderId],
    () =>
      ParentAccountOrderService.search({
        ourParentOrderId: alert.details.trade.parentOrderId
      }),
    {
      enabled: Boolean(alert.details.trade?.parentOrderId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Searching parent account order failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <Stack direction='column' id='alert-detail-fields' spacing={2}>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Type / Sub Type</TextLabel>
          <TextValue data-testid='unexpected-status-update-type-value'>
            {formatters.displayCase(displayType)} /{' '}
            {formatters.displayCase(displaySubType)}
          </TextValue>
        </TextStackItem>
        {getParentAccountQuery.data?.accountType ===
          ParentAccountType.PlanLevel && (
          <TextStackItem>
            <TextLabel>Plan</TextLabel>
            <TextValue
              data-testid='unexpected-status-update-plan-value'
              links={[
                getParentAccountQuery.data?.planId
                  ? {
                      label: `ID: ${getParentAccountQuery.data.planId}`,
                      target: '_blank',
                      to: `/plans/${getParentAccountQuery.data.planId}/plan`
                    }
                  : undefined,
                getParentAccountQuery.data?.parentAccountId
                  ? {
                      label: 'View Parent Acct.',
                      target: '_blank',
                      to: `/ops/accounts/${getParentAccountQuery.data?.parentAccountId}/sub-accounts`
                    }
                  : undefined
              ].filter(Boolean)}>
              {getParentAccountQuery.data?.plan?.name}
            </TextValue>
          </TextStackItem>
        )}
        <TextStackItem>
          <TextLabel>Custodian Acct.</TextLabel>
          <TextValue data-testid='unexpected-status-update-custodian-account-value'>
            {`${formatters.displayCase(alert.details.trade?.custodianId)}: ${alert.details.trade?.custodianAccountNumber}`}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Order ID</TextLabel>
          <TextValue data-testid='unexpected-status-update-order-id-value'>
            {getParentAccountOrderQuery.data?.results[0] ? (
              <Link
                target='_blank'
                to={
                  '/ops/parent-account-orders/' +
                  getParentAccountOrderQuery.data?.results[0].id
                }>
                {alert.details.trade?.parentOrderId}
              </Link>
            ) : (
              alert.details.trade?.parentOrderId
            )}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Order Status</TextLabel>
          <TextValue data-testid='unexpected-status-update-order-status-value'>
            <Badge
              color={orderStatusColorMap[alert.details.orderStatus]}
              maxWidth='fit-content'>
              {formatters.displayCase(alert.details.orderStatus.toLowerCase())}
            </Badge>
          </TextValue>
        </TextStackItem>
      </TextStack>
      <Card>
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Typography color='black' variant='subtitle2'>
              Custodian Update Received
            </Typography>
            <TextStack direction='column' spacing={1}>
              <TextStackItem>
                <TextLabel>Trade ID</TextLabel>
                <TextValue data-testid='unexpected-status-update-trade-id-value'>
                  {alert.details.trade?.id}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Trade Status</TextLabel>
                <TextValue
                  data-testid='unexpected-status-update-trade-status-value'
                  detail={alert.details.trade?.statusReason}>
                  <Badge
                    color={tradeStatusColorMap[alert.details?.trade?.status]}
                    maxWidth='fit-content'>
                    {formatters.displayCase(
                      formatters.snakeToCamelCase(alert.details?.trade?.status)
                    )}
                  </Badge>
                </TextValue>
              </TextStackItem>
              {alert.details.trade?.cusip && (
                <TextStackItem>
                  <TextLabel>Security</TextLabel>
                  <TextValue
                    data-testid='unexpected-status-update-security-value'
                    links={[
                      {
                        label: formatters.displayCase(
                          getSecurityQuery.data?.description ?? 'Details'
                        ),
                        target: '_blank',
                        to: `/ops/securities/${alert.details.trade.cusip}`
                      }
                    ]}>
                    {formatters.formatSecurityName(
                      getSecurityQuery.data?.symbol,
                      alert.details.trade?.cusip
                    )}
                  </TextValue>
                </TextStackItem>
              )}
              <TextStackItem>
                <TextLabel>Units</TextLabel>
                <TextValue data-testid='unexpected-status-update-units-value'>
                  {formatters.formatDecimal(alert.details.trade?.tradeUnits, 3)}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Amount</TextLabel>
                <TextValue data-testid='unexpected-status-update-amount-value'>
                  {formatters.formatDollars(
                    alert.details.trade?.tradeDollars,
                    2
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Trade Date</TextLabel>
                <TextValue data-testid='unexpected-status-update-trade-date-value'>
                  {alert.details.trade?.tradeDate
                    ? formatters.formatFromIsoDateCustom(
                        alert.details.trade?.tradeDate,
                        'MM/DD/YYYY'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
        </CardContent>
      </Card>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Created</TextLabel>
          <TextValue data-testid='unexpected-status-update-created-value'>
            {alert.createdAt
              ? formatters.formatFromIsoDateCustom(
                  alert.createdAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Last Updated</TextLabel>
          <TextValue data-testid='unexpected-status-update-updated-value'>
            {alert.updatedAt
              ? formatters.formatFromIsoDateCustom(
                  alert.updatedAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  );
};
