import { TableCell, TableRow, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface PlanContributionProcessedGroupedHeaderProps {
  recordkeeper?: string;
  columns?: Record<string, any>[];
}

const useStyles = makeStyles((theme: Theme) => ({
  headerGroupedCell: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  },
  isStateIraHeader: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  }
}));

const PlanContributionProcessedGroupedHeader = (
  props: PlanContributionProcessedGroupedHeaderProps
): JSX.Element => {
  const classes = useStyles();

  const companyColumnsLength = props.columns?.filter(column =>
    [
      'pw',
      'safeHarbor',
      'discretionaryMatch',
      'profitSharing',
      'qnec',
      'qmac',
      'companyContribution'
    ].includes(column.field)
  )?.length;

  if (props.recordkeeper === 'Surpas (State IRA)') {
    return (
      <TableRow>
        <TableCell colSpan={1}> </TableCell>
        <TableCell
          align='left'
          className={classes.isStateIraHeader}
          colSpan={1}>
          Employee Contribution
        </TableCell>
      </TableRow>
    );
  }

  if (props.recordkeeper === 'Vestwell ESA') {
    return (
      <TableRow>
        <TableCell colSpan={2}></TableCell>
        <TableCell
          className={classes.headerGroupedCell}
          colSpan={1}></TableCell>
        <TableCell
          align='center'
          className={classes.headerGroupedCell}
          colSpan={companyColumnsLength}>
          Company Contribution
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell colSpan={2}> </TableCell>
      <TableCell
        align='center'
        className={classes.headerGroupedCell}
        colSpan={4}>
        Employee Contribution
      </TableCell>
      <TableCell
        align='center'
        className={classes.headerGroupedCell}
        colSpan={companyColumnsLength}>
        Company Contribution
      </TableCell>
      <TableCell
        align='center'
        className={classes.headerGroupedCell}
        colSpan={1}>
        Loan
      </TableCell>
    </TableRow>
  );
};

export default PlanContributionProcessedGroupedHeader;
