import Card, {
  CardContent,
  CardHeader
} from '@/components/card/Card.component';
import DataTable from '@/components/data-table/DataTable.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import { SecurityDto } from '@/models/suba/security/SecurityDTO.model';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { AccrualRateRule } from '@vestwell-sub-accounting/models/securitiesAndPricing/AccrualRateRule';
import { FundDividendFrequency } from '@vestwell-sub-accounting/models/securitiesAndPricing/FundDividendFrequency';
import { MultiDayAccrualRule } from '@vestwell-sub-accounting/models/securitiesAndPricing/MultiDayAccrualRule';

const displayDividendAccrualRateRule = {
  [AccrualRateRule.perDayRates]: 'Per Day Rate',
  [AccrualRateRule.totalValueRates]: 'Total Value Rate'
};

const displayDividendFrequency = {
  [FundDividendFrequency.annual]: 'Annually',
  [FundDividendFrequency.monthly]: 'Monthly',
  [FundDividendFrequency.quarterly]: 'Quarterly',
  [FundDividendFrequency.semiannual]: 'Semi-Annually'
};

const displayMultiDayAccrualRule = {
  [MultiDayAccrualRule.noBulkRate]: 'No Bulk Rate',
  [MultiDayAccrualRule.prevBusDay]: 'Previous Business Day',
  [MultiDayAccrualRule.nextBusDay]: 'Next Business Day'
};

export const SecuritiesDetailFundRulesTab = ({
  security
}: {
  security: SecurityDto;
}): JSX.Element => {
  return (
    <Card>
      <CardHeader title='Fund Rules' />
      <CardContent disablePadding>
        <Grid container spacing={0}>
          <Grid item xs={true}>
            <Box padding={2}>
              <Stack spacing={4}>
                <div>
                  <Typography display='inline-block' mb={1} variant='overline'>
                    General
                  </Typography>
                  <TextStack direction='column' textLabelColumnWidth='50%'>
                    <TextStackItem>
                      <TextLabel>Dividend Type</TextLabel>
                      <TextValue data-testid='dividend-type'>
                        {security.fundRules
                          ? security.fundRules?.divType || 'Periodic'
                          : undefined}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Dividend Frequency</TextLabel>
                      <TextValue data-testid='dividend-frequency'>
                        {security.fundRules?.divFrequency
                          ? displayDividendFrequency[
                              security.fundRules
                                ?.divFrequency as keyof typeof displayDividendFrequency
                            ]
                          : undefined}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </div>
                <div>
                  <Typography display='inline-block' mb={1} variant='overline'>
                    Minimums
                  </Typography>
                  <TextStack direction='column' textLabelColumnWidth='50%'>
                    <TextStackItem>
                      <TextLabel>Minimum Initial Purchase Amount</TextLabel>
                      <TextValue data-testid='minimum-initial-purchase-amount'>
                        {security.fundRules?.qpMinInitialPurchase}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Minimum Subsequent Purchase Amount</TextLabel>
                      <TextValue data-testid='minimum-subsequent-purchase-amount'>
                        {security.fundRules?.qpMinSubsequentPurchase}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </div>
              </Stack>
            </Box>
          </Grid>
          <Grid display='flex' item justifyContent='flex-end' xs='auto'>
            <Divider orientation='vertical' />
          </Grid>
          <Grid item xs={true}>
            <Stack spacing={4}>
              <Box pt={2} px={4}>
                <Typography display='inline-block' mb={1} variant='overline'>
                  Accrual Info
                </Typography>
                <TextStack direction='column' textLabelColumnWidth='50%'>
                  <TextStackItem>
                    <TextLabel>Accrual Weekend Rule</TextLabel>
                    <TextValue data-testid='accrual-weekend-rule'>
                      {security.fundRules?.accrualWeekEndRule
                        ? displayMultiDayAccrualRule[
                            security.fundRules
                              ?.accrualWeekEndRule as keyof typeof displayMultiDayAccrualRule
                          ]
                        : undefined}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Accrual Month End Rule</TextLabel>
                    <TextValue data-testid='accrual-month-end-rule'>
                      {security.fundRules?.accrualMonthEndRule
                        ? displayMultiDayAccrualRule[
                            security.fundRules
                              ?.accrualMonthEndRule as keyof typeof displayMultiDayAccrualRule
                          ]
                        : undefined}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Dividend Accrual Rate Rule</TextLabel>
                    <TextValue data-testid='dividend-accrual-rate-rule'>
                      {security.divAccrualRateRule
                        ? displayDividendAccrualRateRule[
                            security.divAccrualRateRule as keyof typeof displayDividendAccrualRateRule
                          ]
                        : undefined}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Box>
              <DataTable
                columnDefs={[
                  {
                    field: 'rule',
                    headerName: 'Accrual Rules'
                  },
                  {
                    field: 'value'
                  }
                ]}
                disableLastRowBorder
                rowData={[
                  {
                    rule: 'Purchase Start',
                    value: security.fundRules?.divAccrualPurchaseStart
                  },
                  {
                    rule: 'Redemption Stop',
                    value: security.fundRules?.divAccrualRedemptionStop
                  },
                  {
                    rule: 'Partial Exchange In Start',
                    value: security.fundRules?.divAccrualPartialExchInStart
                  },
                  {
                    rule: 'Partial Exchange Out Stop',
                    value: security.fundRules?.divAccrualPartialExchOutStop
                  },
                  {
                    rule: 'Partial Transfer In Start',
                    value: security.fundRules?.divAccrualPartialTransferInStart
                  },
                  {
                    rule: 'Partial Transfer Out Stop',
                    value: security.fundRules?.divAccrualPartialTransferOutStop
                  },
                  {
                    rule: 'ACAT Transfer In Start',
                    value: security.fundRules?.divAccrualAcatTransferInStart
                  },
                  {
                    rule: 'ACAT Transfer Out Stop',
                    value: security.fundRules?.divAccrualAcatTransferOutStop
                  },
                  {
                    rule: 'Dividend DCCS Accrual Redemption Stop Rule',
                    value: security.fundRules?.divDccsAccrualRedemptionStop
                  }
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
