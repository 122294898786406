import { CardContent } from '@/components/card';
import { DataTable } from '@/components/data-table/DataTable.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { WorkflowContext } from '@/routes/suba/accounts/common/contexts/WorkflowContext';
import { DistributionService } from '@/services/suba/distributions/Distribution.service';
import formatters from '@/utils/Formatters';
import { Card, Divider, Unstable_Grid2 as Grid, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DistributionRequestQueueSubAccountsApiResource } from '@vestwell-sub-accounting/models/conductor/DistributionRequestQueueApiResource';

import { AxiosError } from 'axios';
import { useContext, useMemo } from 'react';

type DisbursementDetailCellRendererProps = {
  data: DistributionRequestQueueSubAccountsApiResource;
};

const DisbursementDetailCellRenderer = (
  props: DisbursementDetailCellRendererProps
) => {
  return (
    <Grid
      columns={14}
      container
      maxWidth='fit-content'
      mt={0}
      p={2}
      spacing={{ lg: 4, xs: 2 }}>
      <Grid lg py={4}>
        <Stack justifySelf='stretch' spacing={2}>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Apply Vesting</TextLabel>
              <TextValue>
                {formatters.displayCase(
                  (props.data.applyVesting || false).toString()
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Apply Forfeiture</TextLabel>
              <TextValue>
                {formatters.displayCase(
                  (props.data.forfeitureFlag || false).toString()
                )}
              </TextValue>
            </TextStackItem>
          </TextStack>
        </Stack>
      </Grid>
      <Grid xs='auto'>
        <Divider orientation='vertical' />
      </Grid>
      <Grid lg py={4}>
        <Stack alignItems='stretch' justifySelf='stretch' spacing={2}>
          <TextStack direction='column'>
            <TextStackItem>
              <TextLabel>Outstanding Loan Balance</TextLabel>
              <TextValue>
                {formatters.formatDollars(props.data.outstandingLoanBalance)}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Previous Partial Withdrawals</TextLabel>
              <TextValue>
                {formatters.formatDollars(props.data.previousPartialWithdrawal)}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Simple Vesting Percent</TextLabel>
              <TextValue>{props.data.simpleVestingPercent}</TextValue>
            </TextStackItem>
          </TextStack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export const DistributionSubAccountsTab = () => {
  const workflow = useContext(WorkflowContext);

  const detailCellRenderer = useMemo(() => {
    return DisbursementDetailCellRenderer;
  }, []);

  const distributionQuery = useQuery(
    ['DistributionService.getByTracerId', workflow.tracerId],
    () => DistributionService.getByTracerId(workflow.tracerId),
    {
      enabled: Boolean(workflow.tracerId),
      onError: (err: AxiosError) => {
        console.error(err.response?.data ? err.response.data : err.message);
      }
    }
  );

  return (
    <Card>
      <CardContent
        disablePadding
        overlayLoading={distributionQuery.isInitialLoading}>
        <DataTable
          columnDefs={[
            {
              cellRenderer: 'agGroupCellRenderer',
              width: 60
            },
            {
              field: 'subAccountId',
              headerName: 'Sub Account ID'
            },
            {
              field: 'subAccountTaxType',
              headerName: 'Tax Type',
              valueFormatter: ({ value }) => formatters.displayCase(value)
            },
            {
              field: 'fundingSource',
              valueFormatter: ({ value }) => formatters.displayCase(value)
            },
            {
              field: 'proratedAmount',
              type: 'numericColumn',
              valueFormatter: ({ value }) => formatters.formatDollars(value)
            },
            {
              field: 'createdAt',
              valueFormatter: ({ value }) => formatters.formatFromIsoDate(value)
            },
            {
              field: 'updatedAt',
              valueFormatter: ({ value }) => formatters.formatFromIsoDate(value)
            }
          ]}
          columnSizing='fit'
          detailCellRenderer={detailCellRenderer}
          detailRowAutoHeight
          rowData={distributionQuery.data?.subAccounts}
        />
      </CardContent>
    </Card>
  );
};
