import CircularLoading from '@/components/circular-loading';
import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParentAccountOrderDto } from '@/models/suba/parent-account-orders/ParentAccountOrderDTO.model';
import { ApiServiceError } from '@/services/Api.service';
import CurrentDatesAndWindowsService from '@/services/suba/current-dates-and-windows/CurrentDatesAndWindows.service';
import ParentAccountOrderService from '@/services/suba/parent-account-orders/ParentAccountOrder.service';
import PriceService, {
  PriceSearchResponse
} from '@/services/suba/prices/Price.service';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isSecurityTypeETF } from '@vestwell-sub-accounting/models/common/SecurityType';
import { ExecutionMethod } from '@vestwell-sub-accounting/models/orderManagement/ExecutionMethod';

import dayjs, { Dayjs } from 'dayjs';
import Decimal from 'decimal.js';
import { Field, Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { isTradingDay } from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

import { ParentAccountOrderInfoBanner } from './ParentAccountOrderInfoBanner.component';

export type ExecuteParentAccountOrderFormValues = {
  tradeDate: string;
  executionPrice: string;
  executionMethod: ExecutionMethod;
};

type ExecuteParentAccountOrderDialogProps = {
  initialValues?: Partial<ExecuteParentAccountOrderFormValues>;
  open: boolean;
  parentAccountOrder: ParentAccountOrderDto;
  onClose: () => void;
  onExecute?: () => void;
};

type ExecuteParentAccountOrderFormProps = {
  enabledExecutionPrice: boolean;
  setEnabledExecutionPrice: (value: boolean) => void;
  showClosePriceCheckbox?: boolean;
  error?: ApiServiceError;
};

export const ExecuteParentAccountOrderDialog: React.FC<
  ExecuteParentAccountOrderDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [queryTradeDate, setQueryTradeDate] = useState(
    props.parentAccountOrder.tradeDate
  );

  const [enabledExecutionPrice, setEnabledExecutionPrice] = useState(true);

  const executeParentAccountOrderMutation = useMutation(
    (params: ExecuteParentAccountOrderFormValues) => {
      return ParentAccountOrderService.execute({
        parentOrderId: String(props.parentAccountOrder.id),
        ...params
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed to manually execute parent account order ${props.parentAccountOrder.ourParentOrderId}`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `The selected order has been manually executed.`,
          severity: 'success'
        });

        queryClient.invalidateQueries({
          queryKey: [
            'ParentAccountService.getById',
            props.parentAccountOrder.id
          ]
        });

        queryClient.invalidateQueries({
          queryKey: ['ParentAccountOrderService.search']
        });

        props.onClose();
        if (props.onExecute) {
          props.onExecute();
        }
      }
    }
  );

  const getPricesQuery = useQuery(
    [
      'PriceService.search',
      props.parentAccountOrder.security.cusip,
      queryTradeDate
    ],
    () =>
      PriceService.search({
        cusip: props.parentAccountOrder.security.cusip,
        effectiveEndDate: queryTradeDate,
        effectiveStartDate: queryTradeDate
      }),
    {
      onError: () => {
        showSnackbar({
          message: `Failed to fetch price for cusip ${props.parentAccountOrder.security.cusip} on ${queryTradeDate}`,
          severity: 'error'
        });
      },
      onSuccess: result => {
        if (
          isSecurityTypeETF(props.parentAccountOrder.securityType) &&
          result.results.length === 0
        ) {
          // uncheck the close price checkbox if no price found
          setEnabledExecutionPrice(false);

          showSnackbar({
            message: `No price found for the cusip on this date`,
            severity: 'warning'
          });
        }
      }
    }
  );

  const getCurrentDatesAndWindowsQuery = useQuery(
    ['CurrentDatesAndWindowsService.get'],
    () => CurrentDatesAndWindowsService.get(),
    {
      cacheTime: 0, // keep response as fresh as we can
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch current trade date and processing windows: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const handleSubmit = (values: ExecuteParentAccountOrderFormValues) => {
    executeParentAccountOrderMutation.mutate({
      executionMethod: values.executionMethod,
      executionPrice: new Decimal(values.executionPrice).toFixed(2).toString(),
      tradeDate: values.tradeDate
    });
  };

  const validationSchema = yup.object({
    tradeDate: yup.string().when('isSecurityTypeETF', {
      is: false,
      then: yup
        .string()
        .test(
          'price-unavailable',
          `No price found for the cusip on this date`,
          async () => {
            const prices = queryClient.getQueryData<PriceSearchResponse>([
              'PriceService.search',
              props.parentAccountOrder.security.cusip,
              queryTradeDate
            ]);
            return prices?.results?.length > 0;
          }
        )
    })
  });

  const ExecuteParentAccountOrderForm: React.FC<
    ExecuteParentAccountOrderFormProps
  > = props => {
    const formikContext =
      useFormikContext<ExecuteParentAccountOrderFormValues>();

    const toggleEnabledExecutionPrice = () =>
      props.setEnabledExecutionPrice(!props.enabledExecutionPrice);

    const [errorMessage, setErrorMessage] = useState('');

    useDebounce(
      () => {
        formikContext.validateField('tradeDate');
        if (formikContext.values.tradeDate) {
          setQueryTradeDate(
            dayjs(formikContext.values.tradeDate).format('YYYY-MM-DD')
          );
        }
      },
      600,
      [formikContext.values.tradeDate]
    );

    const handleError = (
      errorReason: DateValidationError,
      value: dayjs.Dayjs | null
    ) => {
      if (!errorReason) {
        setErrorMessage('');
        return;
      }

      if (errorReason === 'shouldDisableDate') {
        if (!isTradingDay(value.format('YYYY-MM-DD'))) {
          setErrorMessage('As of date must be a trading day');
        }
      }
    };

    return (
      <>
        <Stack direction='row' spacing={2}>
          <FormControl error={Boolean(formikContext.errors.tradeDate)}>
            <Field
              as={DatePicker}
              autoComplete='off'
              data-testid='tradeDate-picker'
              disabled
              errorMessage={errorMessage}
              handleError={handleError}
              label='Trade Date'
              name='tradeDate'
              shouldDisableDate={(date: Dayjs) =>
                !isTradingDay(date.format('YYYY-MM-DD'))
              }
              size='small' // FormControl doesn't pass to our DatePicker
              variant='outlined'
            />
            <FormHelperText>
              {(!errorMessage && formikContext.errors.tradeDate) || ' '}
            </FormHelperText>
          </FormControl>
          <FormControl
            disabled={props.enabledExecutionPrice}
            error={
              formikContext.touched.executionPrice &&
              Boolean(formikContext.errors.executionPrice)
            }
            size='small'>
            <InputLabel htmlFor='execution-price'>Execution Price</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              data-testid='execution-price-input'
              id='execution-price'
              label='Execution Price'
              name='executionPrice'
              onChange={event => {
                // Only allow numeric input and keep trailing zeros
                const value = event.target.value;
                if (value === '' || /^[0-9]+(\.[0-9]{0,2})?$/.test(value)) {
                  formikContext.setFieldValue('executionPrice', value);
                }
              }}
              startAdornment={
                <InputAdornment position='start'>$</InputAdornment>
              }
              step='any'
              type='text'
            />
            <FormHelperText>
              {(formikContext.touched.executionPrice &&
                formikContext.errors.executionPrice) ||
                ' '}
            </FormHelperText>
          </FormControl>

          {props.showClosePriceCheckbox && (
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.enabledExecutionPrice}
                    inputProps={{
                      // @ts-expect-error: Mui is missing types
                      'data-testid':
                        'use-close-price-for-select-trade-date-checkbox'
                    }}
                  />
                }
                label='Use close price for select trade date'
                name='useClosePriceForSelectTradeDate'
                onChange={toggleEnabledExecutionPrice}
              />
            </FormControl>
          )}
        </Stack>
        <FormControl>
          <FormLabel>Reason</FormLabel>
          <RadioGroup
            data-testid='excecution-method-radio-group'
            name='executionMethod'
            value={formikContext.values.executionMethod}>
            <FormControlLabel
              control={<Radio />}
              data-testid='execution-method-manual'
              label='Order was already executed at Custodian / Fund Company'
              onChange={formikContext.handleChange}
              value={ExecutionMethod.Manual}
            />
            <FormControlLabel
              control={<Radio />}
              data-testid='execution-method-asOf'
              label='Execute as-of with Offset to Error'
              onChange={formikContext.handleChange}
              value={ExecutionMethod.AsOf}
            />
          </RadioGroup>
        </FormControl>

        {props.error && <Alert severity='error'>{props.error.message}</Alert>}
      </>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        executionMethod: ExecutionMethod.Manual,
        executionPrice: new Decimal(
          getPricesQuery.data?.results[0]?.closePrice || 0
        )
          .toFixed(2)
          .toString(),
        isSecurityTypeETF: isSecurityTypeETF(
          props.parentAccountOrder.securityType
        ),
        tradeDate: dayjs(queryTradeDate).format('MM/DD/YYYY')
      }}
      onSubmit={(values: ExecuteParentAccountOrderFormValues) =>
        handleSubmit(values)
      }
      validateOnMount
      validationSchema={validationSchema}>
      {formikProps => (
        <Dialog
          fullWidth
          maxWidth='md'
          onClose={() => {
            props.onClose();
          }}
          open={props.open}>
          <DialogTitle>Manually Execute Parent Account Order</DialogTitle>
          <ParentAccountOrderInfoBanner
            parentAccountOrderId={props.parentAccountOrder.id}
          />
          <DialogContent>
            <Box
              aria-busy={getCurrentDatesAndWindowsQuery.isInitialLoading}
              data-testid='execute-summary'
              m={1}>
              {getCurrentDatesAndWindowsQuery.isInitialLoading ? (
                <CircularLoading size='40px' />
              ) : (
                <ExecuteParentAccountOrderForm
                  enabledExecutionPrice={enabledExecutionPrice}
                  error={executeParentAccountOrderMutation.error}
                  setEnabledExecutionPrice={setEnabledExecutionPrice}
                  showClosePriceCheckbox={isSecurityTypeETF(
                    props.parentAccountOrder.securityType
                  )}
                />
              )}
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Stack direction='row' m={1} spacing={2}>
              <Button
                data-testid='cancel-button'
                onClick={() => {
                  props.onClose();
                }}>
                Cancel
              </Button>
              <LoadingButton
                data-testid='execute-button'
                disabled={!formikProps.isValid || getPricesQuery.isFetching}
                loading={executeParentAccountOrderMutation.isLoading}
                onClick={() => formikProps.handleSubmit()}
                variant='contained'>
                Execute
              </LoadingButton>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
