import SimpleUpload from '@/components/simple-upload';
import { useDialog } from '@/contexts/DialogContext';
import { ConversionValidationResponse } from '@/models/BulkCashAllocation.model';
import { CONVERSION_TYPE_MAP } from '@/models/ConversionDTO.model';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  Typography
} from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';

import { FC, useCallback, useState } from 'react';

interface ActionCenterDetailsBulkCashAllocationProps {
  handleSubmit: UseMutationResult<
    ConversionValidationResponse,
    unknown,
    { formData: FormData; documentType: string }
  >;
  documentType: string;
}

export const ActionCenterDetailsCashAllocation: FC<
  ActionCenterDetailsBulkCashAllocationProps
> = props => {
  const { openDialog, closeDialog } = useDialog();
  const [fileName, setFileName] = useState<string | null>(null);

  const getTitle = useCallback(() => {
    return CONVERSION_TYPE_MAP.get(props.documentType).replace(
      /([a-z])([A-Z])/g,
      '$1 $2'
    );
  }, [props.documentType]);

  const onSelect = useCallback(
    async files => {
      if (Array.isArray(files)) {
        const formData = new FormData();
        setFileName(files[0].name);
        formData.append('csvFile', files[0], files[0].name);
        const results = await props.handleSubmit.mutateAsync({
          documentType: props.documentType,
          formData
        });

        setFileName(null);

        if (results.errors.length === 0) {
          return;
        }

        openDialog({
          customContent: (
            <>
              <DialogTitle>{getTitle()} Bulk Upload Errors</DialogTitle>
              <DialogContent>
                <Stack spacing={2}>
                  {results.errors.map((value, index) => (
                    <Typography key={index}>{value.message}</Typography>
                  ))}
                </Stack>
                <DialogActions>
                  <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
              </DialogContent>
            </>
          ),
          dialogProps: {
            maxWidth: 'md'
          }
        });
      }
    },
    [props.documentType]
  );

  return (
    <div data-testid='action-center-details-filter-bulk-cash-allocation'>
      <FormControl fullWidth size='small'>
        <Typography mb={2} variant='body2'>
          Upload {getTitle().toLocaleLowerCase()} file below.
        </Typography>
        <SimpleUpload
          accept={{ 'text/csv': ['.csv'] }}
          data-testid='upload-csv-file'
          onSelect={onSelect}
          selectRawFiles
          style={{
            marginBottom: '15px'
          }}>
          <Button
            data-testid='details-file-upload-button'
            fullWidth
            startIcon={<FileUploadOutlinedIcon />}
            variant='contained'>
            Upload
          </Button>
          <Typography
            sx={{
              color: theme => theme.palette.text.secondary,
              display: 'block',
              marginTop: '10px'
            }}
            variant='caption'>
            {fileName || 'No file chosen'}
          </Typography>
        </SimpleUpload>
      </FormControl>
    </div>
  );
};
