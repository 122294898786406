import { Box, Divider, Stack } from '@mui/material';

import { Field, Form, FormikProps, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { isFunction } from 'lodash';
import React, { FC } from 'react';

import { FieldOptions, FieldOptionsConfig } from '../../contexts/DialogContext';

const renderField = (
  fieldOptions: FieldOptionsConfig,
  formProps: FormikProps<Record<string, any>>,
  name: string,
  key: string
) => {
  return (
    <React.Fragment key={key}>
      {!fieldOptions.isFieldAlwaysHidden &&
        ('component' in fieldOptions ? (
          isFunction(fieldOptions.component) ? (
            fieldOptions.component(formProps.values, formProps.errors)
          ) : (
            fieldOptions.component
          )
        ) : (
          <Field
            InputLabelProps={{
              'data-testid': fieldOptions.dataLabelTestId
            }}
            component={TextField}
            fullWidth
            inputProps={{
              'data-testid': fieldOptions.dataInputTestId
            }}
            label={fieldOptions.label}
            sx={fieldOptions.hidden ? { display: 'none' } : {}}
            value={formProps.values[name] || ''}
            {...fieldOptions.fieldProps}
            disabled={!!fieldOptions.isFieldDisabled}
            helperText={fieldOptions.helperText}
            name={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (fieldOptions.validate) {
                if (fieldOptions.validate(fieldOptions.maxLength, e)) {
                  formProps.handleChange(e);
                }
              } else {
                formProps.handleChange(e);
              }
            }}
            required={!!fieldOptions.isFieldRequired}
          />
        ))}
    </React.Fragment>
  );
};

type FormFieldsProps = {
  fields: FieldOptions<string>;
  stepNumber?: number;
  'data-testid'?: string;
};

export const FromFields: FC<FormFieldsProps> = props => {
  const formikCtx = useFormikContext<Record<string, any>>();

  const groupedFields = Object.entries(props.fields ?? {}).reduce(
    (groupedFields, [fieldName, fieldOptions], i) => {
      const groupLabel = fieldOptions.groupLabel || `Non-grouped ${i}`;

      if (!groupedFields[groupLabel]) {
        groupedFields[groupLabel] = [];
      }

      groupedFields[groupLabel].push([fieldName, fieldOptions]);

      return groupedFields;
    },
    {} as Record<string, [string, FieldOptionsConfig][]>
  );

  return (
    <Form data-testid={props['data-testid']}>
      <Stack mb={2} spacing={2}>
        {Object.entries(groupedFields).map(([groupLabel, fields]) => {
          const isGrouped = !groupLabel.startsWith('Non-grouped');

          const isGroupShown = fields.some(([, fieldOptions]) => {
            const showWhen = fieldOptions.showWhen || (() => true);

            return showWhen(formikCtx.values);
          });

          return !isGroupShown ? (
            <React.Fragment key={groupLabel} />
          ) : (
            <Box key={groupLabel}>
              {isGrouped && (
                <Divider sx={{ mb: 3 }} textAlign='left'>
                  {groupLabel}
                </Divider>
              )}

              <Stack direction={isGrouped ? 'row' : 'column'} spacing={2}>
                {fields.map(([name, fieldOptions]) => {
                  const key = `${props.stepNumber || 0}-${name}`;
                  const showWhen = fieldOptions.showWhen || (() => true);

                  return !showWhen(formikCtx.values) ? (
                    <React.Fragment key={key} />
                  ) : (
                    renderField(fieldOptions, formikCtx, name, key)
                  );
                })}
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Form>
  );
};
