import CopyToClipboard from '@/components/copy-to-clipboard';
import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import AlertService from '@/services/suba/alerts/Alert.service';
import formatters from '@/utils/Formatters';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Link as LinkIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  Fab,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { getExpandedAlertDetailsComponent } from './alert-expanded-details/getExpandedAlertDetailsComponent';

const DRAWER_WIDTH = 480;
const EXPANDED_DRAWER_WIDTH = 1120;

type AlertDetailsDrawerProps = React.PropsWithChildren<{
  alertId?: number;
  hasWritePermissions?: boolean;
  open: boolean;
  onClose: () => void;
}>;
export const AlertDetailsDrawer = (
  props: AlertDetailsDrawerProps
): JSX.Element => {
  const { children, hasWritePermissions, open, onClose } = props;
  const [alertId, setAlertId] = useState(props.alertId);
  const [showExpandedDetails, setShowExpandedDetails] = useState(false);
  const { showSnackbar } = useSnackbar();
  const muiTheme = useTheme();

  // copy alert ID to local state so it is persisted when parent state changes such that alertId is undefined
  useEffect(() => {
    if (props.alertId) setAlertId(props.alertId);
  }, [props, setAlertId]);

  const { data: alert } = useQuery(
    ['AlertService.getById', alertId],
    () => AlertService.getById(Number(alertId)),
    {
      enabled: Boolean(alertId),
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Could not load alert: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  useEffect(() => {
    if (!open) setShowExpandedDetails(false);
  }, [open, setShowExpandedDetails]);

  const ExpandedAlertDetails =
    hasWritePermissions && alert
      ? getExpandedAlertDetailsComponent(alert)
      : null;

  const expandedDrawerTransition = muiTheme.transitions.create('width');

  return (
    <AlertContext.Provider value={alert}>
      <Drawer
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'row',
            // control overflow on child panes to avoid Fab positioning issues
            maxWidth: '100%',

            overflow: 'inherit',
            width: showExpandedDetails ? EXPANDED_DRAWER_WIDTH : DRAWER_WIDTH
          }
        }}
        SlideProps={{
          onEntered: node =>
            (node.style.transition = node.style.transition
              ? `${node.style.transition},${expandedDrawerTransition}`
              : expandedDrawerTransition)
        }}
        anchor='right'
        data-testid='alert-detail-drawer'
        onClose={() => {
          if (typeof onClose === 'function') onClose();
        }}
        open={open}
        sx={{
          zIndex: theme => theme.zIndex.vestwellAppBar + 1
        }}>
        <Fab
          aria-label='close'
          color='inherit'
          onClick={() => {
            if (typeof onClose === 'function') onClose();
          }}
          size='small'
          sx={{
            background: '#fff',
            fontSize: 20,
            height: 28,
            left: '-14px',
            lineHeight: 1,
            minHeight: 28,
            p: 0.5,
            position: 'absolute',
            top: '24px',
            verticalAlign: 'middle',
            width: 28
          }}>
          <ChevronRightIcon fontSize='inherit' />
        </Fab>
        <Box
          display='grid'
          gridTemplateColumns={`${DRAWER_WIDTH}px 1fr`}
          minHeight='100vh'
          // minWidth maintains content width during transition (while parent Paper width changes)
          minWidth={
            ExpandedAlertDetails ? EXPANDED_DRAWER_WIDTH : DRAWER_WIDTH
          }>
          <Box overflow='auto' position='relative'>
            {!alert && <LinearLoading />}
            {alert && (
              <>
                <Box p={3}>
                  <Box
                    data-testid='alert-detail-header'
                    display='flex'
                    justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={0.5}>
                      <Link
                        component={RouterLink}
                        state={{
                          search: window.location.search
                        }}
                        to={`/ops/alerts/${alert?.id}`}>
                        View Page
                      </Link>
                      <CopyToClipboard
                        copyName='Alert Link'
                        copyValue={`${window.location.origin}/ops/alerts/${alert?.id}`}
                        icon={<LinkIcon fontSize='inherit' />}
                        size='small'
                      />
                      {ExpandedAlertDetails && (
                        <Button
                          data-testid='alert-drawer-details-button'
                          endIcon={
                            showExpandedDetails ? (
                              <ChevronLeftIcon />
                            ) : (
                              <ChevronRightIcon />
                            )
                          }
                          onClick={() =>
                            setShowExpandedDetails(!showExpandedDetails)
                          }
                          sx={{
                            position: 'absolute',

                            // 16px gutter from Figma with Button padding subtracted
                            right: 14,

                            // 34px distance from top from Figma with Button padding subtracted
                            top: 23
                          }}
                          variant='text'>
                          Details
                        </Button>
                      )}
                    </Stack>
                  </Box>
                  <Box>
                    <Typography role='heading' variant='h5'>
                      {formatters.displayCase(alert?.alertName) || 'Alert'}
                    </Typography>
                    <Box
                      alignItems='center'
                      display='flex'
                      justifyContent='left'>
                      <Typography data-testid='detail_id' variant='subtitle1'>
                        ID: {alert?.id}
                      </Typography>
                      <CopyToClipboard
                        copyName='Alert ID'
                        copyValue={String(alert?.id)}
                      />
                    </Box>
                  </Box>
                </Box>
                {children}
              </>
            )}
          </Box>
          {ExpandedAlertDetails && (
            <Box
              overflow='auto'
              p={2}
              sx={{
                backgroundColor: theme => theme.palette.grey[100]
              }}>
              <ExpandedAlertDetails />
            </Box>
          )}
        </Box>
      </Drawer>
    </AlertContext.Provider>
  );
};
