import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { useMemo } from 'react';

import { ActionCenterDetailsCashAllocation } from './ActionCenterDetailsBulkCashAllocation.component';
import { BulkActionsConfig } from './useBulkActionCenterConfig.hook';

export const useBulkUploadCashAllocationConfig = (
  showSnackbar: (options: SnackbarOptions) => void,
  queryClient: QueryClient,
  documentType: string
) => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_BULK_UPLOAD_ACTIONS]
  });

  const mutation = useMutation(
    [`ActionCenterService.bulkUploadConversion(${documentType})`],
    (data: { formData: FormData; documentType: string }) => {
      return ActionCenterService.bulkUploadConversion(
        data.formData,
        data.documentType
      );
    },
    {
      onError: () =>
        showSnackbar({
          message:
            'There was an error while validating the file, please try again',
          severity: 'error'
        }),
      onSuccess: data => {
        queryClient.refetchQueries();
        if (data?.errors?.length === 0) {
          showSnackbar({
            message: 'File uploaded succesfully',
            severity: 'success'
          });
        } else {
          showSnackbar({
            message: 'There was an error while validating the file',
            severity: 'error'
          });
        }
      }
    }
  );
  const bulkUploadFilesConfig = useMemo<BulkActionsConfig>(
    () => ({
      step2: (
        <ActionCenterDetailsCashAllocation
          documentType={documentType}
          handleSubmit={mutation}
        />
      ),
      step3: null,
      visible: permissions.isAllowed
    }),
    [mutation, permissions]
  );

  return bulkUploadFilesConfig;
};
