import { ConversionValues } from '@/consts/uploads';

interface ConversionContext {
  allocatedAmount?: number;
  forfeiture?: number;
  suspense?: number;
  tradeAmount?: number;
  userFileName?: string;
}

export enum ConversionStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Processed = 'Processed',
  PendingApproval = 'Pending Approval',
  PendingInvestment = 'Pending Investment',
  Failed = 'Failed',
  Complete = 'Complete',
  CompletedWithErrors = 'Completed with Errors'
}

export enum ConversionType {
  CashAllocation = 'CashAllocation',
  LoanConversion = 'LoanConversion',
  DeferralRates = 'DeferralRates',
  RothCostBasis = 'RothCostBasis',
  HistoricalYearsOfService = 'HistoricalYearsOfService',
  InvestmentElectionMappings = 'InvestmentElectionMappings'
}

export const DOCUMENT_TYPES = {
  CASH_ALLOCATION: 'cash-allocation',
  DEFERRAL_RATES: 'deferral-rates',
  HISTORICAL_YEARS_OF_SERVICE: 'historical-years-of-service',
  INVESTMENTS_FUND_LINEUPS: 'investments-fund-lineups',
  INVESTMENTS_MODELS: 'investments-models',
  INVESTMENT_ELECTION_MAPPINGS: 'investment-election-mappings',
  LOANS: 'loans',
  ROTH_COST_BASIS: 'roth-cost-basis'
};

export const CONVERSION_TYPE_MAP = new Map<string, ConversionType>([
  [DOCUMENT_TYPES.CASH_ALLOCATION, ConversionType.CashAllocation],
  [DOCUMENT_TYPES.DEFERRAL_RATES, ConversionType.DeferralRates],
  [
    DOCUMENT_TYPES.HISTORICAL_YEARS_OF_SERVICE,
    ConversionType.HistoricalYearsOfService
  ],
  [
    DOCUMENT_TYPES.INVESTMENT_ELECTION_MAPPINGS,
    ConversionType.InvestmentElectionMappings
  ],
  [DOCUMENT_TYPES.LOANS, ConversionType.LoanConversion],
  [DOCUMENT_TYPES.ROTH_COST_BASIS, ConversionType.RothCostBasis]
]);

export type Conversion = {
  id?: number;
  fileName: string;
  documentId: number;
  conversionType: ConversionValues;
  status: ConversionStatus;
  context?: ConversionContext;
  approvedBy: any;
  submittedBy: any;
  submittedAt?: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  investmentElectionMappings?: {
    fundMappings: {
      newCusip: string;
      oldCusip: string;
    }[];
    participantData: {
      allocations: {
        allocatedAmount: number;
        cusip: string;
      }[];
      ssn: string;
      participantId: number;
    }[];
  };
};

export interface UpdateConversionDto {
  id?: number;
  documentId?: number;
  status?: ConversionStatus;
  context?: ConversionContext;
  isSubmitted?: boolean;
  isApproved?: boolean;
  version?: number;
}

export interface ConversionInvestmentsResponse {
  status: string;
  depositCode: string;
  amount: number;
  tracerId: string;
  participantId?: number;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  transactions: any;
}

export interface PageConversionResponse {
  data: PageConversionDto[];
  meta: { count: number };
}

export interface PageConversionDto {
  planId: number;
  planName: string;
  blackoutDate?: string;
  planAvailableBalance?: number;
  yos?: number;
  rothBasis?: number;
  cashAllocationStatus?: string;
  loanStatus?: string;
  totalAllocatedAmount?: number;
  allocatedParticipants?: number;
  cashAllocationFileOwners: string[];
  conversionComplete: boolean;
}

export type RothDepositReportDto = {
  amount: number;
  coreAccountId: string;
  firstName: string;
  lastName: string;
  participantId: number;
  rothCostBasis?: number;
  rothEstablishedDate?: string;
};

export type ConversionValidationError = {
  message: string;
  type: string;
};

export type ConversionResultDto = {
  conversionId?: number;
  errors?: ConversionValidationError[];
  fileName?: string;
  resultsCount?: number;
};
