import Badge from '@/components/badge';
import Card, { CardContent, CardPlaceholder } from '@/components/card';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import JSONViewer from '@/components/json-viewer';
import LinearLoading from '@/components/linear-loading';
import { Link } from '@/components/link';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useTitle } from '@/hooks/useTitle';
import { executionStatusColorMap } from '@/models/suba/workflows/ExecutionStatusColorMap.model';
import { TradeRequest } from '@/models/suba/workflows/TradeRequest.model';
import { TradeRequestWorkflowVariations } from '@/models/suba/workflows/TradeRequestWorkflowVariations.model';
import { workflowStatusColorMap } from '@/models/suba/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/suba/workflows/WorkflowStatusEnumObject.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { SubAccounts } from '@/routes/suba/accounts/common/components/SubAccounts.component';
import { SubAccountOrdersTab } from '@/routes/suba/accounts/common/components/tabs/SubAccountOrdersTab.route';
import { TransactionsTab } from '@/routes/suba/common/components/tabs/transactions/TransactionsTab.route';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { CodeOffOutlined, CodeOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  Collapse,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TradeCalculatorMethod } from '@vestwell-sub-accounting/models/common/TradeCalculatorMethod';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WorkflowContext } from '../common/contexts/WorkflowContext';
import { RelatedWorkflowsDialog } from './components/dialogs/RelatedWorkflowsDialog.component';
import { WorkflowStatusHistoryDialog } from './components/dialogs/WorkflowStatusHistoryDialog.component';
import { CustodianRequestsTab } from './components/tabs/CustodianRequestsTab.route';
import { DisbursementsTab } from './components/tabs/DisbursementsTab.route';
import { DistributionSubAccountsTab } from './components/tabs/DistributionSubAccountsTab.route';
import { RequestTab } from './components/tabs/RequestTab.route';
import { WorkflowDetailActions } from './components/WorkflowDetailActions.component';
import { WorkflowInputDetails } from './components/WorkflowInputDetails.component';

type WorkflowsDetailPageProps = {
  workflowId: string;
};

const GridJsonViewer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey['50'],
  fontSize: 12,
  height: 8 * 34,
  overflow: 'auto',
  padding: 8 * 2
}));

export const WorkflowsDetailPage: FC = () => {
  const { workflowId } = useParams<WorkflowsDetailPageProps>();
  const [errorStatus, setErrorStatus] = useState<number | null>(null);
  const [openWorkflowStatusHistoryDialog, setOpenWorkflowStatusHistoryDialog] =
    useState(false);
  const [openRelatedWorkflowsDialog, setOpenRelatedWorkflowsDialog] =
    useState(false);
  const [showJson, setShowJson] = useState(false);

  const workflowQuery = useQuery(
    ['WorkflowService.getById', workflowId],
    () => WorkflowService.getById(workflowId),
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
        if (err?.response?.status) setErrorStatus(err.response.status);
      },
      onSuccess: () => {
        setErrorStatus(null);
      },
      staleTime: Infinity
    }
  );

  // since there are variations of trade request that need special display logic,
  // we need to determine the workflow type based on workflowName and tradeCalculatorMethod from the input
  let workflowType: WorkflowName | TradeRequestWorkflowVariations | undefined =
    workflowQuery.data?.workflowName;

  const title = useTitle(
    `${formatters.displayCase(workflowType)} - Tracer ID: ${workflowQuery.data?.tracerId}`
  );

  if (workflowType === WorkflowName.tradeRequest) {
    const workflowInput = workflowQuery.data?.input as TradeRequest; // assertion because we know this is a trade request workflow
    const tradeCalculatorMethod = workflowInput?.tradeCalculatorMethod;
    if (tradeCalculatorMethod === TradeCalculatorMethod.RebalanceFull) {
      workflowType = TradeRequestWorkflowVariations.tradeRequestRebalance;
    }
    if (tradeCalculatorMethod === TradeCalculatorMethod.Flatten) {
      workflowType = TradeRequestWorkflowVariations.tradeRequestFlatten;
    }
    if (tradeCalculatorMethod === TradeCalculatorMethod.SpecificSecurities) {
      workflowType =
        TradeRequestWorkflowVariations.tradeRequestSpecificSecurities;
    }
    if (tradeCalculatorMethod === TradeCalculatorMethod.Swap) {
      workflowType = TradeRequestWorkflowVariations.tradeRequestSwap;
    }
  }

  let hasWritePermissions = false;
  if (workflowQuery.data?.workflowName === WorkflowName.investContribution) {
    hasWritePermissions = userService.hasPermission(
      FeatureLevelPermissions.WRITE_INVEST_CONTRIBUTION_EDIT
    );
  } else if (workflowQuery.data?.workflowName === WorkflowName.tradeRequest) {
    hasWritePermissions = userService.hasPermission(
      FeatureLevelPermissions.WRITE_TRADE_REQUEST_EDIT
    );
  } else if (workflowQuery.data?.workflowName === WorkflowName.newPlan) {
    hasWritePermissions = userService.hasPermission(
      FeatureLevelPermissions.WRITE_NEW_PLAN_EDIT
    );
  } else if (workflowQuery.data?.workflowName === WorkflowName.depositRequest) {
    hasWritePermissions = userService.hasPermission(
      FeatureLevelPermissions.WRITE_DEPOSIT_REQUEST_EDIT
    );
  }

  const getTabIdx = (availableTabs: TabData[]) => {
    const index = availableTabs.findIndex(
      tab => location.pathname === tab.path
    );

    return index < 0 ? 0 : index;
  };
  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => {
      const calculatedTabElements: TabData[] = [];
      // dont add tabs until data is loaded
      if (workflowType && workflowQuery.data) {
        // Sub Accounts tab conditionally added
        if (
          workflowType === WorkflowName.newPlan &&
          workflowQuery.data?.planId
        ) {
          calculatedTabElements.push({
            component: (
              <SubAccounts
                customDefaultFilters={{
                  accountType: [
                    SubAccountType.breakage,
                    SubAccountType.dividend,
                    SubAccountType.error,
                    SubAccountType.moneyIn,
                    SubAccountType.forfeiture,
                    SubAccountType.spendingBudget
                  ],
                  planId: workflowQuery.data.planId
                }}
                hideFilters
              />
            ),
            label: 'Sub Accounts',
            path: `/ops/workflows/${workflowQuery.data?.id}/sub-accounts`
          });
        }

        if (
          workflowQuery.data?.workflowName === WorkflowName.distributionRequest
        ) {
          calculatedTabElements.push(
            {
              component: <RequestTab />,
              label: 'Request',
              path: `/ops/workflows/${workflowQuery.data?.id}/request`
            },
            {
              component: <DistributionSubAccountsTab />,
              label: 'Sub Accounts',
              path: `/ops/workflows/${workflowQuery.data?.id}/sub-accounts`
            },
            {
              component: <DisbursementsTab />,
              label: 'Disbursements',
              path: `/ops/workflows/${workflowQuery.data?.id}/disbursements`
            }
          );
        }

        // Transactions tab conditionally added
        if (
          [
            WorkflowName.investContribution,
            WorkflowName.depositRequest,
            WorkflowName.distributionRequest,
            TradeRequestWorkflowVariations.tradeRequestRebalance,
            TradeRequestWorkflowVariations.tradeRequestSpecificSecurities,
            // no spec yet for flatten so not sure if it should have transactions tab but including it for now
            TradeRequestWorkflowVariations.tradeRequestFlatten,
            // no spec yet for swap so not sure if it should have transactions tab but including it for now
            TradeRequestWorkflowVariations.tradeRequestSwap
          ].includes(workflowType) &&
          workflowQuery.data?.parentAccount
        ) {
          const parentAccount = workflowQuery.data.parentAccount; // gets ts to stop complaining about possibly undefined
          calculatedTabElements.push({
            component: (
              <TransactionsTab
                accountId={parentAccount.parentAccountId}
                accountLevel={AccountLevel.SubAccount}
                customDefaultFilters={{
                  statuses: [],
                  tracerId: workflowQuery.data.tracerId
                }}
                data-testid='related-transactions-data-table'
                hideColumns={['accountId']}
                hideFilters
                hideHeader
                preventSearchInUrl
              />
            ),
            label: 'Transactions',
            path: `/ops/workflows/${workflowQuery.data?.id}/transactions`
          });
        }

        // Sub Account Orders tab conditionally added
        if (
          [
            WorkflowName.distributionRequest,
            WorkflowName.investContribution,
            TradeRequestWorkflowVariations.tradeRequestRebalance,
            TradeRequestWorkflowVariations.tradeRequestSpecificSecurities,
            // no spec yet for flatten so not sure if it should have transactions tab but including it for now
            TradeRequestWorkflowVariations.tradeRequestFlatten,
            // no spec yet for swap so not sure if it should have transactions tab but including it for now
            TradeRequestWorkflowVariations.tradeRequestSwap
          ].includes(workflowType) &&
          workflowQuery.data?.tracerId
        ) {
          calculatedTabElements.push({
            component: (
              <SubAccountOrdersTab
                customDefaultFilters={{
                  tracerId: workflowQuery.data.tracerId
                }}
                hideActions
                hideFilters
                hideHeader
              />
            ),
            label: 'Sub Account Orders',
            path: `/ops/workflows/${workflowQuery.data?.id}/sub-account-orders`
          });
        }

        // Pending Requests tab conditionally added
        if (
          (workflowType === WorkflowName.newPlan ||
            workflowType === WorkflowName.depositRequest) &&
          workflowQuery.data?.tracerId
        ) {
          calculatedTabElements.push({
            component: (
              <CustodianRequestsTab
                customDefaultFilters={{
                  tracerId: workflowQuery.data.tracerId
                }}
                hideFilters
                hideHeader
              />
            ),
            label: 'Custodian Requests',
            path: `/ops/workflows/${workflowQuery.data?.id}/pending-requests`
          });
        }
      }

      return calculatedTabElements;
    },
    [workflowQuery.data, workflowType]
  );

  const tabs: SimpleTabsProps = {
    defaultTabIdx: getTabIdx(tabElements),
    onChange: index => {
      title.setPageName(
        `${formatters.displayCase(workflowType)} ${tabElements[index].label} - Tracer ID: ${workflowQuery.data?.tracerId}`
      );
    },
    tabs: tabElements,
    tabsAriaLabel: 'workflow-tabs'
  };

  if (errorStatus === 404) {
    return redirectToErrorPage(new Error('404'));
  }

  // fetch friendly display name for workflowStatus
  const displayWorkflowStatus = formatters.getValueKey(
    WorkflowStatusEnumsObject,
    workflowQuery.data?.workflowStatus
  );

  // fetch friendly display name for workflow executionStatus
  const displayExecutionStatus = formatters.getValueKey(
    WorkflowExecutionStatusEnum,
    workflowQuery.data?.executionStatus
  );

  // breadcrumb items
  const paths = [{ name: 'Accounts', to: '/ops/accounts' }];
  if (workflowQuery.data?.parentAccount) {
    // format friendly parent account name
    const parentAccountName =
      workflowQuery.data?.parentAccount?.accountType === 'SuperOmnibus' ||
      workflowQuery.data?.parentAccount?.accountType === 'House'
        ? workflowQuery.data?.parentAccount?.accountName
        : workflowQuery.data?.plan?.name;

    paths.push({
      name:
        parentAccountName ||
        `ID: ${workflowQuery.data.parentAccount.parentAccountId}`,
      to: `/ops/accounts/${workflowQuery.data.parentAccount.parentAccountId}`
    });

    paths.push({
      name: 'Conductor Requests',
      to: `/ops/accounts/${workflowQuery.data.parentAccount.parentAccountId}/conductor`
    });
  }

  return (
    <WorkflowContext.Provider value={workflowQuery.data}>
      {workflowQuery.isInitialLoading && <LinearLoading />}
      {!workflowQuery.isInitialLoading &&
        workflowQuery.data &&
        workflowType && (
          <>
            <WorkflowStatusHistoryDialog
              onClose={() => {
                setOpenWorkflowStatusHistoryDialog(false);
              }}
              open={openWorkflowStatusHistoryDialog}
              workflowStatuses={workflowQuery.data.workflowStatuses}
            />
            <RelatedWorkflowsDialog
              onClose={() => {
                setOpenRelatedWorkflowsDialog(false);
              }}
              open={openRelatedWorkflowsDialog}
              relatedWorkflows={workflowQuery.data.relatedWorkflows}
            />
            <Stack spacing={4}>
              <Box data-testid='workflow-detail-header'>
                <NavigationBreadcrumbs
                  data-testid='workflow-detail-breadcrumbs'
                  paths={paths}
                />
                <Typography role='heading' variant='h4'>
                  {formatters.displayCase(workflowType)}
                </Typography>
                <Box alignItems='center' display='flex' justifyContent='left'>
                  <Typography
                    data-testid='detail-tracer-id'
                    variant='subtitle1'>
                    Tracer ID: {workflowQuery.data.tracerId}
                  </Typography>

                  <CopyToClipboard
                    copyName='Workflow Tracer ID'
                    copyValue={String(workflowQuery.data.tracerId)}
                  />
                </Box>
              </Box>
              {hasWritePermissions && workflowType && (
                <WorkflowDetailActions
                  workflow={workflowQuery.data}
                  workflowType={workflowType}
                />
              )}

              <Card data-testid='workflow-detail-main'>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid xs>
                      <Stack data-testid='workflow-detail-input' spacing={2}>
                        <Typography variant='subtitle1'>
                          Initiating Input
                        </Typography>
                        <WorkflowInputDetails />
                      </Stack>
                    </Grid>
                    <Grid xs='auto'>
                      <Divider orientation='vertical' />
                    </Grid>
                    <Grid xs>
                      <Stack data-testid='workflow-detail-output' spacing={2}>
                        <Typography variant='subtitle1'>Output</Typography>
                        <TextStack direction='column' divider>
                          <TextStackItem>
                            <TextLabel>Workflow Status</TextLabel>
                            <TextValue>
                              <Badge
                                color={
                                  workflowQuery.data.workflowStatus
                                    ? workflowStatusColorMap[
                                        workflowQuery.data.workflowStatus
                                      ]
                                    : 'neutral'
                                }>
                                {formatters.displayCase(displayWorkflowStatus)}
                              </Badge>
                              <Box>
                                <Link
                                  onClick={() =>
                                    setOpenWorkflowStatusHistoryDialog(true)
                                  }>
                                  View history
                                </Link>
                              </Box>
                            </TextValue>
                          </TextStackItem>

                          <TextStackItem>
                            <TextLabel>Execution Status</TextLabel>
                            <TextValue>
                              <Badge
                                color={
                                  workflowQuery.data.executionStatus
                                    ? executionStatusColorMap[
                                        workflowQuery.data.executionStatus
                                      ]
                                    : 'neutral'
                                }>
                                {formatters.displayCase(displayExecutionStatus)}
                              </Badge>
                            </TextValue>
                          </TextStackItem>

                          <TextStackItem>
                            <TextLabel>Related Executions</TextLabel>
                            <TextValue>
                              {Array.isArray(
                                workflowQuery.data.relatedWorkflows
                              ) &&
                                workflowQuery.data.relatedWorkflows.length >
                                  0 && (
                                  <Button
                                    color='primary'
                                    endIcon={<OpenInNewIcon />}
                                    onClick={() =>
                                      setOpenRelatedWorkflowsDialog(true)
                                    }
                                    size='small'>
                                    {
                                      workflowQuery.data.relatedWorkflows
                                        ?.length
                                    }{' '}
                                    executions
                                  </Button>
                                )}
                            </TextValue>
                          </TextStackItem>
                        </TextStack>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
                <Collapse in={showJson}>
                  <Divider />
                  <Grid alignItems='stretch' container>
                    <GridJsonViewer xs>
                      {workflowQuery.data.input && (
                        <Box data-testid='workflow-detail-input-json'>
                          <JSONViewer
                            collapseStringsAfterLength={100}
                            json={workflowQuery.data.input}
                          />
                        </Box>
                      )}
                      {!workflowQuery.data.input && (
                        <CardPlaceholder
                          data-testid='no-workflow-input'
                          subtitle='No input.'
                        />
                      )}
                    </GridJsonViewer>
                    <Grid xs='auto'>
                      <Divider orientation='vertical' />
                    </Grid>
                    <GridJsonViewer xs>
                      {workflowQuery.data.output && (
                        <Box data-testid='workflow-detail-output-json'>
                          <JSONViewer
                            collapseStringsAfterLength={100}
                            json={workflowQuery.data.output}
                          />
                        </Box>
                      )}
                      {!workflowQuery.data.output && (
                        <CardPlaceholder
                          data-testid='no-workflow-output'
                          subtitle='No output.'
                        />
                      )}
                    </GridJsonViewer>
                  </Grid>
                </Collapse>
                <Divider />
                <CardActions>
                  {showJson === true ? (
                    <Button
                      data-testid='hide-json-button'
                      onClick={() => setShowJson(false)}
                      startIcon={<CodeOffOutlined />}
                      variant='text'>
                      Hide JSON
                    </Button>
                  ) : (
                    <Button
                      data-testid='show-json-button'
                      onClick={() => setShowJson(true)}
                      startIcon={<CodeOutlined />}
                      variant='text'>
                      Show JSON
                    </Button>
                  )}
                </CardActions>
              </Card>

              {tabs.tabs.length > 0 && (
                <Box data-testid='workflow-related-record-tables'>
                  <SimpleTabs
                    data-testid='workflow-detail-tabs'
                    {...tabs}
                    style={{
                      border: '1px solid #e0e0e0',
                      borderBottom: '0px',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                      marginBottom: '0'
                    }}
                  />
                </Box>
              )}
            </Stack>
          </>
        )}
    </WorkflowContext.Provider>
  );
};
