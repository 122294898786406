import Badge from '@/components/badge/Badge.component';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanAdminStatusColorMap } from '@/models/suba/common/PlanAdminStatusColorMap.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import DepositPlanService from '@/services/suba/deposit-plans/DepositPlan.service';
import { Card, CardContent, CircularProgress, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import { forwardRef, useContext } from 'react';

import { CardHeader } from './CardHeader.component';
import { TransferFunds } from './TransferFunds.component';

type DepositPlanProps = {
  canChangePlan?: boolean;
  canTransferFunds?: boolean;
  planId?: string;
};

export const DepositPlan = forwardRef<HTMLDivElement, DepositPlanProps>(
  (props, ref) => {
    const { planId } = props;

    const alert = useContext(AlertContext);
    const { showSnackbar } = useSnackbar();

    const { data } = useQuery(
      ['DepositPlanService.get', planId],
      () => DepositPlanService.get(planId.toString()),
      {
        enabled: Boolean(planId),
        onError: (err: AxiosError) => {
          const message = err.response?.data ? err.response.data : err.message;

          showSnackbar({
            message: `Failed to get plan details: ${message}`,
            severity: 'error'
          });
        }
      }
    );

    return (
      <Card ref={ref} sx={{ pb: 1 }}>
        <CardHeader
          action={
            <Link
              data-testid='deposit-plan-link'
              target='_blank'
              to={`/plans/${planId}`}>
              View plan
            </Link>
          }
          title={
            <>
              Plan
              {data?.adminStatus && (
                <Badge
                  color={PlanAdminStatusColorMap[data.adminStatus]}
                  data-testid='deposit-plan-status'
                  size='small'>
                  {data.adminStatus}
                </Badge>
              )}
            </>
          }
        />
        <Divider />
        <CardContent>
          {alert === undefined ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <TextStack direction='column' sx={{ mb: 2 }}>
                <TextStackItem>
                  <TextLabel>Plan</TextLabel>
                  <TextValue
                    data-testid='deposit-plan-name'
                    links={
                      data?.parentAccountId
                        ? [
                            {
                              'data-testid': 'deposit-plan-parent-account-link',
                              label: `Parent Acct. ID: ${data?.parentAccountId}`,
                              target: '_blank',
                              to: `/ops/accounts/${data?.parentAccountId}`
                            }
                          ]
                        : undefined
                    }>
                    {data?.planName}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Conversion Type</TextLabel>
                  <TextValue data-testid='deposit-plan-conversion-type'>
                    {data?.isConversion ? 'Conversion' : 'Starter Plan'}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Previous Plan Provider</TextLabel>
                  <TextValue data-testid='deposit-plan-previous-plan-provider'>
                    {data?.priorProviderName}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <TransferFunds showCloseTicketPrompt />
            </>
          )}
        </CardContent>
      </Card>
    );
  }
);

DepositPlan.displayName = 'DepositPlan';
