import CusipTickerSearch from '@/components/cusip-ticker-search/CusipTickerSearch';
import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionTypeCodesByBaseType } from '@/hooks/suba/useTransactionTypeCodesByBaseType.hook';
import { PostableTransaction } from '@/models/suba/transactions/PostableTransaction.model';
import { TransactionDto } from '@/models/suba/transactions/TransactionDTO.model';
import { TransactionAccountSummary } from '@/routes/suba/common/components/TransactionAccountSummary.component';
import { ApiServiceError } from '@/services/Api.service';
import TransactionsService from '@/services/suba/transactions/Transaction.service';
import formatters from '@/utils/Formatters';
import { testDecimals } from '@/utils/validations/helpers/YupValidationFunctions';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { BalanceType } from '@vestwell-sub-accounting/models/common/BalanceType';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import dayjs, { Dayjs } from 'dayjs';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isTradingDay } from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

export type CreateTransactionFormValues = {
  amount?: string;
  balanceType: BalanceType | '';
  contributionYear?: string;
  cusip?: string;
  fee?: string;
  orderId?: string;
  securityUnitPrice?: string;
  tracerId?: string;
  tradeDate: string;
  transactionBaseType: TransactionBaseType | '';
  transactionTypeCode: TransactionTypeCode | '';
  units?: string;
};

type CreateTransactionDialogProps = DialogProps & {
  accountId: string;
  accountLevel: AccountLevel;
  custodianId: CustodianId;
  initialValues?: Partial<CreateTransactionFormValues>;
  parentAccountId?: string;
  parentAccountType: ParentAccountType;
  open: boolean;
  onClose: () => void;
};

yup.setLocale({
  mixed: {
    required: 'Required'
  }
});

const fieldsByTransactionBaseType = {
  [TransactionBaseType.Buy]: [
    'amount',
    'cusip',
    'fee',
    'orderId',
    'securityUnitPrice',
    'units'
  ],
  [TransactionBaseType.Deposit]: ['amount', 'contributionYear'],
  [TransactionBaseType.Fee]: ['amount'],
  [TransactionBaseType.FeeReversal]: ['amount'],
  [TransactionBaseType.IncomeIn]: ['amount', 'cusip'],
  [TransactionBaseType.IncomeOut]: ['amount', 'cusip'],
  [TransactionBaseType.Sell]: [
    'amount',
    'cusip',
    'fee',
    'orderId',
    'securityUnitPrice',
    'units'
  ],
  [TransactionBaseType.TransferIn]: ['cusip', 'units'],
  [TransactionBaseType.TransferOut]: ['cusip', 'units'],
  [TransactionBaseType.Withdrawal]: ['amount', 'contributionYear']
};

const validationSchema = yup.object({
  amount: yup.number().when('transactionBaseType', {
    is: (value: TransactionBaseType) =>
      (fieldsByTransactionBaseType[value] || []).includes('amount'),
    then: schema =>
      schema
        .required()
        .moreThan(-1, 'Must be a positive number')
        .test('is-decimal', 'Must be two decimal places', testDecimals(2))
  }),
  balanceType: yup.string().required(),
  cusip: yup
    .string()
    .when('transactionBaseType', {
      is: (value: TransactionBaseType) =>
        (fieldsByTransactionBaseType[value] || []).includes('cusip'),
      then: schema => schema.required()
    })
    .when('transactionBaseType', {
      is: (value: TransactionBaseType) =>
        value === TransactionBaseType.IncomeIn ||
        value === TransactionBaseType.IncomeOut,
      then: schema => schema.optional()
    }),
  fee: yup.number().when('transactionBaseType', {
    is: (value: TransactionBaseType) =>
      (fieldsByTransactionBaseType[value] || []).includes('fee'),
    then: schema =>
      schema
        .required()
        .moreThan(-1, 'Must be a positive number')
        .test('is-decimal', 'Must be two decimal places', testDecimals(2))
  }),
  securityUnitPrice: yup.number().when('transactionBaseType', {
    is: (value: TransactionBaseType) =>
      (fieldsByTransactionBaseType[value] || []).includes('securityUnitPrice'),
    then: schema =>
      schema
        .required()
        .moreThan(-1, 'Must be a positive number')
        .test('is-decimal', 'Must be two decimal places', testDecimals(2))
  }),
  tradeDate: yup.string().required(),
  transactionBaseType: yup.string().required(),
  transactionTypeCode: yup.string().required(),
  units: yup.number().when('transactionBaseType', {
    is: (value: TransactionBaseType) =>
      (fieldsByTransactionBaseType[value] || []).includes('units'),
    then: schema =>
      schema
        .required()
        .moreThan(-1, 'Must be a positive number')
        .test('is-decimal', 'Must be three decimal places', testDecimals(3))
  })
  // order ID and tracer ID omitted because they are optional
});

const TypeFieldGroup = () => {
  const { errors, setFieldValue, touched, values } =
    useFormikContext<CreateTransactionFormValues>();

  useEffect(() => {
    const { transactionBaseType } = values;

    const baseTypeFields =
      fieldsByTransactionBaseType[transactionBaseType as TransactionBaseType] ||
      [];

    const dynamicFields = [
      'amount',
      'cusip',
      'fee',
      'orderId',
      'securityUnitPrice',
      'units'
    ];

    Object.keys(values).forEach(key => {
      if (dynamicFields.includes(key) && !baseTypeFields.includes(key)) {
        setFieldValue(key, '');
      }
    });
  }, [setFieldValue, values]);

  const {
    data: transactionCodesByBaseType,
    isFetching: isFetchingTransactionTypeCodes
  } = useTransactionTypeCodesByBaseType();

  return (
    <Stack direction='row' spacing={2}>
      <FormControl size='small' sx={{ width: 200 }}>
        <InputLabel id='transaction-status-button-label'>Status</InputLabel>
        <Field
          MenuProps={{
            'data-testid': 'menu-status'
          }}
          as={Select}
          error={touched.balanceType && Boolean(errors.balanceType)}
          label='Status'
          labelId='transaction-status-button-label'
          name='balanceType'>
          {Object.keys(BalanceType).map(key => (
            <MenuItem
              key={key}
              value={BalanceType[key as keyof typeof BalanceType]}>
              {formatters.displayCase(key)}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      <FormControl size='small' sx={{ width: 200 }}>
        <InputLabel id='transaction-base-type-button-label'>
          Base Type
        </InputLabel>
        <Field
          MenuProps={{
            'data-testid': 'menu-transactionBaseType'
          }}
          as={Select}
          error={
            touched.transactionBaseType && Boolean(errors.transactionBaseType)
          }
          label='Base Type'
          labelId='transaction-base-type-button-label'
          name='transactionBaseType'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('transactionTypeCode', '');
            setFieldValue('transactionBaseType', event.target.value);
          }}>
          {Object.keys(TransactionBaseType).map(key => (
            <MenuItem
              key={key}
              value={
                TransactionBaseType[key as keyof typeof TransactionBaseType]
              }>
              {formatters.displayCase(key)}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
      {isFetchingTransactionTypeCodes ? (
        <Skeleton height={40} variant='rectangular' width={420} />
      ) : (
        <FormControl size='small' sx={{ minWidth: 256 }}>
          <InputLabel id='transaction-type-button-label'>
            Transaction Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-transactionTypeCode'
            }}
            as={Select}
            disabled={!values.transactionBaseType}
            error={
              touched.transactionTypeCode && Boolean(errors.transactionTypeCode)
            }
            label='Transaction Type'
            labelId='transaction-type-button-label'
            name='transactionTypeCode'
            sx={{ width: 420 }}>
            {transactionCodesByBaseType &&
              values.transactionBaseType &&
              (
                transactionCodesByBaseType[values.transactionBaseType] || []
              ).map((value: TransactionTypeCode) => (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(value)}
                </MenuItem>
              ))}
          </Field>
        </FormControl>
      )}
    </Stack>
  );
};

const TradeInfoFieldGroup = () => {
  const {
    errors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = useFormikContext<CreateTransactionFormValues>();

  const { transactionBaseType } = values;

  const baseTypeFields =
    fieldsByTransactionBaseType[transactionBaseType as TransactionBaseType] ||
    [];

  const showAmount = baseTypeFields.includes('amount');
  const showFee = baseTypeFields.includes('fee');
  const showCusip = baseTypeFields.includes('cusip');
  const showPrice = baseTypeFields.includes('securityUnitPrice');
  const showUnits = baseTypeFields.includes('units');

  return (
    <>
      <Divider sx={{ py: 1 }} textAlign='left'>
        Trade Info
      </Divider>
      <Fade in={showCusip} unmountOnExit>
        <CusipTickerSearch
          FormControlProps={{
            error: touched.cusip && Boolean(errors.cusip),
            sx: { width: 200 }
          }}
          FormHelperTextProps={{
            children: touched.cusip ? errors.cusip : undefined
          }}
          onChange={(value, { cusip }) => {
            setFieldTouched('cusip');
            setFieldValue('cusip', cusip);
          }}
          onError={error => {
            setFieldError('cusip', error?.message);
          }}
          summary
        />
      </Fade>
      <Fade in={showAmount || showFee || showPrice || showUnits} unmountOnExit>
        <Stack direction='row' spacing={2}>
          <Fade in={showUnits} unmountOnExit>
            <FormControl
              error={touched.units && Boolean(errors.units)}
              size='small'
              sx={{ width: 200 }}>
              <InputLabel htmlFor='units-input'>Units</InputLabel>
              <Field
                as={OutlinedInput}
                autoComplete='off'
                id='units-input'
                label='Units'
                name='units'
                step='any'
                type='number'
              />
              <FormHelperText>
                {(touched.units && errors.units) || ' '}
              </FormHelperText>
            </FormControl>
          </Fade>
          <Fade in={showPrice} unmountOnExit>
            <FormControl
              error={
                touched.securityUnitPrice && Boolean(errors.securityUnitPrice)
              }
              size='small'
              sx={{ width: 200 }}>
              <InputLabel htmlFor='price-input'>Price</InputLabel>
              <Field
                as={OutlinedInput}
                autoComplete='off'
                id='price-input'
                label='Price'
                name='securityUnitPrice'
                startAdornment={
                  <InputAdornment position='start'>$</InputAdornment>
                }
                step='any'
                type='number'
              />
              <FormHelperText>
                {(touched.securityUnitPrice && errors.securityUnitPrice) || ' '}
              </FormHelperText>
            </FormControl>
          </Fade>
          <Fade in={showFee} unmountOnExit>
            <FormControl
              error={touched.fee && Boolean(errors.fee)}
              size='small'
              sx={{ width: 200 }}>
              <InputLabel htmlFor='fee-input'>Fee</InputLabel>
              <Field
                as={OutlinedInput}
                autoComplete='off'
                id='fee-input'
                label='Fee'
                name='fee'
                startAdornment={
                  <InputAdornment position='start'>$</InputAdornment>
                }
                step='any'
                type='number'
              />
              <FormHelperText>
                {(touched.fee && errors.fee) || ' '}
              </FormHelperText>
            </FormControl>
          </Fade>
          <Fade in={showAmount} unmountOnExit>
            <FormControl
              error={touched.amount && Boolean(errors.amount)}
              size='small'
              sx={{ width: 200 }}>
              <InputLabel htmlFor='amount-input'>Amount</InputLabel>
              <Field
                as={OutlinedInput}
                autoComplete='off'
                id='amount-input'
                label='Amount'
                name='amount'
                startAdornment={
                  <InputAdornment position='start'>$</InputAdornment>
                }
                type='number'
              />
              <FormHelperText>
                {(touched.amount && errors.amount) || ' '}
              </FormHelperText>
            </FormControl>
          </Fade>
        </Stack>
      </Fade>
      <FormControl
        error={touched.tradeDate && Boolean(errors.tradeDate)}
        sx={{ alignSelf: 'flex-start' }}>
        <Field
          as={DatePicker}
          autoComplete='off'
          data-testid='tradeDate'
          disableFuture
          label='Trade Date'
          name='tradeDate'
          shouldDisableDate={(date: Dayjs) =>
            !isTradingDay(date.format('YYYY-MM-DD'))
          }
          size='small' // FormControl doesn't pass to our DatePicker
          sx={{ width: 200 }}
          variant='outlined'
        />
        <FormHelperText>
          {(touched.tradeDate && errors.tradeDate) || ' '}
        </FormHelperText>
      </FormControl>
    </>
  );
};

const MetaFieldGroup = () => {
  const { errors, setFieldValue, touched, values } =
    useFormikContext<CreateTransactionFormValues>();

  const [tradeYear, setTradeYear] = useState<string | undefined>('');
  const [previousTradeYear, setPreviousTradeYear] = useState<string>('');
  const { tradeDate, transactionBaseType } = values;

  useEffect(() => {
    if (!tradeDate) return;

    const newTradeYear = tradeDate.split('-').shift();

    const newPreviousTradeYear = (
      Number(tradeDate.split('-').shift()) - 1
    ).toString();

    if (tradeYear !== newTradeYear) {
      // reset contribution year if options have changed
      setFieldValue('contributionYear', '');
    }

    setTradeYear(newTradeYear);
    setPreviousTradeYear(newPreviousTradeYear);
  }, [tradeDate, tradeYear, setFieldValue]);

  const baseTypeFields =
    fieldsByTransactionBaseType[transactionBaseType as TransactionBaseType] ||
    [];

  const showContributionYear = baseTypeFields.includes('contributionYear');
  const showOrderId = baseTypeFields.includes('orderId');

  return (
    <>
      <Box sx={{ py: 1 }}>
        <Divider textAlign='left'>Meta</Divider>
        <Typography color={theme => theme.palette.grey[700]} variant='caption'>
          Not required
        </Typography>
      </Box>
      <Stack alignItems='flex-start' spacing={2}>
        <Fade in={showContributionYear} unmountOnExit>
          <FormControl size='small' sx={{ minWidth: 256 }}>
            <InputLabel id='contribution-year-button-label'>
              Contribution Year
            </InputLabel>
            <Field
              MenuProps={{
                'data-testid': 'menu-contributionYear'
              }}
              as={Select}
              error={
                touched.contributionYear && Boolean(errors.contributionYear)
              }
              label='Contribution Year'
              labelId='contribution-year-button-label'
              name='contributionYear'>
              <MenuItem key={previousTradeYear} value={previousTradeYear}>
                {previousTradeYear}
              </MenuItem>
              <MenuItem key={tradeYear} value={tradeYear}>
                {tradeYear}
              </MenuItem>
            </Field>
          </FormControl>
        </Fade>
        <Fade in={showOrderId} unmountOnExit>
          <FormControl size='small' sx={{ minWidth: 328 }}>
            <InputLabel htmlFor='order-id-input'>Order ID</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              error={touched.orderId && Boolean(errors.orderId)}
              id='order-id-input'
              label='Order ID'
              name='orderId'
            />
            <FormHelperText>
              {(touched.orderId && errors.orderId) || ' '}
            </FormHelperText>
          </FormControl>
        </Fade>
        <FormControl size='small' sx={{ minWidth: 328 }}>
          <InputLabel htmlFor='tracer-id-input'>Tracer ID</InputLabel>
          <Field
            as={OutlinedInput}
            autoComplete='off'
            error={touched.tracerId && Boolean(errors.tracerId)}
            id='tracer-id-input'
            label='Tracer ID'
            name='tracerId'
          />
          <FormHelperText>
            {(touched.tracerId && errors.tracerId) || ' '}
          </FormHelperText>
        </FormControl>
      </Stack>
    </>
  );
};

export const CreateTransactionDialog: React.FunctionComponent<
  CreateTransactionDialogProps
> = props => {
  const {
    accountId,
    accountLevel,
    custodianId,
    initialValues = {},
    open,
    parentAccountId,
    parentAccountType,
    onClose
  } = props;

  const [shouldPostToParentAccount, setShouldPostToParentAccount] =
    useState(true);
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const { error, isLoading, mutate } = useMutation<
    TransactionDto,
    ApiServiceError,
    PostableTransaction
  >(['TransactionsService.create'], data => TransactionsService.create(data), {
    onError: () => {
      showSnackbar({
        message: `Error creating transaction`,
        severity: 'error'
      });
    },
    onSuccess: data => {
      showSnackbar({
        message: `${capitalize(data.status)} transaction created`,
        severity: 'success'
      });
      // Invalidate all queries to ensure any related components reflect these changes
      queryClient.invalidateQueries();
      onClose();
    }
  });

  const handleSubmit = (data: CreateTransactionFormValues) => {
    if (
      // block if required enum values are empty (Select requires '' value missing in enum)
      data.balanceType === '' ||
      data.transactionBaseType === '' ||
      data.transactionTypeCode === ''
    ) {
      return;
    }

    const submission = {
      ...data,
      accountId,
      accountLevel,
      amount:
        data.amount === '' || data.amount === undefined
          ? undefined
          : parseFloat(data.amount).toFixed(2),
      balanceType: data.balanceType,
      contributionYear: data.contributionYear
        ? parseInt(data.contributionYear, 10)
        : undefined,
      custodianId,
      fee:
        data.fee === '' || data.fee === undefined
          ? undefined
          : parseFloat(data.fee).toFixed(2),
      parentAccountType,
      securityUnitPrice:
        data.securityUnitPrice === '' || data.securityUnitPrice === undefined
          ? undefined
          : formatters.formatDecimal(data.securityUnitPrice, 2),
      transactionBaseType: data.transactionBaseType,
      transactionTypeCode: data.transactionTypeCode,
      units:
        data.units === '' || data.units === undefined
          ? undefined
          : parseFloat(data.units).toFixed(3)
    };

    Object.entries(submission).forEach(([key, value]) => {
      if (value === '') delete submission[key as keyof typeof submission];
    });

    mutate(submission);

    if (
      accountLevel === AccountLevel.SubAccount &&
      parentAccountId &&
      shouldPostToParentAccount
    ) {
      mutate({
        ...submission,
        accountId: parentAccountId,
        accountLevel: AccountLevel.ParentAccount
      });
    }
  };

  return (
    <Formik
      initialValues={{
        amount: '',
        balanceType: '',
        contributionYear: '',
        cusip: '',
        fee: '',
        orderId: '',
        securityUnitPrice: '',
        tracerId: '',
        tradeDate: dayjs(new Date()).format('YYYY-MM-DD'),
        transactionBaseType: '',
        transactionTypeCode: '',
        units: '',
        ...initialValues
      }}
      onSubmit={(values: CreateTransactionFormValues) => handleSubmit(values)}
      validationSchema={validationSchema}>
      {({ handleSubmit: handleFormSubmit }) => (
        <Dialog fullWidth maxWidth='md' onClose={() => onClose()} open={open}>
          <DialogTitle>New Transaction</DialogTitle>
          <TransactionAccountSummary
            accountId={accountId}
            accountLevel={accountLevel}
          />
          <DialogContent
            sx={{
              paddingTop: 3
            }}>
            <Form data-testid='query-form'>
              <Stack spacing={2}>
                <TypeFieldGroup />
                <TradeInfoFieldGroup />
                <MetaFieldGroup />
                {error && <Alert severity='error'>{error.toString()}</Alert>}
              </Stack>
              {/* hidden submit button for keyboard submission without breaking Dialog overflow fixed title/actions */}
              <Box component='input' sx={visuallyHidden} type='submit' />
            </Form>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ px: 3, py: 2 }}>
            {accountLevel === AccountLevel.SubAccount && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldPostToParentAccount}
                    onChange={event =>
                      setShouldPostToParentAccount(event.target.checked)
                    }
                  />
                }
                label='Concurrently post to Parent Account'
                sx={{ mr: 'auto' }}
              />
            )}
            <Button disabled={isLoading} onClick={() => onClose()}>
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              onClick={() => handleFormSubmit()}
              type='submit'
              variant='contained'>
              Create
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
