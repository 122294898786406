import Badge from '@/components/badge';
import Card, { CardContent } from '@/components/card';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ContributionStatusColorMap } from '@/models/suba/alerts/ContributionStatusColorMap.model';
import { ParentAccountDto } from '@/models/suba/ParentAccountDTO.model';
import { AlertContext } from '@/routes/suba/common/contexts/AlertContext';
import { PlanService } from '@/services/Plan.service';
import ParentAccountService from '@/services/suba/accounts/ParentAccount.service';
import AlertService from '@/services/suba/alerts/Alert.service';
import WorkflowService from '@/services/suba/workflows/Workflow.service';
import formatters from '@/utils/Formatters';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';

import { AxiosError } from 'axios';
import { FC, useContext, useMemo } from 'react';

import { CardHeader } from '../common/CardHeader.component';

export const AchReversalExpandedSubmittedDeposit: FC = () => {
  const alert = useContext(AlertContext);
  const alertBody = alert.details.event.body[0];
  const queryClient = useQueryClient();

  const parentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      {
        custodianAccountNumber: alertBody.custodianAccountNumber,
        custodianId: alertBody.custodianId
      }
    ],
    (): Promise<ParentAccountDto> => {
      return ParentAccountService.get({
        custodianAccountNumber: alertBody.custodianAccountNumber,
        custodianId: alertBody.custodianId
      });
    }
  );

  const contributionsQuery = useQuery(
    ['PlanService.getContributions', Number(parentAccountQuery?.data?.planId)],
    async () => {
      if (!parentAccountQuery?.data?.planId) return [];
      return await PlanService.getContributions(
        Number(parentAccountQuery?.data?.planId)
      );
    }
  );

  const contributionByMatchingUcid = useMemo(() => {
    if (contributionsQuery?.data?.length) {
      const result = formatters
        .formatContributionsResponseForOpsGrid(contributionsQuery.data)
        .find(contribution => {
          return (
            contribution.ucid === alert.details.depositRequestContributionUcid
          );
        });
      return result;
    }
  }, [contributionsQuery.data, alert]);

  const workflowQuery = useQuery(
    ['WorkflowService.getById', alert.details.depositRequestContributionId],
    async () => {
      return WorkflowService.getById(
        `tracer:${alert.details.depositRequestTracerId}`
      );
    }
  );

  const latestPayrollSetupQuery = useQuery(
    [
      'PlanService.getLatestPlanPayrollSetupByName',
      contributionByMatchingUcid?.sponsorPlanId,
      contributionByMatchingUcid?.payGroupName
    ],
    async () => {
      if (contributionByMatchingUcid?.sponsorPlanId) {
        const setup = await PlanService.getLatestPlanPayrollSetupByName(
          contributionByMatchingUcid?.sponsorPlanId,
          contributionByMatchingUcid?.payGroupName
        );
        return setup || null;
      } else {
        return null;
      }
    }
  );

  const { showSnackbar } = useSnackbar();
  const alertStatusMutation = useMutation(
    ['AlertService.update'],
    (status: AlertStatus) =>
      AlertService.update(alert.id, { alertStatus: status }),
    {
      onError: (e: AxiosError) => {
        showSnackbar({
          message: e?.message || `Couldn't update alert status at this time`,
          severity: 'error'
        });
      },
      onSuccess: response => {
        queryClient.invalidateQueries(['AlertService.getById']);
        showSnackbar({
          message: `Alert status updated to ${response.alertStatus}`,
          severity: 'success'
        });
      }
    }
  );

  return (
    <Card data-testid='ach-reversal-expanded-submitted-deposit-card'>
      <CardHeader
        action={
          contributionByMatchingUcid && (
            <Link
              href={`/plans/${contributionByMatchingUcid?.sponsorPlanId}/contributions/${contributionByMatchingUcid?.ucid}`}
              target='_blank'>
              <Stack
                alignContent='center'
                direction='row'
                justifyContent='center'
                spacing={1}>
                <Typography variant='body1'>View</Typography>
                <Box component='span'>
                  <OpenInNewIcon sx={{ fontSize: 14 }} />
                </Box>
              </Stack>
            </Link>
          )
        }
        loading={
          contributionsQuery.isFetching ||
          workflowQuery.isFetching ||
          latestPayrollSetupQuery.isFetching
        }
        title={
          contributionByMatchingUcid ? (
            <>
              {formatters.formatFromIsoDateCustom(
                contributionByMatchingUcid?.payrollDate,
                'MM/DD/YYYY'
              )}{' '}
              Contribution
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='flex-between'
                spacing={0}>
                <Stack direction='row'>
                  <Badge
                    color={
                      ContributionStatusColorMap[
                        contributionByMatchingUcid?.processingStatus
                      ]
                    }
                    size='small'>
                    {contributionByMatchingUcid?.processingStatus}
                  </Badge>
                </Stack>
              </Stack>
            </>
          ) : (
            'Loading contribution...'
          )
        }></CardHeader>
      <Divider />
      <CardContent
        loading={
          contributionsQuery.isFetching ||
          workflowQuery.isFetching ||
          latestPayrollSetupQuery.isFetching
        }>
        <Stack direction='column' spacing={6}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextStack direction='column' spacing={1}>
                <TextStackItem>
                  <TextLabel>Amount</TextLabel>
                  <TextValue>
                    {formatters.formatDollars(
                      contributionByMatchingUcid?.total
                    )}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Contribution Date</TextLabel>
                  <TextValue>
                    {formatters.formatFromIsoDateCustom(
                      contributionByMatchingUcid?.payrollDate,
                      'MM/DD/YYYY'
                    )}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Grid>
            <Grid item>
              <Divider orientation='vertical' />
            </Grid>
            <Grid item xs>
              <TextStack direction='column' spacing={1}>
                <TextStackItem>
                  <TextLabel>Pay Group Name</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.payGroupName}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Bank Name</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.bankAccount?.bankName}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Account Number</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.bankAccount?.account}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Routing Number</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.bankAccount?.routingNumber}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Grid>
          </Grid>

          <Alert
            action={
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center'
                }}>
                <Button
                  onClick={() =>
                    alertStatusMutation.mutate(AlertStatus.Closed)
                  }>
                  Close Ticket
                </Button>
              </Box>
            }
            data-testid='ach-reversal-expanded-submitted-deposit-success-alert'
            icon={false}
            severity='success'
            variant='outlined'>
            <Stack direction='column' spacing={2}>
              <AlertTitle>
                <Typography variant='body1'>ACH has been submitted</Typography>
              </AlertTitle>
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Link
                  data-testid='ach-reversal-expanded-submitted-deposit-workflow-link'
                  href={`/ops/workflows/tracer:${alert.details.depositRequestTracerId}`}
                  target='_blank'>
                  tracerId: {alert.details.depositRequestTracerId}{' '}
                  <Box
                    component='span'
                    sx={{
                      lineHeight: 1,
                      pl: 0.5
                    }}>
                    <OpenInNewIcon sx={{ fontSize: 14 }} />
                  </Box>
                </Link>
                <Badge color='success' size='small'>
                  {formatters.displayCase(
                    workflowQuery.data?.executionStatus?.toLowerCase()
                  )}
                </Badge>
              </Stack>
            </Stack>
          </Alert>
        </Stack>
      </CardContent>
    </Card>
  );
};

AchReversalExpandedSubmittedDeposit.displayName =
  'AchReversalExpandedSubmittedDeposit';
