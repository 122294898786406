import { useSnackbar } from '@/contexts/SnackBarContext';
import renderers from '@/utils/DOMInteraction';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { IconButton, Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';

import React from 'react';
import { useCopyToClipboard } from 'react-use';

interface CopyToClipboardProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: React.ReactNode;
  /**
   * The value to be copied to the clipboard.
   * @default children | ''
   */
  copyValue?: string;
  /**
   * The display name used in place of the value to be copied in the tooltip and snackbar.
   * @default children | copyValue
   */
  copyName?: string;
  icon?: React.ReactNode;
  iconCssClass?: string;
  size?: 'small' | 'medium';
  tooltipSx?: SxProps;
}

const CopyToClipboard: React.FunctionComponent<CopyToClipboardProps> = (
  props: CopyToClipboardProps
) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const { showSnackbar } = useSnackbar();

  const {
    children,
    copyValue,
    copyName,
    icon,
    iconCssClass,
    size = 'medium',
    tooltipSx = {}
  } = props;

  let stringChildren = '';
  if (!copyValue || !copyName) {
    // render the children and get textContent property
    stringChildren = renderers.JSXToTextContent(<>{children}</>);
  }

  // if copyValue is undefined, use children converted from reactnode to string with fallback for blank
  const copyValueDisplay = copyValue || stringChildren || '';

  // if copyName is undefined, use children converted from reactnode to string with fallback for copy value
  const copyNameDisplay = copyName || stringChildren || copyValueDisplay;

  return (
    <Box
      sx={{
        display: 'inline-block',
        maxWidth: '100%'
      }}>
      <Stack alignItems='center' direction='row'>
        {children && (
          <Box
            sx={{
              '*': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              },
              color: theme => theme.palette.grey[600],
              fontSize: {
                medium: 14,
                small: 12
              }[size],
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {children}
          </Box>
        )}
        <IconButton
          aria-label='click-to-copy'
          disabled={Boolean(state.error)}
          onClick={() => {
            copyToClipboard(copyValueDisplay);
            showSnackbar({
              autoHideDuration: 1500,
              message: `${copyNameDisplay} copied!`,
              severity: 'success'
            });
          }}
          size={size}
          sx={{
            '&:hover .copy-to-clipboard-tooltip': {
              borderRadius: theme => theme.spacing(0.5),
              fontSize: '0.875rem',
              opacity: '1',
              pb: 1,
              pt: 1,
              visibility: 'visible',
              width: theme => theme.spacing(24),
              zIndex: theme => theme.zIndex.tooltip
            },
            color: theme => theme.palette.info.light,
            fontSize: {
              medium: 20,
              small: 16
            }[size],
            p: {
              medium: '5px',
              small: '4px'
            }[size]
          }}>
          {icon ? (
            icon
          ) : (
            <FileCopyOutlined className={iconCssClass} fontSize='inherit' />
          )}
          <Box
            className='copy-to-clipboard-tooltip'
            sx={{
              background: '#565656',
              color: 'white',
              fontWeight: 'bold',
              mb: theme => theme.spacing(0.5),
              opacity: '0',
              position: 'absolute',
              right: theme => theme.spacing(-9.9),
              top: theme => theme.spacing(4),
              transition: 'visibility 0s, opacity 0.25s linear',
              visibility: 'hidden',
              zIndex: theme => theme.zIndex.tooltip + 1,
              ...tooltipSx
            }}>
            Copy {copyNameDisplay}
          </Box>
        </IconButton>
      </Stack>
    </Box>
  );
};

export default CopyToClipboard;
export type { CopyToClipboardProps };
